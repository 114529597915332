import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ExtendedAutocompletePrediction, GoogleApiService} from '../../../services/google-api.service';
import {TranslateService} from '@ngx-translate/core';
import {IGoogleAddress} from '../../../models/user';
import {AutoCompleteComponent} from '../../ionic2-auto-complete';

export interface IInputAddressParams {
  addressType: string; // place/home/mailing
  map: boolean;

  formattedAddress: string; // "12 golden Hind psg, corte madera, CA 94340, USA"
  placeId: string;
  additionalDetails: string;

  rawAddress: string[];     // ["12 golden Hind psg", "corte madera", "CA 94340", "USA"]
  source: string;    // "google" | "user"

  isPhysical: boolean;
  isHome: boolean;
  isMailing: boolean;

  textSearch: string;
}

@Component({
  selector: 'app-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss'],
  providers: [GoogleApiService],
})
export class InputAddressComponent implements OnInit {

  @Input() address: IGoogleAddress;
  @Output() onChange: EventEmitter<IInputAddressParams> = new EventEmitter<IInputAddressParams>();

  @Input() params: IInputAddressParams;

  @Input() mode: string;
  @Input() type: string;
  @Input() placeholder: string;

  public placeholders = {
    street_address: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.STREET_ADDRESS'),
    apt: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.APT'),
    locality: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.LOCALITY'),
    region: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.STATE'),
    postalcode: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.ZIP'),
    country: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.COUNTRY')
  };

  public inputs: IUserInputAddress;

  public autoCompletePlaceholder: string;
  public showSearch: boolean = true;
  public showEdit: boolean;
  public inputDisable: boolean;
  public matcher: MyErrorStateMatcher;

  public view: string;

  public form: FormGroup;

  public firstInputDisplay = true;

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    public translate: TranslateService,
    public googleApi: GoogleApiService
  ) {
    this.showEdit = this.mode === 'view';
    this.inputDisable = this.mode !== 'view';

  }

  ngOnInit() {
    this.form = new FormGroup({
      street_address: new FormControl(
        {value: '', disabled: (this.mode !== 'view')},
        [Validators.required]),
      apt: new FormControl(
        {value: '', disabled: (this.mode !== 'view')},
        []),
      locality: new FormControl(
        {value: '', disabled: (this.mode !== 'view')},
        [Validators.required]),
      region: new FormControl(
        {value: '', disabled: (this.mode !== 'view')},
        [Validators.required]),
      postalcode: new FormControl(
        {value: '', disabled: (this.mode !== 'view')},
        [Validators.required])
    });

    this.googleApi.emptyItemText = this.translate.instant('ACTIVITY.ACTION.ADDRESS.CANNOT_FIND.TITLE');
    this.googleApi.emptyItemSubText = this.translate.instant('ACTIVITY.ACTION.ADDRESS.CANNOT_FIND.SUBTITLE');
    this.autoCompletePlaceholder = this.placeholder || this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.SEARCHBAR');

    switch (this.params.addressType) {
      case 'home':
        this.params.isHome = true;
        this.googleApi.queryFilter = ['street_address', 'premise', 'subpremise'];
        this.googleApi.emptyItem = true;
        break;

      case 'mailing':
        this.params.isMailing = true;
        this.showSearch = false;
        this.showEdit = false;
        this.params.placeId = '';
        break;
    }

    this.showEdit = this.mode === 'view';
    this.inputDisable = this.mode !== 'view';
    this.parseFormattedAddress();
  }

  public parseFormattedAddress() {
    if (!this.params.formattedAddress) {
      this.params.formattedAddress = '';
    }

    this.params.rawAddress = this.params.formattedAddress.split(',');
    if (this.params.rawAddress.length > 1) {
      this.showSearch = false;
    }

    const street_address = (this.params.rawAddress[0] || '').trim();
    const locality = (this.params.rawAddress[1] || '').trim();
    const region = ((this.params.rawAddress[2] || '').trim().split(' ')[0]) || '';
    const postalcode = ((this.params.rawAddress[2] || '').trim().split(' ')[1]) || '';
    const country = (this.params.rawAddress[3] || '').trim();

    this.inputs = {
      street_address,
      locality,
      region,
      postalcode,
      country
    };
  }

  public parseInputs() {
    this.params.rawAddress = [
      this.inputs.street_address,
      this.inputs.locality,
      this.inputs.region + ' ' + this.inputs.postalcode,
      this.inputs.country
    ];
    this.params.formattedAddress = this.params.rawAddress
      .filter(text => text)
      .map(text => text.trim())
      .join(',');
  }

  public selectOption(event: ExtendedAutocompletePrediction): void {
    if (!event.place_id || event.place_id === '') {
      this.selectUserInput();
      return;
    } else {
      this.selectPlaceId(event.place_id);
    }
  }

  private selectPlaceId(placeId: string): void {
    this.googleApi.getAddressDetails(placeId)
      .then((result) => {
        this.params.placeId = result.place_id;
        this.params.formattedAddress = result.formatted_address;

        this.params.isPhysical = true;
        this.showSearch = false;
        this.params.source = 'google';

        this.parseFormattedAddress();
        this.onChange.emit(this.params);
      }).catch((error: any) => {
      console.error(error);
    });
  }

  private selectUserInput(): void {
    this.params.placeId = '';
    this.showSearch = false;
    this.params.isPhysical = false;
    this.updateUserInput();
  }

  /*
      private loadPlaceId(locationDescription: string): void {
          this.googleApi.getLocalityPredictions(locationDescription)
              .then((result: ExtendedAutocompletePrediction[]) => {
                  this.loadPlaceInfos(result[0].place_id);
              }).catch((error: any) => {
                  console.error(error);
              });
      }
  */

  public updateUserInput() {
    this.params.source = 'user';
    this.params.placeId = '';
    this.parseInputs();
    this.onChange.emit(this.params);
  }

  public onClickIonCard() {
    if (this.type === 'show' && this.params.placeId !== '') {
      window.open('http://maps.google.com/?q=' + this.params.formattedAddress, '_blank');
    }
  }

  public onClickEdit() {
    this.firstInputDisplay = false;
    this.showSearch = true;
    this.params.placeId = '';
    this.params.source = '';
    this.params.textSearch = this.inputs.street_address;
    this.onChange.emit(Object.assign(this.params, {
      formattedAddress: '', // "12 golden Hind psg, corte madera, CA 94340, USA"
      placeId: '',
      additionalDetails: '',
    }));
  }

  public onChangeAdditionalDetails() {
    this.onChange.emit(this.params);
  }

  public onSuggestionClick(event: any, autoCompleteCtrl: AutoCompleteComponent, selectionData: any) {
    this.stopEvent(event);
    autoCompleteCtrl.select(selectionData);
  }

  public stopEvent(event) {
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  public validate(): { [field: string]: any } {
    Object.keys(this.form.controls).forEach(control => {
      this.form.get(control).updateValueAndValidity();
    });
    return Object.keys(this.form.controls)
      .reduce((acc, control) => {
        acc[control] = this.form.get(control).errors;
        return acc;
      }, {});
  }
}

export interface IUserInputAddress {
  street_address: string;
  locality: string;
  region: string;
  postalcode: string;
  country: string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.enabled));
  }
}
