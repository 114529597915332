import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailImagesFormatterService {

  constructor() {
  }

  public async formatImagesForEmailCompatibility(quillRoot: HTMLDivElement): Promise<string> {
    const clone: HTMLDivElement = quillRoot.cloneNode(true) as HTMLDivElement;
    let imgElements: NodeListOf<Element> = clone.querySelectorAll('p > img');
    for (let index: number = 0; index < imgElements.length; index++) {
      const element: HTMLImageElement = imgElements[index] as HTMLImageElement;
      await new Promise<void>((resolve, reject) => {
        element.addEventListener('load', (e) => {
          resolve();
        });
      });
      element.setAttribute('data-naturalheight', element.naturalHeight.toString());
      element.setAttribute('data-naturalwidth', element.naturalWidth.toString());
      const paragraphElement: Node & ParentNode = element.parentNode;
      const rootElement: Node & ParentNode = paragraphElement.parentNode;
      rootElement.insertBefore(element, paragraphElement);
    }
    return clone.innerHTML;
  }
}
