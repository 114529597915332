import { Injectable } from '@angular/core';
import { CognitoAuthService, IAuthChallengeSubjectPayload } from './cognito-auth.service';
import { Router } from '@angular/router';
import { MainLoaderHandlerService } from './main-loader-handler.service';
import { Observable, Subject } from 'rxjs';
import { AutoLoginService, IOttMismatchUserSubjectPayload } from './auto-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthChallengerService {

  public sendCodeCallback: (code: string) => Promise<void>;
  public cancelCodeVerificationCallback: () => void;
  public continueAsConnectedCallback: (continuing: boolean) => Promise<void>;
  public cancelOttMismatchUserCallback: () => void;

  private secondTrySubject: Subject<boolean> = new Subject<boolean>();
  public get secondTry$(): Observable<boolean> {
    return this.secondTrySubject.asObservable();
  }

  constructor(private cognitoAuth: CognitoAuthService,
              private autoLoginService: AutoLoginService,
              private mainLoaderHandler: MainLoaderHandlerService,
              private router: Router) {

    this.cognitoAuth.authChallenge$.subscribe((payload: IAuthChallengeSubjectPayload) => {
      console.debug('cognitoAuth.authChallenge$', payload);
      if (!payload) return;
      this.sendCodeCallback = payload.callback;
      this.cancelCodeVerificationCallback = payload.cancelCodeVerificationCallback;
      if (payload.secondTry) this.secondTrySubject.next(true);
      else this.openVerificationPage(payload.contact);
    });

    this.autoLoginService.ottMismatchUser$.subscribe((payload: IOttMismatchUserSubjectPayload) => {
      console.debug('autoLoginService.ottMismatchUser$', payload);
      if (!payload) return;
      this.continueAsConnectedCallback = payload.callback;
      this.cancelOttMismatchUserCallback = payload.cancel;
      this.openOttMismatchUserPage(payload.connectedUser, payload.ottUser);
    });
  }

  private async openVerificationPage(contact: string): Promise<void> {
    this.mainLoaderHandler.remove();
    this.autoLoginService.didNavigateToChallengerPage = true;
    await this.router.navigate(['verification-code-sent'],
      {
        replaceUrl: true,
        state: {
          contact
        }
      },

    );
  }

  private async openOttMismatchUserPage(connectedUser: string, ottUser: string): Promise<void> {
    this.mainLoaderHandler.remove();
    this.autoLoginService.didNavigateToChallengerPage = true;
    await this.router.navigate(['ott-mismatch-user'],
      {
        replaceUrl: true,
        state: {
          connectedUser,
          ottUser
        }
      },

    );
  }

}
