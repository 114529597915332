import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MimeTypeService} from '../../../services/mime-type.service';
import { isSafariDesktop } from '../../../../utils';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss'],
})
export class PdfModalComponent {
  public url: SafeResourceUrl;
  public isGoogleView: boolean = false;
  public type: string = 'application/pdf';
  public isSafari: boolean = isSafariDesktop;

  constructor(
    private matDialogRef: MatDialogRef<PdfModalComponent>,
    private mimeTypeService: MimeTypeService,
    @Inject(MAT_DIALOG_DATA) data: { url: string },
    private sanitizer: DomSanitizer) {
    this.canGoogleViewOpen(data.url);
  }

  canGoogleViewOpen(url: string): void {
    if (this.mimeTypeService.isDocument(url) && this.mimeTypeService.getExtension(url) !== 'pdf') {
      this.isGoogleView = true;
      this.type = 'text/html';
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + url);
    } else {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  public close(): void {
    this.matDialogRef.close();
  }
}
