import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import DynValue from '../../../../models/dyn-value';
import Action from '../../../../models/action';
import {Dictionary} from 'lodash';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-text',
  templateUrl: './action-text.component.html',
  styleUrls: ['./action-text.component.scss'],
})
export class ActionTextComponent extends InputBaseController {
  public static className: string = 'action-text';
  public static readonly responseKeys: string[] = ['text'];

  public params: { text: string };

  public onChange(text: string): void {
    this.params.text = text;
    super.onChange();
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let text: string = getValue(action.params.text) || '';
    return {text: text.replace(/(?:\r\n|\r|\n)/g, ' ').trim()};
  }
}
