import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ExecuteWithLoaderService {

  private loader: HTMLIonLoadingElement;
  private loadingTasksCount: number = 0;

  constructor(
    public loading: LoadingController,
  ) {
  }

  async executeWithLoader<TErrorActionResult, TResult>(
    action: () => Promise<TResult>,
    errorAction?: (e: any) => Promise<TErrorActionResult>,
  ): Promise<{ result: TResult, errorActionResult: TErrorActionResult, error: any }> {
    this.loadingTasksCount++;
    if (this.loadingTasksCount === 1) {
      this.loader = await this.loading.create();
      await this.loader.present();
    }
    let error: any;
    let result: TResult;
    let errorActionResult: TErrorActionResult;
    try {
      result = await action();
    } catch (e) {
      if (errorAction) errorActionResult = await errorAction(e);
      error = e;
    }
    this.loadingTasksCount--;
    if (!this.loadingTasksCount) {
      await this.loader.dismiss();
    }
    if (!errorAction && error) throw error;
    return {result, errorActionResult, error};
  }
}
