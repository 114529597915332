import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {InputAddressModule} from '../input-address/input-address.module';
import {TranslateModule} from '@ngx-translate/core';
import {InputUserComponent} from './input-user.component';
import {InputPhoneModule} from '../input-phone/input-phone.module';
import {DirectivesModule} from '../../../directives/directives.module';
import {DatePickerModule} from '../../date-time/date-picker/date-picker.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatInputModule,
    MatIconModule,
    DatePickerModule,
    InputPhoneModule,
    InputAddressModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  declarations: [
    InputUserComponent
  ],
  exports: [
    InputUserComponent
  ]
})
export class InputUserModule {
}
