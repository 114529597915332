import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import moment, { Moment } from 'moment';
import { switchToTimeZone } from '../../time-zone-select/time-zone-select.component';
import { DatePickerModalComponent } from '../date-picker-modal/date-picker-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicMatDialogService } from '../../../services/dynamic-mat-dialog/dynamic-mat-dialog.service';
import { INgbTime, TimePickerModalComponent, ITimePickerModalComponentParams, ITimePickerModalComponentResult } from '../time-picker-modal/time-picker-modal.component';
import { meridianStringToNgbTime, ngbTimeToMeridianString } from '../time-picker-wrapper/time-picker-wrapper.component';
import { ModalController } from '@ionic/angular';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {

  public displayableDate: string = '';
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() min: Date;
  @Input() max: Date;
  @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() onChange: EventEmitter<Date> = new EventEmitter<Date>();

  private _date: Date;
  @Input()
  get date(): Date {
    return this._date;
  }

  set date(newDate: Date) {
    if (this._date === newDate) {
      return;
    }
    this._date = newDate;

    if (this.timeZone) {
      this.updateDisplayableDate();
    }
    this.dateChange.emit(newDate);
    this.onChange.emit(newDate);
  }

  private _timeZone: string = moment.tz.guess(true);
  @Input()
  get timeZone(): string {
    return this._timeZone;
  }

  set timeZone(value: string) {
    this._timeZone = value;
    if (!this._date) {
      return;
    }
    this.updateDisplayableDate();
  }

  public selectedDate: Date;
  public currentTime: string = '12:00 pm';

  private _backdrop: boolean = false;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private modalController: ModalController,
    private matDialog: DynamicMatDialogService,
    public translate: TranslateService,
    ) {
  }

  ngOnInit(): void {
    this.selectedDate = this.date;
    if (!this.date || !this.timeZone) {
      this.displayableDate = '';
    } else {
      this.updateDisplayableDate();
    }
  }

  public triggerDatePickerModal(e: Event): void {
    this._backdrop = false;
    let datePickerRef: MatDialogRef<DatePickerModalComponent> = this.matDialog.open(DatePickerModalComponent, {
      data: {
        date: this._date && this.switchLocalToTimeZoneTime(this._date),
        minDate: this.min && this.switchLocalToTimeZoneTime(this.min),
        maxDate: this.max && this.switchLocalToTimeZoneTime(this.max),
      },
      panelClass: 'datePickerDialogContainer'
    });
    datePickerRef.backdropClick().subscribe(date => {
      this._backdrop = true;
    });
    datePickerRef.afterClosed().subscribe((date: Date) => {
      if (!this._backdrop) {
        // date is a date.Now in user time
        // need to convert it into 00:00 timeZone time
        this.selectedDate = moment.tz({
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
        }, this.timeZone).toDate();
        this.triggerTimePicker(e);
      }
    });
  }

  public async triggerTimePicker(e: Event): Promise<void> {
    if (!this.selectedDate) {
      return;
    }
    const convertedTime: INgbTime = meridianStringToNgbTime(this.currentTime || '00:00 AM');
    const dialog: HTMLIonModalElement = await this.modalController.create({
      component: TimePickerModalComponent,
      componentProps: { time: convertedTime } as ITimePickerModalComponentParams,
      cssClass: 'rounded-corner-modal small-ion-modal',
      mode: 'ios',
    });
    dialog.onWillDismiss().then(async (value: OverlayEventDetail<ITimePickerModalComponentResult>) => {
      if (!value.data) {
        this.closeTime(true);
        return;
      }
      const ngbTime: INgbTime = value.data;
      console.debug('time', ngbTime);
      this.currentTime = ngbTimeToMeridianString(ngbTime);

      const timeZoneDate: Moment = moment.tz(this.selectedDate, this.timeZone).set({
        hour: ngbTime.hour,
        minute: ngbTime.minute,
        second: 0,
      });
      this.selectedDate = this.date = timeZoneDate.toDate();
      this.closeTime(false);
    });
    dialog.present();
  }

  public closeTime(reset: boolean): void {
    if (reset) {
      this.selectedDate = this.date = undefined;
      this.displayableDate = '';
    } else {
      this.updateDisplayableDate();
    }
    this.onClose.emit();
  }

  public switchLocalToTimeZoneTime(localDate: Date): Date {
    return switchToTimeZone(localDate, moment.tz.guess(true), this.timeZone);
  }

  private updateDisplayableDate(): void {
    this.displayableDate = moment(this._date).tz(this.timeZone)
      .locale(this.translate.instant('DATE_FORMAT.MOMENT.LOCAL'))
      .format(this.translate.instant('DATE_FORMAT.MOMENT.DATETIME_FULL'));
  }
}
