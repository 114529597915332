<div class="button column">
  <mat-radio-group
    [disabled]="type == 'show' || manager.mode === 'review'"
    [(ngModel)]="selectedSlot"
    (ngModelChange)="onChange()"
  >
    <mat-radio-button
      *ngFor="let option of options; trackBy: trackByIndex"
      [value]="option.value"
      class="overlay-button-container"
      [disabled]="isDisabledSlot(option.value) || (!realTimeActivity.ready && manager.mode !== 'preview')"
      #radioButton
      style="font-size: 0.9em;"
      [class.unconfirmed]="isUnconfirmed(option.value)"
      [class.review]="manager.mode === 'review'"
    >
      <button
        class="overlay-button"
        [disabled]="manager.mode === 'review' || isDisabledSlot(option.value) || !realTimeActivity.ready"
        (click)="onRadioClick($event, option, radioButton)"
      ></button>
      <div class="d-flex justify-content-between">
        <div
          class="flex-grow-1 d-flex flex-column gap-1 justify-content-center align-items-center"
        >
          <div class="d-flex">
            <span text-center>{{option.keyName}}</span>
          </div>
          <span class="text-primary" *ngIf="option.value.subTitle">
                        {{option.value.subTitle}}
                    </span>
          <div
            class="d-flex justify-content-between"
            style="width: 100%; padding-left: 20px; padding-right: 20px;"
            >
                        <span>
                            ${{option.value.cost}}
                        </span>
            <span>
                            {{option.value.availableCount}}/{{option.value.capacity}}
              available
                        </span>
          </div>
        </div>
        <ion-button
          class="align-self-end"
          style="width: 43px; height: 38px; border-radius: 100%;"
          (click)="onInfosClick(option.value)"
        >
          <ion-icon
            slot="icon-only"
            name="information-circle"
            style="font-size: 40px;"
          ></ion-icon>
        </ion-button>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>
