import { Injectable, NgZone } from '@angular/core';
import { DeveloperService } from './developer.service';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { WebView } from '@capacitor/core';
import { Router } from '@angular/router';
import { TriggerUpdateService } from './trigger-update.service';
import { merge } from 'rxjs';
import { delay } from '../../utils';

export interface IVersionInfo {
  currentWebVersion: string;
  readyToInstallWebVersion: string;
  currentWebFolder: string;
  previousWebVersion: string;
  appVersion: string;
  buildVersion: string;
}

interface IChcpConfig {
  name: string;
  ios_identifier: string;
  android_identifier: string;
  update: string;
  content_url: string;
  release: string;
}

interface IChcpConfigHolder {
  config: IChcpConfig;
}

interface IFetchUpdateOptions {
  'config-file': string;
}

export interface IChcp {
  fetchUpdate: (cb: (error: any, data: IChcpConfigHolder) => void, options: IFetchUpdateOptions) => void;
  installUpdate: (cb: (error: any, data: IChcpConfigHolder) => void) => void;
  getVersionInfo: (cb: (error: any, data: IVersionInfo) => void) => void;
  error: any;
}

// tslint:disable-next-line:typedef
declare var chcp: IChcp;

@Injectable({
  providedIn: 'root'
})
export class HotDeployService {

  private pendingUpdate: boolean = false;

  constructor(
    private platform: Platform,
    private zone: NgZone,
    private loadingController: LoadingController,
    private alert: AlertController,
    public toastController: ToastController,
    public router: Router,
    private triggerService: TriggerUpdateService,
    private devService: DeveloperService) {
    if (this.platform.is('hybrid')) {
      merge(
        this.triggerService.activitySubmitTrigger$,
        this.triggerService.whiteListedNavTrigger$,
      )
        .subscribe(() => {
          this.installPendingUpdate();
        });
    }
  }

  public async update(loaderContent: string = 'Searching for a new version...'): Promise<void> {
    if (!this.platform.is('hybrid')) {
      return;
    }

    let loader: HTMLIonLoadingElement = await this.loadingController.create({
      message: loaderContent
    });
    await loader.present();

    let options: IFetchUpdateOptions = { 'config-file': this.devService.getEnvironmentUrl() + '/chcp_new.json' };
    setTimeout(() => loader.message = 'Updating..', 5000);
    console.log('Fetching updates..');
    return new Promise((resolve, reject) => {
      chcp.fetchUpdate(async (error, data) => {
        if (error) {
          await loader.dismiss();
          if (error.code !== chcp.error.NOTHING_TO_UPDATE) {
            await this.showUpdateAlertError(error.description);
            return reject();
          } else {
            console.log(error);
          }
        } else {
          console.log('Found a new version..');
          this.pendingUpdate = true;
          const alertElement: HTMLIonAlertElement = await this.alert.create({
            header: 'Update',
            message: 'A new version has been downloaded, we have to restart your application to install it!',
            backdropDismiss: false,
            buttons: [{
              text: 'Ok',
              handler: () => {
                this.installPendingUpdate();
              }
            }]
          });
          alertElement.style.zIndex = '60000';
          await alertElement.present();
        }
        resolve();
      }, options);
    });
  }

  /**
   * Don't await this function to make it run in the background silently
   */
  public async silentUpdate(attempts: number = 0): Promise<void> {
    if (!this.platform.is('hybrid')) {
      return;
    }
    let options: IFetchUpdateOptions = { 'config-file': this.devService.getEnvironmentUrl() + '/chcp_new.json' };
    console.log('CHCP Fetching updates..');
    return new Promise((resolve, reject) => {
      chcp.fetchUpdate(async (error, data) => {
        if (error) {
          if (error.code === chcp.error.ASSETS_FOLDER_IN_NOT_YET_INSTALLED && attempts < 3) {
            console.log('CHCP folder not installed yet.. retrying after a short delay');
            try {
              await delay(1000);
              await this.silentUpdate(attempts + 1);
            } catch (e) {
              return reject(e);
             }
          } else if (error.code !== chcp.error.NOTHING_TO_UPDATE) {
            console.log('Error fetching updates..');
            console.log(error.code);
            return reject(error);
          }
        } else {
          console.log('CHCP Found a new version..');
          this.pendingUpdate = true;
          const toastElement: HTMLIonToastElement = await this.toastController.create({
            position: 'top',
            color: 'success',
            message: 'A new version of Actionaly is available. Click OK to restart the application and install it.',
            buttons: [{
              text: 'OK',
              handler: async () => {
                toastElement.dismiss();
                await this.installPendingUpdate();
              }
            }]
          });
          await toastElement.present();
        }
        resolve();
      }, options);
    });
  }

  public async installPendingUpdate(): Promise<void> {
    if (!this.pendingUpdate) return;
    this.pendingUpdate = false;

    const toast: HTMLIonToastElement = await this.toastController.create({
      position: 'top',
      color: 'success',
      message: 'Restarting actionaly...',
    });
    await toast.present();
    let loader: HTMLIonLoadingElement = await this.loadingController.create();
    await loader.present();
    return new Promise((resolve, reject) => {
      console.log('Installing the update..');
      chcp.installUpdate(async (err, installUpdateData) => {
        if (err) {
          console.log('Error installing update..');
          console.log(err);
          await toast.dismiss();
          await loader.dismiss();
          await this.showUpdateAlertError(err.description);
          return reject(err);
        } else {
          console.log('Update installed...');
          console.log('getVersionInfo..');
          const versionInfo: IVersionInfo = await this.getVersionInfo();
          console.log(versionInfo);
          let currentWebFolder: string = versionInfo.currentWebFolder;
          if (this.platform.is('ios')) {
            currentWebFolder = this.cleanIosPath(currentWebFolder);
          }
          await loader.dismiss();
          console.log('Setting server base path..');
          await WebView.setServerBasePath({ path: currentWebFolder });
          await WebView.persistServerBasePath();
        }
        resolve();
      });
    });
  }

  private async getVersionInfo(): Promise<IVersionInfo> {
    return await new Promise<any>((resolve, reject) => {
      chcp.getVersionInfo((getVersionInfoError, getVersionInfoData) => {
        if (getVersionInfoError) return reject(getVersionInfoError);
        return resolve(getVersionInfoData);
      });
    });
  }

  private async showUpdateAlertError(error: string): Promise<void> {
    const alertElement: HTMLIonAlertElement = await this.alert.create({
      header: error,
      message: 'Cannot update Actionaly, please check your connection and retry.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Retry',
          handler: () => {
            this.update('Retrying..');
          }
        },
        {
          text: 'Cancel',
          handler: () => {

          }
        }
      ]
    });
    alertElement.style.zIndex = '60000';
    await alertElement.present();
  }

  private cleanIosPath(path: string): string {
    let decoded: string = decodeURI(path).replace('file://', '');
    // remove trailing /
    if (decoded.charAt(decoded.length - 1) === '/') decoded = decoded.replace(/.$/, '');
    return decoded;
  }
}
