import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import Action from '../../../../models/action';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-amount',
  templateUrl: './action-amount.component.html',
  styleUrls: ['./action-amount.component.scss'],
})
export class ActionAmountComponent extends InputBaseController {
  public static className: string = 'action-amount';
  public static readonly responseKeys: string[] = ['amount', 'currency'];

  public params: { amount: number, currency: string };

  // copied from admin panel
  public amountMask = {
    mask: Number,  // enable number mask
  
    // other options are optional with defaults below
    scale: 2,  // digits after point, 0 for integers
    signed: false,  // disallow negative
    thousandsSeparator: ',',  // any single char
    padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
    normalizeZeros: true,  // appends or removes zeros at ends
    radix: '.',  // fractional delimiter
    mapToRadix: ['.'],  // symbols to process as radix
  
    // additional number interval options (e.g.)
    min: 0,
    max: 1000000000000
  };

  public text: string;
  public prefix: string = '$ ';

  protected initAction(action: Action): void {
    super.initAction(action);
    this.text = this.params.amount && this.params.amount.toString();
  }

  public onChange(): void {
    this.params.amount = parseFloat(this.text.replace(',', '')) || 0;
    super.onChange();
  }

}
