import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TimePickerModalComponent, ITimePickerModalComponentParams, ITimePickerModalComponentResult, INgbTime } from '../time-picker-modal/time-picker-modal.component';

export const ngbTimeToMeridianString = (time: INgbTime) => {
  const isAM: boolean = time.hour < 12;
  const hour: number = isAM ? (time.hour === 0 ? 12 : time.hour) : (time.hour === 12 ? 12 : (time.hour - 12));
  return `${hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')} ${isAM ? 'AM' : 'PM'}`;
}

export const meridianStringToNgbTime = (meridianTime: string) => {
  const isAM = (meridianTime[6] + meridianTime[7]) === 'AM';
  const meridianHour = parseInt(meridianTime[0] + meridianTime[1]);
  const internationalHour = isAM ? (meridianHour === 12 ? 0 : meridianHour) : (meridianHour === 12 ? 12 : (meridianHour + 12));
  const minute = parseInt(meridianTime[3] + meridianTime[4])
  return {
    hour: internationalHour,
    minute,
  };
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'time-picker',
  templateUrl: './time-picker-wrapper.component.html',
  styleUrls: ['./time-picker-wrapper.component.scss'],
})
export class TimePickerWrapperComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() timeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() okClick: EventEmitter<INgbTime> = new EventEmitter<INgbTime>();

  private _time: string;
  @Input()
  get time(): string {
    return this._time;
  }
  set time(newTime: string) {
    if (this._time === newTime) return;
    this._time = newTime;
    this.currentTime = newTime;
    this.timeChange.emit(newTime);
    this.onChange.emit(newTime);
  }

  public currentTime: string = '';
  public showHours: boolean = true;

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private modalController: ModalController,
  ) {
  }

  ngOnInit(): void {
  }

  public async openTimePicker(): Promise<void> {
    const convertedTime: INgbTime = meridianStringToNgbTime(this.currentTime || '00:00 AM');
    const dialog: HTMLIonModalElement = await this.modalController.create({
      component: TimePickerModalComponent,
      componentProps: { time: convertedTime } as ITimePickerModalComponentParams,
      cssClass: 'rounded-corner-modal small-ion-modal',
      mode: 'ios',
    });
    dialog.onWillDismiss().then(async (value: OverlayEventDetail<ITimePickerModalComponentResult>) => {
      if (!value.data) { return this.closeTime(); }
      const ngbTime: INgbTime = value.data;
      console.debug('time', ngbTime);
      this.time = ngbTimeToMeridianString(ngbTime);
      this.okClick.emit(ngbTime);
      this.closeTime();
    });
    dialog.present();
  }

  public closeTime(): void {
    this.onClose.emit();
  }
}

