import {Component, Input} from '@angular/core';
import { BbcodeTranslatorService } from '../../../services/bbcode-translator.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'label-form',
  templateUrl: './label-form.component.html',
  styleUrls: ['./label-form.component.scss'],
})
export class LabelFormComponent {
  @Input() public label: string;

  public constructor(
    public bbcodeTranslatorService: BbcodeTranslatorService,
  ) {}
}

