import {Component} from '@angular/core';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import {Dictionary} from 'lodash';
import isEqual from 'lodash/isEqual';
import {InputBaseController} from '../../input-controller';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-radio',
  templateUrl: './action-radio.component.html',
  styleUrls: ['./action-radio.component.scss'],
})
export class ActionRadioComponent extends InputBaseController {
  public static className: string = 'action-radio';
  public static readonly responseKeys: string[] = ['selectedAnswer'];
  public params: { options: Option[], selectedAnswer: any };

  protected reset(): void {
    this.params.options.forEach(o => o.selected = false);
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.setupParams(action);
    // this.updateAction();
  }

  public onChange(option: Option): void {
    this.params.options.filter(s => s.selected).forEach(e => e.selected = false);
    option.selected = true;
    this.params.selectedAnswer = option.value;
    super.onChange();
  }

  // public updateInputs( inputs : { manager: any, sectionCtrl: any, action: Action, index: any }) {
  //     if (inputs.action && this.manager) {
  //         this.setupParams(inputs.action);
  //     }
  //     super.updateInputs(inputs);
  // }

  public setupParams(action: Action): void {
    this.params.options = this.getValue(action.params.options).map(elem => elem instanceof Object ? elem : {
      keyName: elem,
      value: this.bbcodeTranslator.getSource(elem),
      selected: false
    });
    this.reset();
    let selectedValue: Option = this.params.options.find(o => isEqual(o.value, this.params.selectedAnswer));
    if (selectedValue) {
      selectedValue.selected = true;
    }
  }

  public onRadioClick(event: MouseEvent, option: Option): void {
    event.stopImmediatePropagation();
    this.onChange(option);
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let selectedAnswer: string = '';
    if (getValue(action.params.selectedAnswer) === true) {
      selectedAnswer = 'Yes';
    } else if (getValue(action.params.selectedAnswer) === false) {
      selectedAnswer = 'No';
    } else if (getValue(action.params.selectedAnswer)) {
      selectedAnswer = getValue(action.params.selectedAnswer);
    }
    return {selectedAnswer};
  }
}

export interface Option<T = any> {
  keyName: string;
  value: T;
  selected: boolean;
}
