import {Component, EventEmitter, Input, Output} from '@angular/core';
import moment, {MomentZone} from 'moment-timezone';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'time-zone-select',
  templateUrl: './time-zone-select.component.html',
  styleUrls: ['./time-zone-select.component.scss'],
})
export class TimeZoneSelectComponent {

  @Input() timeZone: string = moment.tz.guess(true);
  @Input() input: boolean;
  @Output() timeZoneChange: EventEmitter<string> = new EventEmitter<string>();

  public availableTimeZones: string[] = moment.tz.names();

  public onTimeZonesSelect(): void {
    this.timeZoneChange.emit(this.timeZone);
  }
}

export const switchToTimeZone: (date: Date, newTimeZone: string, fromTimeZone?: string) => Date =
  (date: Date, newTimeZone: string, fromTimeZone?: string) => {
  // console.log('from', fromTimeZone, 'to', newTimeZone);
  const momentNewTimeZone: MomentZone = moment.tz.zone(newTimeZone);
  const momentFromTimeZone: MomentZone = moment.tz.zone(fromTimeZone);

  const totalOffset: number = momentNewTimeZone.utcOffset(date.getTime()) - momentFromTimeZone.utcOffset(date.getTime());
  // console.log('totalOffset', totalOffset / 60, 'hours');
  const convertedDate: Date = moment
    .tz(date, fromTimeZone)
    .utc(true)
    .utcOffset(momentNewTimeZone.utcOffset(date.getTime()))
    .utc(true)
    .toDate();
  // console.log('convertedDate', convertedDate);
  return convertedDate;
};
