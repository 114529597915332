import {Injectable} from '@angular/core';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BbcodeTranslatorService {

  REGEX_TRANSLATION: RegExp = /\[(src@)?lang=([a-zA-Z\-]+?)\]([\s\S]*?)\[\/lang\]+/g;

  // used as extra param by pipe to redraw on state change
  // https://github.com/angular/angular/issues/15041
  public get redraw(): boolean {
    return this.userService.currentUser?.autoTranslateActivity;
  }

  constructor(
    private userService: UserService,
  ) {
  }

  public translate(bbcodeValue: string): string {
    if (!bbcodeValue || typeof bbcodeValue !== 'string') {
      return bbcodeValue;
    }
    bbcodeValue = bbcodeValue.replace(/#!#/g, ''); // Tag for no translation
    let reg: RegExp = new RegExp(this.REGEX_TRANSLATION);
    let results: any = bbcodeValue.match(reg);

    if (!results) {
      return bbcodeValue;
    }

    results = results.map(m => {
      const res: RegExpExecArray = reg.exec(m);
      reg.lastIndex = 0;
      return res;
    });
    if (!this.userService.currentUser?.autoTranslateActivity) {
      return results[0][3];
    }
    let outputValue: string[] = results.find(data => data[2] === this.userService.currentUser.appLanguage);
    return (outputValue && outputValue[3]) || results[0][3];
  }

  public getSource(bbcodeValue: string): string {
    if (!bbcodeValue) {
      return bbcodeValue;
    }

    let reg: RegExp = new RegExp(this.REGEX_TRANSLATION);
    let results: any = bbcodeValue.match(reg);

    if (!results) {
      return bbcodeValue;
    }

    results = results.map(m => {
      const res: RegExpExecArray = reg.exec(m);
      reg.lastIndex = 0;
      return res;
    });

    return results[0][3];
  }
}
