<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Choose Your Time</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <!-- for the ion-page class: https://github.com/ionic-team/ionic-framework/issues/22514 -->
  <div class="bootstrap-component-container ion-page d-flex flex-column justify-content-center align-items-center">
    <ngb-timepicker [(ngModel)]="time" [meridian]="meridian"></ngb-timepicker>
    <ion-button shape="round" (click)="close()">Done</ion-button>
  </div>
</ion-content>