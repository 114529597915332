import Condition from './condition';
import AMergeable from './a-mergeable';
import Builder from './API/builders';

export default class Validation extends AMergeable {
  public message: string;
  public conditions: Condition[][];

  constructor(json: any) {
    super();
    this.message = json.message;
    this.initialize(Condition, json.conditions, 'conditions');
  }

  public static getError(obj: Builder, validations: Validation[]): string {
    if (!validations) {
      return undefined;
    }
    for (let i: number = 0; i < validations.length; i++) {
      let error: string = validations[i].execute(obj);
      if (error) {
        return error;
      }
    }
    return undefined;
  }

  public execute(obj: Builder): string {
    return Condition.isValid(obj, this.conditions) ? undefined : this.message;
  }
}
