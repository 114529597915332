import {Dictionary, mergeWith} from 'lodash';

export class Server {
  public Providers: Dictionary<ServerDynValue>;
  public OnCreate: ServerExecutions[];
  public OnNewResponse: ServerExecutions[];
  public Validations: ActivityValidation[];
  public Payments: Dictionary<ActivityPayment>;

  constructor(json: any) {
    mergeWith(this, json);
  }
}

export class ServerDynValue {
  public Type: string;
  public Value: any;
  public Params: Dictionary<ServerDynValue>;
  public UpdateValue: ServerUpdateValue;
}

export class ServerUpdateValue {
  public Type: string;
  public Params: Dictionary<ServerDynValue>;
  public UpdateValue: ServerUpdateValue;
}

export class ServerExecutions {
  public Type: string;
  public Params: Dictionary<ServerDynValue>;
  public Conditions: ServerCondition[][];
}

export class ActivityValidation {
  public ErrorMessage: string;
  public Providers: Dictionary<ServerDynValue>;
  public ClientExecutions: ClientExecutions[];
  public Conditions: ServerCondition[][];
}

export class ServerCondition {
  public Type: string;
  public Params: Dictionary<ServerDynValue>;
}

export class ClientExecutions {
  public Type: string;
  public Params: Dictionary<object>;
}

export class ActivityPayment {
  public Min: ServerDynValue;
  public Max: ServerDynValue;
  public Currency: ServerDynValue;
  public DestinationAccountId: ServerDynValue;
}
