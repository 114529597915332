<div class="container d-flex flex-column justify-content-between align-items-center">
  <div class="dateSelectBox d-flex justify-content-center align-items-center">
    <ng-container *ngFor="let selectItem of selectObjects; let i = index">
      <select class='form-control' (change)="selectItem.onChange($event)">
        <option *ngFor="let item of selectItem.list"
                [value]="item.value"
                [selected]="selectItem.selected(item.value)">
          {{item.text}}
        </option>
      </select>
      <div *ngIf="i < selectObjects.length -1" class="selectSeparator">/</div>
    </ng-container>
  </div>
  <div class="arrowsMonth d-flex justify-content-between">
    <div class="arrow" (click)="onClickArrowLeft()">
      <ion-icon name="chevron-back"></ion-icon>&nbsp;
    </div>
    <div>{{selectedMonth.text}}</div>
    <div class="arrow" (click)="onClickArrowRight()">&nbsp;<ion-icon name="chevron-forward"></ion-icon>
    </div>
  </div>
  <div class="calendarMonth">
    <div *ngFor="let week of monthCalendar" class="calendarWeek">
      <div *ngFor="let cday of week.days"
           class="calendarDay"
           [class.selected]="cday.selected"
           [class.disabled]="!cday.isCurrentMonth"
           [class.notClickable]="!cday.isClickable"
           (click)="clickOnCalendar(cday.isClickable, cday.moment)">
        <div class="dayName">{{cday.text}}</div>
        <div class="dayNumber">{{cday.number}}</div>
      </div>
    </div>
  </div>
  <button class="closeBtn" (click)="close()">{{ "done" | translate }}</button>
</div>
