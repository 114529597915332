import {Injectable, OnDestroy} from '@angular/core';
import Execution from '../models/execution';
import {SocketEvent} from './socket-event.enum';
import Builder from '../models/API/builders';
import {Observable, Subject} from 'rxjs';
import {BuilderRefService} from './builder-ref.service';
import cloneDeep from 'lodash/cloneDeep';
import {filter, first, takeUntil} from 'rxjs/operators';
import Condition from '../models/condition';
import {delay} from '../../utils';
import {TimerService} from './timer.service';
import {ISocketMessage} from './real-time-activity.service';
import {ISlotsBag} from '../components/form/actions/action-slots/action-slots.interface';

@Injectable()
export class LocalSocketServerService implements OnDestroy {


  private builder: Builder;

  private unsubscribe$: Subject<any> = new Subject();

  private emittedEventsSubject: Subject<{ event: string, data: any }> = new Subject();

  public get events$(): Observable<{ event: string, data: any }> {
    return this.emittedEventsSubject.asObservable();
  }

  private receivedEventsSubject: Subject<{ event: string, data: any }> = new Subject();

  public get activityStateUpdatesRequest$(): Observable<{ event: 'message'; data: ISocketMessage; }> {
    return this.receivedEventsSubject
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter(({event, data}) =>
        event === 'message' && data.action === 'activityStateUpdatesRequest'
      )) as Observable<{ event: 'message'; data: ISocketMessage; }>;
  }

  constructor(
    public builderRef: BuilderRefService,
    public timer: TimerService,
  ) {
    // console.log('Hello LocalSocketServerProvider Provider');

    this.activityStateUpdatesRequest$.subscribe(({data: clientSocketMessage}) => {
      // console.log('json executions', clientSocketMessage.data);
      const executions: Execution[] = (clientSocketMessage.data as any[]).map(jsonExecution => new Execution(jsonExecution));
      // run server validations
      // pod scope
      // user scope
      // console.log('checking condition on', executions, this.builder);
      const executionsValidationsResults: boolean[] = executions.map(execution => {
        return Condition.isValid(this.builder, execution.conditions, true);
      });
      // console.log('requestUpdates conditions results', executionsValidationsResults);
      let isValid: boolean = !executionsValidationsResults.some(v => !v);

      const message: any = this.generateMessage('', clientSocketMessage.data);
      message.id = clientSocketMessage.id;
      if (isValid) {
        this.updateActivity(executions);
        message.action = 'activityStateUpdatesConfirmation';
      } else {
        message.action = 'activityStateUpdatesCancellation';
      }
      this.simulateServerMessage('message', message, 1000);

      if (!this.timer.running) {
        this.simulateServerMessage('message', this.generateMessage('timerStart'));
      }
    });

    this.timer.timerEnd$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      // console.log('timerEnd');
      // this.init();
      this.simulateServerMessage('message', this.generateMessage('timerCompletion'));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async init(): Promise<void> {
    this.builder = cloneDeep(await this.builderRef.builder$.pipe(first()).toPromise());
    await this.simulateServerMessage(SocketEvent.CONNECT, {});
    const firstValues: number[] = [0, 3, 2, 0, 0].reverse();
    const updates: Execution[] = [].concat(
      ...[1, 2, 3, 4].map(actionSlotsIndex => [
        ...(this.builder.aliases[`actionSlots${actionSlotsIndex}`].params.slots.value as ISlotsBag).slots.map((slot, index) =>
          new Execution({
            type: 'update',
            params: {
              path: `'aliases.actionSlots${actionSlotsIndex}.params.slots.value.slots.${index}.availableCount'`,
              value: firstValues.length > 0 ? firstValues.pop() : (Math.floor(Math.random() * 10) % 4),
            }
          })
        ),
        new Execution({
          type: 'update',
          params: {
            path: `'aliases.actionSlots${actionSlotsIndex}.params.selectedSlot.value'`,
            value: '\'\'',
          }
        }),
      ])
    );
    this.updateActivity(updates);
    await this.simulateServerMessage('message', this.generateMessage('initialActivityStateUpdates', updates));
  }

  public async receive<T>(eventName: string, message: string): Promise<void> {
    await delay(200);
    console.log('activityStateUpdatesRequest received', JSON.parse(message).id);
    this.receivedEventsSubject.next({event: eventName, data: JSON.parse(message)});
  }

  private async simulateServerMessage(event: string, data: any, latency: number = 500): Promise<void> {
    // console.log('simulating', data);
    await delay(latency);
    this.emittedEventsSubject.next({event, data: JSON.stringify(data)});
  }

  private generateMessage(action: string, data?: any): ISocketMessage {
    return {
      action,
      id: undefined,
      activityId: this.builderRef.builder.activity.id,
      concernedUserId: this.builderRef.builder.concernedUser.id,
      data,
    };
  }

  private updateActivity(executions: Execution[]): void {
    executions.forEach(execution => execution.execute(this.builder));
  }

}
