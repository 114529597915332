import {IonicModule} from '@ionic/angular';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {InputPhoneComponent} from './input-phone.component';
import { IMaskModule } from 'angular-imask';
import {DirectivesModule} from '../../../directives/directives.module';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatInputModule,
    MatIconModule,
    IMaskModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  declarations: [
    InputPhoneComponent
  ],
  exports: [
    InputPhoneComponent
  ]
})
export class InputPhoneModule {
}
