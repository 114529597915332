import {Component, Injector, ViewContainerRef} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import {Platform} from '@ionic/angular';
import Action from '../../../../models/action';
import { isMobile } from 'src/utils';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-multiple-dates',
  templateUrl: './action-multiple-dates.component.html',
  styleUrls: ['./action-multiple-dates.component.scss'],
})
export class ActionMultipleDatesComponent extends InputBaseController {
  public static className: string = 'action-multiple-dates';
  public static readonly responseKeys: string[] = ['datesArray', 'nbSelectedDates'];
  public params: {
    datesArray: Date[],
    nbSelectedDates: number
  };

  dayNames: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  calendar: CalendarMonth[] = [];
  currentMonth: number;
  minMonth: number;
  maxMonth: number;
  monthsToDisplay: number = 24;
  isMobile: boolean = false;

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    public platform: Platform,
  ) {
    super(injector, viewContainerRef);
    this.isMobile = isMobile(this.platform);
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.params.datesArray = this.params.datesArray || [];
    this.params.nbSelectedDates = this.params.datesArray.length;
    this.fillCalendar();
  }

  public fillCalendar(): void {
    let start: Date = new Date();
    let firstday: CalendarDate = new CalendarDate(start);
    this.currentMonth = 0;
    this.minMonth = 0;
    this.maxMonth = this.monthsToDisplay;

    for (let i: number = 0; i < this.monthsToDisplay; i++) {
      this.calendar.push(
        new CalendarMonth(firstday.month + i, firstday.year, this.params.datesArray)
      );
    }
  }

  nextMonth(): void {
    this.currentMonth += this.currentMonth < this.maxMonth ? 1 : 0;
  }

  lastMonth(): void {
    this.currentMonth -= this.currentMonth > this.minMonth ? 1 : 0;
  }

  onToggleDate(day: CalendarDate): void {
    day.selected = !day.selected;
    this.removeDate(day);
    if (day.selected) {
      this.addDate(day);
      this.params.nbSelectedDates += 1;
    } else {
      this.removeDate(day);
      this.params.nbSelectedDates -= 1;
    }
    this.onChange();
  }

  addDate(day: CalendarDate): void {
    this.params.datesArray.push(day.date);
    this.params.datesArray.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
  }

  removeDate(day: CalendarDate): void {
    let index: number = -1;

    this.params.datesArray.forEach((d, i) => {
      if (day.date.getTime() === d.getTime()) {
        index = i;
      }
    });
    if (index >= 0) {
      this.params.datesArray.splice(index, 1);
    }
  }

}

class CalendarDate {
  date: Date;
  day: number;
  month: number;
  year: number;
  selected: boolean;
  selectable: boolean;
  weekend: boolean;

  constructor(date: Date) {
    this.date = date;
    this.day = date.getDate();
    this.weekend = date.getDay() === 0 || date.getDay() === 6;
    this.month = date.getMonth();
    this.year = date.getFullYear();
    this.selected = false;
    this.selectable = date >= new Date();
  }
}

class CalendarMonth {
  name: string;
  weeks: CalendarDate[][] = [];
  selected: boolean = false;

  constructor(monthNumber: number, year: number, selectedDates: Date[] = []) {
    year = year + (Math.floor(monthNumber / 12));
    monthNumber = monthNumber % 12;
    const monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    this.name = monthNames[monthNumber] + ' ' + year.toString();

    let first: Date = new Date(year, monthNumber);
    if (first.getDay() !== 0) {
      first = this.addDays(first, -(first.getDay()));
    }
    let sameMonth: boolean = true;
    for (let i: number = 0; i <= 12 && sameMonth; i++) {
      let week: CalendarDate[] = [];
      for (let j: number = 0; j < 7; j++) {
        let dayDate: Date = this.addDays(first, j + (i * 7));
        let day: CalendarDate = new CalendarDate(dayDate);
        day.selectable = day.month === monthNumber && day.date >= this.addDays(new Date(), -1);
        day.selected = this.isSelected(day, selectedDates);
        week.push(day);
      }

      if (week[week.length - 1].day <= 7 && this.weeks.length > 1) {
        sameMonth = false;
        if (week[week.length - 1].day < 7) {
          this.weeks.push(week);
        }
      } else {
        this.weeks.push(week);
      }
    }
  }

  addDays(date: Date, days: number): Date {
    let dateOut: Date = new Date(date);
    dateOut.setDate(date.getDate() + days);
    return dateOut;
  }

  isSelected(date: CalendarDate, selected: Date[]): boolean {
    let isIn: boolean = false;
    selected.forEach(d => {
      if (date.date.getTime() === d.getTime() && date.selectable) {
        isIn = true;
      }
    });
    return isIn;
  }
}
