import { Component, Injector, ViewContainerRef } from '@angular/core';
import { SelectChangeEventDetail } from '@ionic/core';
import { InputBaseController } from '../../input-controller';
import Action from '../../../../models/action';
import isEqual from 'lodash/isEqual';
import { TranslateService } from '@ngx-translate/core';

interface Option {
  keyName: string;
  value: any;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-select',
  templateUrl: './action-select.component.html',
  styleUrls: ['./action-select.component.scss'],
})
export class ActionSelectComponent extends InputBaseController {
  public static className: string = 'action-select';

  public static readonly responseKeys: string[] = ['selectedAnswer'];
  public params: { options: Option[], selectedAnswer: any };

  public compareFn: (value: any, other: any) => boolean = isEqual;

  constructor(
    public injector: Injector,
    public viewContainerRef: ViewContainerRef,
    public translate: TranslateService) {
    super(injector, viewContainerRef);
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.setupParams(action);
    this.placeholder = this.placeholder || this.translate.instant('ACTIVITY.ACTION.SELECT.PLACEHOLDER');
  }

  public onChange(answer: CustomEvent<SelectChangeEventDetail<any>>): void {
    if (this.manager.mode === 'review') {
      return;
    }
    this.params.selectedAnswer = answer.detail.value;
    super.onChange();
  }

  public setupParams(action: Action): void {
    this.params.options = this.getValue(action.params.options).map(elem => elem instanceof Object ? elem : {
      keyName: elem,
      value: this.bbcodeTranslator.getSource(elem),
    });
  }
}

