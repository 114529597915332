import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent implements OnInit {
  // public mask: Array<string|RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private phoneRegex: RegExp = /^[\s(]*\d{3}[\s)-]*\d{3}[\s-]*\d{4}\s*$/;

  @Input() inputContent: string;
  @Input() placeholder: string = 'Valid Phone';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  public formControl: FormControl = new FormControl(undefined, this.phoneFormatValidator());

  constructor() {
  }

  async ngOnInit() {
    this.inputContent = this.formatPhoneNumber(this.inputContent);
    if (this.required) {
      await Promise.resolve();
      let validators = [Validators.required];
      if (this.formControl.validator) {
        validators = validators.concat(this.formControl.validator);
      }
      this.formControl.setValidators(validators);
      this.formControl.updateValueAndValidity();
    }
    if (this.disabled) {
      this.formControl.disable();
    }
  }

  public phoneFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const phone: string = control.value;
      return (this.phoneRegex.test(phone) || phone === '') ? null : {phone: true};
    };
  }

  setPhone(target: HTMLInputElement) {
    let phone = target.value;
    let newPhone = this.phoneRegex.test(phone) ? phone : '';
    this.inputContent = newPhone;
    this.onChange.emit(newPhone);
  }

  public formatPhoneNumber(phone: string): string {
    if (!/^[\s(]*\d{3}[\s)-]*\d{3}[\s-]*\d{4}\s*$/.test(this.inputContent)) {
      return '';
    }
    return phone.replace(/[\s(]*(\d{3})[\s)-]*(\d{3})[\s-]*(\d{4})\s*/, '($1) $2-$3');
  }

  public validate() {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
    return this.formControl.errors;
  }
}
