<ng-container *ngIf="type === 'input' && showSearch">

  <ng-template #optionTemplate let-attrs="attrs">
    <div class="overlay-button-container suggestion-container">
      <button class="overlay-button" (click)="onSuggestionClick($event, autoCompleteCtrl, attrs.data)"> </button>
      <span class="street-span">{{ attrs.data.descriptionStart }}</span><br />
      <span class="city-span">{{ attrs.data.descriptionEnd }}</span>
    </div>
  </ng-template>
  <ion-auto-complete #autoCompleteCtrl
                     [(ngModel)]="params.textSearch"
                     [dataProvider]="googleApi"
                     [template]="optionTemplate"
                     [options]="{placeholder:autoCompletePlaceholder}"
                     (itemSelected)="selectOption($event)">
  </ion-auto-complete>
</ng-container>

<ng-container *ngIf="type === 'input' && !showSearch">

  <ion-card *ngIf="params.placeId !==''">
    <ion-card-content>
      <p>{{ inputs.street_address }}</p>
      <mat-form-field>
        <input matInput type="text"
               [placeholder]="placeholders.apt"
               [(ngModel)]="params.additionalDetails"
               (ngModelChange)="onChangeAdditionalDetails()"
               [disabled]="inputDisable">
      </mat-form-field>
      <p>{{ inputs.locality }}</p>
      <p>{{ inputs.region }} {{ inputs.postalcode }}</p>
      <!--<p>{{ inputs.country }}</p>-->

      <button
        *ngIf="showEdit"
        (click)="onClickEdit()"
        class="edit-button" ion-button small item-end >
        {{ "ACTIVITY.ACTION.ADDRESS.EDIT" | translate }}
      </button>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="params.placeId ===''">
    <ion-card-content>
      <mat-form-field>
        <input type="text" matInput
               [placeholder]="placeholders.street_address"
               [(ngModel)]="inputs.street_address"
               (change)="updateUserInput()"
               [formControl]="form.get('street_address')"
               [errorStateMatcher]="matcher">

        <mat-error *ngIf="form.get('street_address').hasError('required')">
          <strong>
            {{ "ACTIVITY.ACTION.ADDRESS.REQUIRED" | translate }}
          </strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input type="text" matInput
               [placeholder]="placeholders.apt"
               [(ngModel)]="params.additionalDetails"
               (change)="onChangeAdditionalDetails()"
               [formControl]="form.get('apt')"
               [errorStateMatcher]="matcher">
      </mat-form-field>

      <mat-form-field>
        <input type="text" matInput
               [placeholder]="placeholders.locality"
               [(ngModel)]="inputs.locality"
               (change)="updateUserInput()"
               [formControl]="form.get('locality')"
               [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.get('locality').hasError('required')">
          <strong>
            {{ "ACTIVITY.ACTION.ADDRESS.REQUIRED" | translate }}
          </strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input type="text" matInput
               [placeholder]="placeholders.region"
               [(ngModel)]="inputs.region"
               (change)="updateUserInput()"
               [formControl]="form.get('region')"
               [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.get('region').hasError('required')">
          <strong>
            {{ "ACTIVITY.ACTION.ADDRESS.REQUIRED" | translate }}
          </strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input type="text" matInput
               [placeholder]="placeholders.postalcode"
               [(ngModel)]="inputs.postalcode"
               (change)="updateUserInput()"
               [formControl]="form.get('postalcode')"
               [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.get('postalcode').hasError('required')">
          <strong>
            {{ "ACTIVITY.ACTION.ADDRESS.REQUIRED" | translate }}
          </strong>
        </mat-error>
      </mat-form-field>
      <br /><br /><br />
      <button
        *ngIf="showEdit"
        (click)="onClickEdit()"
        class="edit-button" ion-button small item-end>
        {{ "ACTIVITY.ACTION.ADDRESS.EDIT" | translate }}
      </button>
    </ion-card-content>
  </ion-card>

</ng-container>

<ng-container *ngIf="type === 'show' && params.formattedAddress !== '' ">

  <ion-card class="clickable" *ngIf="params.placeId !== ''" (click)="onClickIonCard()">

    <img *ngIf="params.map"
         [src]="googleApi.buildServerStaticMapUrl(params.formattedAddress, '14', '400x150', '2', '')" >

    <ion-card-content class="show-google">
      <img class="google-icon" src="./assets/img/mapsSelector.png">
      <div class="address">
        <p>{{ inputs.street_address }}</p>
        <p>{{ params.additionalDetails }}</p>
        <p>{{ inputs.locality }}</p>
        <p>{{ inputs.region }} {{ inputs.postalcode }}</p>
        <!--<p>{{ inputs.country }}</p>-->
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="params.placeId === ''">
    <ion-card-content>
      <div>
        <p>{{ inputs.street_address }}</p>
        <p>{{ params.additionalDetails }}</p>
        <p>{{ inputs.locality }}</p>
        <p>{{ inputs.region }} {{ inputs.postalcode }}</p>
        <!--<p>{{ inputs.country }}</p>-->
      </div>
    </ion-card-content>
  </ion-card>
</ng-container>
