<h2>{{ "ACTIVITY.ACTION.PAY_THEORY.CHECKOUT" | translate }}</h2>
<div class="container bootstrap-component-container w-100">
    <div class="order-summary row w-100">
        <div class="order-summary-title col-12 col-lg-4 d-flex flex-column justify-content-around align-items-center">
            <div class="title">{{ "ACTIVITY.ACTION.PAY_THEORY.ORDER_SUMMARY" | translate }}</div>
        </div>
        <div class="order-summary-content col-12 col-lg-8">
          <div class="row">
              <div class="order-summary-detail-line col-12 d-flex justify-content-between align-items-center">
                  <div>{{params.description}}</div>
                  <div>$ {{params.preFeesAmount | number: '1.2-2'}} USD</div>
              </div>
              <div class="order-summary-detail-line fees-line col-12 d-flex justify-content-between align-items-center"
                   [class.hiddenFees]="!calculatedFees">
                  <div>{{ "ACTIVITY.ACTION.PAY_THEORY.TRANSACTION_FEES" | translate }}</div>
                  <div>$ {{calculatedFees | number: '1.2-2'}} USD</div>
              </div>
              <div class="order-summary-content-divider col-12"></div>
              <div class="order-summary-total col-12 d-flex justify-content-between align-items-center">
                  <div>{{ "ACTIVITY.ACTION.PAY_THEORY.TOTAL" | translate }}</div>
                  <div>$ {{params.amount | number: '1.2-2'}} USD</div>
              </div>
          </div>
        </div>
    </div>
</div>
<h3>{{ "ACTIVITY.ACTION.PAY_THEORY.BILLING" | translate }}</h3>
<ion-progress-bar *ngIf="!ready && !params.paid" type="indeterminate" class="ion-margin-top"></ion-progress-bar>
<form *ngIf="!params.paid" class="pay-theory-inputs-container">
    <div id="pay-theory-credit-card-account-name"></div>
    <ng-container *ngIf="state['card-name'].isDirty && state['card-name'].errorMessages.length">
        <ion-chip *ngFor="let message of state['card-name'].errorMessages" color="danger">{{message}}</ion-chip>
    </ng-container>
    <!--  <div id="pay-theory-credit-card"></div>-->
    <div class="bootstrap-component-container">
        <div class="row">
            <div id="pay-theory-credit-card-number" class="col-12 col-lg-6 pr-lg-2"></div>
            <div id="pay-theory-credit-card-exp" class="col-6 col-lg-3"></div>
            <div id="pay-theory-credit-card-cvv" class="col-6 col-lg-3 pl-2"></div>
        </div>
    </div>
    <ng-container *ngIf="state['card-number'].isDirty && state['card-number'].errorMessages.length">
        <ion-chip *ngFor="let message of state['card-number'].errorMessages" color="danger">{{message}}</ion-chip>
    </ng-container>
    <ng-container *ngIf="state['card-exp'].isDirty && state['card-exp'].errorMessages.length">
        <ion-chip *ngFor="let message of state['card-exp'].errorMessages" color="danger">{{message}}</ion-chip>
    </ng-container>
    <ng-container *ngIf="state['card-cvv'].isDirty && state['card-cvv'].errorMessages.length">
        <ion-chip *ngFor="let message of state['card-cvv'].errorMessages" color="danger">{{message}}</ion-chip>
    </ng-container>
    <div id="pay-theory-credit-card-zip"></div>
    <ng-container *ngIf="state['billing-zip'].isDirty && state['billing-zip'].errorMessages.length">
        <ion-chip *ngFor="let message of state['billing-zip'].errorMessages" color="danger">{{message}}</ion-chip>
    </ng-container>
</form>
<p class="cover-fees-label">{{params.checkboxText | bbcodeTranslation: bbcodeTranslator.redraw}}</p>
<mat-radio-group [disabled]="type == 'show' || (manager.mode !== 'view' && manager.mode !== 'preview') || params.paid">
    <mat-radio-button [value]="'service_fee'"
                      [class.active]="this.transactingParameters.feeMode === 'service_fee'"
                      class="overlay-button-container"
                      [checked]="this.transactingParameters.feeMode === 'service_fee'">
        <button class="overlay-button" (click)="changeFeeMode('service_fee')">
        </button>
        {{ "ACTIVITY.ACTION.PAY_THEORY.PAY_FEES_YES" | translate }}
    </mat-radio-button>
    <mat-radio-button [value]="'merchant_fee'"
                      [class.active]="this.transactingParameters.feeMode === 'merchant_fee'"
                      class="overlay-button-container"
                      [checked]="this.transactingParameters.feeMode === 'merchant_fee'">
        <button class="overlay-button" (click)="changeFeeMode('merchant_fee')">
        </button>
        {{ "ACTIVITY.ACTION.PAY_THEORY.PAY_FEES_NO" | translate }}
    </mat-radio-button>
</mat-radio-group>
<ion-button
        *ngIf="ready && !params.paid"
        shape="round"
        [fill]="'outline'"
        class="positive-focus"
        [disabled]="!isFormValid || paying || this.sectionCtrl.manager.getActivity().status === 'CLOSE' || manager.mode !== 'view'"
        (click)="pay()">
    {{"ACTIVITY.ACTION.PAY_THEORY.PAY" | translate}} ${{params.amount | number: '1.2-2'}}
</ion-button>
<ion-button
        *ngIf="params.paid"
        shape="round"
        [fill]="'solid'"
        class="positive-focus"
        [disabled]="true">
    {{"ACTIVITY.ACTION.PAY_THEORY.PAID" | translate}} ${{params.amount | number: '1.2-2'}}
</ion-button>
<ng-container *ngIf="error">
  <ion-chip color="danger">{{error}}</ion-chip>
</ng-container>
<p class="policy-links my-3">
    {{ "ACTIVITY.ACTION.PAY_THEORY.LEGAL_POLICIES.BY_SUBMITTING" | translate }}
    <a class="grey-nowrap-link" href="https://www.paytheory.com/return-and-refund-policy/" target="_blank">{{ "ACTIVITY.ACTION.PAY_THEORY.LEGAL_POLICIES.REFUND" | translate }}</a>,
    <a class="grey-nowrap-link" href="https://www.actionaly.com/Actionaly%20Privacy%20Policy%20-%20July%202023.pdf" target="_blank">{{ "ACTIVITY.ACTION.PAY_THEORY.LEGAL_POLICIES.PRIVACY" | translate }}</a>,
    {{ "ACTIVITY.ACTION.PAY_THEORY.LEGAL_POLICIES.AND" | translate }}
    <a class="grey-nowrap-link" href="https://www.paytheory.com/secure-checkout-policy/" target="_blank">{{ "ACTIVITY.ACTION.PAY_THEORY.LEGAL_POLICIES.CHECKOUT" | translate }}</a>.
</p>

<div class="external-links">
    <div [innerHTML]="params.merchantLegalAddress | sanitize">
    </div>
    <div>
        {{ "ACTIVITY.ACTION.PAY_THEORY.SUPPORT_CONTACT" | translate }}<br>
        <a class="grey-nowrap-link" [href]="'mailto:' + params.merchantLegalContact">{{params.merchantLegalContact}}</a>
    </div>
    <div>
        <img src="assets/img/powered_by_pay_theory.png" alt="Powered by PayTheory">
    </div>
</div>

<ng-container *ngIf="paymentError">
    <p>{{ "ACTIVITY.ACTION.PAY_THEORY.THE_FOLLOWING" | translate }} <span
            class="text-negative">{{ "ACTIVITY.ACTION.PAY_THEORY.ERROR" | translate }}</span> {{ "ACTIVITY.ACTION.PAY_THEORY.OCCURRED" | translate }}
        :</p>
    <p>{{paymentError}}</p>
</ng-container>
