import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';
import { InputBaseController } from '../../input-controller';
import { Browser } from '@capacitor/browser';
import { ApiUrlService } from '../../../../services/api-url.service';
import DynValue from '../../../../models/dyn-value';
import { Dictionary } from 'lodash';
import Action from '../../../../models/action';

@Component({
  selector: 'app-action-unlayer-newsletter',
  templateUrl: './action-unlayer-newsletter.component.html',
  styleUrls: ['./action-unlayer-newsletter.component.scss'],
})
export class ActionUnlayerNewsletterComponent extends InputBaseController implements OnInit {

  public static className: string = 'action-unlayer-newsletter';
  public static readonly responseKeys: string[] = ['clicked'];

  public params: {
    text: string,
    clicked: boolean
  };

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    private apiUrlService: ApiUrlService,
  ) {
    super(injector, viewContainerRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public openLink(e: Event): void {
    e.preventDefault();
    Browser.open({
      url: encodeURI(`${this.apiUrlService.apiUrl}newsletters/${this.manager.getActivity().id}`),
      presentationStyle: 'fullscreen'
    });
    this.params.clicked = true;
  }

  // tslint:disable-next-line: member-ordering
  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let clicked: string = '';
    if (getValue(action.params.clicked) === true) {
      clicked = 'Clicked';
    } else if (getValue(action.params.clicked) === false) {
      clicked = 'Not clicked';
    } else if (getValue(action.params.clicked)) {
      clicked = getValue(action.params.clicked);
    }
    return { clicked };
  }

}
