import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { isMobile, isSafariDesktop } from '../../../../../utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { QuillImagesRestrictionService } from '../../../../services/quill/quill-images-restriction.service';
import { EmailImagesFormatterService } from '../../../../services/email-images-formatter.service';
import { NativeAppLinksSanitizerService } from '../../../../services/native-app-links-sanitizer.service';
import { QuillPluginsService } from '../../../../services/quill/quill-plugins.service';
import { QuillModule } from 'ngx-quill';
import { InputBaseController } from '../../input-controller';
import { i18n } from '../../../../services/i18n';
import { QuillImagesUploaderService } from '../../../../services/quill/quill-images-uploader.service';

@Component({
  selector: 'app-action-description',
  templateUrl: './action-description.component.html',
  styleUrls: ['./action-description.component.scss'],
})
export class ActionDescriptionComponent extends InputBaseController implements OnInit, AfterViewInit {

  @ViewChild('quillEditorComponent') quillEditorComponent: ElementRef;

  public get canSave() {
    if (!this.quill) return true;
    const uploadImagesContainer = this.quillImagesUploaderService.quillEditorToUploadingImages.get(this.quill);
    if (!uploadImagesContainer) return true;
    return uploadImagesContainer.uploadingImages.length === 0;
  }

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    public plt: Platform,
    private sanitizer: DomSanitizer,
    private quillPlugins: QuillPluginsService,
    private linksSanitizer: NativeAppLinksSanitizerService,
    private emailImagesFormatter: EmailImagesFormatterService,
    private quillImagesRestriction: QuillImagesRestrictionService,
    private quillImagesUploaderService: QuillImagesUploaderService,
  ) {
    super(injector, viewContainerRef);
    if (!isMobile(this.plt)) {
      this.quillModules['ImageOptions'].modules.push('Resize');
    }
  }
  public static className: string = 'action-description';
  public static showMore: string = i18n('Show more');
  public static showLess: string = i18n('Show less');
  public static paragraphVerticalMargin: number = 30;

  public static readonly responseKeys: string[] = ['text'];

  public descriptionOpened: boolean = true;
  public shouldReduceDescription: boolean = false;
  public showButtonText: string;
  public params: {
    text: string,
    formattedText: string,
    reduce: boolean
  };

  // will be filled in descriptionInit..
  public openedMaxHeight: number;
  public closedMaxHeight: number = 120;
  public currentMaxHeight: number;
  private quill: { root: HTMLDivElement };
  public quillBounds: HTMLElement;

  public quillModules: QuillModule = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }],
      [{ color: [] }],
      ['link'],
      ['image']
    ],
    ImageOptions: {
      modules: [
        'DisplaySize',
        'Toolbar',
        'Rotate',
      ]
    }
  };

  // prevent goign to next / previous section when scrolling through quill
  @HostListener('mousewheel', ['$event'])
  private onMouseWheel(event: WheelEvent): void {
    if (this.type === 'input') {
      event.stopPropagation();
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.params.formattedText = this.params.formattedText || this.params.text;
    if (this.params.reduce) {
      this.shouldReduceDescription = true;
    }
  }

  public ngAfterViewInit(): void {
    this.quillBounds = this.quillEditorComponent && this.quillEditorComponent.nativeElement;
  }

  public descriptionInit(elem: ElementRef): void {
    let divElement: HTMLDivElement = (elem.nativeElement as HTMLDivElement);

    this.openDescription();

    // wait for everything to be generated before doing this
    setTimeout(() => {
      this.openedMaxHeight = divElement.clientHeight;
      if (divElement.clientHeight - ActionDescriptionComponent.paragraphVerticalMargin <= this.closedMaxHeight) {
        this.shouldReduceDescription = false;
      } else if (this.shouldReduceDescription) {
        this.closeDescription();
      }
    });
  }

  public editQuillInit(quill: any): void {
    // hyperlink placeholder
    // https://github.com/quilljs/quill/issues/1107
    quill.theme.tooltip.root.querySelector('input[data-link]').dataset.link = 'https://actionaly.com';

    this.quill = quill;
    this.linksSanitizer.sanitizeInAppLinks(this.quill.root);
    this.onChange();
  }

  public async editQuillChanged(quill: any): Promise<void> {
    // this.quillImagesRestriction.processImagesRestriction(quill, 1);
    this.linksSanitizer.sanitizeInAppLinks(this.quill.root);
    await this.quillImagesUploaderService.extractAndUploadImages(quill);
    this.onChange();
  }

  public async onChange(...params: any[]): Promise<void> {
    // await this.formatColorsForDark.formatColorsForDarkModeCompatibility(this.quill.root);
    this.params.formattedText = await this.emailImagesFormatter.formatImagesForEmailCompatibility(this.quill.root);
    super.onChange(params);
  }

  /// can be used safely even if description is already opened
  public openDescription(): void {
    this.showButtonText = ActionDescriptionComponent.showLess;
    this.currentMaxHeight = this.openedMaxHeight;

    this.descriptionOpened = true;
  }

  /// can be used safely even if description is already closed
  public closeDescription(): void {
    this.showButtonText = ActionDescriptionComponent.showMore;
    this.currentMaxHeight = this.closedMaxHeight;

    this.descriptionOpened = false;
  }

  public toggleDescription(): void {
    this.descriptionOpened ? this.closeDescription() : this.openDescription();
  }

  public sanitize(text: string): SafeHtml {
    if (this.plt.is('ios') || isSafariDesktop) {
      return text;
    }
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  public validation(): string {
    if (!this.canSave) {
      return 'Please wait for the upload of all pictures';
    }
    return super.validation();
  }
}
