<ng-container *ngIf="type == 'show' || manager.mode === 'review'; else inputForm">
    <div class="show-div">
        <span class="show-span">{{prefix + text}}</span>
    </div>
</ng-container>
<ng-template #inputForm>
    <mat-form-field>
        <input class="amount-input"
               type="text"
               matInput
               placeholder="{{ placeholder }}"
               [imask]="{mask: [amountMask]}"
               unmask
               [(ngModel)]="text"
               (ngModelChange)="onChange()"
        >
        <span class="prefix" matPrefix>{{prefix}}</span>
    </mat-form-field>
</ng-template>
