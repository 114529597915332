import {Component} from '@angular/core';
import {ICartItem} from '../action-shopping/action-shopping.component';
import {InputBaseController} from '../../input-controller';
import Action from '../../../../models/action';

export interface ActionShoppingCartParams {
  totalPrice: number;
  itemNumber: number;
  cart: ICartItem[];
  displayTitle?: boolean;
  expandable?: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-shopping-cart',
  templateUrl: './action-shopping-cart.component.html',
  styleUrls: ['./action-shopping-cart.component.scss'],
})
export class ActionShoppingCartComponent extends InputBaseController {
  public static className: string = 'action-shopping-cart';

  public static readonly responseKeys: string[] = ['totalPrice', 'itemNumber', 'cart'];
  public params: ActionShoppingCartParams = {
    totalPrice: 0,
    itemNumber: 0,
    cart: [],
    displayTitle: true,
    expandable: true,
  };
  public openedIndex: number = -1;

  protected initAction(action: Action): void {
    super.initAction(action);
    this.updateQuantity();
  }

  public updateQuantity(): void {
    this.params.itemNumber = 0;
    this.params.totalPrice = 0;
    this.params.cart.forEach(i => {
      this.params.itemNumber += i.qty;
      this.params.totalPrice += i.qty * i.unitPrice;
    });
    this.onChange();
  }

  public removeItem(i: number): void {
    this.params.cart[i].qty--;
    if (this.params.cart[i].qty <= 0) {
      this.delItem(i);
    }
    this.updateQuantity();
  }

  public addItem(i: number): void {
    this.params.cart[i].qty++;
    this.updateQuantity();
  }

  public delItem(i: number): void {
    this.params.cart.splice(i, 1);
    this.updateQuantity();
  }

  public openItem(e: MouseEvent, itemIndex: any): void {
    e.stopPropagation();
    if (!this.params.expandable) {
      return;
    }
    this.openedIndex = itemIndex === this.openedIndex ? -1 : itemIndex;
  }
}
