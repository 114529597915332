import {QuillModule} from 'ngx-quill';
import {IonicModule} from '@ionic/angular';
import {NgModule, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {Dictionary} from 'lodash';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../pipes/pipes.module';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatSliderModule} from '@angular/material/slider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {InputBaseController} from './input-controller';
import {TimeZoneSelectModule} from '../time-zone-select/time-zone-select.module';

import {SectionContainerComponent} from './section-container/section-container.component';
import {ActionContainerComponent} from './action-container/action-container.component';
import {LabelSectionComponent} from './label-section/label-section.component';
import {LabelFormComponent} from './label-form/label-form.component';
import {ButtonArrowComponent} from './button-arrow/button-arrow.component';

import {ActionActivityHeaderComponent} from './actions/action-activity-header/action-activity-header.component';
import {ActionAddressComponent} from './actions/action-address/action-address.component';
import {ActionAmountComponent} from './actions/action-amount/action-amount.component';
import {ActionBirthdateComponent} from './actions/action-birthdate/action-birthdate.component';
import {ActionButtonComponent} from './actions/action-button/action-button.component';
import {ActionCheckboxComponent} from './actions/action-checkbox/action-checkbox.component';
import {ActionDateComponent} from './actions/action-date/action-date.component';
import {ActionDateDiffComponent} from './actions/action-date-diff/action-date-diff.component';
import {ActionDateMonthComponent} from './actions/action-date-month/action-date-month.component';
import {ActionDateTimeComponent} from './actions/action-date-time/action-date-time.component';
import {ActionDateTimeRangeComponent} from './actions/action-date-time-range/action-date-time-range.component';
import {ActionDescriptionComponent} from './actions/action-description/action-description.component';
import {ActionDisclaimerComponent} from './actions/action-disclaimer/action-disclaimer.component';
import {ActionDocComponent} from './actions/action-doc/action-doc.component';
import {ActionLinkComponent} from './actions/action-link/action-link.component';
import {ActionLoadFromFileComponent} from './actions/action-load-from-file/action-load-from-file.component';
import {ActionMultipleDatesComponent} from './actions/action-multiple-dates/action-multiple-dates.component';
import {ActionNumberComponent} from './actions/action-number/action-number.component';
import {ActionPasswordComponent} from './actions/action-password/action-password.component';
import {ActionPhoneComponent} from './actions/action-phone/action-phone.component';
import {ActionRadioComponent} from './actions/action-radio/action-radio.component';
import {ActionRangeComponent} from './actions/action-range/action-range.component';
import {ActionRealtimeSlotsComponent} from './actions/action-realtime-slots/action-realtime-slots.component';
import {ActionSelectComponent} from './actions/action-select/action-select.component';
import {ActionShoppingComponent} from './actions/action-shopping/action-shopping.component';
import {ActionShoppingCartComponent} from './actions/action-shopping-cart/action-shopping-cart.component';
import {ActionSlotMakerComponent} from './actions/action-slot-maker/action-slot-maker.component';
import {ActionSlotsComponent} from './actions/action-slots/action-slots.component';
import {ActionStripeComponent} from './actions/action-stripe/action-stripe.component';
import {ActionStripeFeesComponent} from './actions/action-stripe-fees/action-stripe-fees.component';
import {ActionTextComponent} from './actions/action-text/action-text.component';
import {ActionTextAreaComponent} from './actions/action-text-area/action-text-area.component';
import {ActionUploadComponent} from './actions/action-upload/action-upload.component';
import {ActionUsersComponent} from './actions/action-users/action-users.component';

import {InputUserModule} from '../inputs/input-user/input-user.module';
import {InputPhoneModule} from '../inputs/input-phone/input-phone.module';
import {InputFileModule} from '../inputs/input-file/input-file.module';
import {AutoCompleteModule} from '../ionic2-auto-complete';
import {DirectivesModule} from '../../directives/directives.module';
import {DatePickerModule} from '../date-time/date-picker/date-picker.module';
import {TimePickerModule} from '../date-time/time-picker/timepicker.module';
import { ActionHtmlComponent } from './actions/action-html/action-html.component';
import { ActionPayTheoryComponent } from './actions/action-pay-theory/action-pay-theory.component';
import { ActionUnlayerNewsletterComponent } from './actions/action-unlayer-newsletter/action-unlayer-newsletter.component';
import { IMaskModule } from 'angular-imask';

const components: any[] = [
  SectionContainerComponent,
  ActionContainerComponent,
  LabelSectionComponent,
  LabelFormComponent,
  ButtonArrowComponent,
  ActionActivityHeaderComponent,
  ActionAddressComponent,
  ActionAmountComponent,
  ActionBirthdateComponent,
  ActionButtonComponent,
  ActionCheckboxComponent,
  ActionDateComponent,
  ActionDateDiffComponent,
  ActionDateMonthComponent,
  ActionDateTimeComponent,
  ActionDateTimeRangeComponent,
  ActionDescriptionComponent,
  ActionDisclaimerComponent,
  ActionDocComponent,
  ActionHtmlComponent,
  ActionLinkComponent,
  ActionLoadFromFileComponent,
  ActionMultipleDatesComponent,
  ActionNumberComponent,
  ActionPasswordComponent,
  ActionPhoneComponent,
  ActionRadioComponent,
  ActionRangeComponent,
  ActionRealtimeSlotsComponent,
  ActionSelectComponent,
  ActionShoppingComponent,
  ActionShoppingCartComponent,
  ActionSlotMakerComponent,
  ActionSlotsComponent,
  ActionStripeComponent,
  ActionStripeFeesComponent,
  ActionTextComponent,
  ActionTextAreaComponent,
  ActionUploadComponent,
  ActionUsersComponent,
  ActionPayTheoryComponent,
  ActionUnlayerNewsletterComponent,
];

InputBaseController.actions = (() => {
  let dico: Dictionary<typeof InputBaseController> = {};
  components.forEach(type => {
    dico[type.className] = type;
  });
  return dico;
})();
InputBaseController.actionTypes = (() => {
  let dico: Dictionary<Type<InputBaseController>> = {};
  components.forEach(type => {
    dico[type.className] = type;
  });
  return dico;
})();

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    IonicModule,
    QuillModule,
    AutoCompleteModule,
    TimePickerModule,
    IMaskModule,
    TranslateModule.forChild(),
    MatExpansionModule,
    MatSelectModule,
    DatePickerModule,
    DirectivesModule,
    InputUserModule,
    InputPhoneModule,
    InputFileModule,
    TimeZoneSelectModule,
    PipesModule,
    ReactiveFormsModule,
  ],
  exports: components,
  entryComponents: components,
})
export class InputBaseModule {
}

