import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import Action from '../../../../models/action';
import { Day } from '../../../../models/ptc';
import { INgbTime } from '../../../date-time/time-picker-modal/time-picker-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-slot-maker',
  templateUrl: './action-slot-maker.component.html',
  styleUrls: ['./action-slot-maker.component.scss'],
})
export class ActionSlotMakerComponent extends InputBaseController {
  public static className: string = 'action-slot-maker';
  public static readonly responseKeys: string[] = ['slots'];
  public params: {
    datesArray: Date[],
    slotDuration: number, // in minutes
    breaks: number, // in minutes
    slots: SlotCol[],
    days: Day[];
  };
  dates: Day[] = [];
  slotsCount: number = 0;
  startAfterEnd: boolean = false;

  protected initAction(action: Action): void {
    super.initAction(action);
    this.params.datesArray = this.params.datesArray || [];
    this.params.breaks = this.params.breaks || 5;
    this.params.slotDuration = this.params.slotDuration || 20;
    this.params.slots = this.params.slots || [];

    const dayNames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    if (this.params.days
      && this.params.days.length === this.params.datesArray.length
      && this.params.days.every((day, index) => day.date.getTime() === this.params.datesArray[index].getTime())) {
      this.dates = this.params.days;
    } else {
      this.params.datesArray.forEach((d, index) => {
        let existingDay = this.params.days && this.params.days.find(day => day.date.getTime() === d.getTime());
        this.dates.push({
          header: {
            title: dayNames[d.getDay()] + ' ' + this.getNumericSuffix(d.getDate()) + ' ' + monthNames[d.getMonth()]
          },
          shifts: existingDay ? existingDay.shifts : [
            {
              start: null,
              end: null,
            }
          ],
          date: d,
          open: false
        });
      });
    }
    this.params.days = this.dates;
    this.dropDown(this.dates[0]);
    this.setSlots();
  }

  getNumericSuffix(num: number): string {
    if (num === 0) {
      return num + '';
    }
    if (num === 1) {
      return num + 'st';
    }
    if (num === 2) {
      return num + 'nd';
    }
    if (num === 3) {
      return num + 'rd';
    }
    return num + 'th';
  }

  dropDown(day): void {
    this.dates = this.dates.map(d => {
      d.open = false;
      return d;
    });
    day.open = true;
  }

  applyToAll(day): void {
    this.dates = this.dates.map(d => {
      d.shifts = day.shifts.map(s => {
        return {
          start: new Date(s.start),
          end: new Date(s.end)
        };
      });
      return d;
    });
    this.setSlots();
  }

  removeShift(day, shift): void {
    let index = day.shifts.indexOf(shift);
    if (index >= 0) {
      day.shifts.splice(index, 1);
    }
    if (day.shifts.length === 0) {
      this.addShift(day);
    }
    this.setSlots();
  }

  getMin(day, shiftIndex: number): Date {
    if (shiftIndex === 0) {
      return day.date;
    }
    return new Date(day.shifts[shiftIndex - 1].end);
  }

  addShift(day): void {
    day.shifts.push({
      start: null,
      end: null,
    });
    this.setSlots();
  }

  getShiftsNumber(day): any[] {
    return day.shifts.filter(shift => {
      return !!shift.start && !!shift.end;
    });
  }

  setSlots(): void {
    let cols = [];

    this.dates.forEach((day, index) => {
      let col = new SlotCol(day, index);
      let rowIndex = 0;
      day.shifts.forEach((shift) => {
        if (shift.start && shift.end) {
          let y = day.date.getFullYear(), m = day.date.getMonth(), d = day.date.getDate();
          shift.start.setFullYear(y);
          shift.start.setMonth(m, d);
          shift.start.setSeconds(0);
          shift.start.setMilliseconds(0);
          shift.end.setFullYear(y);
          shift.end.setMonth(m, d);
          shift.end.setSeconds(0);
          shift.end.setMilliseconds(0);
          this.getSlots(shift.start, shift.end).forEach((s) => {
            col.rows.push(new SlotRow(s, rowIndex));
            rowIndex++;
          });
        }
      });
      cols.push(col);
    });
    this.params.slots = cols;
    this.countSlots();
    this.onChange();
  }

  countSlots() {
    let count = 0;
    this.params.slots.forEach(col => {
      count += col.rows.length;
    });
    this.slotsCount = count;
  }

  getSlots(from: Date, to: Date): any[] {
    let slots = [];
    if (from.getTime() >= to.getTime()) {
      return [];
    }
    let currentTime: number = from.getTime();
    let slotDuration = this.params.slotDuration * 60 * 1000;//milliseconds
    let breakDuration = this.params.breaks * 60 * 1000;//milliseconds
    for (let i = 0; currentTime + slotDuration <= to.getTime(); i++) {
      slots.push(new Date(currentTime));
      currentTime += (slotDuration + breakDuration);
    }
    return slots;
  }

  setStartDate(e: INgbTime, day: Day, shift: { start: Date; end: Date; }) {
    shift.start = new Date(day.date);
    shift.start.setHours(e.hour, e.minute);
    if (shift.end) {
      this.setSlots();
    }
  }

  setEndDate(e: INgbTime, day: Day, shift: { start: Date; end: Date; }) {
    shift.end = new Date(day.date);
    shift.end.setHours(e.hour, e.minute);
    if (shift.start) {
      this.setSlots();
    }
  }

  checkStartAfterEnd(shift) {
    return shift.end && shift.start && shift.start > shift.end;
  }
}

class SlotCol {
  name: string;
  value: Date;
  index: number;
  rows: SlotRow[] = [];

  constructor(day, index) {
    this.name = day.header.title;
    this.value = day.date;
    this.index = index;
  }
}

class SlotRow {
  name: string;
  value: any;
  index: number;
  taken: number = 0; // 0 = free, 1 = taken
  userId: string = '';

  constructor(date: Date, index: number) {
    this.value = date;
    this.index = index;
    let h = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    let m = date.getMinutes();
    let suffix = date.getHours() >= 12 ? ' PM' : ' AM';
    this.name = this.twoD(h) + ':' + this.twoD(m) + suffix;
  }

  twoD(num: number): string {
    if (num >= 10) {
      return num.toString();
    }
    return '0' + num;
  }
}
