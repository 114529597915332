import AMergeable from './a-mergeable';
import Action from './action';
import Condition from './condition';
import Execution from './execution';
import Validation from './validation';
import AFormManager from './a-form-manager';
import {SectionContainerComponent} from '../components/form/section-container/section-container.component';

export default class Section extends AMergeable {
  alias: string;
  actions: Action[];
  disabled: Condition[][];
  onLeave: Execution[];
  onEnter: Execution[];
  validations: Validation[];
  label: string;

  selected: boolean = false;
  controller: SectionContainerComponent;

  constructor(json: any) {
    super();
    this.alias = json.alias;
    this.initialize(Action, json.actions, 'actions');
    this.initialize(Condition, json.disabled, 'disabled');
    this.initialize(Execution, json.onLeave, 'onLeave');
    this.initialize(Execution, json.onEnter, 'onEnter');
    this.initialize(Validation, json.validations, 'validations');
    this.label = json.label;
  }

  public select(manager: AFormManager): boolean {
    if (this.selected) {
      return true;
    }
    if (this.isDisabled(manager)) {
      return false;
    }
    if (!this.actions.some(action => action ? !action.isDisabled(manager) : false)) {
      return false;
    }
    this.selected = true;
    this.onEnterEvent(manager);
    return true;
  }

  public unselect(manager: AFormManager): boolean {
    if (!this.selected) {
      return true;
    }
    if (manager.validation(this.validations)) {
      return false;
    }
    if (this.actions.some(action => action ? !action.canLeave(manager) : false)) {
      return false;
    }
    this.forceUnselect(manager);
    return true;
  }

  private execute(manager: AFormManager, executions: Execution[]): void {
    executions?.forEach(e => manager.execute(e));
  }

  public onLeaveEvent(manager: AFormManager): void {
    this.execute(manager, this.onLeave);
  }

  public onEnterEvent(manager: AFormManager): void {
    this.execute(manager, this.onEnter);
  }

  public forceUnselect(manager: AFormManager): void {
    this.selected = false;
    this.onLeaveEvent(manager);
  }

  public isDisabled(manager: AFormManager): boolean {
    return manager.checkConditions(this.disabled);
  }

  public getActions(): Action[] {
    return this.actions.filter(a => a);
  }
}
