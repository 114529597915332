<div class="SlotMaker">
  <div class="date" *ngFor="let day of dates; let i = index">
    <div class="header" (click)="dropDown(day)">
      <div class="title">{{day.header.title}}</div>
      <div class="shiftsNb">
        <div class="shiftBadge" *ngFor="let shiftNb of getShiftsNumber(day)"></div>
      </div>
      <div class="dropdownArrow" [ngClass]="{'open': day.open}">
        <ion-icon name="chevron-forward">
        </ion-icon>
      </div>

    </div>
    <div class="body"
         [ngClass]="{'open': day.open, 'close': !day.open}">
      <div class="shifts">
        <div class="shift" *ngFor="let shift of day.shifts; let j = index">
          <time-picker
            placeholder="From"
            [time]="shift.start | date:'hh:mm aaa'"
            (okClick)="setStartDate($event, day, shift)"
          >
          </time-picker>
          <div *ngIf="checkStartAfterEnd(shift)" style="color: #EE6352;">
            {{ "ACTIVITY.ACTION.SLOT_MAKER.THE_END_TIME_YOU_ENTERED_IS_BEFORE" | translate }}
          </div>
          <time-picker
            placeholder="To"
            [time]="shift.end | date:'hh:mm aaa'"
            (okClick)="setEndDate($event, day, shift)"
          >
          </time-picker>
          <span></span>
          <button class="remove-shift-btn" (click)="removeShift(day, shift)"><ion-icon name="close-circle"></ion-icon></button>
        </div>
      </div>
      <ion-button (click)="addShift(day)"><ion-icon slot="icon-only" name="add"></ion-icon></ion-button>
      <ion-button *ngIf="i==0" (click)="applyToAll(day)">{{ "ACTIVITY.ACTION.SLOT_MAKER.APPLY_TO_ALL" | translate }}</ion-button>
    </div>
  </div>
  <div class="nbSlots">{{slotsCount}} {{ "ACTIVITY.ACTION.SLOT_MAKER.SLOTS_CREATED" | translate }}</div>
</div>
