/// <reference types="@types/googlemaps" />

import {Injectable} from '@angular/core';
import GoogleMapsLoader from 'google-maps';
import {BehaviorSubject, Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {filter} from 'rxjs/operators';
import Http from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AutoCompleteService} from '../components/ionic2-auto-complete';

declare var google: any;

const GOOGLE_API_EXTERNAL_URL: string = 'https://maps.googleapis.com';
const GOOGLE_API_URL: string = window.location.hostname === 'localhost' ? '/googlemapsapi' : GOOGLE_API_EXTERNAL_URL;
const GOOGLE_API_STATICMAP_PATH: string = '/maps/api/staticmap';

@Injectable()
export class GoogleApiService implements AutoCompleteService {

  private placesKey: string = environment.GOOGLE_APP_KEY;
  private staticMapKey: string = environment.GOOGLE_APP_KEY;
  private placesAutocompleteService: google.maps.places.AutocompleteService;
  private placesService: google.maps.places.PlacesService;
  private mapLoadSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private mapBounds: google.maps.LatLngBounds;
  public queryFilter: string[];
  public emptyItem: boolean;
  public emptyItemText: string;
  public emptyItemSubText: string;
  public placeTypes: string[] = [];
  public labelAttribute: string = 'descriptionStart';

  // constructor(public http: Http) {
  constructor() {
    (GoogleMapsLoader as any).KEY = environment.GOOGLE_APP_KEY;
    (GoogleMapsLoader as any).LANGUAGE = 'en';
    (GoogleMapsLoader as any).VERSION = 'quarterly';
    (GoogleMapsLoader as any).LIBRARIES = ['places'];

    if (GoogleMapsLoader.isLoaded()) {
      this.init();
    } else {
      GoogleMapsLoader.load((googleMaps) => {
      });
      GoogleMapsLoader.onLoad((googleMaps) => {
        this.init();
      });
    }
  }

  private init(): void {
    this.placesAutocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    /* if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        let circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        this.mapBounds = circle.getBounds();
        console.dir(this.mapBounds);
      });
    } */
    let geolocation: { lat: number, lng: number } = {
      lat: 37.773972,
      lng: -122.431297
    };
    let circle: google.maps.Circle = new google.maps.Circle({
      center: geolocation,
      radius: 20
    });
    this.mapBounds = circle.getBounds();

    this.mapLoadSubj.next(true);
  }

  public getPlacePredictions(value: string): Promise<ExtendedAutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      this.mapLoadSubj.asObservable().pipe(filter((val) => val === true)).subscribe(() => {

        this.placesAutocompleteService.getPlacePredictions({input: value, bounds: this.mapBounds, types: this.placeTypes},
          (result: ExtendedAutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
              return;
            }
            result.forEach((val: ExtendedAutocompletePrediction) => {
              let description: string[] = val.description.split(',');
              val.descriptionStart = description.shift();
              val.descriptionEnd = description.join(',').trim();
            });
            resolve(result);
          });

      });
    });
  }


  public getLocalityPredictions(value: string): Promise<ExtendedAutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      this.mapLoadSubj.asObservable().pipe(filter((val) => val === true)).subscribe(() => {

        this.placesAutocompleteService.getQueryPredictions({input: value, bounds: this.mapBounds},
          (result: ExtendedAutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
              return;
            }
            result.forEach((val: ExtendedAutocompletePrediction) => {
              let description: string[] = val.description.split(',');
              val.descriptionStart = description.shift();
              val.descriptionEnd = description.join(',').trim();
            });

            result = result.filter(res => res.place_id);

            if (this.queryFilter && this.queryFilter.length > 0) { // Types queryFilter
              if (result.length > 0) {
                result = result.filter(res => {
                  return res.types && res.types.length > 0 && res.types.some((val) => this.queryFilter.indexOf(val) !== -1);
                });
              }
            }

            // Dropdown Item to edit address
            if (this.emptyItem) {
              const empty: ExtendedAutocompletePrediction = {
                description: '',
                matched_substrings: [],
                place_id: '',
                reference: '',
                structured_formatting: {
                  main_text: '',
                  main_text_matched_substrings: [],
                  secondary_text: ''
                },
                terms: [],
                types: [],
                descriptionStart: this.emptyItemText,
                descriptionEnd: this.emptyItemSubText
              };
              result.push(empty);
            }
            resolve(result);
          });
      });
    });
  }

  public getAddressDetails(placeId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.placesService.getDetails({placeId},
        (place: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(status);
            return;
          }
          resolve(place);
        });
    });
  }

  public buildRelativeStaticMapUrl(locationDescription: string, zoom: string, size: string, scale: string, markerColor: string): string {
    let parameters: string;
    parameters = '&zoom=' + zoom;
    parameters += '&size=' + size;
    parameters += '&scale=' + scale;
    parameters += '&markers=' + 'color:' + markerColor + '%7C' + encodeURI(locationDescription);
    return GOOGLE_API_STATICMAP_PATH + '?key=' + this.staticMapKey + parameters;
  }

  public buildStaticMapUrl(locationDescription: string, zoom: string, size: string, scale: string, markerColor: string): string {
    return GOOGLE_API_URL + this.buildRelativeStaticMapUrl(locationDescription, zoom, size, scale, markerColor);
  }

  public buildServerStaticMapUrl(locationDescription: string, zoom: string, size: string, scale: string, markerColor: string): string {
    return GOOGLE_API_EXTERNAL_URL + this.buildRelativeStaticMapUrl(locationDescription, zoom, size, scale, markerColor);
  }

  public getResults(keyword: string): Observable<ExtendedAutocompletePrediction[]> {
    if (this.placeTypes.length > 0) {
      return fromPromise(this.getPlacePredictions(keyword));
    }
    return fromPromise(this.getLocalityPredictions(keyword));
  }
}

export interface ExtendedAutocompletePrediction extends google.maps.places.AutocompletePrediction {
  descriptionStart: string;
  descriptionEnd: string;
}
