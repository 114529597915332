import {Component} from '@angular/core';
import Action from '../../../../models/action';
import {InputBaseController} from '../../input-controller';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-phone',
  templateUrl: './action-phone.component.html',
  styleUrls: ['./action-phone.component.scss'],
})
export class ActionPhoneComponent extends InputBaseController {
  public static className: string = 'action-phone';
  public static readonly responseKeys: string[] = ['phone'];

  public mask: Array<string | RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public params: { phone: string };
  public inputContent: string;

  initAction(action: Action): void {
    super.initAction(action);
    this.inputContent = this.params.phone;
  }

  public setPhone(phone: string): void {
    this.params.phone = phone;
    this.onChange();
  }

}
