import {DirectivesModule} from '../../../directives/directives.module';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {NgModule} from '@angular/core';
import {DateTimePickerComponent} from '../date-time-picker/date-time-picker.component';
import {TimePickerWrapperComponent} from '../time-picker-wrapper/time-picker-wrapper.component';
import {PortalModule} from '@angular/cdk/portal';
import { TimePickerModalComponentModule } from '../time-picker-modal/time-picker-modal.module';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatInputModule,
    PortalModule,
    TimePickerModalComponentModule,
  ],
  declarations: [
    DateTimePickerComponent,
    TimePickerWrapperComponent,
  ],
  exports: [
    DateTimePickerComponent,
    TimePickerWrapperComponent,
  ]
})
export class TimePickerModule {
}
