import { Component, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { InputBaseController } from '../../input-controller';
import Action from '../../../../models/action';
import { loadScript } from '../../../../../utils';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../services/user.service';
import {ApiService} from '../../../../services/api.service';
import DynValue from '../../../../models/dyn-value';
import { Dictionary } from 'lodash';
import {RefreshDialogComponent} from '../../../dialogs/refresh-dialog/refresh-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

export interface ITransactionParameters {
  amount: number;
  payorInfo?: IPayTheoryBuyerOptions;
  payorId?: string;
  metadata?: IPaymentMetadata;
  feeMode?: 'merchant_fee' | 'service_fee';
  confirmation?: boolean;
  accountCode?: string;
  reference?: string;
  paymentParameters?: string;
  invoiceId?: string;
  sendReceipt?: boolean;
  receiptDescription?: string;
}

export interface IPaymentMetadata {
  'pay-theory-account-code': string;
  'pay-theory-reference': string;
  'payment-parameters-name': string;
}

export interface IPayTheoryBuyerOptions {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  personal_address?: {
    city?: string;
    country?: string;
    region?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
  };
}

export interface ITokenizedPayment {
  first_six: string; // "XXXXXX",
  brand: string; // "XXXX",
  receipt_number: string; // "pt-dev-XXXXXX",
  amount: number; // 999,

  // included in amount
  service_fee: number; // 195
}

export interface IPaymentCompletionResponse {
  receipt_number: string; // "pt-env-XXXXXX",
  last_four: string; // "XXXX",
  brand: string; // "XXXXXXXXX",
  created_at: string; // "YYYY-MM-DDTHH:MM:SS.ssZ",
  amount: number; // 999,
  service_fee: number; // 195,
  state: 'SUCCEEDED';
  tags: {
    'pay-theory-environment': string; // 'env';
    'pt-number': string; // 'pt-env-XXXXXX';
    [tagKey: string]: string;
  };
}

export interface ICashResponse {
  BarcodeUid: string; // "XXXXXXX-XXXX-XXXX-XXXX-XXXXXXXX@partner",
  Merchant: string; // "XXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXX",
  barcode: string; // "12345678901234567890",
  barcodeFee: string; // "2.0",
  barcodeUrl: string; // "https://partner.env.ptbar.codes/XXXXXX",
  mapUrl: string; // "https://pay.vanilladirect.com/pages/locations",
}

export interface IPaymentFailureResponse {
  receipt_number: string; // "pt-test-XXXXXX",
  last_four: string; // "XXXX",
  brand: string; // "VISA",
  state: 'FAILURE';
  type: string; // "some descriptive reason for the failure / decline"
}

export interface IStateEvent {
  'account-name': IStateEventDetails;
  'account-number': IStateEventDetails;
  'account-type': IStateEventDetails;
  'billing-city': IStateEventDetails;
  'billing-line1': IStateEventDetails;
  'billing-line2': IStateEventDetails;
  'billing-state': IStateEventDetails;
  'billing-zip': IStateEventDetails;
  'card-cvv': IStateEventDetails;
  'card-exp': IStateEventDetails;
  'card-name': IStateEventDetails;
  'card-number': IStateEventDetails;
  'cash-contact': IStateEventDetails;
  'cash-name': IStateEventDetails;
  'credit-card': IStateEventDetails;
  'routing-number': IStateEventDetails;
}

export interface IStateEventDetails {
  isDirty: boolean;
  isFocused: boolean;
  errorMessages: string[];
}

type UnsubscribeFunc = () => void;

export interface IPayTheoryClient {

  /*
  * UNDOCUMENTED
  * seems to cancel a tokenized payment
  */
  cancel(): void;

  /*
  * results of the transaction with confirmation
  * fires once when capture is completed
  * only needed when REQUIRE_CONFIRMATION is true
  */
  captureObserver(callback: (transactionResult: IPaymentCompletionResponse | IPaymentFailureResponse) => void): UnsubscribeFunc;

  /*
  * results of the cash barcode generation
  * fires once barcode is generated following initTransaction
  */
  cashObserver(callback: (cashResult: ICashResponse) => void): UnsubscribeFunc;

  /*
  * UNDOCUMENTED
  * seems to confirm a tokenized payment
  */
  confirm(): void;

  /*
  * error is false or a message
  * fires every time the error state/message changes
  */
  errorObserver(callback: (error: false | string) => void): UnsubscribeFunc;

  /*
  * begin the transaction authorization by providing an amount
  * and optionally details about the buyer and a flag for confirmation
  * amount must be a positive integer or an error will be thrown
  */
  initTransaction(amount: number, buyerOptions?: IPayTheoryBuyerOptions, requireConfirmation?: boolean): void;
  transact(parameters: ITransactionParameters): void;

  /*
  * mount the hosted fields into the container
  */
  mount(): void;

  /*
  * ready is a boolean indicator
  * fires when SDK is loaded and ready
  * this is where you would associate any listeners
  * to trigger initTransaction
  * or optionally confirmation
  */
  readyObserver(callback: (ready: boolean) => void): UnsubscribeFunc;

  /*
  * results of the payment card or ACH tokenization
  * fires once when tokenization is completed
  * this is a good place to enable confirmation
  * only needed when REQUIRE_CONFIRMATION is true
  */
  tokenizeObserver(callback: (tokenized: ITokenizedPayment) => void): UnsubscribeFunc;

  /*
  * results of the transaction without confirmation
  * fires once when transaction is completed
  */
  transactedObserver(callback: (transactionResult: IPaymentCompletionResponse) => void): UnsubscribeFunc;

  /*
  * valid is a boolean indicator
  * fires every time the valid state of the hosted field changes
  * when valid is true is a good time to enable initTransaction
  */
  validObserver(callback: (valid: boolean | string) => void): UnsubscribeFunc;

  /*
   * state is a object indicating the current state of the hosted fields
   * fires every time the state changes or a field is focused/blurred
   * Example of the state object is below.
   */
  stateObserver(callback: (state: IStateEvent) => void): UnsubscribeFunc;
}

export interface ActionPayTheoryParams {
  amount: number;
  description?: string;
  receiptDescription?: string;
  reference?: string;
  paid: boolean;
  preFeesAmount: number;
  feeMode?: FEE_MODE;
  forceFeeMode?: FEE_MODE;
  merchantLegalAddress: string;
  merchantLegalContact: string;
  checkboxText: string;
  accountKey: string;
  options: IPayTheoryOptions;
}

export interface IPayTheoryOptions {
  service?: IPayTheoryOptionDetails;
  interchange?: IPayTheoryOptionDetails;
}

export interface IPayTheoryOptionDetails {
  percent?: number;
  fix?: number;
  minimum?: number;
}

// from https://actionaly.developers.paytheorylab.com/web

// optionally define custom styles for the input components text
const STYLES = {
  default: {
    height: '35px',
    marginTop: '14px',
    backgroundColor: '#f3f3f3',
    borderBottom: '1px solid #d4d4d4',
    color: 'black',
    fontSize: '14px',
    width: '100%'
  },
  success: {
    height: '35px',
    marginTop: '14px',
    backgroundColor: '#f3f3f3',
    borderBottom: '1px solid #5cb85c',
    color: '#5cb85c',
    fontSize: '14px'
  },
  error: {
    height: '35px',
    marginTop: '14px',
    backgroundColor: '#f3f3f3',
    borderBottom: '1px solid #d9534f',
    color: '#d9534f',
    fontSize: '14px'
  }
};

// optionally provide custom metadata to help track SDK Sessions
const SESSION_METADATA = {
  'page-key': 'card-payment',
  'user-id': '123456789'
};

/*
 * optionally set the fee mode for Card and ACH
 * by default interchange mode is used
 * service_fee mode is available only when enabled by Pay Theory
 * interchange mode applies a fee of 2.9% + $0.30
 * to be deducted from original amount
 * service_fee mode calculates a fee based on predetermined parameters
 * and adds it to the original amount
 */
export type FEE_MODE = 'merchant_fee' | 'service_fee';

// optionally provide custom metadata to help track payments
const PAYMENT_METADATA: IPaymentMetadata = {
  'pay-theory-account-code': 'c21cd368-4af8-4c4d-9f99-7469cab69712',
  'pay-theory-reference': 'field-trip',
  'payment-parameters-name': 'expires-in-30-days'
};

// optional parameter to require confimation step for Card or ACH
const REQUIRE_CONFIRMATION: boolean = true;

const DEFAULT_STATE: IStateEventDetails = {
  isDirty: false,
  isFocused: false,
  errorMessages: [],
};

@Component({
  selector: 'app-action-pay-theory',
  templateUrl: './action-pay-theory.component.html',
  styleUrls: ['./action-pay-theory.component.scss'],
})
export class ActionPayTheoryComponent extends InputBaseController implements OnInit, OnDestroy {
  public static className: string = 'action-pay-theory';
  public static readonly responseKeys: string[] = ['paid', 'amount', 'feeMode', 'preFeesAmount'];

  public params: ActionPayTheoryParams;

  public payTheoryClient: IPayTheoryClient;
  public ready: boolean = false;
  public expiredSession: boolean = false;
  public isCardValid: boolean = false;
  public isFormValid: boolean = false;
  public paying: boolean = false;
  public state: IStateEvent = {
    'account-name': DEFAULT_STATE,
    'account-number': DEFAULT_STATE,
    'account-type': DEFAULT_STATE,
    'billing-city': DEFAULT_STATE,
    'billing-line1': DEFAULT_STATE,
    'billing-line2': DEFAULT_STATE,
    'billing-state': DEFAULT_STATE,
    'billing-zip': DEFAULT_STATE,
    'card-cvv': DEFAULT_STATE,
    'card-exp': DEFAULT_STATE,
    'card-name': DEFAULT_STATE,
    'card-number': DEFAULT_STATE,
    'cash-contact': DEFAULT_STATE,
    'cash-name': DEFAULT_STATE,
    'credit-card': DEFAULT_STATE,
    'routing-number': DEFAULT_STATE,
  };
  public paymentError: string;
  public calculatedFees: number;

  public transactingParameters: ITransactionParameters = {
    amount: 0,
    payorInfo: {}, // optional
    // payorId: '', // optional
    // metadata: PAYMENT_METADATA, // optional
    feeMode: 'service_fee', // optional
    confirmation: REQUIRE_CONFIRMATION, // optional
    // accountCode: 'c21cd368-4af8-4c4d-9f99-7469cab69712', // optional
    reference: this.manager.getActivity().podName, // optional
    // paymentParameters: 'expires-in-30-days', // optional
    // invoiceId: 'pt_inv_XXXXXXXXX', // optional
    sendReceipt: true, // optional
    receiptDescription: this.manager.getActivity().title // optional
  };

  public readyUnsub: UnsubscribeFunc;
  public errorUnsub: UnsubscribeFunc;
  public captureUnsub: UnsubscribeFunc;
  public cashUnsub: UnsubscribeFunc;
  public tokenizeUnsub: UnsubscribeFunc;
  public transactedUnsub: UnsubscribeFunc;
  public validUnsub: UnsubscribeFunc;
  public stateUnsub: UnsubscribeFunc;

  public loader: HTMLIonLoadingElement;

  constructor(
    viewContainerRef: ViewContainerRef,
    injector: Injector,
    public api: ApiService,
    public loading: LoadingController,
    public matDialog: MatDialog,
    public translate: TranslateService,
    private userService: UserService,
    private plt: Platform,
  ) {
    super(injector, viewContainerRef);
    (window as any).actionPayTheory = this;
    this.transactingParameters.payorInfo = {
      first_name: this.userService.currentUser.firstName,
      last_name: this.userService.currentUser.lastName,
      email: this.userService.currentUser.email,
      phone: this.userService.currentUser.primaryPhone
    };
  }

  ngOnDestroy(): void {
    if (this.readyUnsub) this.readyUnsub();
    if (this.errorUnsub) this.errorUnsub();
    if (this.captureUnsub) this.captureUnsub();
    if (this.cashUnsub) this.cashUnsub();
    if (this.tokenizeUnsub) this.tokenizeUnsub();
    if (this.transactedUnsub) this.transactedUnsub();
    if (this.validUnsub) this.validUnsub();
    if (this.stateUnsub) this.stateUnsub();

    if (this.loader) {
      this.loader.dismiss();
      this.loader = undefined;
    }
  }

  protected async initAction(action: Action): Promise<void> {
    super.initAction(action);
    this.params.feeMode = this.params.feeMode ?? 'merchant_fee';
    this.transactingParameters.feeMode = this.params.feeMode;
    this.transactingParameters.receiptDescription = this.bbcodeTranslator.translate(this.params.receiptDescription || this.manager.getActivity().title);
    this.transactingParameters.reference = this.bbcodeTranslator.getSource(this.params.reference || this.manager.getActivity().podName);
    if (!this.params.paid) this.params.preFeesAmount = this.params.amount || 10.00;
    this.params.options.interchange = {
      percent: this.params.options?.interchange?.percent ?? 0,
      minimum: this.params.options?.interchange?.minimum ?? 0,
      fix: this.params.options?.interchange?.fix ?? 0,
    };
    this.params.options.service = {
      percent: this.params.options?.service?.percent ?? 0,
      minimum: this.params.options?.service?.minimum ?? 0,
      fix: this.params.options?.service?.fix ?? 0,
    };
    this.params.description = this.params.description || this.translate.instant(
      'ACTIVITY.ACTION.PAY_THEORY.{{GROUP_NAME}}_PAYMENT',
      {
        groupName: this.manager.getActivity().podName
      });
    this.calculateAmount();
    if (!this.params.paid) await this.initPayTheory(this.params.accountKey);
  }

  public calculateAmount(): void {
    this.calculatedFees = 0;
    if (this.transactingParameters.feeMode === 'service_fee') {
      let toPay: number = this.params.preFeesAmount;
      let fees: number = ( (toPay * this.params.options.service.percent / 100 ) + this.params.options.service.fix) / 100;
      this.calculatedFees = Math.max(fees, this.params.options.service.minimum / 100 );
    }
    this.params.amount = this.params.preFeesAmount + this.calculatedFees;
  }

  public changeFeeMode(mode: FEE_MODE): void {
    this.transactingParameters.feeMode = this.params.feeMode = mode;
    this.calculateAmount();
  }

  public async pay(): Promise<void> {
    if (this.expiredSession) return;
    this.calculateAmount();
    // pay theory needs the net amount, without the fees.
    this.transactingParameters.amount = Math.round(this.params.preFeesAmount * 100);
    console.debug(this.transactingParameters);
    this.payTheoryClient.transact(this.transactingParameters);
    this.paying = true;
    this.loader = await this.loading.create({
      message: this.translate.instant('ACTIVITY.ACTION.STRIPE.PROCESSING_PAYMENT')
    });
    await this.loader.present();
  }

  private async initPayTheory(accountKey: string): Promise<void> {
    if (!(window as any).paytheory) await loadScript(environment.PAY_THEORY_JS_URL);
    this.payTheoryClient = await (window as any).paytheory.create(
      accountKey,
      STYLES,
      SESSION_METADATA,
      this.transactingParameters.feeMode,
    );
    this.expiredSession = false;

    // mount the hosted fields into the container
    this.payTheoryClient.mount();

    this.readyUnsub = this.payTheoryClient.readyObserver(ready => {
      console.debug('readyObserver', ready);
      this.ready = ready;
    });

    this.errorUnsub = this.payTheoryClient.errorObserver(async error => {
      console.debug('errorObserver', error);
      if (!error) this.error = undefined;
      if (error && error.includes('SESSION_EXPIRED') && !this.expiredSession) {
        console.error(error);
        this.expiredSession = true;
        let refreshNotice: string = this.plt.is('capacitor') ? 'Please restart the app.' : 'Please refresh the page.';
        let refreshBtn: string = this.plt.is('capacitor') ? 'Restart' : 'Refresh';
        let ref: MatDialogRef<RefreshDialogComponent> = this.matDialog.open(RefreshDialogComponent, {
          data: {
            title: this.translate.instant('Session expired.'),
            text: this.translate.instant(refreshNotice),
            button: this.translate.instant(refreshBtn),
          },
          disableClose: true
        });
        ref.beforeClosed().subscribe( () => {
          // refresh
          window.location.reload();
        });
      } else {
        if (this.loader) await this.loader.dismiss();
        this.error = error as string;
      }
    });

    this.captureUnsub = this.payTheoryClient.captureObserver(async res => {
      console.debug('captureObserver', res);
      if (res.state === 'FAILURE') {
        this.error = res.type;
        if (this.loader) await this.loader.dismiss();
      } else {
        // cannot verify for the moment
        // await this.verifyPayment(res.receipt_number);
        await this.onPaymentSuccess(res);
      }
    });

    this.cashUnsub = this.payTheoryClient.cashObserver(res => {
      console.debug('cashObserver', res);
    });

    this.tokenizeUnsub = this.payTheoryClient.tokenizeObserver(async res => {
      console.debug('tokenizeObserver', res);
      await this.onPaymentTokenized(res);
    });

    this.transactedUnsub = this.payTheoryClient.transactedObserver(async res => {
      console.debug('transactedObserver', res);
      // cannot verify for the moment
      // await this.verifyPayment(res.receipt_number);
      await this.onPaymentSuccess(res);
    });

    this.validUnsub = this.payTheoryClient.validObserver(res => {
      console.debug('validObserver', res);
      this.isCardValid = res === 'card';
    });

    this.stateUnsub = this.payTheoryClient.stateObserver(async state => {
      console.debug('stateObserver', state);
      this.state = state;
      let isZipValid: boolean = state['billing-zip'].isDirty && state['billing-zip'].errorMessages.length === 0;
      let isCardNameValid: boolean = state['card-name'].isDirty && state['card-name'].errorMessages.length === 0;
      this.isFormValid = this.isCardValid && isZipValid && isCardNameValid;
      if (this.isFormValid) this.error = undefined;
      if (this.isFormValid) console.log('this.isFormValid', this.isFormValid);
    });
    this.focusInputs();
  }

  // focusing the inputs helps loading the iframes faster
  private focusInputs(): void {
    let ids: string[] = [
      'pay-theory-credit-card-account-name',
      'pay-theory-credit-card-number',
      'pay-theory-credit-card-exp',
      'pay-theory-credit-card-cvv',
      'pay-theory-credit-card-zip',
    ];
    ids.forEach(id => {
      let tag: HTMLElement = document.getElementById(id);
      tag.focus();
    });
  }

  private async onPaymentTokenized(res: ITokenizedPayment): Promise<void> {
    if (this.loader) {
      await this.loader.dismiss();
      this.loader = undefined;
    }
    this.payTheoryClient.confirm();
    this.loader = await this.loading.create({
      message: this.translate.instant('Processing payment after confirmation...'),
    });
    await this.loader.present();
  }

  private async verifyPayment(transactionId: string): Promise<void> {
    const activity = this.manager.getActivity();
    const concernedId = this.manager.getConcernedUser() && this.manager.getConcernedUser().id;
    let resp = await this.api.verifyPayTheoryPayment(
      transactionId,
      this.params.accountKey,
      concernedId,
      this.userService.currentUser._id,
      activity.id
    );
    console.log(resp);
  }

  private async onPaymentSuccess(res: IPaymentCompletionResponse | IPaymentFailureResponse): Promise<void> {
    if (this.loader) {
      await this.loader.dismiss();
      this.loader = undefined;
    }
    this.params.paid = true;
    this.params.amount = (res as IPaymentCompletionResponse).amount / 100;
    super.onChange();
    this.onClick();
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let isPaid: any = getValue(action.params.paid) || '';
    let amountPaid: number = getValue(action.params.amount) || 0;
    let preFees: number = getValue(action.params.preFeesAmount) || 0;
    let includingFees: any = amountPaid - preFees;
    return {
      isPaid: isPaid ? 'Yes' : '',
      amount: isPaid ? this.roundAmount(preFees) : '',
      totalPaid: isPaid ? this.roundAmount(amountPaid) : '',
      includedFees: isPaid ? this.roundAmount(includingFees) : '',
    };
  }

  private static roundAmount(amount: number): number {
    return Math.round((amount * 100)) / 100;
  }

}
