<div *ngIf="type == 'input' && manager.mode !== 'review'" style="text-align: center; width: 100%;">
  <div>
    <p class="ion-text-center">Expected input file format:</p>
    <mat-form-field id="profile-input">
      <input type="text" matInput [(ngModel)]="profileName" [disabled]="true">
    </mat-form-field>
  </div>
  <label id="file-name-label" class="centered-block text-positive ion-margin-top" *ngIf="this.params.fileName">
    {{this.params.fileName}}
  </label>
  <div style="position: relative">
    <input type="file" accept="{{params.fileType}}" (change)="addFile($event)"
           style="cursor: pointer; position:absolute; display: block; height: 100%; width: 100%; margin-top: 0px; z-index: 5; opacity: 0">
    <ion-button shape="round">
      {{ "ACTIVITY.ACTION.LINK.CHOOSE_FILE" | translate }}
    </ion-button>
  </div>
  <ng-container *ngIf="error">
    <p class="ion-text-center"><span class="text-negative">{{error}}</span></p>
  </ng-container>
</div>
