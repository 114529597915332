import {IonicModule} from '@ionic/angular';
import {DirectivesModule} from '../directives/directives.module';
import {NgModule} from '@angular/core';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {DatePickerModule} from './date-time/date-picker/date-picker.module';
import {InputBaseModule} from './form/input-components.module';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../pipes/pipes.module';
import {InputPhoneModule} from './inputs/input-phone/input-phone.module';
import {InputUserModule} from './inputs/input-user/input-user.module';
import {TimeZoneSelectModule} from './time-zone-select/time-zone-select.module';
import {TimerComponent} from '../../components/timer/timer';
import {DatePickerModalComponent} from './date-time/date-picker-modal/date-picker-modal.component';
import {TimePickerModule} from './date-time/time-picker/timepicker.module';
import {DisplayDisclaimerComponent} from './dialogs/display-disclaimer/display-disclaimer.component';
import {YesNoDialogComponent} from './dialogs/yes-no-dialog/yes-no-dialog.component';
import {PdfModalComponent} from './modal/pdf-modal/pdf-modal.component';
import {LinkPopUpComponent} from './link-pop-up/link-pop-up.component';
import {DocModalComponent} from './modal/doc-modal/doc-modal.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {DescriptionModalComponent} from './modal/description-modal/description-modal.component';
import {IncomingCallComponent} from './incoming-call/incoming-call.component';
import {ManagedPodHeaderComponent} from './managed-pod-header/managed-pod-header.component';
import {PodUserCountComponent} from './pod-user-count/pod-user-count.component';
import {QuillModule} from 'ngx-quill';
import {PodHeaderBarComponent} from './pod-header-bar/pod-header-bar.component';
import {ActivityViewComponent} from './activity-view/activity-view.component';
import {Calendar} from '@ionic-native/calendar/ngx';
import {Device} from '@ionic-native/device/ngx';
import {UsersListComponent} from './users-list/users-list.component';
import {AvatarComponent} from './avatar/avatar.component';
import {UserHeaderComponent} from './user-header/user-header.component';
import { BadgeIconComponent } from './badge-icon/badge-icon.component';
import { CallButtonComponent } from './call-button/call-button.component';
import { MatIconModule } from '@angular/material/icon';
import { HtmlModalComponent } from './modal/html-modal/html-modal.component';
import { ToolbarAccountButtonComponent } from './toolbar-account-button/toolbar-account-button.component';
import { RouterModule } from '@angular/router';
import { EasterComponent } from './easter/easter.component';
import {RefreshDialogComponent} from './dialogs/refresh-dialog/refresh-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import { PreferredLanguageBannerComponent } from './preferred-language-banner/preferred-language-banner.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    DirectivesModule,
    MatOptionModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    DatePickerModule,
    InputBaseModule,
    TimePickerModule,
    TranslateModule.forChild(),
    PipesModule,
    InputPhoneModule,
    InputUserModule,
    TimeZoneSelectModule,
    FormsModule,
    QuillModule,
    RouterModule,
    MatButtonModule,
  ],
  exports: [
    TranslateModule,
    TimerComponent,
    ManagedPodHeaderComponent,
    PodUserCountComponent,
    PodHeaderBarComponent,
    ActivityViewComponent,
    UsersListComponent,
    AvatarComponent,
    DatePickerModalComponent,
    YesNoDialogComponent,
    RefreshDialogComponent,
    UserHeaderComponent,
    DisplayDisclaimerComponent,
    BadgeIconComponent,
    CallButtonComponent,
    ToolbarAccountButtonComponent,
    EasterComponent,
    PreferredLanguageBannerComponent,
  ],
  declarations: [
    DatePickerModalComponent,
    TimerComponent,
    DisplayDisclaimerComponent,
    YesNoDialogComponent,
    RefreshDialogComponent,
    PdfModalComponent,
    LinkPopUpComponent,
    DocModalComponent,
    DescriptionModalComponent,
    HtmlModalComponent,
    IncomingCallComponent,
    ManagedPodHeaderComponent,
    PodUserCountComponent,
    PodHeaderBarComponent,
    ActivityViewComponent,
    UsersListComponent,
    AvatarComponent,
    UserHeaderComponent,
    BadgeIconComponent,
    CallButtonComponent,
    ToolbarAccountButtonComponent,
    EasterComponent,
    PreferredLanguageBannerComponent,
  ],
  entryComponents: [
    TimerComponent,
    DatePickerModalComponent,
    YesNoDialogComponent,
    RefreshDialogComponent,
    DisplayDisclaimerComponent
  ],
  providers: [
    Calendar,
    Device,
  ],
})
export class ComponentsModule {
}
