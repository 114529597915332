import {Component} from '@angular/core';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import {InputBaseController} from '../../input-controller';
import {Dictionary} from 'lodash';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-text-area',
  templateUrl: './action-text-area.component.html',
  styleUrls: ['./action-text-area.component.scss'],
})
export class ActionTextAreaComponent extends InputBaseController {
  public static className: string = 'action-text-area';
  public static readonly responseKeys: string[] = ['text'];

  public params: { text: string };

  protected initAction(action: Action): void {
    super.initAction(action);
    if (this.params && this.params.text) {
      this.params.text = this.bbcodeTranslator.translate(this.params.text);
    }
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let text: string = getValue(action.params.text) || '';
    return {text: text.replace(/(?:\r\n|\r|\n)/g, ' ').trim()};
  }
}
