import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PORTAL_TIMER_PROVIDER} from './timerData';
import {TimerService} from '../../app/services/timer.service';

/**
 * Generated class for the TimerComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'timer',
  templateUrl: 'timer.html'
})
export class TimerComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(PORTAL_TIMER_PROVIDER) public timer: TimerService,
  ) {
    // console.log('Hello TimerComponent Component');
  }

  ngOnInit(): void {
    // console.dir('TimerComponent ngOnInit')
  }

  ngOnDestroy(): void {
    // console.dir('TimerComponent ngOnDestroy')
  }

}
