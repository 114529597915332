import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { HotDeployService } from './services/hot-deploy.service';
import { TranslateService } from '@ngx-translate/core';
import { i18n } from './services/i18n';
import { Location } from '@angular/common';
import { AutoLoginService } from './services/auto-login.service';
import { ApiErrorHandlerService } from './services/api-error-handler.service';
import { MainLoaderHandlerService } from './services/main-loader-handler.service';
import { PushNotificationService } from './services/push-notification.service';
import { UrlExecutionService } from './services/url-execution.service';
import { LegacyUrlRedirectService } from './services/legacy-url-redirect/legacy-url-redirect.service';
import { VirtualPathRedirectService } from './services/virtual-path-redirect/virtual-path-redirect.service';
import { DeepLinkService } from './services/deep-link.service';
import { Router } from '@angular/router';
import { PreviousUrlService } from './services/routing/previous-url.service';
import { ZendeskService } from './services/zendesk/zendesk.service';
import { AuthChallengerService } from './services/auth-challenger.service';
import { ActivityNavigationTrackingService } from './services/activity-navigation-tracking/activity-navigation-tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public platformType: string;
  public redirecting: boolean = false;

  private hotDeployCompletion: Promise<void>;

  constructor(
    router: Router,
    private platform: Platform,
    private location: Location,
    private hotDeploy: HotDeployService,
    private translate: TranslateService,
    private autoLoginService: AutoLoginService,
    private apiErrorHandler: ApiErrorHandlerService,
    private mainLoaderHandler: MainLoaderHandlerService,
    private pushNotification: PushNotificationService,
    private urlExecution: UrlExecutionService,
    private legacyUrlRedirect: LegacyUrlRedirectService,
    private virtualPathRedirect: VirtualPathRedirectService,
    private activityNavigationTrackingService: ActivityNavigationTrackingService,
    private previousUrlService: PreviousUrlService,
    private deepLinkService: DeepLinkService,
    private zendeskService: ZendeskService, // needs to be instanciated even if not used
    private authChallengerService: AuthChallengerService, // needs to be instanciated even if not used
  ) {
    // https://github.com/angular/angular/issues/13586
    // @ts-ignore: private option not yet exposed for public use
    // router.canceledNavigationResolution = 'computed';  // angular 12 fix of deactivate history mess up

    translate.addLangs([i18n('en'), i18n('es'), i18n('fr')]);
    translate.setDefaultLang('en');
    translate.use('en');
    this.platformType = this.platform.is('hybrid') ? 'mobile' : 'webapp';
    this.pushNotification.subscribe();
    if (this.urlExecution.isDeprecatedUrl(window.location.hash)) {
      console.debug('isDeprecatedUrl');
      const { hash, query, shouldReload } = this.urlExecution.convertDeprecatedUrl(window.location.hash);
      const newUrl: URL = new URL('', window.location.origin);
      newUrl.hash = hash;
      if (query) {
        newUrl.search = query;
      }
      this.redirecting = shouldReload;
      window.location.href = newUrl.href;
      return;
    }
  }

  async ngOnInit(): Promise<void> {
    // no more need to separate iOS and Android init functions
    await this.init();
    await this.initializeApp();
  }

  private async init(): Promise<void> {
    if (!this.platform.is('hybrid')) return;
    this.deepLinkService.listen();
    this.hotDeployCompletion = this.hotDeploy.silentUpdate();
  }

  private async initializeApp(): Promise<void> {
    await this.platform.ready();
    await SplashScreen.hide();
    await this.mainLoaderHandler.remove();
    if (Capacitor.isPluginAvailable('StatusBar')) {
      await StatusBar.setStyle({ style: Style.Default });
      if (this.platform.is('ios')) await StatusBar.hide();
    }
  }

}
