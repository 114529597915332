import {Component, Injector, ViewContainerRef} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import {TranslateService} from '@ngx-translate/core';
import Action from '../../../../models/action';
import {DisplayDisclaimerComponent} from '../../../dialogs/display-disclaimer/display-disclaimer.component';
import { DynamicMatDialogService } from '../../../../services/dynamic-mat-dialog/dynamic-mat-dialog.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-disclaimer',
  templateUrl: './action-disclaimer.component.html',
  styleUrls: ['./action-disclaimer.component.scss'],
})
export class ActionDisclaimerComponent extends InputBaseController {
  public static className: string = 'action-disclaimer';

  public static readonly responseKeys: string[] = ['clicked'];
  public params: { text: string, clicked: boolean, buttonText: string };
  public buttonText: string;

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    private matDialog: DynamicMatDialogService,
    public translate: TranslateService,
  ) {
    super(injector, viewContainerRef);
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    if (!this.params.buttonText) {
      this.buttonText = this.translate.instant('ACTIVITY.ACTION.DISCLAIMER.BUTTON');
    } else {
      this.buttonText = this.bbcodeTranslator.translate(this.params.buttonText);
    }
    this.setupParams(action);
  }

  public setupParams(action: Action) {
    this.params.text = this.getValue(action.params.text);
    if (this.manager.mode === 'review') {
      this.params.clicked = true;
    }
  }

  public showDisclaimer(text: string): void {
    this.params.clicked = true;
    text = this.bbcodeTranslator.translate(text);
    this.matDialog.open(DisplayDisclaimerComponent, {
      data: {text},
    });
    this.updateAction();
  }
}
