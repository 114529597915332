import {Component} from '@angular/core';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import {InputBaseController} from '../../input-controller';
import {Dictionary} from 'lodash';

export interface ICartItem {
  name: string;
  option: string;
  size: string;
  qty: number;
  unitPrice: number;
  description?: string;
}

export interface IShopItem {
  name: string;
  price: number;
  options: string[];
  sizes: string[];
}

export interface ActionShoppingParams {
  cart: ICartItem[];
  totalPrice: number;
  itemNumber: number;
  shopItems: IShopItem[];
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-shopping',
  templateUrl: './action-shopping.component.html',
  styleUrls: ['./action-shopping.component.scss'],
})
export class ActionShoppingComponent extends InputBaseController {
  public static className: string = 'action-shopping';

  public static readonly responseKeys: string[] = ['cart'];
  public params: ActionShoppingParams = {
    cart: [],
    totalPrice: 0.0,
    itemNumber: 0,
    shopItems: []
  };
  public openedIndex: number = -1;

  protected initAction(action: Action): void {
    super.initAction(action);
    this.params.cart = this.params.cart || [];
    this.updateQuantity();
  }

  public addToBasket(item: IShopItem, optionValue: string, sizeValue: string): void {
    let update: boolean = false;
    this.params.cart.forEach(i => {
      if (i.name === item.name && i.option === optionValue && i.size === sizeValue) {
        i.qty++;
        update = true;
      }
    });
    if (!update) {
      this.params.cart.push({
        name: item.name,
        option: optionValue,
        size: sizeValue,
        qty: 1,
        unitPrice: item.price
      });
    }
    this.updateQuantity();
  }

  public updateQuantity(): void {
    this.params.itemNumber = 0;
    this.params.totalPrice = 0;
    this.params.cart.forEach(i => {
      this.params.itemNumber += i.qty;
      this.params.totalPrice += i.qty * i.unitPrice;
    });
    this.onChange();
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    return {};
  }

  public openItem(e: MouseEvent, itemIndex: any): void {
    e.stopPropagation();
    this.openedIndex = itemIndex === this.openedIndex ? -1 : itemIndex;
  }
}
