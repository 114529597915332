import { NgModule } from '@angular/core';
import { BbcodeTranslationPipe } from './bbcode-translation.pipe';
import { CalendarTimePipe } from './calendar-time.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { LocalizedDateTimePipe } from './localized-date-time.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { TimerFormatPipe } from './timer-format.pipe';
import { UpperfirstPipe } from './upperfirst.pipe';
import { UpperfirstEachWordPipe } from './upperfirstEachWord.pipe';
import { LinkTargetNewTabPipe } from './link-target-new-tab.pipe';
import { CommunityRoleDisplayPipe } from './community-role-display.pipe';

@NgModule({
  declarations: [
    BbcodeTranslationPipe,
    CalendarTimePipe,
    LocalizedDatePipe,
    LocalizedDateTimePipe,
    SanitizePipe,
    UpperfirstPipe,
    TimerFormatPipe,
    UpperfirstEachWordPipe,
    LinkTargetNewTabPipe,
    CommunityRoleDisplayPipe,
  ],
  entryComponents: [],
  exports: [
    LocalizedDateTimePipe,
    BbcodeTranslationPipe,
    CalendarTimePipe,
    LocalizedDatePipe,
    LocalizedDateTimePipe,
    SanitizePipe,
    UpperfirstPipe,
    TimerFormatPipe,
    UpperfirstEachWordPipe,
    LinkTargetNewTabPipe,
    CommunityRoleDisplayPipe,
  ]
})
export class PipesModule {
}
