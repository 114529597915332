import {Injectable} from '@angular/core';
import {ISCSSData, loadSCSS} from './file-loader/scssLoad';

export interface ILoader<T> {
  name: string;
  load: (fileContent: string) => Promise<T>;
}

const profileToLoader: { SCSS_CLASSES: ILoader<ISCSSData> } = {
  SCSS_CLASSES: {
    name: 'Super Cool Summer School Classes',
    load: loadSCSS,
  } as ILoader<ISCSSData>,
};

@Injectable({
  providedIn: 'root'
})
export class FileLoaderService {

  constructor() {
  }

  getLoader<T>(profile: string): ILoader<T> {
    return profileToLoader[profile];
  }

  async loadFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent) => {
        resolve((e.target as FileReader).result as string);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  }
}
