<mat-form-field *ngIf="!disabled" class="phone-input">
  <input [placeholder]="placeholder"
         matInput
         type="tel"
         [imask]="{mask: [{mask: '+1 (000) 000-0000', lazy: false}, {mask: '+0[00] 0000000000', lazy: false}]}"
         unmask
         [(ngModel)]="inputContent"
         (input)="setPhone($event.target)"
         [formControl]="formControl"
  >
  <mat-error *ngIf="formControl.hasError('required') || formControl.hasError('phone')">
    <strong *ngIf="formControl.hasError('required'); else elseBlock">
      {{ "ACTIVITY.INPUT.PHONE.REQUIRED" | translate }}
    </strong>
    <ng-template #elseBlock>
      <strong *ngIf="formControl.hasError('phone')">
        {{ "ACTIVITY.INPUT.PHONE.INVALID_FORMAT" | translate }}
      </strong>
    </ng-template>
  </mat-error>
</mat-form-field>
<ng-container *ngIf="disabled">
  <div class="show-div">
    <span class="show-span">{{inputContent}}</span>
  </div>
</ng-container>
