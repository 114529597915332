import XLSX, {CellAddress} from 'xlsx';

export const cellInfos: (ws: XLSX.WorkSheet, cellAddress: XLSX.CellAddress) => {
  sourceAddress: CellAddress,
  value: string | number | boolean | Date
} = (ws: XLSX.WorkSheet, cellAddress: XLSX.CellAddress) => {
  const cell: XLSX.CellObject = ws[XLSX.utils.encode_cell(cellAddress)];
  if (!cell) {
    const mergeRange: XLSX.Range = ws['!merges'].find(range => {
      return range.s.c <= cellAddress.c && range.s.r <= cellAddress.r && range.e.c >= cellAddress.c && range.e.r >= cellAddress.r
    });
    if (!mergeRange) {
      return;
    }
    return {
      value: ws[XLSX.utils.encode_cell(mergeRange.s)].v,
      sourceAddress: mergeRange.s,
    };
  }
  return {
    value: cell.v,
    sourceAddress: cellAddress,
  };
};

export const cellValue: ( ws: XLSX.WorkSheet, cellAddress: XLSX.CellAddress) => string | number | boolean | Date
  = (ws: XLSX.WorkSheet, cellAddress: XLSX.CellAddress) => {
  const value: {sourceAddress: CellAddress, value: string | number | boolean | Date} = cellInfos(ws, cellAddress);
  return value && value.value;
};
