<ng-container *ngIf="type != 'show'">
  <mat-form-field class="action-text-input">
    <input [placeholder]="placeholder"
           type="text"
           matInput
           [ngModel]="params.text | bbcodeTranslation: bbcodeTranslator.redraw"
           (ngModelChange)="onChange($event)"
           [disabled]="manager.mode === 'review'">
  </mat-form-field>
</ng-container>
<ng-container *ngIf="type == 'show'">
  <div class="show-div">
    <span class="show-span">{{params.text | bbcodeTranslation: bbcodeTranslator.redraw}}</span>
  </div>
</ng-container>
