import {Component, Injector, OnInit, ViewContainerRef} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import {LoadingController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {PdfModalComponent} from '../../../modal/pdf-modal/pdf-modal.component';
import { DynamicMatDialogService } from '../../../../services/dynamic-mat-dialog/dynamic-mat-dialog.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-link',
  templateUrl: './action-link.component.html',
  styleUrls: ['./action-link.component.scss'],
})
export class ActionLinkComponent extends InputBaseController implements OnInit {
  public static className: string = 'action-link';
  public static readonly responseKeys: string[] = ['clicked'];
  public params: { fileName: string, clicked: boolean, allowedExtensions: string[] };
  public error: string = undefined;
  public fileDisplayName: string;

  constructor(
    public plt: Platform,
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    private matDialog: DynamicMatDialogService,
    public loadingCtrl: LoadingController,
    public translate: TranslateService
  ) {
    super(injector, viewContainerRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.manager.mode === 'review') {
      this.params.clicked = true;
    }
    this.updateAction();
  }

  public onEnter(): void {
    super.onEnter();
    if (this.params && this.params.fileName) {
      this.fileDisplayName = decodeURIComponent(this.params.fileName);
    }
    this.params.allowedExtensions = this.params.allowedExtensions ||
      ['pdf'];
  }

  public addFile(file: File): void {
    if (!file) {
      return;
    }
    let fileName: string = file.name.replace(/ /g, '_');
    let fakeUrl: string = this.manager.getExpectedResourceUrl(fileName);
    this.manager.setFileAlias(fakeUrl, URL.createObjectURL(file));
    this.manager.updateFiles(this.sectionCtrl.index, this.index, [file]);
    this.fileDisplayName = fileName;
    this.params.fileName = encodeURIComponent(fileName);
    this.onChange();
  }

  public async getResourceUrl(): Promise<void> {
    this.params.clicked = true;
    this.openExternalResource(
      this.params.fileName,
      this.plt,
      (url) => {
        this.matDialog.open(
          PdfModalComponent,
          {
            data: {
              url
            },
            panelClass: 'full-screen-modal'
          });
      });
    this.onChange();
  }

  /*public async downloadRessource(): Promise<void> {
      this.loader = this.loadingCtrl.create();
      await this.loader.present();
      let url = this.manager.getActivityResourceUrl(this.manager.getExpectedResourceUrl(this.params.fileName));
      await FileSaver.saveAs(url, this.fileDisplayName);
      await this.loader.dismiss();
      this.params.clicked = true;
      this.onChange();
  } //*/
}
