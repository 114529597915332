import { Component, Injector, OnInit, ViewContainerRef } from '@angular/core';
import { InputBaseController } from '../../input-controller';
import { i18n } from '../../../../services/i18n';
import { MatDialog } from '@angular/material/dialog';
import { HtmlModalComponent } from '../../../modal/html-modal/html-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-html',
  templateUrl: './action-html.component.html',
  styleUrls: ['./action-html.component.scss'],
})
export class ActionHtmlComponent extends InputBaseController implements OnInit {
  public static className: string = 'action-html';
  public static showMore: string = i18n('Show more');
  public static showLess: string = i18n('Show less');
  public static readonly responseKeys: string[] = ['text'];

  public params: {
    text: string,
    clicked: boolean
  };

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    private matDialog: MatDialog,
  ) {
    super(injector, viewContainerRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public openModal(e: Event): void {
    e.preventDefault();
    this.params.clicked = true;
    this.matDialog.open(HtmlModalComponent, {
      data: {
        content: this.params.text,
        mode: this.manager.mode,
        type: this.type,
        title: this.manager.getActivity().title
      },
      panelClass: 'full-screen-html-modal',
    });
  }

}
