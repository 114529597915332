import AMergeable from './a-mergeable';
import DynValue from './dyn-value';
import Execution from './execution';
import Condition from './condition';
import {Dictionary} from 'lodash';
import AFormManager from './a-form-manager';
import Validation from './validation';
import {InputBaseController} from '../components/form/input-controller';
import {Injector} from '@angular/core';

export default class Action extends AMergeable {
  name: string;
  alias: string;
  type: string;
  label: string;
  dataType: string;
  placeholder: DynValue;
  disabled: Condition[][];
  onClick: Execution[];
  onChange: Execution[];
  validations: Validation[];
  sortLabel: string;
  styles: string[];
  params: Dictionary<DynValue>;
  sharedParams?: string[];

  controller: InputBaseController;
  error: string;
  isSet: boolean = false;
  displayCSV: string;

  constructor(json: any) {
    super();
    this.name = json.name;
    this.alias = json.alias;
    this.type = json.type;
    this.label = json.label;
    this.dataType = json.dataType;
    this.sortLabel = json.sortLabel;
    this.styles = json.styles ? this.create(String, json.styles) : [];
    this.params = json.params ? this.createDictionary(DynValue, json.params) : {};
    if (json.sharedParams) {
      this.sharedParams = json.sharedParams;
    }
    this.placeholder = this.create(DynValue, json.placeholder);
    this.displayCSV = json.displayCSV;
    this.initTabs(json);
  }

  public getResponse(getValue: (dyn: DynValue) => any): Dictionary<any> {
    return InputBaseController.getStatic(this.name).getResponseFromAction(getValue, this);
  }

  public getCsvResponse(getValue: (dyn: DynValue) => any, activityId: string, injector?: Injector): Dictionary<any> {
    return InputBaseController.getStatic(this.name).getCsvResponses(getValue, this, activityId, injector);

  }

  public setResponse(response: Dictionary<any>): any {
    return InputBaseController.getStatic(this.name).setResponseToAction(this, response);
  }

  public validation(manager: AFormManager): string {
    if (this.isDisabled(manager)) {
      return '';
    }
    return this.controller.validation() || manager.validation(this.validations);
  }

  public isDisabled(manager: AFormManager): boolean {
    return manager.checkConditions(this.disabled);
  }

  private async execute(manager: AFormManager, executions: Execution[]): Promise<void> {
    if (!executions) {
      return;
    }
    const executionResults: any[] = [];
    for (const execution of executions) {
      const executionResult: any = await manager.execute(execution, executionResults);
      executionResults.push(executionResult);
    }
  }

  public onChangeEvent(manager: AFormManager): void {
    this.execute(manager, this.onChange);
  }

  public onClickEvent(manager: AFormManager): void {
    this.execute(manager, this.onClick);
  }

  public canLeave(manager: AFormManager): boolean {
    return !(this.error = this.validation(manager));
  }

  private initTabs(json: any): void {
    this.initialize(Validation, json.validations, 'validations');
    this.initialize(Condition, json.disabled, 'disabled');
    this.initialize(Execution, json.onClick, 'onClick');
    this.initialize(Execution, json.onChange, 'onChange');
  }
}
