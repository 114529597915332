<mat-accordion>
  <div class="totalContainer d-flex justify-content-between align-items-center">
    <!--
        <div>
         $ {{ params.totalPrice }}
         </div>
     -->
    <h2>{{ "ACTIVITY.ACTION.SHOPPING.SELECT_ITEMS" | translate }}</h2>
    <div>
      <ion-icon class="cart-icon" name="cart-outline"></ion-icon>
      <ion-badge>{{ params.itemNumber }}</ion-badge>
    </div>
  </div>
  <mat-expansion-panel *ngFor="let item of params.shopItems; let i = index" [expanded]="i === openedIndex"
                       class="mat-expansion-panel-to-ion-card mat-elevation-z3">
    <mat-expansion-panel-header class="overlay-button-container">
      <button class="overlay-button" (click)="openItem($event, i)">
      </button>
      <mat-panel-title>
        {{ item.name }}
      </mat-panel-title>
      <mat-panel-description class="d-flex justify-content-center align-items-center">
        <div class="itemPrice">
          $ {{ item.price }}
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <!-- <mat-form-field>
        <mat-select #optionSelect placeholder="Options:" [value]="item.options[0]">
            <mat-option *ngFor="let option of item.options" [value]="option">
                {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
    <ion-item>
      <ion-label>{{ "ACTIVITY.ACTION.SHOPPING.OPTION" | translate }}</ion-label>
      <ion-select #optionSelect [ngModel]="item.options[0]">
        <ion-select-option *ngFor="let option of item.options" [value]="option">
          {{option}}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <br/>

    <!-- <mat-form-field>
        <mat-select  #sizeSelect placeholder="Size:" [value]="item.sizes[0]">
            <mat-option *ngFor="let size of item.sizes" [value]="size">
                {{ size }}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
    <ion-item>
      <ion-label>{{ "ACTIVITY.ACTION.SHOPPING.SIZE" | translate }}</ion-label>
      <ion-select #sizeSelect [ngModel]="item.sizes[0]">
        <ion-select-option *ngFor="let size of item.sizes" [value]="size">
          {{size}}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <br/>
    <ion-button (click)="addToBasket(item, optionSelect.value, sizeSelect.value)">
      {{ "ACTIVITY.ACTION.SHOPPING.ADD_TO_CART" | translate }}
    </ion-button>
  </mat-expansion-panel>
</mat-accordion>
