import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthMigrationService } from './auth-migration.service';
import jwt_decode from 'jwt-decode';
import { Hub } from '@aws-amplify/core';
import { AuthOptions, CognitoHostedUIIdentityProvider, AwsCognitoOAuthOpts } from '@aws-amplify/auth/lib-esm/types';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { AUTH_CONFIG } from '../modules/auth/auth.config';
import { ICognitoIdTokenPayload } from './cognitoTokenPayload.interface';
import { OneTimeTokenService } from './one-time-token.service';
import { IOneTimeTokenPayload } from './one-time-token.interface';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Platform, ToastController, AlertController } from '@ionic/angular';
import { delay } from '../../utils';
import { ActivityNavigationTrackingService } from './activity-navigation-tracking/activity-navigation-tracking.service';

export const enum CognitoIdentityProviderActionalyError {
  NO_PROVIDER_SIGNUP = 'NO_PROVIDER_SIGNUP',
  EMAIL_NOT_UNIQ = 'EMAIL_NOT_UNIQ',
  LINKED_EXTERNAL_USER = 'LINKED_EXTERNAL_USER',
  ALREADY_LINKED = 'ALREADY_LINKED',
  NO_COGNITO_USER = 'NO_COGNITO_USER',
}

const cognitoUrlParsingBlackList: string[] = [
  'google-oauth-response',
  'clever-oauth',
  'classlink-oauth',
];

export interface IProviderSignupFailureEvent {
  error: CognitoIdentityProviderActionalyError;
  data?: any;
}

export interface IAuthChallengeSubjectPayload {
  callback: (code: string) => Promise<void>;
  cancelCodeVerificationCallback: () => void;
  contact: string;
  secondTry?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoAuthService implements OnDestroy {

  public logoutAndRedirectUrl: string = '/login';

  private cognitoSessionSubject: ReplaySubject<CognitoUserSession> = new ReplaySubject<CognitoUserSession>(1);

  public get cognitoSession$(): Observable<CognitoUserSession> {
    return this.cognitoSessionSubject.asObservable().pipe(
      distinctUntilChanged((x, y) => (x && x.getAccessToken().getJwtToken()) === (y && y.getAccessToken().getJwtToken()))
    );
  }

  private cognitoSessionErrorSubject: Subject<any> = new Subject<any>();

  public get cognitoSessionError$(): Observable<any> {
    return this.cognitoSessionErrorSubject.asObservable();
  }

  private authChallengeSubject: Subject<IAuthChallengeSubjectPayload> = new Subject<IAuthChallengeSubjectPayload>();

  public get authChallenge$(): Observable<IAuthChallengeSubjectPayload> {
    return this.authChallengeSubject.asObservable();
  }

  private cognitoPasswordLoginSuccessSubject: Subject<any> = new Subject<any>();

  public get cognitoPasswordLoginSuccess$(): Observable<any> {
    return this.cognitoPasswordLoginSuccessSubject.asObservable();
  }

  private cognitoLogoutSubject: Subject<string> = new Subject<string>();

  public get cognitoLogout$(): Observable<string> {
    return this.cognitoLogoutSubject.asObservable();
  }

  private isAuthenticatingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get isAuthenticating$(): Observable<boolean> {
    return this.isAuthenticatingSubject.asObservable();
  }

  private providerSignupFailureSubject: ReplaySubject<IProviderSignupFailureEvent> =
    new ReplaySubject<IProviderSignupFailureEvent>();
  public get providerSignupFailure$(): Observable<IProviderSignupFailureEvent> {
    return this.providerSignupFailureSubject.asObservable();
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(AUTH_CONFIG) config: AuthOptions,
    private router: Router,
    private plt: Platform,
    public http: HttpClient,
    private oneTimeTokenService: OneTimeTokenService,
    private authMigration: AuthMigrationService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private activityNavigationTrackingService: ActivityNavigationTrackingService,
  ) {
    // tslint:disable-next-line: cyclomatic-complexity
    Hub.listen('auth', async event => {
      console.debug('auth event', event);
      // let toast: HTMLIonToastElement = await this.toastCtrl.create({
      //   message: event.payload.event,
      //   // duration: 5000,
      //   buttons: [
      //     {
      //       text: 'Done',
      //       role: 'cancel',
      //     }
      //   ]
      // });
      // await toast.present();
      if (event.payload.event === 'signIn') {
        const user: CognitoUser = (event.payload.data as CognitoUser);
        this.cognitoSessionSubject.next(user.getSignInUserSession());
      } else if (
        event.payload.event === 'cognitoHostedUI'
        && event.payload.message.includes('has been signed in via Cognito Hosted UI')
      ) {
        this.router.navigateByUrl('/');
      } else if (
        event.payload.event === 'cognitoHostedUI_failure'
        && event.payload.data.message.includes(CognitoIdentityProviderActionalyError.LINKED_EXTERNAL_USER)
      ) {
        if (this.plt.is('hybrid')) {
          // let's wait plt.ready before redirecting because else deepLink canRedirect will be false
          await this.plt.ready();
        }
        const provider = /LINKED_EXTERNAL_USER-([^.]*)\.\+/.exec(event.payload.data.message)[1];
        if (provider === 'Google') {
          return this.googleFederatedLogin();
        } else if (provider === 'Clever') {
          return this.cleverFederatedLogin();
        } else if (provider === 'ClassLink') {
          return this.classLinkFederatedLogin();
        }
      } else if (
        event.payload.event === 'signIn_failure'
        && event.payload.data.message.includes(CognitoIdentityProviderActionalyError.NO_PROVIDER_SIGNUP)
      ) {
        const provider = /NO_PROVIDER_SIGNUP-([^.]*)\.\+/.exec(event.payload.data.message)[1];
        this.providerSignupFailureSubject.next({ error: CognitoIdentityProviderActionalyError.NO_PROVIDER_SIGNUP, data: { provider } });

        // for some reason something is restoring the url if we do this too early (probably cognito trying to remove one of the params)
        this.removeUrlSearchParamsWithDelay();
      } else if (
        event.payload.event === 'signIn_failure'
        && event.payload.data.message.includes(CognitoIdentityProviderActionalyError.EMAIL_NOT_UNIQ)
      ) {
        this.providerSignupFailureSubject.next({ error: CognitoIdentityProviderActionalyError.EMAIL_NOT_UNIQ });

        // for some reason something is restoring the url if we do this too early (probably cognito trying to remove one of the params)
        this.removeUrlSearchParamsWithDelay();
      } else if (
        event.payload.event === 'signIn_failure'
        && event.payload.data.message.includes(CognitoIdentityProviderActionalyError.NO_COGNITO_USER)
      ) {
        this.providerSignupFailureSubject.next({ error: CognitoIdentityProviderActionalyError.NO_COGNITO_USER });

        // for some reason something is restoring the url if we do this too early (probably cognito trying to remove one of the params)
        this.removeUrlSearchParamsWithDelay();
      } else if (
        // due to cognito issue on deeplink from QA/prod oauth endpoints on native platforms
        // see discussions here https://github.com/aws-amplify/amplify-js/issues/5351
        event.payload.event === 'cognitoHostedUI_failure'
        && (
          event.payload.data.message.includes('Failed to execute \'replaceState\' on \'History\'')
          || event.payload.data.message.includes('Blocked attempt to use history.replaceState()')
        )
        && this.plt.is('hybrid')
      ) {
        // let toast: HTMLIonToastElement = await this.toastCtrl.create({
        //   message: 'going to /',
        //   // duration: 5000,
        //   buttons: [
        //     {
        //       text: 'Done',
        //       role: 'cancel',
        //     }
        //   ]
        // });
        // await toast.present();
        this.router.navigateByUrl('/');
      }
    });
    this.cognitoSession$.subscribe((session) => {
      console.debug('new session', session);
    });
    this.isAuthenticating$.subscribe((status) => {
      console.debug('isAuthenticating$', status);
    });
    this.cognitoLogout$.subscribe((msg) => {
      console.debug('cognitoLogout', msg);
    });

    // required for refresh call (we cannot get the username from the encrypted refreshToken)
    // tslint:disable-next-line:typedef
    const originalFetch = window.fetch;
    window.fetch = (...fetchArguments) => (async args => {
      // console.log('fetch args', args);
      if ((args[1]?.headers['X-Amz-Target'] === 'AWSCognitoIdentityProviderService.InitiateAuth')) {
        const lastUserKey = `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`;
        const lastAuthUser: string = (Auth as any).userPool.storage.getItem(lastUserKey);
        if (lastAuthUser) {
          const idTokenKey = `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${lastAuthUser}.idToken`;
          const idToken: string = (Auth as any).userPool.storage.getItem(idTokenKey);
          const username = (jwt_decode(idToken) as ICognitoIdTokenPayload)['cognito:username'];
          args[1].headers['Actionaly-Username'] = username;
        }
      }
      if (((args[0] as string).includes(`${environment.COGNITO.OAUTH.DOMAIN}/oauth2/token`))) {
        args[0] = `${environment.COGNITO.OAUTH.ENDPOINT}/token`;
      }

      const result: Response = await originalFetch(...args);
      return result;
    })(fetchArguments);

    const originalHandleAuthResponse: any = (Auth as any)._handleAuthResponse.bind(Auth);
    (Auth as any)._handleAuthResponse = (URL?: string) => {
      if (URL && cognitoUrlParsingBlackList.some(pattern => URL.includes(pattern))) return;
      return originalHandleAuthResponse(URL);
    };

    if (this.plt.is('capacitor')) {
      (config.oauth as AwsCognitoOAuthOpts).redirectSignIn = `${environment.URL}/login`;
      (config.oauth as AwsCognitoOAuthOpts).redirectSignOut = `${environment.URL}/login`;
    }
    Auth.configure(config);
    (window as any).Auth = Auth;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public async loginFromStoredSession(): Promise<void> {
    console.debug('loginFromStoredSession');
    this.isAuthenticatingSubject.next(true);
    try {
      const cognitoUser: any = await Auth.currentAuthenticatedUser();
      const cognitoSession: CognitoUserSession = await Auth.currentSession();
      this.cognitoSessionSubject.next(cognitoSession);
      return cognitoUser;
    } catch (e) {
      this.cognitoSessionErrorSubject.next(e);
      throw e;
    } finally {
      this.isAuthenticatingSubject.next(false);
    }
  }

  public async login(username: string, password: string): Promise<any> {
    this.isAuthenticatingSubject.next(true);
    try {
      await Auth.configure({
        authenticationFlowType: 'USER_PASSWORD_AUTH',
      });
      // TODO after legacy migration expiration
      // delete and replace with
      // let cognitoUser: any = await Auth.signIn(username.toLowerCase(), password);
      let cognitoUser: any = await this.authMigration.signInWithLegacyFallBack(username.toLowerCase(), password);
      console.debug('login cognitoUser', cognitoUser);
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        cognitoUser = await Auth.completeNewPassword(cognitoUser, password);
      }
      this.cognitoPasswordLoginSuccessSubject.next(cognitoUser);
      return cognitoUser;
    } catch (e) {
      console.debug('login error', e);
      this.cognitoSessionErrorSubject.next(e);
      throw e;
    } finally {
      this.isAuthenticatingSubject.next(false);
    }
  }

  public async loginWithOneTimeToken(oneTimeToken: string): Promise<void> {
    this.isAuthenticatingSubject.next(true);
    try {
      await this.logout();
      console.debug('oneTimeToken', oneTimeToken);
      // TODO after legacy migration expiration
      // delete wrapper (keep there the content of the tokenLogin param lambda)
      await this.authMigration.tokenLoginWithLegacyFallback(
        oneTimeToken,
        async (oTToken: string) => {
          const tokenPayload: IOneTimeTokenPayload = this.oneTimeTokenService.decodePayload(oTToken);
          console.debug('tokenPayload', tokenPayload);
          await Auth.configure({
            authenticationFlowType: 'CUSTOM_AUTH',
          });
          await this.resetPasswordIfRequiredAndRetry(oTToken, async () => {
            const user: any = await Auth.signIn(tokenPayload.sub);
            console.debug('user', user);

            if (user.challengeParam.type !== 'ONE_TIME_TOKEN_CHALLENGE') return;

            const res: any = await Auth.sendCustomChallengeAnswer(
              user,
              JSON.stringify({
                oneTimeToken: oTToken,
              }),
              {
                endpoint: environment.COGNITO.ONE_TIME_TOKEN_CHALLENGE_ENDPOINT || environment.API_URL,
                oneTimeToken: oTToken,
                ...this.activityNavigationTrackingService.activityId && { activityId: this.activityNavigationTrackingService.activityId },
              },
            );
            console.debug('ONE_TIME_TOKEN_CHALLENGE sendCustomChallengeAnswer res', res);

            if (user.challengeParam?.type !== 'CODE_VERIFICATION_CHALLENGE') return;
            // ask for verification code 1st time
            try {
              await this.waitForCodeVerification(user, tokenPayload);
            } catch (e) {
              if (e.message === 'code verification canceled') {
                return;
              } else {
                throw e;
              }
            }

            if (user.challengeParam?.type !== 'CODE_VERIFICATION_CHALLENGE') return;
            // ask for verification code 2nd time
            try {
              await this.waitForCodeVerification(user, tokenPayload, true);
            } catch (e) {
              if (e.message === 'code verification canceled') {
                return;
              } else {
                throw e;
              }
            }
          });
        },
        async message => {
          await this.logoutAndRedirect(message);
        },
      );
    } catch (e) {
      // disabled because we handle the redirection directly in autoLogin
      // this.cognitoSessionErrorSubject.next(e);
      throw e;
    } finally {
      this.isAuthenticatingSubject.next(false);
    }
  }

  public async waitForCodeVerification(user: any, tokenPayload: IOneTimeTokenPayload, secondTry?: boolean): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      this.authChallengeSubject.next({
        secondTry,
        contact: tokenPayload.email,
        callback: async (code: string) => {
          try {
            const res: any = await Auth.sendCustomChallengeAnswer(
              user,
              JSON.stringify({
                verificationCode: code,
              }),
              {
                endpoint: environment.COGNITO.ONE_TIME_TOKEN_CHALLENGE_ENDPOINT || environment.API_URL,
              },
            );
            console.debug('CODE_VERIFICATION_CHALLENGE sendCustomChallengeAnswer res', res);
            console.debug('CODE_VERIFICATION_CHALLENGE sendCustomChallengeAnswer user', user);
            await delay(800);
            resolve();
          } catch (e) {
            console.error(e);
            reject(e);
          }
        },
        cancelCodeVerificationCallback: () => reject(new Error('code verification canceled')),
      });
    });
  }

  public async googleFederatedLogin(): Promise<void> {
    this.isAuthenticatingSubject.next(true);
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  }

  public async cleverFederatedLogin(): Promise<void> {
    this.isAuthenticatingSubject.next(true);
    await Auth.federatedSignIn({
      customProvider: 'Clever',
    });
  }

  public async classLinkFederatedLogin(): Promise<void> {
    this.isAuthenticatingSubject.next(true);
    await Auth.federatedSignIn({
      customProvider: 'ClassLink',
    });
  }

  private async resetPasswordIfRequiredAndRetry(oneTimeToken: string, oneTimeTokenSignIn: () => Promise<void>): Promise<void> {
    try {
      return await oneTimeTokenSignIn();
    } catch (e) {
      if (e?.code === 'PasswordResetRequiredException') {
        // user was most likely created recently and its loader didn't set his random password yet
        console.debug('PasswordResetRequiredException', e);
        await this.oneTimeTokenService.setRandomPassword(oneTimeToken);
      } else {
        throw e;
      }
    }
    return await oneTimeTokenSignIn();
  }

  public async isCurrentlyAuthenticated(userId: string, username: string): Promise<{ isActive: boolean, isSameUser: boolean }> {
    try {
      const cognitoSession: CognitoUserSession = await Auth.currentSession();
      const user: any = await Auth.currentAuthenticatedUser();
      console.debug('username', user.getUsername());
      console.debug('isValid', cognitoSession.isValid());
      // cognito will either put the id or the username as a user 'username' depending on which was used to auth the user
      // (id or preferred_username in cognito language)
      return {
        isActive: cognitoSession.isValid(),
        isSameUser: cognitoSession.isValid() && (user.getUsername() === userId || user.getUsername() === username)
      };
    } catch (e) {
      console.debug('isCurrentlyAuthenticated error', e);
      return { isActive: false, isSameUser: false };
    }
  }

  public async getCurrentSession(): Promise<CognitoUserSession> {
    try {
      const cognitoSession: CognitoUserSession = await Auth.currentSession();
      this.cognitoSessionSubject.next(cognitoSession);
      return cognitoSession;
    } catch (e) {
      console.debug('getCurrentSession error', e);
      this.cognitoSessionErrorSubject.next(e);
      throw e;
    }
  }

  public async getCurrentAccessJwtToken(): Promise<string> {
    const session: CognitoUserSession = await this.getCurrentSession();
    return session.getAccessToken().getJwtToken();
  }

  public async logout(logoutMessage?: string): Promise<void> {
    this.cognitoLogoutSubject.next(logoutMessage);
    await Auth.signOut();
    this.cognitoSessionSubject.next(undefined);
  }

  public getAndConsumeRedirectUrl(): string {
    const currentUrl: string = this.logoutAndRedirectUrl;
    this.logoutAndRedirectUrl = '/login';
    return currentUrl;
  }

  public async logoutAndRedirect(logoutMessage?: string): Promise<void> {
    console.debug('logoutAndRedirect', logoutMessage);
    await this.logout(logoutMessage);
    this.router.navigate([this.getAndConsumeRedirectUrl()], { state: { logoutMessage, byPassActivityCreatorGuard: true } });
  }

  private removeUrlSearchParamsWithDelay(): void {
    setTimeout(() => {
      const newUrl: URL = new URL(window.location.href);
      console.debug('removeUrlSearchParams');
      window.history.replaceState({}, document.title, newUrl.pathname);
    }, 500);
  }
}
