import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import AuthenticatedUser from '../../models/user';
import { CognitoAuthService } from '../cognito-auth.service';
import { ApiService } from '../api.service';
import { delay, isSafariDesktop } from '../../../utils';
import { Platform } from '@ionic/angular';

declare var zE: (event: string, actionOrProperty: string, callbackOrValue?: any) => {};
@Injectable({
  providedIn: 'root'
})
export class ZendeskService {
  authenticatedUser: AuthenticatedUser;

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private cognitoAuth: CognitoAuthService,
    private plt: Platform,
  ) {
    this.waitUntilZendeskLoaded().then(() => this.hide());
    this.userService.validCurrentUser$.subscribe(async currentUser => {
      this.create();
      this.authenticatedUser = currentUser;
      await this.logUserIn();
    });
    this.cognitoAuth.cognitoLogout$.subscribe(async () => {
      await this.logUserOut();
      this.destroy();
    });
  }

  public async logUserIn(): Promise<void> {
    try {
      await this.logUserOut();
      const response = await this.apiService.request('GET', 'zendesk/app/token');
      // zE('messenger', 'loginUser', (callback) => {
      //   callback(response.token);
      // });
    } catch (e) {
      console.error(e);
    }
  }

  public async logUserOut(): Promise<void> {
    await this.waitUntilZendeskLoaded();
    // zE('messenger', 'logoutUser');
    await this.hide();
  }

  public create(): void {
    const existingTag: HTMLScriptElement = document.querySelector('#ze-snippet');
    if (existingTag) return;
    const tag: HTMLScriptElement = document.createElement('script');
    tag.id = 'ze-snippet';
    tag.src = 'https://static.zdassets.com/ekr/snippet.js?key=bc3f46a9-d1d8-4ced-be44-cd858215c767';
    document.body.append(tag);
  }

  public destroy(): void {
    console.log('destroy');
    // This correctly remove everything.
    // But calling the create back doesn't work after that.
    // It re-injects the script which doesn't initiate again.

    /*
    document.getElementById('ze-snippet').remove();
    const iframeElement: HTMLIFrameElement = document.querySelector('body > iframe[data-product="web_widget"]');
    iframeElement.remove();
    const btnElement: HTMLDivElement = document.querySelector('body > div > div[role="presentation"]');
    btnElement.parentElement.remove();
    // */
  }

  public async open(): Promise<void> {
    // await this.upsertZendeskUser();
    await this.show();
    // zE('messenger', 'open');
  }

  public async close(): Promise<void> {
    await this.waitUntilZendeskLoaded();
    // zE('messenger', 'close');
  }

  public async show(): Promise<void> {
    await this.waitUntilZendeskLoaded();
    // zE('messenger:set', 'zIndex', 999999);
  }

  public async hide(): Promise<void> {
    await this.close();
    // zE('messenger:set', 'zIndex', -99999);
  }

  public async openHelp() {
    // await this.upsertZendeskUser();
    const { token } = await this.getSSOJWT();

    const returnTo: string = encodeURI('https://actionaly.zendesk.com/hc/en-us');
    const url: string = `https://actionaly.zendesk.com/access/jwt?jwt=${token}&return_to=${returnTo}`;
    if (isSafariDesktop || this.plt.is('ios')) {
      setTimeout(() => {
        window.open(url, '_blank');
      });
    } else {
      window.open(url, '_blank');
    }
  }

  private async waitUntilZendeskLoaded(): Promise<any> {
    if (typeof ((window as any).zE) !== 'undefined') return Promise.resolve((window as any).zE);
    let retries: number = 0;
    return new Promise((resolve, reject) => {
      let timerId: number = window.setInterval(() => {
        if (typeof ((window as any).zE) !== 'undefined') {
          console.debug('Zendesk loaded after', retries, 'retries');
          clearInterval(timerId);
          resolve(this.waitUntilZendeskLoaded());
        }
        retries++;
      }, 20);
    });
  }

  public async upsertZendeskUser(): Promise<void> {
    try {
      await this.apiService.request('POST', 'zendesk/upsert-user');
    } catch (e) {
      if (e.status === 429) {
        // retry without await as the user needs reactivity, support may just have to merge users later
        (async () => {
          await delay(60000);
          await this.upsertZendeskUser();
        })();
      }
    }
  }

  public async getSSOJWT(): Promise<{ token: string }> {
    return await this.apiService.request('GET', 'zendesk/sso/jwt');
  }
}
