import {Injectable} from '@angular/core';
import {IOneTimeTokenPayload} from './one-time-token.interface';
import {ApiUrlService} from './api-url.service';
import {HttpClient} from '@angular/common/http';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class OneTimeTokenService {

  constructor(
    public http: HttpClient,
    private apiUrlService: ApiUrlService,
  ) {
  }

  public decodePayload(oneTimeToken: string): IOneTimeTokenPayload {
    return jwt_decode(oneTimeToken);
  }

  public async getOneTimeTokenUsername(oneTimeToken: string): Promise<any> {
    return await this.http.get(
      `${this.apiUrlService.apiUrl}magic-link-auth/username`,
      {
        headers: {Authorization: `Bearer ${oneTimeToken}`},
        responseType: 'text',
      },
    ).toPromise();
  }

  public async setRandomPassword(oneTimeToken: string): Promise<any> {
    return await this.http.post(
      `${this.apiUrlService.apiUrl}magic-link-auth/set-random-password`,
      undefined,
      {
        headers: {Authorization: `Bearer ${oneTimeToken}`},
      },
    ).toPromise();
  }
}
