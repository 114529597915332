import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy, Platform} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthModule} from './modules/auth/auth.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {Httpd} from '@ionic-native/httpd/ngx';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {PipesModule} from './pipes/pipes.module';
import {ComponentsModule} from './components/components.module';
import {CommonModule} from '@angular/common';
import {QuillModule} from 'ngx-quill';
import {QuillPluginsService} from './services/quill/quill-plugins.service';
import {QuillImagesRestrictionService} from './services/quill/quill-images-restriction.service';
import {Badge} from '@ionic-native/badge/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {PushNotificationService} from './services/push-notification.service';
import { ActivityV2Module } from './modules/activity-v2/activity-v2.module';
import { AuthChallengerService } from './services/auth-challenger.service';
import { NgxMaskModule } from "ngx-mask";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.PRODUCTION}),
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot({
      userPoolId: environment.COGNITO.USER_POOL_ID,
      userPoolWebClientId: environment.COGNITO.USER_POOL_WEB_CLIENT_ID,
      region: environment.COGNITO.REGION,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      clientMetadata: {
        endpoint: window.location.origin,
      },
      // endpoint: environment.API_URL,
      // endpoint: "https://cognito-idp.us-west-2.amazonaws.com/",
      endpoint: environment.COGNITO.ENDPOINT,
      oauth: {
        domain: environment.COGNITO.OAUTH.DOMAIN,
        scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${window.location.origin}/login`,
        redirectSignOut: `${window.location.origin}/login`,
        responseType: 'code',
      }
    }),
    QuillModule.forRoot(),
    ComponentsModule,
    ActivityV2Module,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    Httpd,
    PushNotificationService,
    QuillPluginsService,
    QuillImagesRestrictionService,
    AuthChallengerService,
    Badge,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
