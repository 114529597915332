import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timerFormat'
})
export class TimerFormatPipe implements PipeTransform {
  pad(num: number, size: number): string {
    let s: string = num.toString(10);
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  transform(seconds: number): string {
    const minutesStr: string = this.pad(Math.floor(seconds / 60), 2);
    const secondsStr: string = this.pad(seconds % 60, 2);
    return `${minutesStr}:${secondsStr}`;
  }

}
