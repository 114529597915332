import {Directive, ElementRef, Output, EventEmitter, OnInit} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[onInit]'
})
export class BpOnInitDirective implements OnInit {
  @Output() onInit: EventEmitter<ElementRef> = new EventEmitter();

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    this.onInit.emit(this.element);
  }
}
