import {ComponentRef, Injectable, Injector, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {TimerComponent} from '../../components/timer/timer';
import {PORTAL_TIMER_PROVIDER} from '../../components/timer/timerData';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';

@Injectable()
export class TimerService implements OnDestroy {

  private _dialogRef: OverlayRef;
  private _timerComponentPortal: ComponentPortal<TimerComponent>;
  private _timerComponentRef: ComponentRef<TimerComponent>;

  private _seconds: number;
  public get seconds(): number {
    return this._seconds;
  }
  public set seconds(value: number) {
    this._seconds = value;
  }

  private timerSubscription: Subscription;
  public get running(): boolean {
    return this.timerSubscription && !this.timerSubscription.closed;
  }

  private timerEndSubject: Subject<void> = new Subject();
  public get timerEnd$(): Observable<void> {
    return this.timerEndSubject.asObservable();
  }

  constructor(
    public overlay: Overlay,
    private injector: Injector,
  ) {
    // console.log('Hello TimerProvider Provider');
    let overlayState: OverlayConfig = new OverlayConfig();
    overlayState.positionStrategy = overlay.position()
      .global()
      .bottom('12px')
      .left('12px');
    this._dialogRef = overlay.create(overlayState);
    const tokens: WeakMap<any, any> = new WeakMap();
    tokens.set(PORTAL_TIMER_PROVIDER, this);
    this._timerComponentPortal = new ComponentPortal(TimerComponent, undefined, new PortalInjector(this.injector, tokens));
  }

  ngOnDestroy(): void {
    // console.dir('TimerProvider ngOnDestroy')
    this._dialogRef.detach();
  }

  show(): void {
    if (this._timerComponentRef) return;
    setTimeout(() => {
      this._timerComponentRef = this._dialogRef.attach(this._timerComponentPortal);
    });
  }

  hide(): void {
    if (!this._timerComponentRef) return;
    this._dialogRef.detach();
    this._timerComponentRef = undefined;
  }

  resume(): void {
    if (this.timerSubscription && !this.timerSubscription.closed) return;
    const initialSeconds: number = this.seconds;
    this.timerSubscription = timer(0, 1000)
      .pipe(map(value => initialSeconds - value))
      .pipe(takeWhile(value => value >= 0))
      .subscribe(value => this.seconds = value, undefined, () => this.timerEndSubject.next());
  }

  pause(): void {
    this.timerSubscription.unsubscribe();
  }
}
