<div *ngIf="action.type == 'show' else elsePart" class="static-form-section">
  <img [src]="getTypeImage(activity.icon)" class="title-image"/>
  <h3 *ngIf="concernedUser" class="text-positive">
    {{ "ACTIVITY.ACTION.HEADER.FOR" | translate }} {{ concernedUser.firstName }} {{ concernedUser.lastName }}
  </h3>
  <h4 class="activity-title">{{activity.title | bbcodeTranslation: bbcodeTranslator.redraw}}</h4>
</div>

<ng-template #elsePart>
  <img [src]="getTypeImage(activity.icon)" class="title-image"/>
  <h2 class="text-positive">{{title | bbcodeTranslation: bbcodeTranslator.redraw}}</h2>
  <h4 class="activity-help">
    {{ "ACTIVITY.ACTION.CREATE.LET_ME_HELP" | translate }}
  </h4>
  <ion-item class="title-input" >
    <ion-label color="primary" position="floating">{{ "ACTIVITY.MAX_CHARACTERS" | translate }}</ion-label>
    <ion-input [ngModel]="params.title" (ngModelChange)="onChange($event)" maxlength="70"></ion-input>
  </ion-item>
</ng-template>
