import {Injectable} from '@angular/core';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UrlExecutionService {

  static readonly oldRootUrls: string[] = [
    'take-activity',
    'show-activity',
    'recover-account',
    'authentication',
  ];

  get isHome(): boolean {
    const urlPath: string[] = window.location.hash.match(/\/([^\/\?]*)/g).map(value => value.replace('/', ''));
    return urlPath.length === 1 && urlPath[0] === 'home';
  }

  get urlActivityParameters(): { activityId: string, concernedUserId: string } {
    if (!window.location.search) {
      return;
    }
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const activityId: string | null = urlParams.get('activityId');
    let concernedId: string | null = urlParams.get('concernedId');
    if (!activityId) {
      return;
    }
    if (!concernedId) {
      concernedId = this.userService.currentUser._id;
    }
    return {
      activityId,
      concernedUserId: concernedId
    };
  }

  get urlTokenParameter(): string {
    if (!window.location.search) {
      return;
    }
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    return urlParams.get('token');
  }

  constructor(private userService: UserService) {
  }

  public isDeprecatedUrl(hash: string): boolean {
    const urlRoot: string = (hash.split('/').slice(1, 2))[0];
    if (!urlRoot) {
      return false;
    }
    return UrlExecutionService.oldRootUrls.some(oldUrl => urlRoot.includes(oldUrl));

  }

  public extractDeprecatedUrlElements(hash: string): { urlParams: URLSearchParams; urlRoot: string; urlPath: string[]; token: string } {
    const urlPath: string[] = hash.match(/\/([^\/\?]*)/g).map(value => value.replace('/', ''));
    const rawQuery: RegExpExecArray = /[?].*$/.exec(hash);
    let urlParams: URLSearchParams;
    if (rawQuery) {
      urlParams = new URLSearchParams(rawQuery[0]);
    }
    const urlRoot: string = urlPath[0];
    const token: string = urlPath[1];
    return {
      urlRoot,
      token,
      urlParams,
      urlPath
    };
  }

  public convertDeprecatedUrl(hash: string): { hash: string; query?: string; shouldReload?: boolean } {
    const {urlRoot, token, urlParams, urlPath} = this.extractDeprecatedUrlElements(hash);

    try {
      switch (urlRoot) {
        case 'authentication': {
          return {query: `token=${token}`, hash: `#/home`, shouldReload: true};
        }
        case 'recover-account': {
          return {query: `token=${token}`, hash: `#/home/history/settings`};
        }
        case 'show-activity': {
          return this.convertDeprecatedShowActivityUrl(urlPath, urlParams);
        }
        case 'take-activity': {
          return this.convertDeprecatedTakeActivityUrl(urlPath, urlParams);
        }
        default: {
          return {query: `token=${token}`, hash: `#/`};
        }
      }
    } catch (e) {
      return {hash: `#/home`};
    }
  }

  public convertDeprecatedShowActivityUrl(urlPath: string[], urlParams: URLSearchParams): { query: string; hash: string } {
    const activityId: string = urlPath[2];
    const concernedUserId: string = urlParams && urlParams.get('target');
    return {hash: `#/home`, query: `activityId=${activityId}` + (concernedUserId ? `&concernedId=${concernedUserId}` : '')};
  }

  public convertDeprecatedTakeActivityUrl(urlPath: string[], urlParams: URLSearchParams): { query: string; hash: string } {
    const activityId: string = urlPath[2];
    const concernedUserId: string = urlParams.get('target');
    const response: string = urlParams.get('responses');
    return {hash: `#/home/history/${activityId}/take/${concernedUserId}`, query: `responses=${encodeURIComponent(response)}`};
  }

  public extractUrlTokenParameter(url: string): string {
    const validatedUrl: URL = new URL(url, window.location.origin);
    if (!validatedUrl.search) {
      return;
    }
    const urlParams: URLSearchParams = new URLSearchParams(validatedUrl.search);
    return urlParams.get('token');
  }
}
