import get from 'lodash/get';
import set from 'lodash/set';
import isArray from 'lodash/isArray';
import Condition from './condition';
import DynValue from './dyn-value';
import AMergeable from './a-mergeable';

import Builder from './API/builders';
import { ApiService } from '../services/api.service';
import { FormHelperService } from '../services/form-helper.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CognitoAuthService } from '../services/cognito-auth.service';
import AuthenticatedUser from './user';
import { UserService } from '../services/user.service';
import { take } from 'rxjs/operators';
import { BbcodeTranslatorService } from '../services/bbcode-translator.service';
import { DisplayDisclaimerComponent } from '../components/dialogs/display-disclaimer/display-disclaimer.component';
import { SubmitService } from '../services/submit.service';
import { Router } from '@angular/router';

export default class Execution extends AMergeable {

  constructor(json: string |
  {
    type: string,
    params: { [key: string]: number | string | DynValue },
    conditions?: Condition[][]
  }) {
    super();
    if (typeof json === 'string') {
      this.fromString(json);
      return;
    }
    this.type = json.type;
    Object.keys(json.params || {}).forEach(key => {
      this.params[key] = this.create(DynValue, json.params[key]);
    });
    this.initialize(Condition, json.conditions, 'conditions');
  }

  public type: string;
  public params: { [key: string]: DynValue } = {};
  public conditions: Condition[][];

  public static createField(field: string): any {
    return field.match('[0-9]+$') ? [] : {};
  }

  public static writeValue(obj: any, path: string, value: any): void {
    let rootObject = obj;
    let field = path.split('.');
    let i: number = 0;
    while (i < field.length - 1) {
      let currentField: string = field[i];
      if (!obj[currentField]) {
        obj[currentField] = this.createField(field[i + 1]);
      }
      obj = obj[currentField];
      i++;
    }
    obj[field[i]] = value;
  }

  private fromString(type: string): void {
    this.type = type;
  }

  public getParam(obj: Builder, key: string): any {
    let dyn: any = this.params[key];
    return dyn && dyn.execute(obj);
  }

  public getParams(obj: Builder): { [key: string]: any } {
    let ret: { [key: string]: any } = {};
    Object.keys(this.params).forEach(key => {
      ret[key] = this.params[key].execute(obj);
    });
    return ret;
  }

  public pushFormFile(obj: Builder): void {
    this.update(obj);
  }

  public openUrl(obj: Builder): void {
    let url: string = this.getParam(obj, 'url');
    let target: string = this.getParam(obj, 'target') || '_system';
    window.open(url, target);
  }

  public logout(obj: Builder): Promise<void> {
    let logoutMessage: string = this.getParam(obj, 'message') || '';
    return obj.manager.injector.get(CognitoAuthService).logoutAndRedirect(logoutMessage);
  }

  public refreshPendings(obj: Builder): any[] {
    return obj.manager.events.publish('pending-activities:refresh', {});
  }

  public refreshHistory(obj: Builder): any[] {
    return obj.manager.events.publish(
      'history-activities:refresh',
      { activityId: obj.activity.id, concernedUserId: obj.concernedUser?.id },
    );
  }

  public async login(obj: Builder, previousResults?: any[]): Promise<AuthenticatedUser> {
    let token: string = this.getParam(previousResults as any, 'tokenPath');
    if (!token) {
      return;
    }
    let loading: HTMLIonLoadingElement = await obj.manager.loadingController.create({
      message: 'Login...'
    });
    obj.manager.canAutoUpdateOnSubmit = false;
    loading.present();
    try {
      await obj.manager.injector.get(CognitoAuthService).loginWithOneTimeToken(token);
      const user = await obj.manager.injector.get(UserService).currentUserAfterPendingLoad$.pipe(take(1)).toPromise();
      const router: Router = await obj.manager.injector.get(Router);
      await router.navigateByUrl('/');
      return user;
    } catch (e) {
      console.debug('execution login error', e);
    } finally {
      await loading.dismiss();
    }
  }

  public createCalendarEvent(obj: Builder): void {
    if (obj.manager.mode !== 'view') {
      return;
    }
    if (!Condition.isValid(obj, this.conditions)) {
      return;
    }
    let params: any = this.getParams(obj);

    const bbCode: BbcodeTranslatorService = obj.manager.injector.get(BbcodeTranslatorService);
    const title: string = bbCode.translate(obj.activity.title);
    const desc: string = bbCode.translate(obj.activity.description);
    const location: string = bbCode.translate(params.location);

    obj.manager.createCalendarEvent(title, location, desc, params.dateFrom, params.dateTo);
    obj.manager.canAutoUpdateOnSubmit = false;
  }

  public async saveDraftResults(obj: Builder): Promise<any> {
    if (obj.manager.mode !== 'view') {
      return;
    }
    const api: ApiService = obj.manager.injector.get(ApiService);
    const formHelperService: FormHelperService = obj.manager.injector.get(FormHelperService);
    const activityId: string = obj.manager.getActivity().id;
    const concernedId: string = obj.manager.getConcernedUser() && obj.manager.getConcernedUser().id;
    const responses = formHelperService.getResponses(obj.manager);
    return api.saveActivityDraftResults(activityId, responses, concernedId);
  }

  public update(obj: Builder): void {
    Execution.writeValue(obj, this.getParam(obj, 'path'), this.getParam(obj, 'value'));
  }

  public updateFromPrevious(obj: Builder, previousResults: any[] = []): void {
    let index: number = this.getParam(obj, 'index');
    let subkey: string = this.getParam(obj, 'subkey');
    if (previousResults && previousResults[index] !== undefined) {
      let value: any = previousResults[index];
      if (subkey && value.hasOwnProperty(subkey)) {
        value = value[subkey];
      }
      Execution.writeValue(obj, this.getParam(obj, 'path'), value);
    }
  }

  public add(obj: Builder): void {
    let path: string | DynValue = this.getParam(obj, 'path');
    let pathValue: string = path instanceof DynValue ? path.execute(obj) : path;

    let dest: any = get(obj, pathValue);
    if (dest instanceof DynValue) {
      dest = dest.execute(obj);
    }
    const newValue: any = dest + this.getParam(obj, 'value');

    Execution.writeValue(obj, this.getParam(obj, 'path'), newValue);
  }

  public addToSet(obj: Builder): void {
    const { path, value, criterionPath, dest } = this.parseSetParams(obj);

    let destArray: any[] = [];
    if (isArray(dest)) {
      destArray = dest;
    }
    destArray = destArray.filter(v => {
      if (criterionPath) {
        return get(v, criterionPath) !== get(value, criterionPath);
      }
      return v !== value;
    }).concat(value);
    set(obj, path, new DynValue(destArray));
  }

  public removeFromSet(obj: Builder): void {
    const { path, value, dest } = this.parseSetParams(obj);

    let destArray: any[] = [];
    if (isArray(dest)) {
      destArray = dest;
    }
    destArray = destArray.filter(v => v !== value);
    set(obj, path, new DynValue(destArray));
  }

  private parseSetParams(obj: Builder): {
    path: string;
    value: any;
    dest: any;
    criterionPath: any
  } {
    let path: string | DynValue = this.getParam(obj, 'path');
    let pathValue: string = path instanceof DynValue ? path.execute(obj) : path;

    let value: any = this.getParam(obj, 'value');
    let valValue: any = value instanceof DynValue ? value.execute(obj) : value;

    let criterionPath: any = this.getParam(obj, 'criterionPath');
    let criterionPathValue: any = criterionPath instanceof DynValue ? criterionPath.execute(obj) : criterionPath;

    let dest: any = get(obj, pathValue);
    if (dest instanceof DynValue) {
      dest = dest.execute(obj);
    }

    return { path: pathValue, value: valValue, dest, criterionPath: criterionPathValue };
  }

  public goToNext(obj: Builder): void {
    obj.manager.goToNext();
  }

  public goToSection(obj: Builder): void {
    const target: number = this.getParam(obj, 'target');
    const currentValid: boolean = this.getParam(obj, 'currentValid') ?? false;
    const nextValid: boolean = this.getParam(obj, 'nextValid') ?? false;
    obj.manager.goToSection(target, currentValid, nextValid);
  }

  public goToPrev(obj: Builder): void {
    obj.manager.goToPrev();
  }

  public async submitClick(obj: Builder): Promise<any> {
    if (obj.localProviders.canSubmit) return await obj.manager.injector.get(SubmitService).submitActivity(obj.manager);
  }

  public async displayModal(obj: Builder): Promise<void> {
    let awaitClose: boolean = this.getParam(obj, 'awaitClose');
    let text: string = this.getParam(obj, 'text');
    const matDialog: MatDialog = obj.manager.injector.get(MatDialog);
    const translator: BbcodeTranslatorService = obj.manager.injector.get(BbcodeTranslatorService);

    let ref: MatDialogRef<DisplayDisclaimerComponent> = matDialog.open(DisplayDisclaimerComponent, {
      data: { text: translator.translate(text) },
    });
    obj.manager.canAutoUpdateOnSubmit = false;
    if (awaitClose) {
      await new Promise<void>(resolve => {
        ref.beforeClosed().subscribe(() => {
          return resolve();
        });
      });
    }
  }

  public goToPreview(obj: Builder): void {
    return obj.localProviders.goToPreview();
  }

  public async execute(obj: Builder, previousResults?: any[]): Promise<any> {
    if (Condition.isValid(obj, this.conditions, true)) {
      return await this[this.type](obj, previousResults);
    }
  }

  public delete(obj: any): void {
    let path: string = this.getParam(obj, 'path');
    let pathArray: string[] = path.split('.');
    let key: string = pathArray.splice(pathArray.length - 1, 1)[0];
    let parent: any = DynValue.path(obj, pathArray.join('.'));
    delete parent[key];
  }
}
