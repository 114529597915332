import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Version from '../../../blupods-version/Blupods-front-v3/version.json';

export interface IVersionInfo {
  major: number;
  minor: number;
  revision: number;
  sha: string;
  version: string;
}

class DeveloperSettings {
  public enabled: boolean = false;
  public environmentName: string = environment.NAME;
  public apiName: string = environment.API_URL;
  public socketAPI: string = environment.SOCKET_URL;
  public activityV2Color: boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class DeveloperService {

  private static readonly storageKey: string = 'developerSettings';

  private static readonly productionUrl: string = 'https://app.actionaly.com';
  private static readonly qaUrl: string = 'https://app.qa.actionaly.com';
  private static readonly demoUrl: string = 'https://demo.actionaly.com';
  private static readonly designRevampUrl: string = 'https://redesign.actionaly.com';
  private static readonly developmentUrl: string = 'http://app.dev.actionaly.com';
  private static readonly localUrl: string = 'http://localhost:4201';

  private static readonly productionPTCSocketUrl: string = 'https://socket.actionaly.com';
  private static readonly qaPTCSocketUrl: string = 'https://socket.qa.actionaly.com';
  private static readonly demoPTCSocketUrl: string = 'https://socket.demo.actionaly.com';
  private static readonly developmentPTCSocketUrl: string = 'http://localhost:8100';
  private static readonly dockerPTCSocketUrl: string = 'http://localhost:3001';
  public static readonly socketPTCUrls: { [env: string]: string } = {
    Production: DeveloperService.productionPTCSocketUrl,
    QA: DeveloperService.qaPTCSocketUrl,
    Demo: DeveloperService.demoPTCSocketUrl,
    Local: DeveloperService.developmentPTCSocketUrl,
    Docker: DeveloperService.dockerPTCSocketUrl,
  };

  public static readonly productionEnvironment: string = 'Master';
  public static readonly qaEnvironment: string = 'Experimental';
  public static readonly demoEnvironment: string = 'Demo';
  public static readonly designRevampEnvironment: string = 'Design Revamp';
  public static readonly localEnvironment: string = 'Local';

  public settings: DeveloperSettings;
  public versionInfo: IVersionInfo = (Version as any).default;

  constructor() {
    this.settings = JSON.parse(this.getDeveloperSettings());
    if (!this.settings.enabled ||
      !Object.keys(DeveloperService.socketPTCUrls).some(key => DeveloperService.socketPTCUrls[key] === this.settings.socketAPI)) {
      this.settings = new DeveloperSettings();
    }
    this.ensureCorrectSettings();
  }

  private getDeveloperSettings(): string {
    let settings: string = window.localStorage.getItem(DeveloperService.storageKey);
    if (!settings) {
      settings = JSON.stringify(new DeveloperSettings());
      window.localStorage.setItem(DeveloperService.storageKey, settings);
    }
    return settings;
  }

  private ensureCorrectSettings(): void {
    if (!this.settings.environmentName || this.settings.environmentName === '') {
      this.settings.environmentName = 'Master';
    }
    if (!this.settings.apiName || this.settings.apiName === '' || this.settings.apiName.includes('ngrok')) {
      this.settings.apiName = environment.API_URL;
    }

    this.save();
  }

  public getEnvironmentUrl(): string {
    if (this.settings.environmentName === DeveloperService.productionEnvironment) {
      return DeveloperService.productionUrl;
    }
    if (this.settings.environmentName === DeveloperService.qaEnvironment) {
      return DeveloperService.qaUrl;
    }
    if (this.settings.environmentName === DeveloperService.demoEnvironment) {
      return DeveloperService.demoUrl;
    }
    if (this.settings.environmentName === DeveloperService.designRevampEnvironment) {
      return DeveloperService.designRevampUrl;
    }
    if (this.settings.environmentName === DeveloperService.localEnvironment) {
      return DeveloperService.localUrl;
    }
    return DeveloperService.developmentUrl + '/' + this.settings.environmentName + '/www';
  }

  public save(): void {
    window.localStorage.setItem(DeveloperService.storageKey, JSON.stringify(this.settings));
  }
}
