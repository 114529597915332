import Quill from 'quill';

const ImageFormatAttributesList: string[] = [
  'alt',
  'height',
  'width',
  'style'
];

let BaseImageFormat = Quill.import('formats/image');

export class ImageFormat extends BaseImageFormat {
  domNode: typeof BaseImageFormat.domNode;
  static formats(domNode: any): object {
    return ImageFormatAttributesList.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  public format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
