<div class="MultipleDatePicker">
  <div class="head">
    <div *ngIf="currentMonth<=minMonth"> </div>
    <ion-icon *ngIf="currentMonth>minMonth"
              name="chevron-back"
              (click)="lastMonth()">
    </ion-icon>
    <div class="monthName">{{calendar[currentMonth].name}}</div>
    <ion-icon *ngIf="currentMonth<maxMonth"
              name="chevron-forward" (click)="nextMonth()">
    </ion-icon>
  </div>
  <div class="dayNames" *ngFor="let d of dayNames">{{d}}</div>
  <div *ngFor="let w of calendar[currentMonth].weeks">
    <button *ngFor="let day of w"
            (click)="onToggleDate(day)"
            [ngClass]="{'selected': day.selected, 'weekend' : day.weekend, 'reactHover': !isMobile}"
            [disabled]="!day.selectable">
      {{day.day}}
    </button>
  </div>
  <div class="nbSelectedDates" [ngPlural]="params.nbSelectedDates">
    <ng-template ngPluralCase="=0">{{ "ACTIVITY.ACTION.MULTIPLE-DATES.NOTHING_SELECTED" | translate }}</ng-template>
    <ng-template ngPluralCase="=1">{{ "ACTIVITY.ACTION.MULTIPLE-DATES.1_DAY_SELECTED" | translate }}</ng-template>
    <ng-template ngPluralCase="other">{{params.nbSelectedDates}} {{ "ACTIVITY.ACTION.MULTIPLE-DATES.DAYS_SELECTED" | translate }}</ng-template>
  </div>
</div>
