import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {SocketEvent} from './socket-event.enum';
import io, { Socket } from 'socket.io-client';
import {DeveloperService} from './developer.service';
import {environment} from '../../environments/environment';
import {LocalSocketServerService} from './local-socket-server.service';

abstract class ASocketIOService {
  protected _initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _socket: Socket;
  public get socket(): Socket {
    return this._socket;
  }

  public set socket(value: Socket) {
    this._socket = value;
    this._initialized.next(true);
  }

  public abstract initSocket(...args: any[]): Socket;

  public send<T>(message: T, eventName: string = 'message'): void {
    if (!this._socket) {
      return;
    }
    this._socket.emit(eventName, message);
  }

  public onMessage$<T>(event: string = 'message'): Observable<T> {
    return this._initialized.pipe(
      filter(v => v),
      switchMap(() => new Observable<T>(observer => {
        this._socket.on(event, (data: T) => observer.next(data));
      })),
    );
  }

  public disconnect(): void {
    if (!this._socket) {
      return;
    }
    this._socket.disconnect();
    this._initialized.next(false);
  }

  public onEvent$<TEventParam>(event: SocketEvent): Observable<TEventParam> {
    return this.onMessage$(event);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PTCSocketIOService extends ASocketIOService {

  constructor(
    private devService: DeveloperService,
  ) {
    super();
  }

  public initSocket(): Socket {
    this.socket = io(this.devService.settings.socketAPI, {
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      secure: true,
    });
    return this.socket;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ActivitiesSocketIOService extends ASocketIOService {

  public initSocket(userToken: string): Socket {
    if (this.socket && this.socket.connected) {
      return this.socket;
    }
    this.socket = io(environment.ACTIVITY_SOCKET_URL, {
      path: window.localStorage.getItem('socketPath') || environment.ACTIVITY_SOCKET_PATH || '/activitysocket',
      transports: ['websocket', 'polling'],
      secure: true,
      query: {token: userToken}
    });
    // window.addEventListener("keypress", (e: KeyboardEvent) => {if (e.key === 'd') this.socket.disconnect()});
    return this.socket;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TestActivitiesSocketIOService extends ASocketIOService {

  public constructor(
    public localSocketServer: LocalSocketServerService,
  ) {
    super();
  }

  public initSocket(): Socket {
    if (this._initialized.getValue()) {
      return;
    }
    this.socket = undefined;
    this.localSocketServer.init();
    return this.socket;
  }

  public send<T>(message: T, eventName: string = 'message'): void {
    this.localSocketServer.receive(eventName, JSON.stringify(message));
  }

  public onMessage$<T>(event: string = 'message'): Observable<T> {
    return this._initialized.pipe(
      filter(v => v),
      switchMap(() => {
        return this.localSocketServer.events$
          .pipe(filter(e => e.event === event))
          .pipe(map(e => JSON.parse(e.data)));
      }),
    ) as Observable<T>;
  }
}
