import {Component, Injector, OnInit, ViewContainerRef} from '@angular/core';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import {InputBaseController} from '../../input-controller';
import {Dictionary} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {DocModalComponent, IDocModalResult} from '../../../modal/doc-modal/doc-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-doc',
  templateUrl: './action-doc.component.html',
  styleUrls: ['./action-doc.component.scss'],
})
export class ActionDocComponent extends InputBaseController implements OnInit {
  public static className: string = 'action-doc';
  public static readonly responseKeys: string[] = ['text', 'clicked'];
  public params: {
    label: string, // Button Label
    text: string,  // Html content
    formattedText: string;
    clicked: boolean, // Modal opened ?
    preview: boolean  // Show preview div ?
  };
  public disabled: boolean = true;
  public buttonLabel: string;

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    private matDialog: MatDialog,
    public translate: TranslateService,
  ) {
    super(injector, viewContainerRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.params.label) {
      this.buttonLabel = this.params.label;
    } else if (this.type === 'show') {
      this.buttonLabel = this.translate.instant('ACTIVITY.ACTION.DOC.BTN_TYPE_SHOW');
    } else if (this.type === 'input') {
      this.buttonLabel = this.translate.instant('ACTIVITY.ACTION.DOC.BTN_TYPE_INPUT');
    }
  }

  public onChange(): void {
    super.onChange();
  }

  public openEditor(): void {
    this.params.clicked = true;
    this.matDialog.open(DocModalComponent, {
      data: {
        content: this.params.text,
        mode: this.manager.mode,
        type: this.type
      },
      panelClass: 'full-screen-doc-modal',
      disableClose: true,
    }).afterClosed().subscribe((result: IDocModalResult) => {
      if (result) {
        this.params.text = result.content;
        this.params.formattedText = result.formattedText;
        this.onChange();
      }
    });
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let text: string = getValue(action.params.clicked) || '';
    return {text: text.replace(/(?:\r\n|\r|\n)/g, ' ').trim()};
  }
}
