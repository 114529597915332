import {Component, Inject, OnInit} from '@angular/core';
import moment, {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import upperFirst from 'lodash/upperFirst';

export interface IDatePickerModalDialogData {
  date: Date;
  minDate: Date;
  maxDate: Date;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss'],
})
export class DatePickerModalComponent implements OnInit {

  private lang: string = 'en';
  public years: { value: number, text: string }[] = [];
  public selectedYear: number;
  public months: { value: number, text: string }[] = [];
  public selectedMonth: { value: number, text: string };
  public days: { value: number, text: string }[] = [];
  public selectedDay: number;
  public monthCalendar: IWeekCalendarView[] = [];

  public date: Date;
  public displayableDate: string = '';
  public selectObjects: ISelectModel[] = [];
  public minDate: Date;
  public maxDate: Date;

  constructor(
    private matDialogRef: MatDialogRef<DatePickerModalComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: IDatePickerModalDialogData) {
    this.date = data.date;
    this.minDate = data.minDate;
    this.maxDate = data.maxDate;
  }

  public ngOnInit(): void {
    this.date = this.date || new Date();
    this.minDate = this.minDate || new Date(1900, 0, 0, 0);
    this.maxDate = this.maxDate || new Date(2100, 0, 0, 0);
    this.matDialogRef.beforeClosed().subscribe(() => this.matDialogRef.close(this.date));
    this.lang = this.translate.instant('DATE_FORMAT.MOMENT.LOCAL');
    if (moment(this.minDate).diff(this.maxDate, 'days') > 0) {
      throw new Error('Invalid: Max date must be upper than Min date');
    }
    this.initViews(this.date);
  }

  public close(): void {
    this.matDialogRef.close(this.date);
  }

  public renderSelectOrder(): void {
    let order: string[] = this.translate.instant('DATE_FORMAT.MOMENT.DATE_ORDER').split(',');
    let selectModels: { [item: string]: ISelectModel; } = {
      y: {
        list: this.years,
        onChange: this.changeYear.bind(this),
        selected: this.isSelectedYear.bind(this)
      },
      m: {
        list: this.months,
        onChange: this.changeMonth.bind(this),
        selected: this.isSelectedMonth.bind(this)
      },
      d: {
        list: this.days,
        onChange: this.changeDay.bind(this),
        selected: this.isSelectedDay.bind(this)
      }
    };
    this.selectObjects = order.map(item => selectModels[item]);
  }

  public decomposeDate(date: Date): void {
    this.selectedYear = date.getFullYear();
    this.selectedMonth = {
      value: date.getMonth() + 1,
      text: upperFirst(moment(date)
        .locale(this.lang)
        .format('MMMM'))
    };
    this.selectedDay = date.getDate();
  }

  public setDate(date: Date): void {
    this.date = new Date(date);
    this.decomposeDate(this.date);
    this.displayableDate = moment().locale(this.lang).format(this.translate.instant('DATE_FORMAT.MOMENT.DATE_FULL'));
    this.checkBetween();
  }

  public checkBetween(): void {
    if (!this.date) {
      return;
    }
    if (!this.isBetween()) {
      // if the difference between date and min date is negative
      if (moment(this.date).isBefore(this.minDate, 'days')) {
        this.initViews(new Date(this.minDate));
      }
      // if the difference between date and max date is positive
      if (moment(this.date).isAfter(moment(this.maxDate).toDate(), 'days')) {
        this.initViews(new Date(this.maxDate));
      }
    }
  }

  /**
   * isBetween inclusive
   * check if date is between min and max
   */
  public isBetween(date: Date = this.date, granularity: moment.unitOfTime.StartOf = 'days'): boolean {
    return moment(date).isSameOrAfter(this.minDate, granularity) &&
      moment(date).isSameOrBefore(this.maxDate, granularity);
  }

  public initViews(date: Date): void {
    this.setDate(date);
    this.initSelectYear();
    this.initSelectMonth();
    this.initSelectDay();
    this.initCalendarView();
    this.renderSelectOrder();
  }

  public isSelectedDay(d: number): boolean {
    return d === this.selectedDay;
  }

  public isSelectedMonth(m: number): boolean {
    return m === this.selectedMonth.value;
  }

  public isSelectedYear(y: number): boolean {
    return y === this.selectedYear;
  }

  public changeYear(e: Event): void {
    this.date.setFullYear(parseInt((e.target as HTMLSelectElement).value, 10), this.selectedMonth.value - 1, this.selectedDay);
    this.initViews(this.date);
  }

  public changeMonth(e: Event): void {
    this.date.setFullYear(this.selectedYear, parseInt((e.target as HTMLSelectElement).value, 10) - 1, this.selectedDay);
    this.initViews(this.date);
  }

  public changeDay(e: Event): void {
    this.date.setFullYear(this.selectedYear, this.selectedMonth.value - 1, parseInt((e.target as HTMLSelectElement).value, 10));
    this.initViews(this.date);
  }

  public initSelectYear(): void {
    this.years = [];
    let yearsNb: number = this.maxDate.getFullYear() - this.minDate.getFullYear();
    for (let year: number = yearsNb; year >= 0; year--) {
      this.years.unshift(
        {
          text: (this.minDate.getFullYear() + year).toString(),
          value: this.minDate.getFullYear() + year
        });
    }
  }

  public initSelectMonth(): void {
    this.months = [];
    for (let month: number = 11; month >= 0; month--) {
      if (this.isBetween(moment().year(this.selectedYear).month(month).toDate(), 'months')) {
        this.months.unshift(
          {
            text: upperFirst(moment()
              .locale(this.lang)
              .year(this.selectedYear)
              .month(month)
              .format('MM')),
            value: month + 1
          });
      }
    }
  }

  public initSelectDay(): void {
    this.days = [];
    const monthIndex: number = this.selectedMonth.value - 1;
    const date: Date = new Date(this.selectedYear, monthIndex, 1);
    while (date.getMonth() === monthIndex) {
      if (this.isBetween(date)) {
        this.days.unshift(
          {
            text: moment(date)
              .locale(this.lang)
              .format('DD'),
            value: date.getDate()
          });
      }
      date.setDate(date.getDate() + 1);
    }
    this.days.reverse();
  }

  public initCalendarView(): void {
    this.monthCalendar = [];
    let startWeek: number = moment(this.date).startOf('month').week();
    let endWeek: number = moment(this.date).endOf('month').week();
    if (endWeek < startWeek) {
      endWeek = 52 + endWeek;
    } // last week number of december can be less, and bugs the for loop
    for (let week: number = startWeek; week <= endWeek; week++) {
      this.monthCalendar.push({
        week,
        days: Array(7).fill(0).map((n, i) => {
          let day: Moment = moment()
            .year(this.selectedYear)
            .week(week)
            .startOf('week')
            .clone().add(n + i, 'day');
          return {
            moment: day.toDate(),
            isCurrentMonth: day.isSame(this.date, 'month'),
            isClickable: this.isBetween(day.toDate()),
            number: day.date(),
            selected: day.isSame(this.date, 'day'),
            text: day.locale(this.lang).format('ddd')
          };
        })
      });
    }
  }

  public onClickArrowLeft(): void {
    let newDate: Date = new Date(this.date.setMonth(this.date.getMonth() - 1));
    this.initViews(newDate);
  }

  public onClickArrowRight(): void {
    let newDate: Date = new Date(this.date.setMonth(this.date.getMonth() + 1));
    this.initViews(newDate);
  }

  public clickOnCalendar(clickable: boolean, day: Date): void {
    if (!clickable) {
      return;
    }
    this.initViews(day);
  }
}

interface IWeekCalendarView {
  week: number;
  days: {
    moment: Date,
    isCurrentMonth: boolean,
    isClickable: boolean,
    number: number,
    text: string,
    selected?: boolean,
  }[];
}

interface ISelectModel {
  list: { value: number, text: string }[];
  onChange: (...args: any) => void;
  selected: (arg: number) => boolean;
}
