<div class="description-container">
  <quill-editor #quillEditorComponent
    *ngIf="type == 'input' && manager.mode !== 'review'"
    [bounds]="quillBounds"
    (onContentChanged)="editQuillChanged($event)"
    (onEditorCreated)="editQuillInit($event)"
    [modules]="quillModules"
    [(ngModel)]="params.text">
  </quill-editor>

  <div *ngIf="(type == 'show' || manager.mode === 'review') && params.text" class="flex-column">
    <div [style.maxHeight]="currentMaxHeight + 'px'"
         class="show-description ql-editor"
         [innerHTML]="params.text | bbcodeTranslation: bbcodeTranslator.redraw | sanitize"
         (onInit)="descriptionInit($event)"
         convert-cordova-app-links
         [class.close]="!descriptionOpened"></div>
    <a *ngIf="shouldReduceDescription"
       (click)="toggleDescription()"
       class="show-button">
      {{ showButtonText | translate }}
    </a>
  </div>
</div>
