<div *ngIf="input" class="time-zone-container d-flex align-items-center">
  <button mat-icon-button (click)="timeZonesSelect.open()">
    <ion-icon class="greyish" aria-label="Time zone" name="earth" size="large"></ion-icon>
  </button>
  <ion-item>
    <ion-select #timeZonesSelect [(ngModel)]="timeZone" (ngModelChange)="onTimeZonesSelect()" class="greyish">
      <ion-select-option *ngFor="let tz of availableTimeZones" [value]="tz">{{tz}}</ion-select-option>
    </ion-select>
  </ion-item>
</div>
