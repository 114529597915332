import {Component} from '@angular/core';
import Action from '../../../../models/action';
import moment from 'moment-timezone';
import {InputBaseController} from '../../input-controller';
import {switchToTimeZone} from '../../../time-zone-select/time-zone-select.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-date-time',
  templateUrl: './action-date-time.component.html',
  styleUrls: ['./action-date-time.component.scss'],
})
export class ActionDateTimeComponent extends InputBaseController {
  public static className: string = 'action-date-time';
  public static readonly responseKeys: string[] = ['selectedDate'];

  public params: {
    minDate: Date,
    maxDate: Date,
    selectedDate: Date,
    time: string,
    timeZone: string,
  };
  public previousTimeZone: string = moment.tz.guess(true);

  protected async initAction(action: Action): Promise<void> {
    super.initAction(action);
    this.params.selectedDate = this.params.selectedDate ? new Date(this.params.selectedDate) : undefined;
    this.params.minDate = this.params.minDate ? new Date(this.params.minDate) : undefined;
    this.params.maxDate = this.params.maxDate ? new Date(this.params.maxDate) : undefined;

    try {
      this.params.timeZone = this.params.timeZone || (await this.getPod()).communities[0].timeZone || this.previousTimeZone;
      this.previousTimeZone = this.params.timeZone;
    } catch (e) {
      console.warn(e);
      this.params.timeZone = this.previousTimeZone;
    }
  }

  public updateDateTime(): void {
    if (this.params.selectedDate) {
      this.params.selectedDate.setSeconds(0);
      this.params.time = moment(this.params.selectedDate).tz(this.params.timeZone).format('hh:mmA');
    }
  }

  public onTimeZonesSelect(newTimeZone: string): void {
    this.params.selectedDate = this.params.selectedDate && switchToTimeZone(this.params.selectedDate, newTimeZone, this.previousTimeZone);
    this.updateDateTime();
    this.previousTimeZone = this.params.timeZone;
  }
}
