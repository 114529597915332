import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import Action from '../../../../models/action';
import moment from 'moment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-birthdate',
  templateUrl: './action-birthdate.component.html',
  styleUrls: ['./action-birthdate.component.scss'],
})
export class ActionBirthdateComponent extends InputBaseController {
  public static className: string = 'action-birthdate';
  public static readonly responseKeys: string[] = ['selectedDate'];
  public params: {
    selectedDate: string,
    minDate: Date,
    maxDate: Date,
    format: string,
  };
  public pickerDate: Date;

  protected initAction(action: Action): void {
    super.initAction(action);
    this.placeholder = this.placeholder || 'Birthdate';
    if (this.params.selectedDate) {
      this.params.selectedDate = this.params.selectedDate.split('T')[0];
      let dateArray: number[] = this.params.selectedDate.split('-').map(s => parseInt(s, 10));
      this.pickerDate = moment().year(dateArray[0]).month(dateArray[1] - 1).date(dateArray[2]).toDate();
    }
    this.params.minDate = this.params.minDate ? new Date(this.params.minDate) : undefined;
    this.params.maxDate = this.params.maxDate ? new Date(this.params.maxDate) : undefined;
  }

  public onChange(): any {
    this.params.selectedDate = moment(this.pickerDate).format('YYYY-MM-DD');
    super.onChange();
  }
}
