import {Component, Injector, OnInit, ViewContainerRef} from '@angular/core';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import {ApiService} from '../../../../services/api.service';
import {Dictionary} from 'lodash';
import {InputBaseController} from '../../input-controller';
import {LoadingController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../services/user.service';
import {PdfModalComponent} from '../../../modal/pdf-modal/pdf-modal.component';
import {ApiUrlService} from '../../../../services/api-url.service';
import { DynamicMatDialogService } from '../../../../services/dynamic-mat-dialog/dynamic-mat-dialog.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-upload',
  templateUrl: './action-upload.component.html',
  styleUrls: ['./action-upload.component.scss'],
})
export class ActionUploadComponent extends InputBaseController implements OnInit {
  public static className: string = 'action-upload';
  public static readonly responseKeys: string[] = ['fileName', 'fileDisplayName', 'clicked'];
  public params: {
    fileName: string,
    fileDisplayName: string,
    clicked: boolean,
    allowedExtensions: string[]
  };
  public error: string = undefined;
  public activityId: string;
  public currentUserId: string;
  public concernedUser: string;

  constructor(
    public plt: Platform,
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    private matDialog: DynamicMatDialogService,
    public loadingCtrl: LoadingController,
    public translate: TranslateService,
    public api: ApiService,
    private userService: UserService,
  ) {
    super(injector, viewContainerRef);
  }

  public ngOnInit() {
    super.ngOnInit();
    if (this.manager.mode === 'review') {
      this.params.clicked = true;
    }
    this.updateAction();
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.params.allowedExtensions = this.params.allowedExtensions ||
      ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'ppt', 'pptx'];
    this.activityId = this.manager['rootObject'].activity.id;
    this.currentUserId = this.userService.currentUser._id;
    this.concernedUser = this.manager['rootObject'].concernedUser ?
      this.manager['rootObject'].concernedUser.id : this.currentUserId;
  }

  public async addFile(file: File): Promise<void> {
    this.error = undefined;
    let loader: HTMLIonLoadingElement = await this.loadingCtrl.create({message: 'Uploading...'});
    await loader.present();
    try {
      let fileName = file.name.replace(/ /g, '_');
      let fakeUrl = this.manager.getExpectedResourceUrl(fileName);
      this.manager.setFileAlias(fakeUrl, URL.createObjectURL(file));
      let name: string[] = await this.api.postActivityFile(this.activityId, this.concernedUser, this.currentUserId, file);
      this.params.fileName = name[0];
      this.params.fileDisplayName = fileName;
    } catch (e) {
      this.error = 'There has been an error. Please try again.';
    }
    await loader.dismiss();
    this.onChange();
  }

  public async getResourceUrl(): Promise<void> {
    this.params.clicked = true;
    this.openExternalResource(
      this.params.fileName,
      this.plt,
      (url) => {
        this.matDialog.open(
          PdfModalComponent,
          {
            data: {
              url
            },
            panelClass: 'full-screen-modal'
          });
      });
    this.onChange();
  }


  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action, activityId: string, injector?: Injector): Dictionary<any> {
    let fileName: string = getValue(action.params.fileName);
    if (!fileName) {
      return;
    }
    const apiUrlProvider: ApiUrlService = injector.get(ApiUrlService);
    let url: string = `${apiUrlProvider.apiUrl}Activities/${activityId}/Ressource/${fileName}`;
    return {url};
  }
}
