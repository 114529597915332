import { Injectable } from '@angular/core';
import { ILinkExpiredPageParams } from '../pages/link-expired/link-expired.params';

@Injectable({
  providedIn: 'root'
})
export class ExpiredTokenStorageService {

  public info?: ILinkExpiredPageParams;

  constructor() { }
}
