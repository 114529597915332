import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {InputFileComponent} from './input-file.component';
import {DirectivesModule} from '../../../directives/directives.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatInputModule,
    MatIconModule,
    TranslateModule.forChild()
  ],
  declarations: [
    InputFileComponent
  ],
  exports: [
    InputFileComponent
  ]
})
export class InputFileModule {
}
