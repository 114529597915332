import { Injectable } from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from '../../environments/environment';
import { Platform, AlertController } from '@ionic/angular';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { CognitoAuthService } from './cognito-auth.service';
import { distinctUntilChanged, take } from 'rxjs/operators';
import AuthenticatedUser from '../models/user';
import { DeepLinkService } from './deep-link.service';
import { EventsService } from './events.service';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import NotificationReceivedEvent from 'onesignal-cordova-plugin/dist/NotificationReceivedEvent';
import { DeviceState } from 'onesignal-cordova-plugin/dist/Subscription';

@Injectable()
export class PushNotificationService {

  constructor(
    private platform: Platform,
    private apiService: ApiService,
    private userService: UserService,
    private cognitoAuth: CognitoAuthService,
    private deepLink: DeepLinkService,
    private events: EventsService,
    // private alertCtrl: AlertController,
  ) {
  }

  public subscribe(): void {

    if (!this.platform.is('hybrid')) return;

    OneSignal.setAppId(environment.ONE_SIGNAL_APP_ID);
    this.onNotificationOpened();

    this.cognitoAuth.cognitoLogout$.subscribe(async () => {
      const currentUser: AuthenticatedUser = await this.userService.currentUser$.pipe(take(1)).toPromise();
      if (this.oneSignalUser && currentUser) this.unregister();
    });
    this.userService.validCurrentUser$.pipe(
      distinctUntilChanged((prev, curr) => prev._id === curr._id),
    ).subscribe(async (user: AuthenticatedUser) => {
      if (!this.oneSignalUser) await this.init();
      console.log('registering one signal');
      await this.register();
    });
  }

  public oneSignalUser: { userId: string, pushToken: string };

  public async init(): Promise<{ userId: string, pushToken: string }> {
    this.oneSignalUser = await new Promise((resolve, reject) => {
      OneSignal.getDeviceState((state: DeviceState) => {
        console.debug('OneSignal.getDeviceState', JSON.stringify(state));
        resolve(state);
      });
    });
    return this.oneSignalUser;
  }

  public async register(): Promise<unknown> {
    if (!this.oneSignalUser) throw new Error('cannot register notifications without a user!');
    return await this.apiService.pushNotifiationRegister(this.oneSignalUser.userId);
  }

  public async unregister(): Promise<unknown> {
    if (!this.oneSignalUser) throw new Error('cannot unregister notifications without a user!');
    return await this.apiService.pushNotificationUnregister(this.oneSignalUser.userId);
  }

  public onNotificationReceived(): void {
    OneSignal.setNotificationWillShowInForegroundHandler((event: NotificationReceivedEvent) => {
      console.debug('NotificationWillShowInForeground', event);
    });

  }

  public onNotificationOpened(): void {
    OneSignal.setNotificationOpenedHandler((openedEvent: OpenedEvent) => {

      // const alert = await this.alertCtrl.create({message: JSON.stringify(data)})
      // await alert.present();
      console.debug('notification data', openedEvent);
      let url: string = (openedEvent?.notification?.additionalData as ({ url: string })).url;
      console.debug('notification url', url);
      if (url) {
        this.events.publish('pending-activities:refresh');
        this.deepLink.execute(url);
      }
    });
  }

  public ignoreNotifs(): void {
    OneSignal.disablePush(true);
  }
}
