import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface LinkPopUpDialogData {
  description: string;
  url: string;
  linkText: string;
}

@Component({
  selector: 'app-link-pop-up',
  templateUrl: './link-pop-up.component.html',
  styleUrls: ['./link-pop-up.component.scss'],
})
export class LinkPopUpComponent {

  constructor(
    public dialogRef: MatDialogRef<LinkPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LinkPopUpDialogData,
  ) { }

  onLinkClick(): void {
    this.dialogRef.close();
  }
}
