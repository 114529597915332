<!-- Generated template for the TimerComponent component -->
<div>
  <button
      ion-button
      round
      color="secondary"
      class="time-indicator"
      [ngClass]="{'running': timer.running}"
  >
    {{timer.seconds | timerFormat}}
  </button>
</div>
