import uniq from 'lodash/uniq';

export interface IJsonAuthenticatedUser {
  _id: string;
  creationDate: string;
  updatedDate: string;
  username: string;
  SISids: any;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: Gender;
  emailAddresses: string[];
  phones: {
    home: string;
    cell: string;
    work: string;
  };
  addresses: IGoogleAddress[];
  birthdate: string;
  language: string;
  accountGrades: AccountGrade[];
  accountStatus: AccountStatus;
  responsibleIds: string[];
  pushNotificationTokens: string[];
  notificationTypes: {
    email: boolean,
    push: boolean,
    sms: boolean
  };
  chatNotificationTypes: {
    email: boolean,
    push: boolean,
    sms: boolean
  };
  ssoIdentifiers: string[];
  appLanguage: string;
  appLanguageRequested?: boolean;
  chatUsage: IChatUsage;
  voiceUsage: IVoiceUsage;
  lastSyncDown: string;
  lastSyncUp: string;
  studentId: string;
  type: 'user';
}

export default class AuthenticatedUser implements ISSOLinked {
  public _id: string;
  public creationDate: Date;

  public accountGrades: string[];
  public accountStatus: AccountStatus = 'SIGNED';

  public username: string;
  public firstName: string;
  public lastName: string;
  public emailAddresses: string[];
  public phones: {
    home: string;
    cell: string;
    work: string;
  };

  public responsibleIds: string[];

  public SISids: any;
  public ssoIdentifiers: string[];
  public pushNotificationTokens: string[];

  public notificationTypes: {
    email: boolean,
    push: boolean,
    sms: boolean
  };
  public chatNotificationTypes: {
    email: boolean,
    push: boolean,
    sms: boolean
  };
  public appLanguage: string;
  public appLanguageRequested?: boolean;
  public chatUsage: IChatUsage;
  public voiceUsage: IVoiceUsage;

  public autoTranslateActivity: boolean = true;

  public constructor(jsonUser: IJsonAuthenticatedUser) {
    this._id = jsonUser._id;
    this.creationDate = new Date(jsonUser.creationDate);
    this.accountGrades = jsonUser.accountGrades;
    this.accountStatus = jsonUser.accountStatus;
    this.username = jsonUser.username;
    this.firstName = jsonUser.firstName;
    this.lastName = jsonUser.lastName;
    this.emailAddresses = jsonUser.emailAddresses;
    this.phones = jsonUser.phones;
    this.responsibleIds = jsonUser.responsibleIds;
    this.SISids = jsonUser.SISids;
    this.ssoIdentifiers = jsonUser.ssoIdentifiers;
    this.pushNotificationTokens = jsonUser.pushNotificationTokens;
    this.notificationTypes = jsonUser.notificationTypes;
    this.chatNotificationTypes = jsonUser.chatNotificationTypes;
    this.appLanguage = jsonUser.appLanguage;
    this.appLanguageRequested = jsonUser.appLanguageRequested;
    this.chatUsage = jsonUser.chatUsage;
    this.voiceUsage = jsonUser.voiceUsage;
  }

  public get primaryPhone(): string {
    if (this.phones.cell) return this.phones.cell;
    if (this.phones.home) return this.phones.home;
    if (this.phones.work) return this.phones.work;
  }

  public get email(): string {
    this.emailAddresses = this.emailAddresses || [];
    return this.emailAddresses[0] ? this.emailAddresses[0] : '';
  }

  public set email(emailAddress: string) {
    if (!emailAddress) {
      return;
    }
    let email: string = emailAddress.toLowerCase().trim();
    let regexp: RegExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.emailAddresses = this.emailAddresses || [];
    if (regexp.test(email)) {
      this.emailAddresses.unshift(email);
      this.emailAddresses = uniq(this.emailAddresses);
    }
  }

  public get isPublic(): boolean {
    return this.accountGrades.includes('PUBLIC');
  }

  public get id(): string {
    return this._id;
  }

  public get chatMessages(): number {
    if (!this.chatUsage) {
      return 0;
    }
    return this.chatUsage.pendingMessages;
  }

  public get canChat(): boolean {
    if (!this.chatUsage) {
      return false;
    }
    return this.chatUsage.active;
  }

  public set canChat(canHe: boolean) {
    this.chatUsage = this.chatUsage || {
      pendingMessages: 0,
      active: true,
      translate: false
    };
    this.chatUsage.active = canHe;
  }

  public get hasCallsHistory(): boolean {
    return this.voiceUsage.callsCount > 0;
  }
}

export interface IProvidedUser {
  id: string;
  email: string;
  addresses: IGoogleAddress[];
  firstName: string;
  lastName: string;
  middleName: string;
  language: string;
  appLanguage: string;
  gender: string;
  birthdate: string;
  phones: IPhones;
}

export interface IPhones {
  home: string;
  cell: string;
  work: string;
  other: string;
}

export interface IGoogleAddress {
  placeId: string;
  formattedAddress: string;
  additionalDetails: string;
}

export type Gender = 'UNDEFINED' | 'MALE' | 'FEMALE' | 'OTHER';

export type AccountGrade = 'USER' | 'ADMIN' | 'PUBLIC';

export type AccountStatus = 'CREATED' | 'SIGNED' | 'REGISTERED';

export interface ISSOLinked {
  ssoIdentifiers: string[];
}

export interface IChatUsage {
  active: boolean;
  pendingMessages: number;
  translate: boolean;
}

export interface IVoiceUsage {
  callsCount: number;
}
