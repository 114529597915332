import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import {LoadingController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import { FileExtension, MimeTypeService } from '../../../services/mime-type.service';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {

  @Input('ios') ios: boolean;
  @Input('fileName') fileName: string;
  @Input('fileDisplayName') fileDisplayName: string;
  @Input('allowedExtensions') allowedExtensions: FileExtension[] = ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx', 'xls', 'xlsx', 'csv'];
  @Input('error') error: string;
  @Output() onFile: EventEmitter<File> = new EventEmitter<File>();
  public acceptTypes: string;
  public mimetypes: string[];
  public plt: Platform;

  constructor(public loadingCtrl: LoadingController,
              public translate: TranslateService,
              private mimeTypeService: MimeTypeService) {
  }

  ngOnInit(): void {
    this.mimetypes = this.mimeTypeService.getTypesFor(this.allowedExtensions);
    this.acceptTypes = this.mimetypes.join(',');
  }

  public async addFile(fileInput: any): Promise<void> {
    let files: FileList = (fileInput.target as HTMLInputElement).files;
    let file = files[0];
    if (!file) {
      return;
    }
    if (this.mimetypes.indexOf(file.type) === -1) {
      this.error = this.translate.instant('ACTIVITY.ACTION.UPLOAD.PROVIDE_ALLOWED')
        + this.allowedExtensions.join(', ');
      return;
    }
    this.onFile.emit(file);
  }

}
