import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, RoutesRecognized, NavigationEnd, NavigationError, ActivatedRouteSnapshot } from '@angular/router';
import { filter, mergeMap, scan, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityNavigationTrackingService {

  public activityId?: string;

  constructor(
    private router: Router,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      mergeMap((e: NavigationStart) => this.router.events.pipe(
        filter(event => (event as any)?.id === e.id),
        takeWhile(event => !(event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError), true),
      )),
    ).subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        let child: ActivatedRouteSnapshot = event.state.root;
        while (child.firstChild) {
          child = child.firstChild;
          if (child.params.activityId) {
            this.activityId = child.params.activityId;
            break;
          }
        }
      } else if (event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel) {
        this.activityId = undefined
      }
    });
  }
}
