import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-password',
  templateUrl: './action-password.component.html',
  styleUrls: ['./action-password.component.scss'],
})
export class ActionPasswordComponent extends InputBaseController {
  public static className: string = 'action-password';
  public static readonly responseKeys: string[] = ['password'];
  public params: { password: string };

}
