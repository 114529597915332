import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnexpectedErrorDataContainerService {

  public urlBeforeError: string;

  constructor() {}
}
