import { Injectable, Injector } from '@angular/core';
import _ from 'lodash';
import { BbcodeTranslatorService } from './bbcode-translator.service';
import UserResponses from '../models/user-responses';
import APIUsersResponses from '../models/API/users-responses';
import Action from '../models/action';
import DynValue from '../models/dyn-value';
import { InputBaseController } from '../components/form/input-controller';

@Injectable({
  providedIn: 'root'
})
export class CsvExporterService {


  constructor(
    private injector: Injector,
    public translation: BbcodeTranslatorService
  ) {

  }

  public static ConvertDate(field: Date): any {
    let date: string = '';
    let day: string = '';
    let time: string = '';
    if (field instanceof Date && !isNaN(field.getTime())) {
      let dateWithDay: string = field.toDateString();
      date = dateWithDay.replace(/^\S+\s/, '');
      day = dateWithDay.split(' ')[0];
      time = field.toLocaleTimeString('en-US');
    }
    return { date, day, time };
  }

  public getCSVBaseHeader(): string[] {
    return ['Last Answer', 'Completed by', 'Answered by', 'Answered for', 'Concerned user'];
  }

  public getCSVBaseResponse(userResponse: UserResponses): any[] {
    return [
      CsvExporterService.ConvertDate(userResponse.responseDate),
      userResponse.impersonatedBy ? userResponse.impersonatedBy.firstName + ' ' + userResponse.impersonatedBy.lastName : '',
      userResponse.answeredBy ? userResponse.answeredBy.firstName + ' ' + userResponse.answeredBy.lastName : '',
      userResponse.users.map(u => u.firstName + ' ' + u.lastName).join(' | '),
      userResponse.concernedUser ? userResponse.concernedUser.firstName + ' ' + userResponse.concernedUser.lastName : '',
    ];
  }

  public getCSVMinimalBaseHeader(): string[] {
    return ['Concerned user', 'Answered by'];
  }

  public getCSVMinimalBaseResponse(userResponse: UserResponses): any[] {
    return [
      userResponse.concernedUser ? userResponse.concernedUser.firstName + ' ' + userResponse.concernedUser.lastName : '',
      userResponse.answeredBy ? userResponse.answeredBy.firstName + ' ' + userResponse.answeredBy.lastName : '',
    ];
  }

  private getCsvField(field: string): string {
    return '"' + (field != null ? field : '').replace(/"/g, '""') + '"';
  }

  public getCSVResponses(apiUserResponses: APIUsersResponses, full: boolean): void {

    let userResponses: UserResponses[] = apiUserResponses.getCSVResponses();
    let actionsInput: Action[] = apiUserResponses.getCSVActions();


    let csvResponses = userResponses.map(userResponse => {
      let csvResponses = actionsInput.map(action => ({ action, response: (userResponse.responses || {})[action.alias] }))
        .map(e => {
          InputBaseController.getStatic(e.action.name).setResponseToAction(e.action, e.response || {});
          let responses = e.action.getCsvResponse((dyn: DynValue) => dyn.execute({}), apiUserResponses.activity.id, this.injector);
          responses = _.mapValues(responses, response => _.isString(response) ? this.translation.translate(response) : response);
          return {
            title: this.getCsvField(e.action.displayCSV ? e.action.displayCSV : e.action.label),
            content: responses
          };
        });
      if (full) {
        csvResponses = this.getCSVBaseResponse(userResponse).map((r, i) => {
          return ({ title: this.getCSVBaseHeader()[i], content: r } as any);
        }).concat(csvResponses);
      } else {
        csvResponses = this.getCSVMinimalBaseResponse(userResponse).map((r, i) => {
          return ({ title: this.getCSVMinimalBaseHeader()[i], content: r } as any);
        }).concat(csvResponses);
      }
      return csvResponses;
    }) as any[];

    // now we have an array of {title, content}
    // we can start building the csv array based on title + '/' + content.key

    let explodeResponseBuilder = () => {
      let discoveredTitles = [];

      const checkForTitle = (response) => {
        let res = response;
        const sameTitles = discoveredTitles.filter(title => title === response.title);
        if (sameTitles.length > 0) {
          res = { title: response.title + '/' + sameTitles.length, content: response.content };
        }
        discoveredTitles.push(response.title);
        return res;
      };

      let explodeResponse = (response) => {
        if (!response.content || typeof response.content !== 'object') {
          return checkForTitle(response);
        }
        let keys: string[] = Object.keys(response.content);
        return _.flatMap(keys, k => {
          let res: { title: string, content: any } = { title: response.title + '/' + k, content: response.content[k] };
          return explodeResponse(res);
        });
      };
      return explodeResponse;
    };

    csvResponses = _.map(csvResponses, (userResponses => {
      return _.flatMap(userResponses, explodeResponseBuilder());
    }));

    const rawTitles = _(csvResponses).map((userResponses: any[]) => userResponses.map(response => response.title)).value();
    const rearrangedTitles = _.zip(...rawTitles);
    const orderedTitles = _(rearrangedTitles).flatMap().reverse().uniq().reverse().value();

    const normalizedCsvResponses = csvResponses.map(userResponses => {
      return orderedTitles.map(title =>
        _.find(userResponses, ['title', title]) || { title, content: undefined }
      );
    });

    let csvArray: ArrayLike<any> = _.zip(...normalizedCsvResponses) as (ArrayLike<any>);
    csvArray = _.map(csvArray, entries => {
      return {
        header: entries[0].title,
        values: entries.map(entry => this.getCsvField(entry && entry.content != null && entry.content.toString() || '')),
      };
    });

    let header: string = _.map(csvArray, col => col.header).join(',');
    let rows = _.zip(..._.map(csvArray, col => col.values));
    let formattedRows = _.map(rows, r => r.join(',').replace(/[‘’]/g, '\''));

    this.downloadCsvFile(header + '\n' + formattedRows.join('\n'), apiUserResponses.activity.title + '.csv');
  }

  public downloadCsvFile(content: string, fileName: string): void {
    let a: HTMLAnchorElement = document.createElement('a');
    let blob: Blob = new Blob(
      [
        new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
        content
      ],
      { type: 'text/plain;charset=utf-8' });
    let url: string = window.URL.createObjectURL(blob);
    a.href = url;
    a.setAttribute('type', 'hidden');
    document.body.appendChild(a);
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
