import { cloneDeep, Dictionary } from 'lodash';
import AMergeable from './../a-mergeable';
import { environment } from '../../../environments/environment';
import Builder from '../API/builders';

export default class LocalProviders extends AMergeable {
  public canSubmit: boolean = false;
  public goToPreview: () => void;
  public filesAlias: Dictionary<string>;

  constructor(json: LocalProviders) {
    super();
    this.filesAlias = cloneDeep(json.filesAlias) || {};
  }

  public getResourceUrl(path: string, rootObject: Builder): string {
    return this.filesAlias[path] || path.replace(/ACTIVITY_ID/, rootObject.activity.id);
  }

  get now(): Date {
    return new Date();
  }

  public openStripePayment(amount: number, desc: string, tokenCallback: any): void {
    const handler: any = (window as any).StripeCheckout.configure({
      key: environment.STRIPE_PUBLISH_KEY,
      locale: 'auto',
      image: './assets/img/actionaly_logo_full.png',
      allowRememberMe: true,
      token: tokenCallback
    });
    handler.open({
      name: 'Actionaly',
      description: desc,
      amount,
      currency: 'usd',
      panelLabel: 'Pay {{amount}}',
    });
  }
}
