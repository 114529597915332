import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import ActivityListInfo, { IPodTargetsDetails } from '../models/activity-list-info';
import AuthenticatedUser, { IJsonAuthenticatedUser } from '../models/user';
import { Dictionary } from 'lodash';
import { IJsonActivityResult } from '../models/action-response';
import BuilderContainer from '../models/builder-container';
import PaymentRequest, {
  IPaymentParameters,
  IPaymentSessionInfos,
  IPaymentStatusParams
} from './payment-request.interface';
import { CognitoAuthService } from './cognito-auth.service';
import { ApiUrlService } from './api-url.service';

export type HttpMethod = 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {
  private headers: { [header: string]: string | string[]; } = {};

  private errorSubject: Subject<any> = new Subject<any>();

  public get error$(): Observable<any> {
    return this.errorSubject.asObservable();
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private cognitoAuth: CognitoAuthService
  ) {
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getParams(objParams: object): HttpParams {
    let urlPrams: HttpParams = new HttpParams();
    Object.keys(objParams).forEach(key => urlPrams.append(key, objParams[key]));
    return urlPrams;
  }

  public async request(
    method: HttpMethod,
    path: string,
    data?: any,
    params?: { [param: string]: string | string[]; },
    emitError: boolean = true,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
  ): Promise<any> {
    const jwtToken: string = await this.cognitoAuth.getCurrentAccessJwtToken();
    this.headers.Authorization = `Bearer ${jwtToken}`;
    return new Promise<Response>((resolve, reject) => this.httpClient.request(
      method,
      this.apiUrlService.apiUrl + path,
      {
        body: data,
        headers: this.headers,
        params,
        ...responseType && { responseType },
        // params: params && this.getParams(params)
      })
    .subscribe(resolve, error => {
      if (emitError) {
        this.errorSubject.next(error);
      }
      return reject(error);
    }));
  }

  public postActivity(activity: any, files: any[][][] = []): Promise<any> {
    let form: FormData = new FormData();
    files.forEach((section, sIndex) => {
      section.forEach((action, aIndex) => {
        for (let i: number = 0; i < action.length; i++) {
          form.append('file-' + sIndex + '-' + aIndex + '-' + i, action[i]);
        }
      });
    });
    form.append('data', JSON.stringify(activity));
    return this.request('POST', 'Activities', form);
  }

  public sendActivityReminderToUser(activityId: string, concernedUserId: string, userId: string, email: boolean = true, sms: boolean = false): Promise<any> {
    return this.request('POST', `Activities/Remind/${activityId}/${concernedUserId}/${userId}`, {
      forceSms: sms,
      sendEmail: email,
      taken: false,
    });
  }

  public sendActivity(activityId: string): Promise<any> {
    return this.request('POST', `Activities/${activityId}/send`);
  }

  public joinChat(): Promise<unknown> {
    return this.request('POST', 'chat/join');
  }

  public createChatChannel(userIds: string[], podId: string): Promise<{ channelId: string }> {
    return this.request('POST', 'chat/channel/create', { userIds, podId });
  }

  public archiveChatChannel(channelId: string, memberIds: string[]): Promise<any> {
    return this.request('POST', 'chat/channel/archive', { channelId, memberIds });
  }

  public async getCategoryUsersList(podId: string, category: keyof IPodTargetsDetails): Promise<any> {
    return this.request('GET', `Pods/${podId}/users/${category}`);
  }

  public getUsersCount(podId: string): Promise<any> {
    return this.request('GET', 'Pods/' + podId + '/usersCount');
  }

  public getAppLanguagesList(): Promise<any> {
    return this.request('GET', '/Users/User/Parameters/AppLanguagesList');
  }

  public async getPendingActivities(): Promise<any[]> {
    return await this.request('GET', 'Activities/Pending');
  }

  public resetPendingActivities(activityId: string, concernedUserId: string): Promise<any> {
    return this.request('PATCH', 'Activities/' + activityId + '/Setpending/' + concernedUserId);
  }

  public async getActivitiesCount(status: string): Promise<number> {
    const response: string = await this.request('GET', 'Activities/' + status + '/Count');
    return parseInt(response, 10);
  }

  public getStats(): Promise<any> {
    return this.request('GET', 'Stats/PodsStats');
  }

  public patchChatNotificationTypes(notificationTypes: string[]): Promise<any> {
    return this.request('PATCH', 'Users/User/Parameters/ChatNotificationTypes', notificationTypes);
  }

  public patchNotificationTypes(notificationTypes: string[]): Promise<any> {
    return this.request('PATCH', 'Users/User/Parameters/NotificationTypes', notificationTypes);
  }

  public postReminder(activityId: string): Promise<any> {
    return this.request('POST', 'Activities/Remind/' + activityId, null);
  }

  public postConfirmation(activityId: string): Promise<any> {
    return this.request('POST', 'Activities/TakenRemind/' + activityId, null);
  }

  public postActivityBuilder(activityBuilder: unknown, podId: string): Promise<any> {
    return this.request('POST', 'Pods/' + podId + '/Builder', activityBuilder);
  }

  public pushNotifiationRegister(id: string): Promise<any> {
    return this.request('POST', 'Users/User/Parameters/NotificationId/' + id);
  }

  public pushNotificationUnregister(id: string): Promise<any> {
    return this.request('DELETE', 'Users/User/Parameters/NotificationId/' + id);
  }

  public patchActivityStatus(activityId: string, newStatus: string): Promise<unknown> {
    return this.request('PATCH', 'Activities/' + activityId + '/Status', {
      value: newStatus,
    });
  }

  public cancelActivity(activityId: string): Promise<any> {
    return this.request('POST', 'Activities/' + activityId + '/cancel');
  }

  public getActivities(filters?: {
    role?: 'following' | 'managing';
    limit?: number;
    skip?: number;
    people?: string[];
    community?: string[];
    activityType?: string[]
  }): Promise<ActivityListInfo[]> {
    // const { role = 'following', limit, skip, people, community, activityType } = filters;
    let filterParams: { [param: string]: string | string[]; };
    if (filters) {
      filterParams = {
        role: filters.role || 'following',
        limit: filters.limit.toString(10),
        skip: filters.skip.toString(10),
        people: filters.people,
        community: filters.community,
        activityType: filters.activityType,
      };
    }
    return this.request('GET', 'Activities/', null, filterParams);
  }

  public getActivity(activityId: string, concernedUserId: string, role: string): Promise<any> {
    return this.request('GET', 'Activities/' + activityId, null, { role, concernedUserId });
  }

  public getPodActivities(id: string, role: string, limit?: number, skip?: number): Promise<any> {
    return this.request('GET', 'Pods/' + id + '/Activities/' + role, null, {
      limit: limit.toString(10),
      skip: skip.toString(10)
    });
  }

  public getPodActivity(podId: string, activityId: string, role: string): Promise<any> {
    return this.request('GET', 'Pods/' + podId + '/Activity/' + activityId, null, { type: role });
  }

  public patchPassword(password: string): Promise<any> {
    return this.request('PATCH', 'Users/User/Password', { password });
  }

  public patchProfileField(key: string, value: any, type?: string): Promise<any> {
    return this.request('PATCH', 'Users/User/' + (type || key), {
      [key]: value
    }, null, false);
  }

  public recoverAccountMail(email: string): Promise<any> {
    return this.request('GET', 'Users/User/RecoverAccount?email=' + email.toLowerCase());
  }

  public recoverAccountPhone(phone: string): Promise<any> {
    return this.request('GET', 'Users/User/RecoverAccount?phone=' + phone);
  }

  public async getDependentUsers(): Promise<AuthenticatedUser[]> {
    const rawDependentUsers: IJsonAuthenticatedUser[] = await this.request('GET', 'Users/User/dependentUsers');
    return rawDependentUsers.map(r => new AuthenticatedUser(r));
  }

  public postReport(activityId: string): Promise<any> {
    return this.request('POST', 'Activities/Report/' + activityId);
  }

  public getActivityResponses(activityId: string): Promise<any> {
    return this.request('GET', 'Activities/' + activityId + '/responses');
  }

  public getActivityResponse(activityId: string, responsibleUserId: string, concernedUserId: string): Promise<any> {
    return this.request('GET', `Activities/${activityId}/response/${responsibleUserId}/${concernedUserId}`);
  }

  public async getCurrentResponse(activityId: string): Promise<any> {
    return await this.request('GET', 'Activities/' + activityId + '/response');
  }

  public async getCurrentResponseByConcernedUser(activityId: string, concernedUserId: string): Promise<any> {
    return await this.request('GET', `Activities/${activityId}/response/${concernedUserId}`);
  }

  public respondToActivity(activityId: string,
                           activityResponses: Dictionary<Dictionary<any>>,
                           concernedUserId: string,
                           clientId?: string,
                           responsibleUserId?: string): Promise<any> {
    return this.request('POST', 'Activities/Response', {
      activityId,
      responses: activityResponses,
      concernedUserId: concernedUserId || '',
      responsibleUserId,
      clientId,
    });
  }

  public async postActivityFile(activityId: string, concernedEntityId: string, userId: string, file: any): Promise<string[]> {
    let form: FormData = new FormData();
    form.append('file', file);
    form.append('data', JSON.stringify({
      activityId,
      concernedEntityId,
      userId
    }));
    return await this.request('POST', `Activities/${activityId}/upload`, form) as string[];
    // should always return the file names as saved in db (string[])
  }


  public async saveActivityDraftResults(
    activityId: string,
    activityResponses: Dictionary<Dictionary<any>>,
    concernedUserId: string
  ): Promise<IJsonActivityResult> {
    return await this.request('POST', 'Activities/DraftResponse', {
      activityId,
      responses: activityResponses,
      concernedUserId: concernedUserId || '',
    }) as IJsonActivityResult;
  }

  public getBuildersIds(podId: string): Promise<BuilderContainer[]> {
    return this.request('GET', 'Pods/' + podId + '/BuildersIds')
    .then(res => res.map(i => i as BuilderContainer));
  }

  public async getBuilder(podId: string, builderId: string): Promise<any> {
    return this.request('GET', 'Pods/' + podId + '/Builder/' + builderId);
  }

  /**
   * @deprecated
   */
  public postStripePayment(
    paymentKey: string,
    amount: number,
    token: string,
    activityId: string,
    concernedUserId: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.request('POST', 'Users/User/Pay',
        {
          PaymentKey: paymentKey,
          Amount: amount,
          SrcToken: token,
          ActivityId: activityId,
          ConcernedUserId: concernedUserId || ''
        } as PaymentRequest)

      .then(res => resolve(res.status))
      .catch(err => reject(err));
    });
  }

  public async createStripePaymentSession(params: IPaymentParameters): Promise<any> {
    return this.request('POST', 'Users/User/CreateStripeSession',
      params,
    );
  }

  public async cancelStripePaymentSession(sessionId: string, destinationAccountId: string): Promise<any> {
    return this.request('POST', 'Users/User/CancelStripeSession',
      {
        sessionId,
        destinationAccountId,
      } as IPaymentSessionInfos);
  }

  public async getStripePaymentStatus(activityId: string, concernedUserId: string): Promise<any> {
    return this.request('POST', '/Users/User/paymentStatus',
      {
        activityId,
        concernedUserId,
      } as IPaymentStatusParams);
  }

  public async notifyStripePaymentSessionCompletion(sessionId: string, destinationAccountId: string): Promise<any> {
    return this.request('POST', 'Users/User/NotifyStripePaymentSessionCompletion',
      {
        sessionId,
        destinationAccountId,
      } as IPaymentSessionInfos);
  }

  public async verifyPayTheoryPayment(transactionId: string,
                                      accountId: string,
                                      concernedUserId: string,
                                      userId: string,
                                      activityId: string): Promise<any> {
    return this.request('POST', 'pay-theory/verify/' + transactionId,
      {
        accountId,
        concernedUserId,
        userId,
        activityId,
      });
  }

  /**
   * @description Unsubscribes Pods List for Notifications-parameters
   */
  public async getUnsubscribes(): Promise<any> {
    return await this.request('GET', 'Users/PodsSubscriptions');
  }

  /**
   * @description Get pod Subscribable State. ( Email unsubscribe link )
   */
  public async patchUnsub(podId: string): Promise<string> {
    return this.request(
      'PATCH',
      'Pods/' + podId + '/unsubscribe',
      null,
      null,
      false,
      'text',
    );
  }

  /**
   * @description Toggle pod Subscribable State. ( Checkbox Pods Subscribe )
   */
  public async postUnsub(podId: string): Promise<any> {
    return await this.request('POST', 'Pods/' + podId + '/unsubscribe');
  }
}
