import { AlertController, Platform } from '@ionic/angular';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import _ from 'lodash';

export interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void;
}

// https://stackoverflow.com/questions/34255351/is-there-a-version-of-settimeout-that-returns-an-es6-promise
export function delay(ms: number): CancellablePromise<void> {
  let ctr: number;
  let rej: (reason?: any) => void;
  let p: CancellablePromise<void> = new Promise<void>((resolve, reject) => {
    ctr = window.setTimeout(resolve, ms);
    rej = reject;
  }) as CancellablePromise<void>;
  p.cancel = () => {
    clearTimeout(ctr);
    rej(Error('Cancelled'));
  };
  return p;
}

export function msgBox(alertCtrl: AlertController, header: string, subHeader: string): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    let alert: HTMLIonAlertElement = await alertCtrl.create({
      header,
      subHeader,
      buttons: [{
        text: 'OK',
        handler: () => {
          alert.dismiss().then(() => {
            resolve(true);
          });
          return false;
        }
      }]
    });
    alert.present();
  });
}

// https://gist.github.com/Yimiprod/7ee176597fef230d1451
/**
 * Deep diff between two object, using lodash
 * @param  {Object} newObject Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function objectsDifference(newObject: any, base: any): object {
  const res: object = transform(newObject, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? objectsDifference(value, base[key]) : value;
    }
  });
  difference(Object.keys(base), Object.keys(newObject))
    .forEach(key => res[key] = undefined);
  return res;
}

export const isSafariDesktop: boolean = (window as any).safari !== undefined;

// https://github.com/ionic-team/ionic-framework/issues/19942
export function isMobile(plt: Platform): boolean {
  return plt.is('hybrid') ||
  plt.is('android') ||
  plt.is('ios') ||
  plt.is('ipad') ||
  plt.is('iphone');
}

export function loadScript(url) {
  return new Promise((resolve, reject) => {
    const body = document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
  });
}
