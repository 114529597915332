export interface IJsonConcernedUser {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  chatId?: string;
  phones?: { [type: string]: string };
  responsibleIds?: string[];
  avatar?: string;
  type?: string;
  canReceiveSms?: boolean;
  canReceiveEmail?: boolean;
}

export default class ConcernedUser {
  public id: string;
  public firstName: string;
  public lastName: string;
  public email?: string;
  public chatId?: string;
  public phones?: any;
  public canReceiveSms?: boolean;
  public canReceiveEmail?: boolean;
  public responsibleIds?: string[];
  public avatar?: string;
  public type?: string;

  constructor(json?: IJsonConcernedUser) {
    if (json) {
      this.id = json.id;
      this.firstName = json.firstName;
      this.lastName = json.lastName;
      this.email = json.email;
      this.canReceiveSms = json.canReceiveSms;
      this.canReceiveEmail = json.canReceiveEmail;
      this.chatId = json.chatId;
      this.phones = json.phones ? json.phones : {};
      this.responsibleIds = json.responsibleIds;
      this.avatar = json.avatar;
    }
  }
}
