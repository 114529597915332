<div class="checkboxes-container" *ngIf="params.percentFees > 0">
  <mat-checkbox [checked]="params.feesAdded"
                (change)="calculateFees($event)">
    {{ params.checkboxText }}
  </mat-checkbox>
</div>
<ion-button shape="round"
            [fill]="params.paid ? 'solid' : 'outline'"
            class="positive-focus"
            [disabled]="params.paid || this.sectionCtrl.manager.getActivity().status === 'CLOSE' || manager.mode === 'review'"
            (click)="openCheckout()">
  {{ "ACTIVITY.ACTION.STRIPE.PAY" | translate }} ${{params.amount}}
</ion-button>
<ng-container *ngIf="params.paid">
  <p>{{ "ACTIVITY.ACTION.STRIPE.PAYMENT" | translate }} <span
    class="text-positive">{{ "ACTIVITY.ACTION.STRIPE.ACCEPTED" | translate }}</span></p>
  <p>{{ "ACTIVITY.ACTION.STRIPE.THANK_YOU" | translate }}</p>
</ng-container>
<ng-container *ngIf="returnedError">
  <p>{{ "ACTIVITY.ACTION.STRIPE.THE_FOLLOWING" | translate }} <span
    class="text-negative">{{ "ACTIVITY.ACTION.STRIPE.ERROR" | translate }}</span> {{ "ACTIVITY.ACTION.STRIPE.OCCURRED" | translate }}
    :</p>
  <p>{{returnedError}}</p>
  <!-- <p>Please try again later.</p> -->
</ng-container>
