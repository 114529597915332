import {Server} from './server';
import Action from './action';
import TargetRole from './target-role';
import AMergeable from './a-mergeable';
import ActionResponse from './action-response';
import Execution from './execution';
import Validation from './validation';
import {ActivityParameter} from './activity-parameters';
import Section from './section';
import Builder from './API/builders';

export default class Activity extends AMergeable {
  title: string;
  icon: string;
  description: string;
  server: Server;
  socket: ISocketSettings;
  sections: Section[];
  onCreate: Execution[];
  podId: string;
  id: string;
  type: string;
  realtime: boolean;
  displayName: string;
  status: string;
  creationDate: string;
  updatedDate: string;
  podName: string;
  targets: TargetRole[];
  providers: any[];
  onCreateEmail: any;
  onConfirmEmail: any;
  onUpcomingEmail: any;
  results: ActionResponse;
  parameters: ActivityParameter[];
  csvSort: string[];
  csvOrderBy: ICsvOrderBy;
  srcLanguage: string;

  constructor(json: any) {
    super();
    this.title = json.title;
    this.description = json.description;
    this.initialize(Server, json.server, 'server');
    if (json.socket) {
      this.socket = {
        validations: this.initialize(Validation, json.socket.validations) as Validation[] || [],
        onInitialActivityUpdated: this.initialize(Execution, json.socket.onInitialActivityUpdated) as Execution[] || [],
        onActivityUpdated: this.initialize(Execution, json.socket.onActivityUpdated) as Execution[] || [],
        timer: json.socket.timer,
      };
    }
    this.initialize(Section, json.sections, 'sections');
    this.podId = json.podId;
    this.id = json.id;
    this.initialize(TargetRole, json.targets, 'targets');
    this.type = json.type;
    this.realtime = json.realtime;
    this.displayName = json.displayName;
    this.status = json.status;
    this.creationDate = json.creationDate;
    this.updatedDate = json.updatedDate;
    this.podName = json.podName;
    this.providers = json.providers;
    this.parameters = json.parameters;
    this.onCreateEmail = json.onCreateEmail;
    this.onConfirmEmail = json.onConfirmEmail;
    this.onUpcomingEmail = json.onUpcomingEmail;
    this.icon = json.icon;
    this.initialize(ActionResponse, json.results, 'results');
    this.initialize(Execution, json.onCreate, 'onCreate');
    this.csvSort = json.csvSort;
    this.csvOrderBy = json.csvOrderBy;
    this.srcLanguage = json.srcLanguage;
  }

  public onCreateEvent(obj: Builder): void {
    (this.onCreate || []).forEach(e => e.execute(obj));
  }

  public getSections(): Section[] {
    return this.sections.filter(s => s);
  }

  public getActions(): Action[] {
    return this.getSections().map(s => s.getActions()).reduce((a, b) => a.concat(b));
  }
}

interface IEmailParams {
  templateId: number;
  subject: string;
  variables: any;
  buttons?: any[];
  attachments?: any[];
}

export interface ICsvOrderBy {
  path: string;
  type: string;
  order: string;
}

export interface ISocketSettings {
  validations: Validation[];
  onInitialActivityUpdated: Execution[];
  onActivityUpdated: Execution[];
  timer: {
    duration: number;
  };
}
