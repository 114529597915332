import {Injectable} from '@angular/core';
import {DeveloperService} from './developer.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {

  public apiUrl: string;

  constructor(
    private devService: DeveloperService,
  ) {
    this.apiUrl = devService.settings.apiName + '/api/';
  }

}
