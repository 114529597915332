import {Pipe, PipeTransform} from '@angular/core';
import {BbcodeTranslatorService} from '../services/bbcode-translator.service';

@Pipe({
  name: 'bbcodeTranslation',
})
export class BbcodeTranslationPipe implements PipeTransform {

  constructor(private bbcodeTranslator: BbcodeTranslatorService) {
  }

  // see thread for why there is a useless redrawToggle parameter
  // hint: to force redraw this pure pipe when something "external" changes
  // https://github.com/angular/angular/issues/15041
  transform(value: string, redrawToggle?: boolean): string {
    return this.bbcodeTranslator.translate(value);
  }

}
