import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { NativeAppLinksSanitizerService } from '../services/native-app-links-sanitizer.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[convert-cordova-app-links]'
})
export class ConvertCordovaAppLinksDirective implements OnDestroy {

  public onInnerHtmlChangeRef: (e: MutationEvent) => void = this.onInnerHtmlChange.bind(this);

  constructor(
    private element: ElementRef,
    private linksSanitizer: NativeAppLinksSanitizerService,

  ) {
    this.element.nativeElement.addEventListener('DOMSubtreeModified', this.onInnerHtmlChangeRef);
  }

  ngOnDestroy(): void {
    (this.element.nativeElement as HTMLDivElement).removeEventListener('DOMSubtreeModified', this.onInnerHtmlChangeRef);
  }

  public onInnerHtmlChange(e: MutationEvent): void {
    this.linksSanitizer.sanitizeInAppLinks(e.srcElement as HTMLDivElement);
  }

}
