import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TriggerUpdateService {

  private whiteListedNavTrigger: Subject<void> = new Subject<void>();

  public get whiteListedNavTrigger$(): Observable<void> {
    return this.whiteListedNavTrigger.asObservable();
  }

  public activitySubmitTrigger: Subject<void> = new Subject<void>();

  public get activitySubmitTrigger$(): Observable<void> {
    return this.activitySubmitTrigger.asObservable();
  }


  private whiteList: string[] = [
    '/login',
    '/home/todo',
    '/home/todo/history',
    '/home/settings',
    '/home/pods',
  ];

  constructor(private router: Router) {
    this.listenNavigationToUpdate();
  }

  /**
   * Listen navigation to trigger auto-update
   * @private
   */
  private listenNavigationToUpdate(): void {
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd && this.whiteList.includes(routerEvent.url)) {
        this.whiteListedNavTrigger.next();
      }
    });
  }
}
