import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import Activity from '../../../../models/activity';
import DynValue from '../../../../models/dyn-value';
import Action from '../../../../models/action';
import {Dictionary} from 'lodash';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent extends InputBaseController {
  public static className: string = 'action-button';
  public static readonly responseKeys: string[] = ['clicked'];
  public activity: Activity = this.sectionCtrl.manager.getActivity();

  public params: { text: string, clicked: boolean };

  public execute(e: MouseEvent): void {
    e.stopImmediatePropagation();
    this.params.clicked = true;
    this.updateAction();
    this.onClick();
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let clicked: string = '';
    if (getValue(action.params.clicked) === true) {
      clicked = 'Clicked';
    } else if (getValue(action.params.clicked) === false) {
      clicked = 'Not clicked';
    } else if (getValue(action.params.clicked)) {
      clicked = getValue(action.params.clicked);
    }
    return {clicked};
  }
}
