import {Component} from '@angular/core';
import DynValue from '../../../../models/dyn-value';
import Action from '../../../../models/action';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {InputBaseController} from '../../input-controller';
import isEqual from 'lodash/isEqual';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-checkbox',
  templateUrl: './action-checkbox.component.html',
  styleUrls: ['./action-checkbox.component.scss'],
})
export class ActionCheckboxComponent extends InputBaseController {
  public static className: string = 'action-checkbox';
  public static readonly responseKeys: string[] = ['checkedAnswers'];
  public params: { options: Option[], checkedAnswers: any[] };

  protected initAction(action: Action): void {
    super.initAction(action);
    this.setupParams(action);
  }

  public onChange(event: MatCheckboxChange, option: Option): any {
    option.checked = event.checked;
    this.params.checkedAnswers = this.params.options.filter(o => o.checked).map(o => o.value);
    super.onChange();
  }

  public setupParams(action: Action) {
    this.params.options = this.getValue(action.params.options).map(elem => elem instanceof Object ? elem : {
      keyName: elem,
      value: this.bbcodeTranslator.getSource(elem),
      checked: false
    });
    if (typeof this.params.checkedAnswers === 'string') {
      this.params.checkedAnswers = JSON.parse(this.params.checkedAnswers);
    }
    let responses = (this.params.checkedAnswers || []).concat(this.params.options.filter(o => o.checked).map(o => o.value));
    this.params.options.forEach(option => option.checked = !!responses.find(v => isEqual(v, option.value)));
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): { [option: string]: string } {
    let output: { [option: string]: string } = {};

    let options = getValue(action.params.options);
    let checked = getValue(action.params.checkedAnswers) || [];
    if (typeof checked === 'string') {
      checked = JSON.parse(checked);
    }
    options.forEach(option => {
      let colName = option;
      if (typeof colName === 'string') {
        colName = colName.replace(/[,"\n]/g, '');
      }
      output[colName] = checked.find(v => isEqual(v, option)) ? 'Yes' : '';
    });
    return output;
  }
}

interface Option {
  keyName: string;
  value: any;
  checked: boolean;
}
