import {Injectable} from '@angular/core';
import {CognitoAuthService} from './cognito-auth.service';
import { UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  public enabled: boolean = false;

  constructor(
    private cognitoAuth: CognitoAuthService,
  ) {
    this.cognitoAuth.cognitoLogout$.subscribe(() => {
      this.disable();
    });
  }

  public init(tree?: UrlTree): void {
    this.enabled = this.isActiveFromStorage();
    if (!this.isActiveFromQuery(tree)) {
      return;
    }
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    urlParams.delete('impersonate');
    window.history.replaceState({}, document.title, `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`);
    this.enabled = true;
    window.localStorage.setItem('impersonateMode', JSON.stringify(true));
  }

  public isActiveFromQuery(tree?: UrlTree): string {
    if (!window.location.search && !tree?.queryParams) {
      return;
    }
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    return urlParams.get('impersonate') || tree?.queryParams?.impersonate;
  }

  public isActiveFromStorage(): boolean {
    return JSON.parse(window.localStorage.getItem('impersonateMode'));
  }

  public disable(): void {
    window.localStorage.removeItem('impersonateMode');
    this.enabled = false;
  }

}
