<ion-item [class.review-mode]="manager.mode === 'review'">
  <!--
  safari compatibility
  https://stackoverflow.com/questions/64585634/ionic-v5-with-angular-component-issue-on-safari/64661235#64661235
   -->
  <div tabindex="0"></div>
  <ion-select [placeholder]="placeholder"
              [disabled]="type == 'show'"
              (ionChange)="onChange($event)"
              [value]="params.selectedAnswer"
              [compareWith]="compareFn">
    <ion-select-option *ngFor="let option of params.options" [value]="option.value" [disabled]="manager.mode === 'review'">
      {{ option.keyName | bbcodeTranslation: bbcodeTranslator.redraw }}
    </ion-select-option>
  </ion-select>
</ion-item>
