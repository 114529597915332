<quill-editor *ngIf="type == 'input' && mode !== 'review'"
              bounds="self"
              (onContentChanged)="editQuillChanged($event)"
              (onEditorCreated)="editQuillInit($event)"
              [modules]="quillModules"
              [(ngModel)]="content">
</quill-editor>
<quill-view-html *ngIf="(type == 'show' || mode === 'review') && content"
                 class="no-eddit"
                 convert-cordova-app-links
                 [content]="content | bbcodeTranslation">
</quill-view-html>

<div class="modal-footer">
  <div class="ModalBtns">
    <ion-button *ngIf="type == 'input' && mode !== 'review'" [disabled]="!canSave" shape="round" (click)="save()">
      {{ "SAVE" | translate }}
    </ion-button>
    <ion-button shape="round" (click)="close()">
      {{ "close" | translate | uppercase }}
    </ion-button>
    <ion-button *ngIf="canPrint" (click)="print()" shape="round">
      {{ "print" | translate | uppercase }}
    </ion-button>
  </div>
</div>
