import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UrlExecutionService } from './url-execution.service';
import { EventsService } from './events.service';
import AuthenticatedUser from '../models/user';
import { User } from 'oidc-client';
import { GG4LAuthService } from './gg4l-auth.service';
import { UserService } from './user.service';
import { CognitoAuthService } from './cognito-auth.service';
import { OneTimeTokenService } from './one-time-token.service';
import { OneTimeTokenInvalidatorService } from './one-time-token-invalidator.service';
import { IOneTimeTokenPayload } from './one-time-token.interface';
import { Router, NavigationEnd, ActivatedRoute, UrlTree } from '@angular/router';
import { ILinkExpiredPageParams } from '../pages/link-expired/link-expired.params';
import { DeveloperService } from './developer.service';
import { MainLoaderHandlerService } from './main-loader-handler.service';
import Auth from '@aws-amplify/auth';
import _ from 'lodash';
import { ImpersonationService } from './impersonation.service';

// it would have been too nice to be able to include the state in the urlTree object
// so angular made it right by silently not doing it
// https://github.com/angular/angular/issues/27148
export interface ILoginOutput {
  user?: AuthenticatedUser;
  consumedOTT?: boolean;
  redirectUrlTree?: UrlTree;
  redirectState?: any;
}

export interface IOttMismatchUserSubjectPayload {
  callback: (continuing: boolean) => Promise<void>;
  cancel: () => void;
  connectedUser: string;
  ottUser: string;
}

@Injectable({
  providedIn: 'root'
})
export class AutoLoginService {

  private isCompleteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get isComplete$(): Observable<boolean> {
    return this.isCompleteSubject.asObservable().pipe(filter(v => v));
  }

  private ottMismatchUserSubject: Subject<IOttMismatchUserSubjectPayload> = new Subject<IOttMismatchUserSubjectPayload>();

  public get ottMismatchUser$(): Observable<IOttMismatchUserSubjectPayload> {
    return this.ottMismatchUserSubject.asObservable();
  }

  didNavigateToChallengerPage: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private events: EventsService,
    public gg4lAuth: GG4LAuthService,
    private userProvider: UserService,
    private cognitoAuth: CognitoAuthService,
    private urlExecution: UrlExecutionService,
    private oneTimeTokenService: OneTimeTokenService,
    private oneTimeTokenInvalidator: OneTimeTokenInvalidatorService,
    private zone: NgZone,
    private devService: DeveloperService,
    private mainLoaderHandler: MainLoaderHandlerService,
    private impersonationService: ImpersonationService,
  ) {
    this.isComplete$.subscribe((status) => {
      console.debug('isComplete$', status);
    });

    // remove token from url
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd && e.urlAfterRedirects.includes('token=')))
      .subscribe(async (e: NavigationEnd) => {
        // https://stackoverflow.com/questions/43698032/angular-how-to-update-queryparams-without-changing-route
        await this.zone.run(() => this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { token: null },
            queryParamsHandling: 'merge',
            preserveFragment: true,
          }));
      });
  }

  public async autoLogin(user?: User, url?: string): Promise<ILoginOutput> {
    url = url || window.location.href;

    this.mainLoaderHandler.cancelRemoval();

    this.isCompleteSubject.next(false);

    let loginOutput: ILoginOutput;
    try {
      loginOutput = await this.attemptAuthentication(user, url);
    } catch (e) {
      console.error('attemptAuthentication error', e);
    }

    this.isCompleteSubject.next(true);

    const oneTimeToken: string = this.urlExecution.extractUrlTokenParameter(url);
    if (oneTimeToken) {

      // invalidate the oneTimeToken even if it was not used to auth
      if (loginOutput?.user && !loginOutput?.consumedOTT) {
        await this.oneTimeTokenInvalidator.consume(oneTimeToken);
      }
    }

    console.debug('autoLogin completed', loginOutput);
    this.impersonationService.init(loginOutput?.redirectUrlTree);
    this.mainLoaderHandler.remove();

    return loginOutput;
  }

  public async gg4lSignIn(): Promise<AuthenticatedUser> {
    const user: User = await this.gg4lAuth.signin();
    console.debug(user);
    if (user) {
      return (await this.autoLogin(user)).user;
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private async attemptAuthentication(user?: User, url?: string): Promise<ILoginOutput> {
    url = url || window.location.href;
    let loginOutput: ILoginOutput = { user: undefined, redirectUrlTree: undefined };
    // if (window.localStorage.getItem('sso:invalidate')) {
    //   this.events.publish('gg4l:auth:invalid');
    //   window.localStorage.removeItem('sso:invalidate');
    // } else {
    //   try {
    //     let gg4lUser: User = user || await this.gg4lAuth.getUserFromRedirectCallBackOrLocal();
    //     console.debug('gg4lUser', gg4lUser);
    //     if (gg4lUser.profile.sub) {
    //       console.debug('gg4l userId', gg4lUser.profile.sub);
    //       gg4lUser = await this.gg4lAuth.refreshTokens(gg4lUser);
    //       const idToken: string = (await this.gg4lAuth.refreshTokens(gg4lUser)).id_token;
    //       const oTToken: string = await this.gg4lAuth.getActionalyToken(idToken);
    //       await this.cognitoAuth.loginWithOneTimeToken(oTToken);
    //       loginOutput.user = await this.userProvider.currentUserAfterPendingLoad$
    //         .pipe(take(1))
    //         .toPromise();
    //     }
    //   } catch (e) {
    //     if (
    //       (e && e.code === 'NotAuthorizedException')
    //       || (e instanceof Response && e.status === 401 && (await e.json()).message === 'user not found')
    //     ) {
    //       console.debug('gg4l user not found');
    //       this.events.publish('gg4l:auth:invalid');
    //       await this.cognitoAuth.logout('GG4L.NO_ACTIONALY_LINK');
    //       return;
    //     } else if (e && e.message !== 'cannot getUser') {
    //       console.debug(e);
    //     }
    //   }
    // }
    // console.debug('autoLogin after gg4l login attempt', loginOutput.user);
    console.debug('current url', url);
    // const oneTimeToken: string = this.urlExecution.urlTokenParameter;
    const oneTimeToken: string = this.urlExecution.extractUrlTokenParameter(url);
    console.debug('oneTimeToken', oneTimeToken);
    if (oneTimeToken) {
      const { isActive, isSameUser } = await this.hasActiveSessionFromSameUser(oneTimeToken);
      if (!isActive) loginOutput = await this.loginFromOneTimeToken(oneTimeToken, url);
      else if (isActive && !isSameUser) { // if connected account mismatches the ott account
        const payload: IOneTimeTokenPayload = this.oneTimeTokenService.decodePayload(oneTimeToken);
        const connectedUser: any = await Auth.currentAuthenticatedUser();
        // wait for user to decide which account to connect to: connected or ott
        await new Promise<void>((resolve, reject) => {
          this.ottMismatchUserSubject.next({
            connectedUser: connectedUser.attributes.preferred_username,
            ottUser: payload.username,
            callback: async (continuing: boolean) => {
              try {
                // switch user or redirect to home
                if (!continuing) {
                  loginOutput = await this.loginFromOneTimeToken(oneTimeToken, url);
                } else loginOutput.redirectUrlTree = this.router.createUrlTree(['home']);
                resolve();
              } catch (e) {
                reject(e);
              }
            },
            cancel: () => {
              reject(new Error('User cancelled the ott mismatch user switch'));
            }
          });
        });
      }
    }
    if (!loginOutput.user) {
      try {
        await this.cognitoAuth.loginFromStoredSession();
        loginOutput.user = await this.userProvider.currentUserAfterPendingLoad$
          .pipe(take(1))
          .toPromise();
      } catch (e) {
        console.debug(e);
      }
    }

    // set redirectUrlTree to come back on the original url if the auth challenger navigated
    if (this.didNavigateToChallengerPage && loginOutput.user && !loginOutput.redirectUrlTree) {
      loginOutput.redirectUrlTree = this.router.parseUrl(url);
      delete loginOutput.redirectUrlTree.queryParams.token;
    }

    return loginOutput;
  }

  public async loginFromOneTimeToken(oneTimeToken: string, expiredLink: string): Promise<ILoginOutput> {
    console.log('loginFromOneTimeToken expiredLink', expiredLink);
    // let followUpLink: string = this.toNavUrl(expiredLink);
    expiredLink = this.toEnvUrl(expiredLink);

    // fix for the "native app already connected with another user, deprecated token not asking to renew token" issue
    // let currentUser: AuthenticatedUser = await this.userProvider.currentUser$.pipe(take(1)).toPromise();
    // if (currentUser) return { user: currentUser };
    let currentUser: AuthenticatedUser;

    try {
      await this.cognitoAuth.loginWithOneTimeToken(oneTimeToken);
      currentUser = await this.userProvider.currentUserAfterPendingLoad$
        .pipe(take(1))
        .toPromise();
    } catch (e) {
      console.debug('loginWithTokenParameter error', e);
      if (e?.code === 'NotAuthorizedException') {
        console.debug('redirect to renew one time token page', expiredLink);
        // await this.zone.run(() => this.router.navigate(['link-expired'], {
        //   state: { oneTimeToken, expiredLink } as ILinkExpiredPageParams,
        //   // replaceUrl: true
        // }));
        return {
          redirectUrlTree: this.router.createUrlTree(['link-expired']),
          redirectState: { oneTimeToken, expiredLink } as ILinkExpiredPageParams,
        };
      } else {
        throw e;
      }
    }

    // this.router.navigateByUrl(followUpLink);
    return { user: currentUser, consumedOTT: true };
  }

  private toEnvUrl(url: string): string {
    const checkedUrl: URL = new URL(url, window.location.origin);
    const path: string = checkedUrl.pathname + checkedUrl.search + checkedUrl.hash;
    return this.devService.getEnvironmentUrl() + (path ?? '');
  }

  // private toNavUrl(url: string): string {
  //   const checkedUrl: URL = new URL(url, window.location.origin);
  //   const path: string = checkedUrl.pathname + checkedUrl.search + checkedUrl.hash;
  //   return path ?? '';
  // }

  private async hasActiveSessionFromSameUser(oneTimeToken: string): Promise<{ isActive: boolean, isSameUser: boolean }> {
    try {
      const payload: IOneTimeTokenPayload = this.oneTimeTokenService.decodePayload(oneTimeToken);
      const { isActive, isSameUser } = await this.cognitoAuth.isCurrentlyAuthenticated(payload.sub, payload.username);
      console.debug('hasActiveSessionFromSameUser', isSameUser);
      return { isActive, isSameUser };
    } catch (e) {
      console.debug('hasActiveSessionFromSameUser error', e);
      return { isActive: false, isSameUser: false };
    }
  }

  private removeTokenFromUrl(): void {
    const newUrl: URL = new URL(window.location.href);
    newUrl.searchParams.delete('token');
    window.history.replaceState({}, document.title, newUrl.href);
  }
}
