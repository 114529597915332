<mat-accordion>
  <mat-expansion-panel *ngFor="let iuser of expandableUsers;let i = index"
                       class="mat-expansion-panel-to-ion-card"
                       [expanded]="iuser.open"
                       (closed)="closeExpansionPanel(i)" #panel>
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" class="overlay-button-container">
      <p><b>{{ iuser.firstName }} {{ iuser.lastName }}</b></p>
      <p>{{ iuser.email }}</p>
      <p *ngIf="errors && errors[i] && errors[i] > 0"
         class="activity-action-error-message">{{ "ACTIVITY.ACTION.USERS.ENTER_REQUIRED_INFORMATION" | translate }}</p>
      <button class="overlay-button" (click)="toggleExpansionPanel($event, panel)"></button>
    </mat-expansion-panel-header>

    <app-input-user
      [disabled]="type === 'show' || manager.mode === 'review'"
      [type]="type"
      [mode]="manager.mode"
      [fields]="params.fields"
      [languages]="params.languages"
      [builder]="manager.getBuilder()"
      [user]="expandableUsers[i]"
      (onChange)="onChange()"></app-input-user>
    <br/>
    <mat-action-row *ngIf="manager.mode === 'view' && showDelete(expandableUsers[i].id) && !params.hideButtons">
      <ion-button (click)="delUser(i)" size="small">{{ "ACTIVITY.ACTION.USERS.REMOVE.BUTTON" | translate }}</ion-button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
<br/>
<ion-button *ngIf="manager.mode === 'view' && !params.hideButtons" (click)="addUser()" size="small" item-end>
  {{ "ACTIVITY.ACTION.USERS.ADD" | translate}}
</ion-button>
