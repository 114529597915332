import {Directive, Inject, InjectionToken, Injector, OnInit, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {Dictionary} from 'lodash';
import AFormManager from '../../models/a-form-manager';
import {BbcodeTranslatorService} from '../../services/bbcode-translator.service';
import Action from '../../models/action';
import DynValue from '../../models/dyn-value';
import {Platform} from '@ionic/angular';
import {MimeTypeService} from '../../services/mime-type.service';
import { delay, isMobile, isSafariDesktop } from '../../../utils';
import {PodForPodsList, PodsService} from '../../services/pods.service';
import {SectionContainerComponent} from './section-container/section-container.component';
import { Browser } from '@capacitor/browser';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class InputBaseController implements OnInit {

  public static readonly className: string = 'action-base';
  public static readonly responseKeys: string[];
  public static actions: Dictionary<typeof InputBaseController>;
  public static actionTypes: Dictionary<Type<InputBaseController>>;

  @ViewChild('mainInput') public mainInput: HTMLElement;
  public placeholder: string;
  public error: string;
  public nativElem: HTMLElement;
  public type: string;
  public abstract params: any = {};
  public manager: AFormManager;
  public sectionCtrl: SectionContainerComponent;
  public action: Action;
  public index: number;
  public bbcodeTranslator: BbcodeTranslatorService;
  public mimeTypeService: MimeTypeService;

  public static getStatic(name: string): typeof InputBaseController {
    return this.actions[name];
  }

  public static getResponseFromAction(getValue: (dyn: DynValue) => any, action: Action): any {
    let result: any = {};
    this.responseKeys.forEach(key => result[key] = getValue(action.params[key]));
    return result;
  }

  public static setResponseToAction(action: Action, response: Dictionary<any>): void {
    this.responseKeys.forEach(key => {
      action.params[key] = DynValue.CreateStatic(response[key]);
      action.isSet = true;
    });
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action, activityId: string, injector?: Injector): Dictionary<any> {
    let result: any = {};
    this.responseKeys.forEach(key => result[key] = getValue(action.params[key]));
    return result;
  }

  protected constructor(
    @Inject(Injector) protected injector: Injector,
    @Inject(ViewContainerRef) protected viewContainerRef: ViewContainerRef
  ) {
    this.bbcodeTranslator = injector.get(BbcodeTranslatorService);
    this.mimeTypeService = injector.get(MimeTypeService);
    this.nativElem = viewContainerRef.element.nativeElement;
    this.nativElem.tabIndex = -1; // required to be focusable
    this.nativElem.classList.add('static-form-section');
    this.updateInputs(
      {
        manager: this.injector.get('manager'),
        sectionCtrl: this.injector.get('sectionCtrl'),
        action: this.injector.get('action'),
        index: this.injector.get('index'),
      },
      false,
    );
  }

  updateInputs({manager, sectionCtrl, action, index}, init: boolean = true): void {
    this.manager = manager || this.manager;
    this.sectionCtrl = sectionCtrl || this.sectionCtrl;
    this.action = action || this.action;
    this.index = index !== undefined ? index : this.index;
    if (action) {
      this.placeholder = this.action.placeholder ? this.bbcodeTranslator.translate(this.getValue(this.action.placeholder)) : '';
      this.type = this.action.type;
      this.action.controller = this;
      if (!this.action.isSet) {
        if (init) {
          this.initAction(action);
        }
        // update action with old params
        this.updateAction();
      }
    }
  }

  public getResponse(): any {
    let result: any = {};
    this.getStatic().responseKeys.forEach(key => result[key] = this.params[key]);
    return result;
  }

  protected initAction(action: Action): void {
    this.params = this.params || {};
    for (let key in action.params) {
      if (action.params[key] == null) {
        continue;
      }
      const dynValue: DynValue = action.params[key] instanceof DynValue ? action.params[key] : new DynValue(action.params[key]);
      this.params[key] = this.getValue(dynValue);
    }
  }

  private getStatic(): typeof InputBaseController {
    return this.constructor as typeof InputBaseController;
  }

  public onClick(): void {
    this.action.onClickEvent(this.manager);
  }

  public updateAction(): void {
    Object.keys(this.params).forEach(key => {
      this.action.params[key] = DynValue.CreateStatic(this.params[key]);
    });
  }

  public async openExternalResource(filename: string, platform: Platform, defaultOpen: (stringUrl: string) => void): Promise<void> {
    try {
      let url: string = this.manager.getActivityResourceUrl(this.manager.getExpectedResourceUrl(filename));
      if (platform.is('hybrid') && this.mimeTypeService.isDocument(url) && this.mimeTypeService.getExtension(url) !== 'pdf') {
        Browser.open({
          url: encodeURI('https://docs.google.com/gview?embedded=true&url=' + url),
          presentationStyle: 'popover'
        });
      } else if (platform.is('hybrid')) {
        Browser.open({
          url : encodeURI(url),
          presentationStyle: 'popover'
        });
      } else if (isMobile(platform) || !this.mimeTypeService.isDocument(url) || isSafariDesktop) {
        window.open(url, '_system');
      } else {
        defaultOpen(url);
      }
    } catch (e) {
      console.log(e);
    }

  }

  public onChange(...params: any[]): void {
    this.updateAction();
    this.action.onChangeEvent(this.manager);
  }

  public onEnter(): void {
  }

  public onLeave(): void {
    this.action.setResponse(this.getResponse());
  }

  public getValue(dyn: DynValue): any {
    return this.manager.getValue(dyn);
  }

  public ngOnInit(): void {
    this.initAction(this.action);
    this.onEnter();
  }

  public canLeave(): boolean {
    return this.action.canLeave(this.manager);
  }

  public validation(): string {
    return undefined;
  }

  public isDisabled(): boolean {
    return this.action.isDisabled(this.manager);
  }

  public async focus(): Promise<void> {
    await delay(100);
    // this.nativElem.focus()
    this.nativElem.parentElement.scrollIntoView();
  }

  protected async getPod(): Promise<PodForPodsList> {
    return await this.injector.get(PodsService).pod(this.manager.getActivity().podId);
  }

  public getFromInjector<T>(token: Type<T> | InjectionToken<T>): T {
    return this.injector.get(token);
  }
}
