import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[pullDown]'
})
export class PullDownDirective {
  private pointerDown: boolean = false;
  private waitScroll: boolean = false;
  private startPosition: { x: number, y: number };
  private fireEventOffset: number = 120;
  private element: HTMLElement;
  private wheelY: number = 0;
  private currentEventFiered: boolean = false;
  @Output()
  public pullDown: EventEmitter<HTMLElement> = new EventEmitter();

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('touchstart', ['$event'])
  private touchStartEvent(event: TouchEvent | any): void {
    this.onPointerDown({x: event.touches[0].clientX, y: event.touches[0].clientY});
  }

  @HostListener('touchend', ['$event'])
  private touchEndEvent(event: TouchEvent | any): void {
    this.resetEvent();
  }

  @HostListener('touchmove', ['$event'])
  private touchMoveEvent(event: TouchEvent | any): void {
    this.onPointerMove({x: event.touches[0].clientX, y: event.touches[0].clientY});
  }

  @HostListener('mousewheel', ['$event'])
  private onMouseWheelChrome(event: WheelEvent): void {
    if (!(event.deltaY > 50)) {
      return;
    }
    if ((this.element.clientHeight + this.element.scrollTop) < (this.element.scrollHeight - 1)) {
      return;
    }
    let currentWheel: number = this.wheelY += (event.deltaY / 2);
    if (currentWheel > this.fireEventOffset) {
      this.fireEvent();
    }
    this.changeScroll(currentWheel / 2);
    setTimeout(() => {
      if (this.wheelY !== currentWheel) {
        return;
      }
      this.resetEvent();
    }, 500);
  }

  private changeScroll(pixels: number): void {
    if (pixels > this.fireEventOffset) {
      pixels = this.fireEventOffset;
    }
    this.element.style.transform = 'translateY(-' + pixels + 'px)';
  }

  private resetEvent(): void {
    this.wheelY = 0;
    this.pointerDown = false;
    this.waitScroll = false;
    this.currentEventFiered = false;
    this.element.style.transform = 'translateY(0px)';
  }

  private onPointerDown(pos: { x: number, y: number }): void {
    if ((this.element.clientHeight + this.element.scrollTop) < (this.element.scrollHeight - 1)) {
      this.waitScroll = true;
      return;
    }
    this.waitScroll = false;
    this.startPosition = pos;
    this.pointerDown = true;
  }

  private onPointerMove(pos: { x: number, y: number }): void {
    if (!this.pointerDown) {
      return;
    }
    if (this.waitScroll) {
      return this.onPointerDown(pos);
    }
    let offset: number = this.startPosition.y - pos.y;
    if (offset < 1) {
      return;
    }
    if (offset > this.fireEventOffset) {
      this.fireEvent();
    }
    this.changeScroll(offset);
  }

  public fireEvent(): void {
    if (this.currentEventFiered) {
      return;
    }
    this.currentEventFiered = true;
    this.pullDown.emit(this.element);
  }
}
