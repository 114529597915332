import Quill from 'quill';


// https://stackoverflow.com/questions/3543187/prepending-http-to-a-url-that-doesnt-already-contain-http
const prependHttps = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url;
  }
  return url;
};

// https://github.com/quilljs/quill/issues/550
// https://stackoverflow.com/questions/40956020/how-can-i-prefill-links-with-http-in-a-quill-editor
var Link = Quill.import('formats/link');

export class CustomLink extends Link {

  static sanitize(url: string) {
    let value: string = super.sanitize(url);
    if (value && (Link.PROTOCOL_WHITELIST as string[]).some(protocol => value.startsWith(protocol))) return value;

    return prependHttps(value);
  }
}
