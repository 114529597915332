<ng-container *ngIf="type == 'show' || manager.mode === 'review'; else inputForm">
    <div class="show-div">
        <span class="show-span">{{params.number}}</span>
    </div>
</ng-container>
<ng-template #inputForm>
    <mat-form-field>
        <input [placeholder]="placeholder"
               matInput
               type="number"
               (ngModelChange)="onChange($event)"
               [(ngModel)]="params.number">
    </mat-form-field>
</ng-template>
