import {Component} from '@angular/core';
import moment from 'moment';
import Action from '../../../../models/action';
import {InputBaseController} from '../../input-controller';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-date-diff',
  templateUrl: './action-date-diff.component.html',
  styleUrls: ['./action-date-diff.component.scss'],
})
export class ActionDateDiffComponent extends InputBaseController {
  public static className: string = 'action-date-diff';
  public static readonly responseKeys: string[] = ['timeDiff'];
  public params: {
    timeDiff: number,
    from: Date,
    unit: 'days' | 'weeks' | 'months' | 'years',
    minDiff: number,
    maxDiff: number,
    format: string,
    future: boolean
  };
  public pickerDate: Date;
  public minDate: Date;
  public maxDate: Date;

  protected initAction(action: Action): void {
    super.initAction(action);
    this.params.from = this.params.from || new Date();
    this.params.unit = this.params.unit || 'days';
    if (this.params.timeDiff) {
      let serverDate: Date;
      if (this.params.future) {
        serverDate = moment(new Date(this.params.from).setHours(0, 0, 0))
          .add(this.params.timeDiff, this.params.unit).toDate();
      } else {
        serverDate = moment(new Date(this.params.from).setHours(0, 0, 0))
          .subtract(this.params.timeDiff, this.params.unit).toDate();
      }
      this.pickerDate = serverDate;
    }
    if (this.params.future) {
      this.minDate = this.params.minDiff !== undefined ?
        moment(new Date(this.params.from).setHours(0, 0, 0))
          .add(this.params.minDiff, this.params.unit).toDate() : new Date();
      this.maxDate = this.params.maxDiff !== undefined ?
        moment(new Date(this.params.from).setHours(0, 0, 0))
          .add(this.params.maxDiff, this.params.unit).toDate() : undefined;
    } else {
      this.maxDate = this.params.minDiff !== undefined ?
        moment(new Date(this.params.from).setHours(0, 0, 0))
          .subtract(this.params.minDiff, this.params.unit).toDate() : undefined;
      this.minDate = this.params.maxDiff !== undefined ?
        moment(new Date(this.params.from).setHours(0, 0, 0))
          .subtract(this.params.maxDiff, this.params.unit).toDate() : new Date();
    }
    if (this.minDate) {
      this.minDate.setHours(0, 0, 0);
    }
    if (this.maxDate) {
      this.maxDate.setHours(0, 0, 0);
    }

  }

  public onChange(): any {
    let serverDate: Date = this.pickerDate;
    if (this.params.future) {
      this.params.timeDiff = moment(serverDate.setHours(0, 0, 0))
        .diff(this.params.from.setHours(0, 0, 0), this.params.unit);
    } else {
      this.params.timeDiff = moment(this.params.from.setHours(0, 0, 0))
        .diff(serverDate.setHours(0, 0, 0), this.params.unit);
    }
    super.onChange();
  }

}
