import XLSX from 'xlsx';
import _ from 'lodash';
import {cellInfos, cellValue} from './loaderUtils';
import {ISlotsBag, ISubscribableSlot} from '../../components/form/actions/action-slots/action-slots.interface';

const testDescription: string = 'Join Just dance academy hip hop instructor Mr. Kyle for an exciting two weeks of hip hop dance to your favorite pop songs! Curriculum new each year! Students will enjoy learning basic hip hop movement, improvisation, and choreography all in just two weeks! Watch your child’s confidence soar as they create their own dances, learn a new routine, and explore various isolations and trendy hip hop moves and steps! Informal showing of routine(s) learned for family and friends at the end of the session!';

export interface ICourseInfos {
  name: string;
  instructor: string;
  cost: number;
  capacity: number;
  location: string;
  minGrade: number;
  maxGrade: number;
}

export interface IClass extends ICourseInfos {
  _id: string;
  period: string;
  session: string;
  linked: string[];
}

export interface IPeriod {
  classes: { [name: string]: IClass };
}

export interface ISession {
  periods: { [name: string]: IPeriod };
}

export interface ISchedule {
  [sessionName: string]: ISession;
}

export interface ICourse extends ICourseInfos {
  periods: { name: string; minGrade: number; maxGrade: number }[];
  sessions: string[];
  linkedPeriods: string[][];
}

export interface ISCSSData {
  courses: ICourse[];
  schedule: ISchedule;
  sessionsSlotsBags: _.Dictionary<ISlotsBag[]>;
}

export const loadSCSSCourse: (ws: XLSX.WorkSheet, rowIndex: number) => ICourse = (ws: XLSX.WorkSheet, rowIndex: number) => {
  const scssClass: ICourse = {
    name: cellValue(ws, {c: 0, r: rowIndex}),
    instructor: cellValue(ws, {c: 1, r: rowIndex}),
    cost: cellValue(ws, {c: 8, r: rowIndex}),
    capacity: cellValue(ws, {c: 7, r: rowIndex}),
    location: cellValue(ws, {c: 9, r: rowIndex}),
    periods: [],
    sessions: [],
    linkedPeriods: [],
  } as ICourse;
  for (let periodColIndex: number = 3; periodColIndex <= 6; periodColIndex++) {
    const periodAddress: XLSX.CellAddress = {c: periodColIndex, r: rowIndex};
    const periodInfos: {sourceAddress: XLSX.CellAddress, value: string | number | boolean | Date} = cellInfos(ws, periodAddress);
    if (!periodInfos) {
      continue;
    }
    const gradesRange: string = periodInfos.value as string;
    const periodName: string = cellValue(ws, {c: periodColIndex, r: 0}) as string;
    scssClass.periods.push({
      name: periodName,
      minGrade: +(gradesRange.split('-')[0]),
      maxGrade: +(gradesRange.split('-')[1]),
    });
    if (!_.isEqual(periodInfos.sourceAddress, periodAddress)) {
      const sourcePeriodName: string = cellValue(ws, {c: periodInfos.sourceAddress.c, r: 0}) as string;
      console.log(periodName, 'linked with', sourcePeriodName);
      const existingLink: string[] = scssClass.linkedPeriods.find(links => links.some(pName => pName === sourcePeriodName));
      if (!existingLink) {
        scssClass.linkedPeriods.push([
          sourcePeriodName,
          periodName,
        ]);
      } else {
        existingLink.push(periodName);
      }
    }
  }
  const sessionsRange: string = cellValue(ws, {c: 2, r: rowIndex}) as string;
  if (sessionsRange) {
    scssClass.sessions = sessionsRange.split('-');
  }
  return scssClass;
};

export const coursesToSchedule: (courses: ICourse[]) => ISchedule = (courses: ICourse[]) => {
  const sessionNumberToName: {[n: number]: string} = {
    1: 'Session I',
    2: 'Session II',
  };
  const sessionNames: any[] = _.uniq(_.flatMap(courses, sheetClass => sheetClass.sessions).map(session => sessionNumberToName[session]));
  const periodNames: any[] = _.uniq(_.flatMap(courses, sheetClass => sheetClass.periods.map(period => period.name)));
  const schedule: ISchedule = _.mapValues(_.keyBy(sessionNames), () => ({
    periods: _.mapValues(_.keyBy(periodNames), () => ({
      classes: {},
    })),
  }));
  courses.forEach(course => {
    course.sessions.forEach(session => {
      course.periods.forEach(period => {
        const scheduleClass: IClass = {
          ..._.omit(course, ['periods', 'sessions', 'linkedPeriods']),
          _id: `${sessionNumberToName[session]}-${period.name}-${course.name}`,
          period: period.name,
          session: sessionNumberToName[session],
          linked: [],
        };
        schedule[sessionNumberToName[session]].periods[period.name].classes[course.name] = scheduleClass;
      });
      course.periods.forEach(period => {
        const scheduleClass: IClass = schedule[sessionNumberToName[session]].periods[period.name].classes[course.name];
        const periodsPool: string[] = course.linkedPeriods.find(linkedPeriod => linkedPeriod.some(name => name === period.name));
        if (!periodsPool) {
          return;
        }
        // console.log(period.name, 'linked to', periodsPool.filter(linkedPeriodName => linkedPeriodName != period.name));
        scheduleClass.linked = periodsPool
          .filter(linkedPeriodName => linkedPeriodName !== period.name)
          .map(linkedPeriodName => schedule[sessionNumberToName[session]].periods[linkedPeriodName].classes[course.name]._id);
      });
    });
  });
  return schedule;
};

export const loadSCSSCourses: (fileContent: string) => ICourse[] = (fileContent: string) => {
  const wb: XLSX.WorkBook = XLSX.read(fileContent, {type: 'binary'});
  const wsname: string = wb.SheetNames[0];
  const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  const range: XLSX.Range = XLSX.utils.decode_range(ws['!ref']);

  let scssClasses: ICourse[] = [];
  for (let rowIndex: number = 1; rowIndex < range.e.r; rowIndex++) {
    const cellRef: string = XLSX.utils.encode_cell({c: 0, r: rowIndex});
    const scssClass: ICourse = loadSCSSCourse(ws, rowIndex);
    scssClasses.push(scssClass);
  }
  return scssClasses;
};

const linkedPeriods: (scssClass: IClass, courses: ICourse[]) => string = (scssClass: IClass, courses: ICourse[]) => {
  const course: ICourse = courses
    .find(c => c.name === scssClass.name);
  return course.linkedPeriods
    .find(linkArray => linkArray.some(periodName => periodName === scssClass.period))
    .join(' - ');
};

export const toSubscribableSlots: (schedule: ISchedule, courses: ICourse[]) => _.Dictionary<ISlotsBag[]>
  = (schedule: ISchedule, courses: ICourse[]) => {
  const slots: _.Dictionary<ISlotsBag[]> = _.mapValues(schedule, (session, sessionName) => _.map(session.periods, (period, periodName) =>
    ({
      slots: _.map(period.classes, scssClass => ({
        _id: scssClass._id,
        name: scssClass.name,
        subTitle: (scssClass.linked.length > 0) && linkedPeriods(scssClass, courses),
        cost: scssClass.cost,
        location: scssClass.location,
        availableCount: scssClass.capacity,
        capacity: scssClass.capacity,
        linked: scssClass.linked,
        incompatible: [],
        description: testDescription,
      } as ISubscribableSlot)),
      name: `${sessionName} - ${periodName}`,
    } as ISlotsBag)
  ));
  return slots;
};

export const loadSCSS: (fileContent: string) => Promise<
  {
    courses: ICourse[],
    schedule: ISchedule,
    sessionsSlotsBags: _.Dictionary<ISlotsBag[]>
  }> = async (fileContent: string) => {
  const courses: ICourse[] = loadSCSSCourses(fileContent);
  const schedule: ISchedule = coursesToSchedule(courses);
  const sessionsSlotsBags: _.Dictionary<any[]> = toSubscribableSlots(schedule, courses);
  return {courses, schedule, sessionsSlotsBags};
};
