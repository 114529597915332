<div *ngIf="!disabled"
     class="dateFormElement overlay-button-container d-flex align-items-center justify-content-between">
  <div class="iconContainer"><ion-icon name="calendar"></ion-icon></div>
  <div *ngIf="placeholder && !displayableDate" class="selectDatePickerPlaceholder overLabel">{{placeholder}}</div>
  <div class="selectedDate" [class.overLabel]="placeholder && !!displayableDate">
    <div *ngIf="placeholder && !!displayableDate" class="selectDatePickerPlaceholder">{{placeholder}}</div>
    {{displayableDate}}
  </div>
  <button class="overlay-button" (click)="triggerDatePickerModal()"></button>
</div>
<div *ngIf="disabled" class="dateFormElement">
  <span *ngIf="placeholder" class="selectDatePickerPlaceholder">{{placeholder}}:</span>
  <div class="d-flex justify-content-around align-items-center">
    <div class="iconContainer"><ion-icon name="calendar"></ion-icon></div>
    <span class="selectedDate disabled flex-grow">{{displayableDate}}</span>
  </div>
</div>
