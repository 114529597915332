import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DisplayDisclaimerComponent} from '../../components/dialogs/display-disclaimer/display-disclaimer.component';

@Injectable({
  providedIn: 'root'
})
export class QuillImagesRestrictionService {

  public totalImagesMaxSizeInMB: number = 7; // 7Mo
  public totalImagesMaxSize: number = this.totalImagesMaxSizeInMB * 1024 * 1024;

  constructor(
    private matDialog: MatDialog,
    public translate: TranslateService,
  ) {
  }

  public processImagesRestriction(quill: any, maxImagesCount: number = 100): void {
    if (quill.delta.ops) {
      quill.delta.ops.forEach((deltaOp) => {
        if (
          deltaOp.hasOwnProperty('insert')
          && deltaOp.insert.hasOwnProperty('image')
        ) {
          let imagesCount: number = (quill.html.match(/<img src="data:image/g) || []).length;
          let images: HTMLImageElement[] = Array.from(quill.editor.root.querySelectorAll('img'));
          const totalSize: number = images.reduce((acc, img) => acc + new TextEncoder().encode(img.outerHTML).length, 0);
          console.debug('total images base64 encoded size', totalSize);
          if (totalSize > this.totalImagesMaxSize) {
            this.matDialog.open(DisplayDisclaimerComponent, {
              data: {text: this.translate.instant('ACTIVITY.ACTION.CUMULATIVE_IMAGE_SIZE_ERROR',
                  {size: this.totalImagesMaxSizeInMB}
                )}
            });
            quill.editor.history.undo();
          }
          if (imagesCount > maxImagesCount) {
            this.matDialog.open(DisplayDisclaimerComponent, {
              data: {
                text: this.translate.instant(
                  'ACTIVITY.ACTION.IMAGE_{{QUANTITY}}_ERROR',
                  {quantity: maxImagesCount},
                ),
              }
            });
            quill.editor.history.undo();
          }

          // if (deltaOp.insert.image.length >= this.imageMaxSize) {
          //     this.matDialog.open(DisplayDisclaimerComponent, {
          //         data: { text: this.translate.instant('ACTIVITY.ACTION.IMAGE_SIZE_ERROR') }
          //     });
          //     quill.editor.history.undo();
          // } else if (imagesCount > 3) {
          //     this.matDialog.open(DisplayDisclaimerComponent, {
          //         data: { text: this.translate.instant('ACTIVITY.ACTION.IMAGE_QUANTITY_ERROR_3') }
          //     });
          //     quill.editor.history.undo();
          // }
        }
      });
    }
  }
}
