import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import Activity from '../../../../models/activity';
import ConcernedUser from '../../../../models/concerned-user';
import Action from '../../../../models/action';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-activity-header',
  templateUrl: './action-activity-header.component.html',
  styleUrls: ['./action-activity-header.component.scss'],
})
export class ActionActivityHeaderComponent extends InputBaseController {
  public static className: string = 'action-activity-header';
  public static readonly responseKeys: string[] = ['title'];
  public activity: Activity;
  public title: string;
  public concernedUser: ConcernedUser;
  public index: number;

  public params: { title: string };

  public onChange(title: string): void {
    this.params.title = title;
    super.onChange();
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.activity = this.manager.getActivity();
    this.concernedUser = this.manager.getConcernedUser();
    this.title = this.activity.displayName;
  }

  public getTypeImage(icon: string): string {
    return './assets/img/svg/' + icon + '.svg';
  }
}
