<mat-accordion class="ion-margin-top">
  <h3 *ngIf="params.displayTitle">{{ "ACTIVITY.ACTION.SHOPPING.REVIEW_CART" | translate }}</h3>
  <div>
    <mat-expansion-panel *ngFor="let basketItem of params.cart; let i = index" [expanded]="i === openedIndex"
                         class="mat-expansion-panel-to-ion-card mat-elevation-z3"
                         [hideToggle]="true">
      <mat-expansion-panel-header class="overlay-button-container">
        <button class="overlay-button" (click)="openItem($event, i)">
        </button>
        <mat-panel-title>
          {{ basketItem.name }}
        </mat-panel-title>
        <mat-panel-description class="d-flex justify-content-center align-items-center">
          <ion-badge *ngIf="!basketItem.description; else elseBlock">{{ basketItem.qty }}</ion-badge>
          <ng-template #elseBlock>{{ basketItem.description }}</ng-template>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="ion-text-start ion-padding-start">
        <div class="basketItemDetail">
          {{ "ACTIVITY.ACTION.SHOPPING.OPTION" | translate }} {{ basketItem.option }} <br />
          {{ "ACTIVITY.ACTION.SHOPPING.SIZE" | translate }} {{ basketItem.size }} <br />

          {{ "ACTIVITY.ACTION.SHOPPING.UNIT_PRICE" | translate }} $ {{ basketItem.unitPrice }} <br />
          {{ "ACTIVITY.ACTION.SHOPPING.TOTAL:" | translate }} $ {{ basketItem.unitPrice * basketItem.qty }}
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <ion-button color="light" (click)="removeItem(i)">
              <ion-icon slot="icon-only" name="remove"></ion-icon>
            </ion-button>


            <ion-button color="light" (click)="addItem(i)">
              <ion-icon slot="icon-only" name="add"></ion-icon>
            </ion-button>
          </div>

          <ion-button size="large" fill="clear" color="danger" (click)="delItem(i)">
            <ion-icon slot="icon-only" name="close-circle"></ion-icon>
          </ion-button>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</mat-accordion>
<br />
<div class="totalContainer d-flex justify-content-between align-items-center">
  <div>
    {{ "ACTIVITY.ACTION.SHOPPING.TOTAL" | translate }}
  </div>
  <div>
    $ {{ params.totalPrice }}
  </div>
</div>
