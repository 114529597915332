import { Component, Injector, OnDestroy, ViewContainerRef } from '@angular/core';
import AuthenticatedUser from '../../../../models/user';
import { InputBaseController } from '../../input-controller';
import { UserService } from '../../../../services/user.service';
import { ApiService } from '../../../../services/api.service';
import { PTCSocketIOService } from '../../../../services/socket.service';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import { Dictionary } from 'lodash';
import { CsvExporterService } from '../../../../services/csv-exporter.service';
import { SlotCol, SlotRow, SocketMessageOut, SocketStatus } from '../../../../models/ptc';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-realtime-slots',
  templateUrl: './action-realtime-slots.component.html',
  styleUrls: ['./action-realtime-slots.component.scss'],
})
export class ActionRealtimeSlotsComponent extends InputBaseController implements OnDestroy {
  public static className: string = 'action-realtime-slots';
  public static readonly responseKeys: string[] = ['reservedSlot', 'reservedDetails', 'reservedDate', 'calendarFrom', 'calendarTo', 'reservedIndex'];
  public params: {
    slots: SlotCol[],
    reservedSlot: SlotRow,
    reservedDate: string
    reservedDetails: { day: string, hour: string },
    reservedIndex: string
    mode: 'follower' | 'manager' | 'review',
    calendarFrom: Date,
    calendarTo: Date,
    slotDuration: number, // minutes
  };

  openTabs: number[] = [0];
  waitingForValidation: boolean = false;
  currentUser: AuthenticatedUser;
  countDownTimer;
  countDownLeft;
  socketStatus: SocketStatus = 'Off-line';
  activity: any;
  concernedUser: any;
  someoneElse: boolean = false;
  firstUpdate: boolean = true;
  lastSlotHeld: SlotRow;
  alreadyConfirmed: boolean = false;

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    public alertCtrl: AlertController,
    public api: ApiService,
    public userService: UserService,
    private socketService: PTCSocketIOService,
    public translate: TranslateService,
  ) {
    super(injector, viewContainerRef);
    this.currentUser = this.userService.currentUser;
  }

  private isManagerActivityReviewMode() {
    return this.params.mode === 'review' && this.manager.mode === 'managerActivityReview';
  }

  private initSocket() {
    if ((this.params.mode === 'follower' || this.isManagerActivityReviewMode()) && this.activity.id) {
      this.socketService.initSocket();
      this.socketStatus = 'Connecting...';
      this.socketService.socket.on('connect', () => {
        this.socketService.send({ ActivityId: this.activity.id }, 'init');
      });
      this.socketService.socket.on('disconnect', () => {
        this.socketStatus = 'Connecting...';
      });
      this.socketService.socket.on('message', (data) => {
        if (data.ActivityId === this.activity.id) {
          this.socketStatus = 'Connected';
          this.updateFromServer(data);
        }
      });
    } else {
      this.socketStatus = '';
    }
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.waitingForValidation = false;
    this.params.reservedDate = '';
    this.params.reservedIndex = '';
    this.params.reservedDetails = { day: '', hour: '' };
    this.params.slotDuration = this.params.slotDuration || 20;
    this.params.slots = this.params.slots || [];
    this.concernedUser = this.manager['rootObject'].concernedUser ?
      this.manager['rootObject'].concernedUser : this.currentUser;
    this.activity = this.manager['rootObject'].activity;
    if (this.activity.status !== 'OPEN' ||
      (this.concernedUser.id === this.currentUser.id &&
        this.params.mode !== 'manager' &&
        this.manager.mode !== 'view')
    ) {
      this.params.mode = 'review';
    }
    this.initSocket();
  }

  ngOnDestroy() {
    this.waitingForValidation = false;
    this.socketService.disconnect();
    clearInterval(this.countDownTimer);
  }

  startCountDown() {
    this.onChange();
    clearInterval(this.countDownTimer);
    this.countDownLeft = 2 * 60;
    this.countDownTimer = setInterval(() => {
      this.countDownLeft -= 1;
      if (this.countDownLeft <= 0) {
        this.unSelectOne(this.params.reservedSlot);
        clearInterval(this.countDownTimer);
      }
    }, 1000);
  }

  getCountDownStr(): string {
    let mLeft: number = Math.floor(this.countDownLeft / 60);
    let sLeft: number = Math.floor(this.countDownLeft % 60);
    return mLeft + 'min and ' + sLeft + 's';
  }

  // tslint:disable-next-line: cyclomatic-complexity
  getMode(): 'Wait' | 'Select' | 'Confirm' | 'Someone else' {
    if (this.params.mode !== 'follower') {
      return 'Select';
    }
    if (this.socketStatus !== 'Connected') {
      return 'Wait';
    }
    if (this.socketStatus === 'Connected' &&
      ((!this.params.reservedSlot && !this.someoneElse) ||
        (this.params.reservedSlot && !this.someoneElse && !this.countDownTimer))) {
      return 'Select';
    }
    if (this.params.reservedSlot && !this.someoneElse && this.countDownTimer) {
      return 'Confirm';
    }
    if (this.someoneElse) {
      return 'Someone else';
    }
  }

  dropDown(index: number): void {
    if (this.openTabs.includes(index)) this.openTabs = this.openTabs.filter(i => i !== index);
    else this.openTabs.push(index);
  }

  isOpen(index: number): boolean {
    return this.openTabs.includes(index);
  }

  openAll(e): void {
    if (e.checked) this.openTabs = this.params.slots.map(s => s.index);
    else (this.openTabs = []);
  }

  selectOne(slotRow: SlotRow, slotCol: SlotCol) {
    if (this.params.mode === 'manager') {
      slotRow.taken = slotRow.taken === 2 ? 0 : 2;
      return;
    }
    if (this.manager.mode === 'review') return;
    if (slotRow.taken === 2 || this.params.mode === 'review') {
      return;
    }
    if (this.socketStatus === 'Connected') {
      let lastSelected;
      this.params.slots.forEach(col => {
        col.rows.forEach(row => {
          if (this.isMine(row)) {
            lastSelected = JSON.parse(JSON.stringify(row));
            row.userId = '';
            this.params.reservedIndex = '';
          }
        });
      });
      this.params.reservedSlot = slotRow;
      this.params.reservedDetails.day = slotCol.name;
      this.params.reservedDetails.hour = slotRow.name;
      this.lastSlotHeld = slotRow;
      this.params.reservedDate = this.getSelectionDetails();
      if (!lastSelected || lastSelected.value !== slotRow.value) {
        this.startCountDown();
      }
      slotRow.userId = this.concernedUser.id;
      this.onChange();
      this.selectOnServer();
    }
  }

  async selectOnServer() {
    if (this.socketStatus === 'Connected') {
      let myCol;
      this.params.slots.forEach(col => {
        col.rows.forEach(row => {
          if (this.isMine(row)) {
            myCol = col;
            this.params.reservedIndex = col.index.toString() + '-' + row.index.toString();
          }
        });
      });
      let message: SocketMessageOut = new SocketMessageOut(
        this.params.reservedSlot.userId,
        this.activity.id,
        myCol,
        this.params.reservedSlot,
      );
      if (this.activity.id) {
        await this.api.resetPendingActivities(this.activity.id, this.concernedUser.id);
        this.manager.events.publish('history-activities:refresh');
        this.socketService.send(message);
      }
    }
  }

  updateFromServer(data) {
    let wasSelected: boolean = !!this.lastSlotHeld;
    this.params.reservedSlot = undefined;
    this.lastSlotHeld = undefined;
    this.params.reservedDate = '';
    this.params.reservedIndex = '';
    this.params.slots = [];
    this.params.slots = data['Cols'];
    this.someoneElse = false;
    this.params.slots.forEach(col => {
      col.rows.forEach(row => {
        if (this.isMine(row)) {
          this.lastSlotHeld = row;
          this.alreadyConfirmed = row.taken === 2;
          this.params.reservedSlot = row;
          this.params.reservedDetails.day = col.name;
          this.params.reservedDetails.hour = row.name;
          this.params.reservedDate = this.getSelectionDetails();
          this.params.reservedIndex = col.index.toString() + '-' + row.index.toString();
          if (this.waitingForValidation) {
            this.onChange();
            this.onClick();
          } else {
            if (this.firstUpdate) {
              // this.openTab = col.index;
              this.firstUpdate = false;
            }
            if (!wasSelected && !this.alreadyConfirmed) {
              this.someoneElse = true;
              this.params.reservedSlot = undefined;
              this.lastSlotHeld = undefined;
              this.params.reservedDate = '';
              this.params.reservedDetails = { day: '', hour: '' };
              this.params.reservedIndex = '';
              clearInterval(this.countDownTimer);
            }
          }
        }
      });
    });
    this.waitingForValidation = false;
    if (wasSelected && !this.params.reservedSlot) {
      clearInterval(this.countDownTimer);
    }

  }

  async unSelectOne(slot: SlotRow): Promise<void> {
    if (this.manager.responsibleUser) {
      let promiseResolve = (confirmed: boolean) => { };
      let promiseReject = (confirmed: boolean) => { };
      const confirmationPromise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
      });

      const alert: HTMLIonAlertElement = await this.alertCtrl.create({
        header: this.translate.instant('ACTIVITY.ACTION.REALTIME_SLOTS.CANCEL_CONFIRM_ALERT.HEADER'),
        message: this.translate.instant('ACTIVITY.ACTION.REALTIME_SLOTS.CANCEL_CONFIRM_ALERT.MESSAGE'),
        buttons: [
          {
            text: this.translate.instant('ACTIVITY.ACTION.REALTIME_SLOTS.CANCEL_CONFIRM_ALERT.NO_BTN'),
            role: 'cancel',
            cssClass: 'ion-text-capitalize',
            handler: () => promiseResolve(false),
          }, {
            text: this.translate.instant('ACTIVITY.ACTION.REALTIME_SLOTS.CANCEL_CONFIRM_ALERT.YES_BTN'),
            cssClass: 'ion-text-capitalize',
            handler: () => promiseResolve(true),
          }
        ]
      });

      await alert.present();

      const confirmed: boolean = await confirmationPromise;
      if (!confirmed) return;
    }

    this.waitingForValidation = false;
    if (this.socketStatus === 'Connected') {
      this.lastSlotHeld = undefined;
      let lastSelectedRow;
      let lastSelectedCol;
      this.params.slots.forEach(col => {
        col.rows.forEach(row => {
          if (this.isMine(row)) {
            lastSelectedRow = row;
            lastSelectedCol = col;
          }
        });
      });
      if (this.activity.id) {
        try {
          await this.socketService.send(new SocketMessageOut(
            this.concernedUser.id,
            this.activity.id,
            lastSelectedCol,
            lastSelectedRow, false));
        } catch (e) {
          console.error(e);
        }
      }

      this.params.reservedSlot = null;
      this.params.reservedDate = '';
      this.params.reservedIndex = '';
      this.params.reservedDetails = { day: '', hour: '' };
      clearInterval(this.countDownTimer);
      slot.userId = '';
    }
  }

  isMine(slot: SlotRow): boolean {
    if (this.params.mode === 'review') {
      return this.params.reservedSlot?.value === slot.value;
    }
    return !!this.concernedUser && slot.userId === this.concernedUser.id;
  }

  isDisabledButton(slot: SlotRow): boolean {
    return (this.params.mode !== 'manager' &&
      !this.isMine(slot) &&
      ((!!slot.userId && slot.userId.length > 0) || slot.taken > 0)) ||
      this.type === 'show' ||
      (this.socketStatus !== 'Connected' && this.params.mode !== 'manager');
  }

  isTakenButton(slot: SlotRow): boolean {
    if (this.params.mode !== 'manager') {
      return (!this.isMine(slot) &&
        ((!!slot.userId && slot.userId.length > 0) || slot.taken > 0)) ||
        this.type === 'show';
    } else {
      return slot.taken > 1;
    }
  }

  getSelectionDetails(): string {
    if (this.params.reservedSlot.value) {
      return new Date(this.params.reservedSlot.value).toDateString() + ' ' + this.params.reservedSlot.name;
    } else {
      return '';
    }
  }

  onConfirm() {
    if (this.params.mode === 'follower' && this.socketStatus === 'Connected' && !!this.activity.id) {
      let myCol;
      let reservedIndex: string = '';
      this.params.slots.forEach(col => {
        col.rows.forEach(row => {
          if (this.isMine(row)) {
            myCol = col;
            reservedIndex = col.index.toString() + '-' + row.index.toString();
          }
        });
      });
      let message: SocketMessageOut = new SocketMessageOut(
        this.params.reservedSlot.userId,
        this.activity.id,
        myCol,
        this.params.reservedSlot);
      this.params.calendarFrom = new Date(this.params.reservedSlot.value);
      this.params.calendarTo = new Date(this.params.calendarFrom.getTime() + (this.params.slotDuration * 60 * 1000));
      this.params.reservedIndex = reservedIndex;
      this.socketService.send(message, 'take');
      this.waitingForValidation = true;
    }
  }

  // tslint:disable-next-line: member-ordering
  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let details = getValue(action.params.reservedDetails);
    if (details && details.day && details.hour && details.day.length && details.hour.length) {
      let date: string = details.day.split(' ')[1] + ' ' + details.day.split(' ')[2];
      let day: string = details.day.split(' ')[0];
      let time: string = details.hour;
      return { date, day, time };
    } else { // try with the date
      let index = getValue(action.params.reservedIndex);
      let dateStr = getValue(action.params.reservedDate);
      let date: Date = dateStr && new Date(dateStr) || undefined;
      index = index ? '(' + index + ') ' : '';
      return CsvExporterService.ConvertDate(date);
    }
  }

}
