<div *ngIf="type == 'input' && manager.mode !== 'review' && manager.mode !== 'managerUserResponseReview' && manager.mode !== 'managerActivityReview'"
     style="text-align: center; position: relative">
  <label id="file-name-label" class="centered-block" *ngIf="params.fileName" margin-top color="positive">
    <a (click)="getResourceUrl()" class="link-cursor">{{params.fileDisplayName}}</a><br>
  </label>
  <app-input-file [fileName]="params.fileName"
              [ios]="plt.is('ios')"
              [fileDisplayName]="params.fileDisplayName"
              [allowedExtensions]="params.allowedExtensions"
              [error]="error"
              (onFile)="addFile($event)"
  ></app-input-file>
</div>
<div *ngIf="type == 'show' || manager.mode === 'review' || manager.mode === 'managerUserResponseReview' || manager.mode === 'managerActivityReview'"
     class="openLink d-flex justify-content-around">
  <a (click)="getResourceUrl()" class="link-cursor">{{params.fileDisplayName}}</a>
</div>
