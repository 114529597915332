import {Injectable, Pipe, PipeTransform} from '@angular/core';

/**
 * bolds the beggining of the matching string in the item
 */
@Pipe({
  name: 'boldprefix'
})
@Injectable()
export class BoldPrefix implements PipeTransform {
  transform(value: string, keyword: string): any {
    if (!keyword) {
      return value;
    }
    let escapedKeyword: string = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return value.replace(new RegExp(escapedKeyword, 'gi'), (str: string) => {
      return str.bold();
    });
  }
}
