<div class="pdf-modal-container d-flex flex-column">
  <div class="pdfModalBtns d-flex justify-content-between align-items-center">
    <a [href]="url" target="_blank">{{ "ACTIVITY.ACTION.LINK.CLICK_HERE_TO_OPEN_PDF" | translate }}</a>
    <ion-button shape="round" (click)="close()">{{ "close" | translate }}</ion-button>
  </div>
  <object *ngIf="type === 'application/pdf'" [data]="url"
          [type]="isSafari ? undefined : 'application/pdf'">
    <embed [src]="url"/>
  </object>
  <iframe *ngIf="type !== 'application/pdf'" [src]="url"></iframe>
</div>
