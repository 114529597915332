<div class="realTimeSlots">
  <div *ngIf="getMode() === 'Wait'" class="socketStatus"
       [ngClass]="{'warn': socketStatus !== 'Connected'}">
    {{socketStatus}}
  </div>
  <div *ngIf="getMode() === 'Select'">
    <div class="hint d-flex justify-content-center align-items-center">
      <ion-button shape="round" class="slotBtn">
        {{ "ACTIVITY.ACTION.REALTIME_SLOTS.FREE" | translate }}
      </ion-button>
      <ion-button *ngIf="params.mode === 'manager'" shape="round" class="slotBtn disabled">
        {{ "ACTIVITY.ACTION.REALTIME_SLOTS.DISABLED" | translate }}
      </ion-button>
      <ion-button *ngIf="params.mode !== 'manager'" shape="round" class="slotBtn taken" disabled>
        {{ "ACTIVITY.ACTION.REALTIME_SLOTS.TAKEN" | translate }}
      </ion-button>
      <ion-button *ngIf="params.mode !== 'manager'" shape="round" class="slotBtn mine">
        {{ "ACTIVITY.ACTION.REALTIME_SLOTS.MINE" | translate }}
      </ion-button>
    </div>
    <div class="w-100 d-flex justify-content-end align-items-center">
      <mat-checkbox [checked]="openTabs.length === params.slots?.length"
                    labelPosition="before"
                    [indeterminate]="openTabs.length > 0 && openTabs.length < params.slots?.length"
                    (change)="openAll($event)" >
        Expand All
      </mat-checkbox>
    </div>
    <div class="date" *ngFor="let col of params.slots">
      <div class="header" tappable (click)="dropDown(col.index)">
        <div class="title">{{col.name}}</div>
        <div class="dropdownArrow" [ngClass]="{'open': isOpen(col.index)}">
          <ion-icon name="caret-forward">
          </ion-icon>
        </div>
      </div>
      <div class="body"
           [ngClass]="{'open': isOpen(col.index), 'close': !isOpen(col.index)}">
        <div class="slots">
          <ion-button shape="round"
                      class="slotBtn"
                      *ngFor="let slot of col.rows"
                      [ngClass]="{'taken': isTakenButton(slot), 'mine': isMine(slot)}"
                      [disabled]="isDisabledButton(slot)"
                      (click)="selectOne(slot, col)">
            {{slot.name}}
          </ion-button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="getMode() === 'Confirm'">
    <!-- {{ "ACTIVITY.ACTION.REALTIME_SLOTS.CONGRATULATIONS" | translate }}<br> -->
    <ion-card *ngIf="manager.responsibleUser">
      <ion-card-content>
        <span>{{ "ACTIVITY.ACTION.REALTIME_SLOTS.BEHALF_WARNING" | translate }}</span>
        <br>
        <span class="italic-text">* {{ "ACTIVITY.ACTION.REALTIME_SLOTS.BEHALF_WARNING_CONSEQUENCE" | translate }}</span>
      </ion-card-content>
    </ion-card>
    <br>
    {{ "ACTIVITY.ACTION.REALTIME_SLOTS.YOU_HAVE_SELECTED" | translate }}<br>
    <!-- {{activity.title | bbcodeTranslation}} -->
    <b>{{getSelectionDetails()}}</b><br>
    <div *ngIf="concernedUser.id != currentUser.id">
      {{ "ACTIVITY.ACTION.REALTIME_SLOTS.FOR" | translate }}<br>
      <b>{{concernedUser.firstName}} {{concernedUser.lastName}}</b>
    </div>
    <div *ngIf="manager.responsibleUser">
      {{ "ACTIVITY.ACTION.REALTIME_SLOTS.ON_BEHALF_OF" | translate }}
      <br>
      <b>{{manager.responsibleUser.firstName}} {{manager.responsibleUser.lastName}}</b>
    </div>
    <br>
    <!-- {{ "ACTIVITY.ACTION.REALTIME_SLOTS.ON" | translate }}<br> -->

    {{ "ACTIVITY.ACTION.REALTIME_SLOTS.THIS_SLOT_REMAINS_SELECTED" | translate }}<br>
    <div *ngIf="waitingForValidation">
      {{ "ACTIVITY.ACTION.REALTIME_SLOTS.CONFIRMING" | translate }}
    </div>
    <div *ngIf="!waitingForValidation">
      <div *ngIf="countDownTimer"><b>{{getCountDownStr()}}</b></div>
      <br>
      <ion-button shape="round"
                  color="danger"
                  (click)="unSelectOne(params.reservedSlot)">
        {{ "ACTIVITY.ACTION.REALTIME_SLOTS.CANCEL_MY_SELECTION" | translate }}
      </ion-button>
      <ion-button shape="round"
                  class="confirm"
                  (click)="onConfirm()">
        {{ "ACTIVITY.ACTION.REALTIME_SLOTS.CONFIRM_MY_SELECTION" | translate }}
      </ion-button>
    </div>
  </div>
  <div *ngIf="getMode() === 'Someone else'">
    {{ "ACTIVITY.ACTION.REALTIME_SLOTS.SOMEONE_ELSE_IS_CURRENTLY_TAKING_A_SLOT_FOR" | translate }} {{concernedUser.firstName}} {{concernedUser.lastName}}
  </div>
</div>
