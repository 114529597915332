<div *ngIf="type == 'input' && manager.mode !== 'review'" style="text-align: center; position: relative">
  <label id="file-name-label" class="centered-block" *ngIf="params.fileName" margin-top color="positive">
    {{fileDisplayName}}
  </label>
  <app-input-file [fileName]="params.fileName"
              [ios]="plt.is('ios')"
              [fileDisplayName]="fileDisplayName"
              [allowedExtensions]="params.allowedExtensions"
              [error]="error"
              (onFile)="addFile($event)"
  ></app-input-file>
</div>
<div *ngIf="type == 'show' || manager.mode === 'review'"
     class="openLink d-flex justify-content-around">
  <a (click)="getResourceUrl()" class="link-cursor">{{fileDisplayName}}</a>
  <!--<button *ngIf="isPdf()"
     (click)="downloadRessource()"
     class="downloadBtn">
      <ion-icon name="download"></ion-icon>
  </button>-->
</div>
