import {Injectable} from '@angular/core';
import Quill from 'quill';
import {ImageFormat} from '../../quillPlugins/imageFormat';
import {CustomLink} from '../../quillPlugins/customLink';
import ImageResize from 'quill-image-resize-module';
import ImageOptions from '../../components/quill-image-options/imageOptions';

export const defaultStyleFontWhitelist: string[] = ['roboto', 'arial', 'courier', 'tahoma', 'verdana', 'sans-serif', 'monospace'];
export const defaultStyleSizeWhitelist: Array<string | boolean> = ['12px', '13px', false, '18px', '24px', '30px'];

@Injectable({
  providedIn: 'root'
})
export class QuillPluginsService {

  private registered: boolean = false;

  constructor() {
    if (!this.registered) {
      this.registerPlugins();
      this.registered = true;
    }
  }

  private registerPlugins() {
    // https://github.com/quilljs/quill/issues/1631#issuecomment-320782792
    // https://github.com/quilljs/quill/issues/228
    const Parchment = Quill.import('parchment');
    let Block = Parchment.query('block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);

    Quill.register('modules/ImageOptions', ImageOptions);
    Quill.register(CustomLink);

    const StyleFont = Quill.import('attributors/style/font');
    StyleFont.whitelist = defaultStyleFontWhitelist;
    Quill.register(StyleFont, true);

    const StyleSize = Quill.import('attributors/style/size');
    StyleSize.whitelist = defaultStyleSizeWhitelist;
    Quill.register(StyleSize, true);

    Quill.register(Quill.import('attributors/style/direction'), true);
    Quill.register(Quill.import('attributors/style/align'), true);
    Quill.register(Quill.import('attributors/style/color'), true);
    Quill.register('modules/imageResize', ImageResize);
    Quill.register(ImageFormat, true);
  }

}
