import {Dictionary} from 'lodash';

export default class AMergeable {
  constructor() {
  }

  public initialize<T>(type: { new(json: any): T }, json: any, key: string): void;
  public initialize<T>(type: { new(json: any): T }, json: any): T | T[];
  public initialize<T>(type: { new(json: any): T }, json: any, key?: string) {
    let value = this.create(type, json);
    if (!key) {
      return value;
    }
    if (value) {
      this[key] = value;
    }
  }

  public create<T>(type: { new(json: any): T }, json: any): any {
    if (!json) {
      return json;
    }
    if (Array.isArray(json)) {
      return json.map(val => this.create(type, val));
    }
    return new type(json);
  }

  public createDictionary<T>(type: { new(json: any): T }, json: Dictionary<T>): any {
    let result = {};
    for (let key in json) {
      result[key] = json[key] && new type(json[key]);
    }
    return result;
  }
}
