import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import AFormManager from '../models/a-form-manager';
import Section from '../models/section';
import Activity from '../models/activity';
import { Dictionary } from 'lodash';
import { FormHelperService } from './form-helper.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { EventsService } from './events.service';
import { AlertButton } from '@ionic/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface ISubmittedActivityResponse {
  response: Response;
  manager: AFormManager;
}

@Injectable({
  providedIn: 'root'
})
export class SubmitService {

  private submittedSubject: Subject<ISubmittedActivityResponse> = new Subject<ISubmittedActivityResponse>();

  public get submittedResponse$(): Observable<ISubmittedActivityResponse> {
    return this.submittedSubject.asObservable();
  }

  constructor(private formHelper: FormHelperService,
              private apiService: ApiService,
              private alertCtrl: AlertController,
              private loading: LoadingController,
              private router: Router,
              private events: EventsService) {
  }

  public async submitActivity(manager: AFormManager): Promise<Response> {
    const activity: Activity = manager.getActivity();
    if (activity.status === 'CLOSE') {
      await this.refuseSubmit();
      throw new Error('Cannot submit closed activity');
    }
    const currentSection: Section = manager.getCurrentSection();
    if (!manager.checkCurrentErrors()) {
      throw new Error('Current section validation failed');
    }
    manager.leaveActivity();
    const concernedId: string = (manager.getConcernedUser() || {} as any).id;
    const responsibleUserId: string = manager.responsibleUser?.id;
    const responses: Dictionary<Dictionary<any>> = this.formHelper.getResponses(manager);
    const loader: HTMLIonLoadingElement = await this.loading.create();
    await loader.present();
    let submitResponse: Response;
    let clientId: string;
    if (manager.getRealTimeActivityProvider() && manager.getRealTimeActivityProvider().socketService.socket) {
      clientId = manager.getRealTimeActivityProvider().socketService.socket.id;
    }
    try {
      // throw new Error('throwing error intentionally');
      submitResponse = await this.apiService.respondToActivity(activity.id, responses, concernedId, clientId, responsibleUserId);
      this.submittedSubject.next({response: submitResponse, manager});
      if (!responsibleUserId) {
        this.events.publish('history-activities:refresh', {
          activityId: activity.id,
          concernedUserId: concernedId
        });
      }
    } catch (e) {
      const message: string = e instanceof Response ? (await e.json()).message : e;
      if (e.status === 403) {
        await this.refuseSubmit();
      } else {
        await this.createAlert('Error', message, [{text: 'OK'}]);
      }
      currentSection.select(manager);
      throw e;
    } finally {
      await loader.dismiss();
    }
    return submitResponse;
  }

  private async refuseSubmit(): Promise<void> {
    await this.createAlert('Activity is closed', 'You can\'t re-submit because this activity is closed',
      [
        {
          text: 'OK'
        }
      ]);
  }

  private async createAlert(title: string, msg: string, body: (string | AlertButton)[]): Promise<void> {
    let alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: body
    });
    await alert.present();
  }
}
