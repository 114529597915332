import {Component, Injector, OnDestroy, ViewContainerRef} from '@angular/core';
import {ICartItem} from '../action-shopping/action-shopping.component';
import {ISlotsBag, ISubscribableSlot} from './action-slots.interface';
import {IClass, ICourse} from '../../../../services/file-loader/scssLoad';
import Execution from '../../../../models/execution';
import Condition from '../../../../models/condition';
import {MatRadioButton} from '@angular/material/radio';
import {Option} from '../action-radio/action-radio.component';
import Action from '../../../../models/action';
import {merge, Subject} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {ISocketMessage, RealTimeActivityService} from '../../../../services/real-time-activity.service';
import {takeUntil} from 'rxjs/operators';
import {InputBaseController} from '../../input-controller';
import {DescriptionModalComponent, IDescriptionModalParameters} from '../../../modal/description-modal/description-modal.component';

interface IActionSlotsParams {
  courses: ICourse[];
  slots: ISlotsBag;
  selectedSlot: string;
  linkedActions: string[];
  cartItem: ICartItem;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-slots',
  templateUrl: './action-slots.component.html',
  styleUrls: ['./action-slots.component.scss'],
})
export class ActionSlotsComponent extends InputBaseController implements OnDestroy {

  public static className: string = 'action-slots';
  public static readonly responseKeys: Array<keyof IActionSlotsParams> = ['selectedSlot'];
  public params: IActionSlotsParams;

  options: Option<ISubscribableSlot>[];
  unconfirmedSlotActions: Array<{ message: ISocketMessage, slot: ISubscribableSlot }> = [];
  selectedSlot: ISubscribableSlot;

  allClasses: IClass[];

  private unsubscribe$: Subject<any> = new Subject();

  public get unconfirmedSelectedSlot() {
    if (!this.options) {
      return;
    }
    const actionSelectedSlotParam = this.realTimeActivity.localBuilder.aliases[this.action.alias].params.selectedSlot;
    if (!actionSelectedSlotParam) {
      return;
    }
    const option = this.options.find(opt => opt.value._id === actionSelectedSlotParam.value);
    return option && option.value;
  }

  public constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    public realTimeActivity: RealTimeActivityService,
    public modalCtrl: ModalController,
  ) {
    super(injector, viewContainerRef);

    this.realTimeActivity.activityStateUpdated$.pipe(takeUntil(this.unsubscribe$)).subscribe((update) => {
      this.unconfirmedSlotActions = this.unconfirmedSlotActions.filter(item => item.message.id !== update.id);
    });

    merge(
      this.realTimeActivity.initialActivityStateUpdated$,
      this.realTimeActivity.activityStateReloaded$,
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.unconfirmedSlotActions = [];
    });

    merge(
      this.realTimeActivity.activityStateUpdated$,
      this.realTimeActivity.initialActivityStateUpdated$,
      this.realTimeActivity.activityStateReloaded$,
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.initAction(this.action);
    });
  }

  ngOnDestroy(): void {
    // super.ngOnDestroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    const displayedSelectedSlotId = (this.unconfirmedSelectedSlot && this.unconfirmedSelectedSlot._id) || this.params.selectedSlot;
    this.selectedSlot = this.params.slots.slots.find(slot => slot._id === displayedSelectedSlotId);
    this.options = this.params.slots.slots.map(slot => ({
      keyName: slot.name,
      value: slot,
      selected: this.selectedSlot && this.selectedSlot._id === slot._id,
    }) as Option<ISubscribableSlot>);

    const validatedSelectedSlot = this.params.slots.slots.find(slot => slot._id === this.params.selectedSlot);
    this.params.cartItem = validatedSelectedSlot && {
      name: validatedSelectedSlot.name,
      option: undefined,
      size: undefined,
      qty: 1,
      unitPrice: validatedSelectedSlot.cost,
      description: `$ ${validatedSelectedSlot.cost}`,
    };
    super.updateAction();
  }

  public onRadioClick(event: MouseEvent, option: Option<ISubscribableSlot>, radioButton: MatRadioButton): void {
    event.stopImmediatePropagation();

    // avoid click on slots with unconfirmed actions (to limit spam and bugs)
    // if (this.unconfirmedSlotActions.some(item => item.slot._id === option.value._id)) return;

    // unselect previous selection
    const unconfirmedSelectedSlot = this.unconfirmedSelectedSlot;
    if (unconfirmedSelectedSlot) {
      const previousSlotIndex = this.params.slots.slots.findIndex(o => o._id === unconfirmedSelectedSlot._id);
      const updates = [
        new Execution({
          type: 'add',
          params: {
            path: `'aliases.${this.action.alias}.params.slots.value.slots.${previousSlotIndex}.availableCount'`,
            value: 1,
          },
          conditions: [
            [
              new Condition({
                type: 'equal',
                params: {
                  value: `aliases.${this.action.alias}.params.selectedSlot.value`,
                  comparator: `'${unconfirmedSelectedSlot._id}'`,
                }
              }),
            ]
          ],
        }),
        new Execution({
          type: 'update',
          params: {
            path: `'aliases.${this.action.alias}.params.selectedSlot.value'`,
            value: '\'\'',
          },
        }),
      ];
      const message = this.realTimeActivity.requestUpdates(...updates);
      this.unconfirmedSlotActions.push({message, slot: unconfirmedSelectedSlot});
    }
    this.options.filter(s => s.selected && s.value._id !== option.value._id).forEach(e => e.selected = false);

    // select new one
    option.selected = !option.selected;

    // client side validations (should also run in the server from a json setup)
    const nextSlot = option.selected && option.value;
    if (!nextSlot) {
      return;
    }
    if (this.isDisabledSlot(nextSlot)) {
      return;
    }

    this.selectedSlot = option.selected && option.value;
    if (this.selectedSlot) {
      const slotIndex = this.params.slots.slots.findIndex(o => o._id === option.value._id);
      const updates = [
        new Execution({
          type: 'add',
          params: {
            path: `'aliases.${this.action.alias}.params.slots.value.slots.${slotIndex}.availableCount'`,
            value: -1,
          },
          conditions: [
            [
              new Condition({
                type: 'moreThan',
                params: {
                  value: `aliases.${this.action.alias}.params.slots.value.slots.${slotIndex}.availableCount`,
                  comparator: 0,
                },
              }),
              new Condition({
                type: 'regex',
                params: {
                  value: `aliases.${this.action.alias}.params.selectedSlot.value`,
                  regex: `'^$|^none$'`,
                },
              }),
            ],
          ],
        }),
        new Execution({
          type: 'update',
          params: {
            path: `'aliases.${this.action.alias}.params.selectedSlot.value'`,
            value: `'${this.selectedSlot._id}'`,
          },
        }),
      ];
      const message = this.realTimeActivity.requestUpdates(...updates);
      this.unconfirmedSlotActions.push({message, slot: this.selectedSlot});
    }
  }

  public async onInfosClick(slot: ISubscribableSlot): Promise<void> {
    const descriptionModal: HTMLIonModalElement = await this.modalCtrl.create({
      component: DescriptionModalComponent,
      componentProps: {
        title: slot.name,
        subTitle: slot.location,
        content: slot.description,
      } as IDescriptionModalParameters
    });
    await descriptionModal.present();
  }

  public isUnconfirmed(slot: ISubscribableSlot): boolean {
    return this.unconfirmedSlotActions.some(item => item.slot._id === slot._id);
  }

  public isDisabledSlot(subscribableSlot: ISubscribableSlot): boolean {
    const actionSelectedSlotName = (actionAlias) => {
      const actionParams: any = this.realTimeActivity.localBuilder.aliases[actionAlias].params;
      const selectedSlotId: string = actionParams.selectedSlot && actionParams.selectedSlot.value;
      if (!selectedSlotId) {
        return;
      }
      const selectedSlot: ISubscribableSlot = (actionParams.slots.value as ISlotsBag).slots.find(slot => slot._id === selectedSlotId);
      if (!selectedSlot) {
        return;
      }
      const slotName: string = selectedSlot.name;
      return slotName;
    };

    const sessionOtherSelectedSlotsNames: string[] = this.params.linkedActions
      .filter(alias => alias !== this.action.alias)
      .map(alias => actionSelectedSlotName(alias));

    return sessionOtherSelectedSlotsNames.some(name => name === subscribableSlot.name);
  }

  public trackByIndex(slot: { _id: string }): string {
    return slot._id;
  }
}

