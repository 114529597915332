import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {IInputAddressParams, InputAddressComponent} from '../input-address/input-address.component';
import {IActionUser, IExpandableActionUser} from '../../form/actions/action-users/action-users.component';
import {ErrorStateMatcher} from '@angular/material/core';
import {IGoogleAddress} from '../../../models/user';
import moment from 'moment';
import DynValue from '../../../models/dyn-value';
import {i18n} from '../../../services/i18n';
import Builder from '../../../models/API/builders';
import {InputPhoneComponent} from '../input-phone/input-phone.component';

export function addressesRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const address: IInputAddressParams = control.value;
    return !address.formattedAddress ? {required: true} : null;
  };
}

export function birthdateRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    return !control.value ? {required: true} : null;
  };
}

const nameToRequiredValidatorDict = {
  addresses: addressesRequiredValidator(),
  birthdate: birthdateRequiredValidator(),
  default: Validators.required,
};

const nameToRequiredValidator = (name) => nameToRequiredValidatorDict[name] || nameToRequiredValidatorDict.default;

@Component({
  selector: 'app-input-user',
  templateUrl: './input-user.component.html',
  styleUrls: ['./input-user.component.scss'],
})
export class InputUserComponent implements OnInit {

  @Input() mode: string; // Manager.mode ( view/preview/review)
  @Input() type: string; // input/show
  @Input() disabled: boolean;

  @Input() fields: IFieldsConfig;
  @Input() user: IExpandableActionUser;
  @Input() languages: string[];
  @Output() onChange: EventEmitter<IExpandableActionUser> = new EventEmitter<IExpandableActionUser>();

  @Input() builder: Builder;
  @ViewChild(InputAddressComponent) addressInput: InputAddressComponent;
  @ViewChildren(InputPhoneComponent) phoneInputs: QueryList<InputPhoneComponent>;

  public birthdate: Date;

  public matcher: UserErrorStateMatcher = new UserErrorStateMatcher();

  public form: FormGroup = new FormGroup({
    firstName: new FormControl(),
    middleName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl(undefined, [this.validOrEmptyEmailValidator]),
    language: new FormControl(),
    gender: new FormControl(),
    addresses: new FormControl(),
    birthdate: new FormControl(),
    // phones: new FormControl(),
  });

  public inputAddressParams: IInputAddressParams;


  public genders = [
    'MALE',
    'FEMALE',
    'OTHER'
  ];

  constructor() {
    i18n('ACTIVITY.INPUT.USER.GENDERS.MALE');
    i18n('ACTIVITY.INPUT.USER.GENDERS.FEMALE');
    i18n('ACTIVITY.INPUT.USER.GENDERS.OTHER');
  }

  async ngOnInit() {
    if (this.fields.gender) {
      this.user.gender = this.genders.find(gender => gender === this.user.gender);
    }
    if (this.fields.addresses) {
      if (this.user.addresses.length === 0) {
        this.user.addresses.push({
          formattedAddress: '',
          placeId: '',
          additionalDetails: ''
        });
      }
      this.inputAddressParams = {
        addressType: 'home',
        formattedAddress: this.user.addresses[0].formattedAddress,
        placeId: this.user.addresses[0].placeId,
        additionalDetails: this.user.addresses[0].additionalDetails,
        textSearch: this.user.addresses[0].formattedAddress.split(',')[0],
        map: false,
        rawAddress: [],
        source: '',
        isPhysical: false,
        isHome: false,
        isMailing: false
      };
      this.form.get('addresses').setValue(this.user.addresses[0]);
    }

    if (this.fields.birthdate) {
      if (!this.fields.birthdate.params) {
        this.fields.birthdate.params = {};
      }
      this.fields.birthdate.params.maxDate = this.fields.birthdate.params.maxDate &&
        new Date(new DynValue(this.fields.birthdate.params.maxDate).execute(this.builder));
      this.fields.birthdate.params.minDate = this.fields.birthdate.params.minDate &&
        new Date(new DynValue(this.fields.birthdate.params.minDate).execute(this.builder));

      this.form.get('birthdate').setValue(this.user.birthdate);
    }
    if (this.user.birthdate) {
      let dateArray: number[] = this.user.birthdate.split('-').map(s => parseInt(s, 10));
      this.birthdate = moment().year(dateArray[0]).month(dateArray[1] - 1).date(dateArray[2]).toDate();
    }

    await Promise.resolve();
    Object.keys(this.form.controls).map(controlName => {
      this.form.get(controlName).setValidators([
        ...this.fields[controlName] && this.fields[controlName].required ? [nameToRequiredValidator(controlName)] : [],
        ...this.form.get(controlName).validator ? [this.form.get(controlName).validator] : [],
      ]);
    });

    if (!this.fields.email) {
      this.form.get('email').setValidators([]);
    }

    this.form.updateValueAndValidity();
  }

  // Default Validators.email is invalid when email empty.
  // Now can be empty if not required. And must be correctly formatted.
  // https://stackoverflow.com/questions/43919547/angular-validators-email-invalid-if-empty
  private validOrEmptyEmailValidator(control: AbstractControl): ValidationErrors {
    return (control.value === '') ? null : Validators.email(control);
  }

  public setPhone(value: string, key: string) {
    this.user.phones[key] = value;
    this.userUpdated();
  }

  public setBirthdate(birthdate: Date) {
    this.user.birthdate = moment(birthdate).format('YYYY-MM-DD');
    this.form.get('birthdate').setValue(this.user.birthdate);
    this.userUpdated();
  }

  public setAddress(address: IInputAddressParams) {
    this.user.addresses[0].formattedAddress = address.formattedAddress;
    this.user.addresses[0].placeId = address.placeId;
    this.user.addresses[0].additionalDetails = address.additionalDetails;
    this.form.get('addresses').setValue(address);
    this.userUpdated();
  }

  public userUpdated() {
    this.onChange.emit(this.user);
  }

  public validate(): ValidationErrors {
    Object.keys(this.form.controls).forEach(control => {
      this.form.get(control).markAsTouched();
      this.form.get(control).markAsDirty();
      this.form.get(control).updateValueAndValidity();
    });
    const errors: ValidationErrors = Object.keys(this.form.controls)
      .reduce((acc, control) => {
        acc[control] = this.form.get(control).errors;
        return acc;
      }, {});
    if (this.form.get('addresses').value && !(this.form.get('addresses').value as IGoogleAddress).placeId) {
      const manualAddressErrors = this.addressInput.validate();
      if (Object.keys(manualAddressErrors).map(key => manualAddressErrors[key]).filter(v => v).length > 0) {
        errors.addresses = {};
        Object.assign(errors.addresses, this.addressInput.validate());
      }
    }
    Object.assign(errors, ...this.phoneInputs.map(input => ({[input.placeholder]: input.validate()})));
    return errors;
  }
}

export class UserErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

type phoneFields = 'homePhone' | 'cellPhone' | 'workPhone' | 'otherPhone';
type fieldKeys = Exclude<keyof IActionUser, 'phones'> | phoneFields;

export type IFieldsConfig = {
  [fieldName in fieldKeys]?: IFieldConfig;
};

export interface IFieldConfig {
  required: boolean;
  params: { [key: string]: any };
}
