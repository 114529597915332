<ion-button *ngIf="!(type =='show' && params.preview)"
            shape="round"
            [fill]="params.clicked ? 'solid' : 'outline'"
            class="positive-focus show-disclaimer"
            (click)="openEditor()">
  {{ buttonLabel | bbcodeTranslation: bbcodeTranslator.redraw }}
</ion-button>
<quill-view-html *ngIf="params.text && params.preview"
                 class="quill-preview mat-elevation-z6"
                 [content]="params.text | bbcodeTranslation: bbcodeTranslator.redraw"
                 convert-cordova-app-links
                 (click)="openEditor()">
</quill-view-html>
