import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import Action from '../../../../models/action';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-number',
  templateUrl: './action-number.component.html',
  styleUrls: ['./action-number.component.scss'],
})
export class ActionNumberComponent extends InputBaseController {
  public static className: string = 'action-number';
  public static readonly responseKeys: string[] = ['number'];
  public text: string;

  public params: { number: number };

  protected initAction(action: Action): void {
    super.initAction(action);
    this.text = this.params.number && this.params.number.toString();
  }

  public onChange(text: string): void {
    this.params.number = parseInt(text, 10) || undefined;
    super.onChange();
  }
}
