import { BaseModule } from './BaseModule';

export class Rotate extends BaseModule {
    private clickHandler: EventListenerOrEventListenerObject = this.rotate.bind(this);
    public btn;

    onCreate() {
        this.addButton()
    }

    onDestroy() {
        this.removeButton();
    }

    onUpdate() {
        this.updateBtnPosition();
    }

    removeButton() {
        if (!this.btn) return;
        this.btn.removeEventListener('click', this.clickHandler);
        this.overlay.removeChild(this.btn);
        this.btn = undefined;
    }


    addButton() {
        this.removeButton();
        // create div element for resize handle
        this.btn = document.createElement('div');
        this.updateBtnPosition();
        this.btn.innerHTML = `<svg width="15pt" height="15pt" viewBox="0 0 15 15">
    <g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(68,68,68);fill-opacity:1;" d="M 7.5 2.800781 L 7.5 0 L 3.75 3.5 L 7.5 7 L 7.5 4.199219 C 9.984375 4.199219 12 6.078125 12 8.398438 C 12 10.71875 9.984375 12.601562 7.5 12.601562 C 5.015625 12.601562 3 10.71875 3 8.398438 L 1.5 8.398438 C 1.5 11.492188 4.183594 14 7.5 14 C 10.816406 14 13.5 11.492188 13.5 8.398438 C 13.5 5.304688 10.816406 2.800781 7.5 2.800781 Z M 7.5 2.800781 "/>
    </g>
</svg>`;
        // listen for mousedown on each box
        this.btn.addEventListener('click', this.clickHandler);
        // add drag handle to document
        this.overlay.appendChild(this.btn);
    }

    updateBtnPosition() {
        if (!this.btn) return;
        // Start with the specified styles
        Object.assign(this.btn.style, this.options.rotateBtnStyle);
        this.btn.style.cursor = "pointer";

        // Set the width/height to use 'px'
        this.btn.style.width = `${this.options.rotateBtnStyle.width}px`;
        this.btn.style.height = `${this.options.rotateBtnStyle.height}px`;
        this.btn.style.top = `${(this.img.height - this.options.rotateBtnStyle.height) / 2}px`;
        this.btn.style.left = `${(this.img.width - this.options.rotateBtnStyle.width) / 2}px`;
    }

    rotate() {
        this.removeButton();
        let canvas1 = document.createElement("canvas");
        let image = document.createElement("img");
        image.src = this.img.src;
        let largerSide: number = Math.max(image.width, image.height);
        canvas1.height =largerSide;
        canvas1.width = largerSide;
        let ctx = canvas1.getContext("2d");
        let angleInDegrees=-90;
        ctx.clearRect( 0, 0, canvas1.width, canvas1.height);
        ctx.save();
        ctx.translate(canvas1.width/2, canvas1.height/2);
        ctx.rotate(angleInDegrees*Math.PI/180);
        ctx.drawImage(image,-image.width/2,-image.height/2);
        ctx.restore();

        let canvas2 = document.createElement("canvas");
        // clip in new canvas
        canvas2.height = image.width;
        canvas2.width = image.height;
        let ctx2 = canvas2.getContext("2d");
        ctx2.drawImage(canvas1, (canvas1.height-image.height)/2, (canvas1.width-image.width)/2, canvas2.width, canvas2.height, 0, 0, canvas2.width, canvas2.height);

        this.img.src = canvas2.toDataURL();
        //this.img.height = canvas2.height;
        this.img.width = canvas2.width;
        this.requestUpdate();
        this.addButton();
    }
}
