import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MimeTypeService {

  public extensionTypes: {[key in FileExtension]: string} = {
    js: 'application/javascript',
    json: 'application/json',
    xml: 'application/xml',
    zip: 'application/zip',
    pdf: 'application/pdf',
    sql: 'application/sql',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    odt: 'application/vnd.oasis.opendocument.text',
    zst: 'application/zstd',
    mpeg: 'audio/mpeg',
    ogg: 'audio/ogg',
    css: 'text/css',
    html: 'text/html',
    csv: 'text/csv',
    txt: 'text/plain',
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    gif: 'image/gif'
  };

  public getExtensionsFor(mimetypes: string[]): FileExtension[] {
    return (Object.keys(this.extensionTypes) as FileExtension[])
    .filter(ext => mimetypes.indexOf(this.extensionTypes[ext]));
  }

  public getTypesFor(extensions: FileExtension[]): string[] {
    return extensions.map(ext => this.extensionTypes[ext]).filter(e => e);
  }

  public getType(fileName: string): string {
    return this.extensionTypes[this.getExtension(fileName)];
  }

  public getExtension(fileName: string): FileExtension {
    const splittedName: string[] = fileName.split('.');
    return splittedName[splittedName.length - 1].toLowerCase() as FileExtension;
  }

  public isDocument(url: string): boolean {
    const docExtensions: string[] = ['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xls', 'xlsx', 'csv'];
    return this.isInExtensions(url.toLowerCase(), docExtensions);
  }

  public isInExtensions(fileName: string, extensions: string[]): boolean {
    // remove the dot in extensions
    const cleanedExtensions: string[] = extensions.map(e => e.replace('.', ''));
    return cleanedExtensions.indexOf(this.getExtension(fileName)) >= 0;
  }
}

export type FileExtension = 'js' |
  'json' |
  'xml' |
  'zip' |
  'pdf' |
  'sql' |
  'doc' |
  'docx' |
  'xls' |
  'xlsx' |
  'ppt' |
  'pptx' |
  'odt' |
  'zst' |
  'mpeg' |
  'ogg' |
  'css' |
  'html' |
  'csv' |
  'txt' |
  'png' |
  'jpg' |
  'jpeg' |
  'gif';
