import {Injectable, OnDestroy} from '@angular/core';
import {CordovaIFrameNavigator, CordovaPopupNavigator, User, UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client';
import _ from 'lodash';
import {first, takeUntil} from 'rxjs/operators';
import {CognitoAuthService} from './cognito-auth.service';
import {ApiUrlService} from './api-url.service';
import {Platform} from '@ionic/angular';
import {EventsService} from './events.service';
import {HttpClient} from '@angular/common/http';
import {ReplaySubject, Subject} from 'rxjs';
import {Httpd} from '@ionic-native/httpd/ngx';
import {environment} from '../../environments/environment';

declare var cordova: any;

export const GG4LApiBaseUrl: string = environment.GG4L_SSO.GG4L_API_BASE_URL;
export const GG4LTokenApiBaseUrl: string = environment.GG4L_SSO.GG4L_TOKEN_API_BASE_URL;
export const GG4LWebsiteBaseUrl: string = environment.GG4L_SSO.GG4L_WEBSITE_BASE_URL;
export const GG4LTokenIssuer: string = environment.GG4L_SSO.GG4L_TOKEN_ISSUER;

// https://github.com/IdentityModel/oidc-client-js/wiki
let settings: UserManagerSettings = {
  authority: `${GG4LTokenApiBaseUrl}/oauth`,
  client_id: 'PTRDYAHSNX',
  redirect_uri: `${window.location.origin}/#/home/apps/gg4l`,
  post_logout_redirect_uri: `${window.location.origin}`,
  response_type: 'code',
  scope: 'user.profile openid',
  // userInfoJwtIssuer: 'oauth.edutone.com',

  silent_redirect_uri: `${window.location.origin}/gg4l-sso-silent-renew.html`,
  automaticSilentRenew: true,

  loadUserInfo: true,
  metadata: {
    issuer: GG4LTokenIssuer,
    authorization_endpoint: `${GG4LWebsiteBaseUrl}/oauth/auth`,
    token_endpoint: `${GG4LTokenApiBaseUrl}/oauth/token`,
    scopes_supported: ['user.profile'],
    response_types_supported: ['code', 'token'],
    userinfo_endpoint: `${GG4LTokenApiBaseUrl}/services/v1.4/users/me`,
  },
  extraQueryParams: {
    orgGuid: 'Actionaly',
  },

  userStore: new WebStorageStateStore({store: window.localStorage}),
};

const UserManagerFactory = (settings: UserManagerSettings) => {
  const userManager = new UserManager(settings);

  // Override some checks done by oidc-client
  // Look elsewhere

  // GG4L invalid audience in id_token
  (userManager as any)._joseUtil.originalValidateJwtAttributes = (userManager as any)._joseUtil.validateJwtAttributes;
  (userManager as any)._joseUtil.validateJwtAttributes = (jwt, issuer, audience, clockSkew, now, timeInsensitive) => {
    const dummyAudience = (userManager as any)._joseUtil.parseJwt(jwt).payload.aud;
    return (userManager as any)._joseUtil.originalValidateJwtAttributes(jwt, issuer, dummyAudience, clockSkew, now, timeInsensitive);
  };

  // GG4L invalid sub in id_token (does not match access_token sub)
  // same function as implemented here https://github.com/IdentityModel/oidc-client-js/blob/dev/src/ResponseValidator.js
  (userManager as any)._validator.__proto__.original_processClaims = (userManager as any)._validator.__proto__._processClaims;
  (userManager as any)._validator.__proto__._processClaims = function(state, response) {
    if (response.isOpenIdConnect) {
      response.profile = this._filterProtocolClaims(response.profile);
      if (state.skipUserInfo !== true && this._settings.loadUserInfo && response.access_token) {
        return this._userInfoService.getClaims(response.access_token).then(claims => {
          response.profile = this._mergeClaims(response.profile, claims);
          return response;
        });
      }
    }
    return Promise.resolve(response);
  };
  return userManager;
};

// to be used by silent callback page
_.set(window, 'gg4lSSO.settings', settings);
_.set(window, 'gg4lSSO.UserManagerFactory', UserManagerFactory);

@Injectable({
  providedIn: 'root'
})
export class GG4LAuthService implements OnDestroy {

  private userManagerSubject: ReplaySubject<UserManager> = new ReplaySubject(1);

  public get userManager$() {
    return this.userManagerSubject.asObservable();
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    private events: EventsService,
    private plt: Platform,
    private httpd: Httpd,
    private cognitoAuth: CognitoAuthService,
    private apiUrlService: ApiUrlService,
  ) {
    this.init();
    this.events.subscribe('gg4l:auth:invalid', () => this.invalidateNextSession());
    this.cognitoAuth.cognitoLogout$.subscribe(() => this.invalidateNextSession());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public async init() {
    if (this.plt.is('hybrid')) {
      await this.plt.ready();
      // fix @ionic-native/httpd mapping failure
      if (typeof cordova !== 'undefined') {
        cordova.plugins.CorHttpd.getUrl = cordova.plugins.CorHttpd.getURL;
      }
      let url: string = await this.httpd.getUrl();
      if (!url) {
        url = await this.httpd.startServer({localhost_only: true}).pipe(takeUntil(this.destroy$)).pipe(first()).toPromise();
      }
      settings = Object.assign(settings, {
        popupNavigator: new CordovaPopupNavigator(),
        popupWindowFeatures: 'zoom=no',
        iframeNavigator: new CordovaIFrameNavigator(),
        popup_redirect_uri: `${url}/#/home/login`,
      } as UserManagerSettings);
    }
    const userManager = UserManagerFactory(settings);
    this.userManagerSubject.next(userManager);
  }

  public async signin(): Promise<User> {
    try {
      const userManager = await this.userManager$.pipe(first()).toPromise();
      if (this.plt.is('hybrid')) {
        return await userManager.signinPopup();
      } else {
        await userManager.signinRedirect();
      }
    } catch (e) {
      console.log('signin error', e);
    }
  }

  public async getUserFromRedirectCallBackOrLocal(): Promise<User> {
    let user: User;
    const userManager = await this.userManager$.pipe(first()).toPromise();
    try {
      if (this.plt.is('hybrid')) {
        user = await userManager.signinPopupCallback();
      } else {
        user = await userManager.signinRedirectCallback();
      }
    } catch (e) {
      if (e.message === 'No state in response') {
        user = await userManager.getUser();
        if (!user) {
          throw new Error('cannot getUser');
        }
      } else {
        throw e;
      }
    }
    return user;
  }

  public async refreshTokens(user: User): Promise<User> {
    const id_token = user.id_token;
    try {
      const userManager = await this.userManager$.pipe(first()).toPromise();
      const newUser = await userManager.signinSilent();
      if (!newUser.id_token) {
        newUser.id_token = id_token;
        await userManager.storeUser(newUser);
      }
      await userManager.clearStaleState();
      return newUser;
    } catch (e) {
      console.dir(e);
      throw (e);
    }
  }

  public async refreshTokenIfExpired(): Promise<User> {
    const userManager: UserManager = await this.userManager$.pipe(first()).toPromise();
    let user: User = await userManager.getUser();
    if (!user) {
      return;
    }
    if (user.expired) {
      user = await this.refreshTokens(user);
    }
    return user;
  }

  public async getActionalyToken(idToken: string): Promise<string> {
    const response = await this.http.get(
      `${this.apiUrlService.apiUrl}gg4l/one-time-token`,
      {
        headers: {Authorization: `Bearer ${idToken}`},
        responseType: 'text',
      },
    ).toPromise();
    return response;
  }

  private async invalidateNextSession() {
    const userManager = await this.userManager$.pipe(first()).toPromise();
    await userManager.removeUser();
    settings.extraQueryParams['invalidate'] = true;
  }
}
