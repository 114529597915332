import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Platform } from '@ionic/angular';
import { BbcodeTranslatorService } from '../../../services/bbcode-translator.service';
import { isMobile } from 'src/utils';

@Component({
  selector: 'app-html-modal',
  templateUrl: './html-modal.component.html',
  styleUrls: ['./html-modal.component.scss'],
})
export class HtmlModalComponent {
  public content: string;
  public type: string;
  public title: string;
  public mode: string;
  public isMobile: boolean;

  constructor(
    private matDialogRef: MatDialogRef<HtmlModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: {
      content: string;
      mode: string;
      type: string;
      title: string;
    },
    public plt: Platform,
    public bbcodeTranslator: BbcodeTranslatorService,
  ) {
    this.content = data.content;
    this.mode = data.mode;
    this.type = data.type;
    this.title = data.title ?? '';
    this.isMobile = isMobile(this.plt);
  }

  public close(): void {
    this.matDialogRef.close();
  }
}
