import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextInputComponent } from './text-input/text-input.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from './button/button.component';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from './text/text.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { ActivityHeaderInputComponent } from './activity-header-input/activity-header-input.component';
import { AmountInputComponent } from './amount-input/amount-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { IMaskModule } from 'angular-imask';
import { PipesModule } from '../../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { QuillModule } from 'ngx-quill';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectComponent } from './select/select.component';
import { MatRadioModule } from '@angular/material/radio';
import { RadioComponent } from './radio/radio.component';
import { ActivityHeaderComponent } from './activity-header/activity-header.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DatePickerModule } from '../../../components/date-time/date-picker/date-picker.module';
import { FileDisplayComponent } from './file-display/file-display.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DateTimeInputComponent } from './date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from './date-time-range-input/date-time-range-input.component';
import { TimePickerModule } from '../../../components/date-time/time-picker/timepicker.module';
import { TimeZoneV2SelectModule } from '../../../components/activity-v2/time-zone-v2-select/time-zone-v2-select.module';
import { RangeComponent } from './range/range.component';
import { MatSliderModule } from '@angular/material/slider';
import { DateTimeRangeDisplayComponent } from './date-time-range-display/date-time-range-display.component';
import { DescriptionInputComponent } from './description-input/description-input.component';
import { DescriptionDisplayComponent } from './description-display/description-display.component';
import { UnlayerNewsletterComponent } from './unlayer-newsletter/unlayer-newsletter.component';
import { StripeComponent } from './stripe/stripe.component';
import { StripeFeesComponent } from './stripe-fees/stripe-fees.component';
import { AddressDisplayComponent } from './address-display/address-display.component';
import { GoogleApiService } from '../../../services/google-api.service';
import { AddressInputComponent } from './address-input/address-input.component';
import { PayTheoryComponent } from './pay-theory/pay-theory.component';
import { MatButtonModule } from '@angular/material/button';
import { AutoCompleteModule } from '../../../components/ionic2-auto-complete';
import { TextAreaInputComponent } from './text-area-input/text-area-input.component';

@NgModule({
  declarations: [
    TextInputComponent,
    TextAreaInputComponent,
    TextComponent,
    ButtonComponent,
    NumberInputComponent,
    PasswordInputComponent,
    AmountInputComponent,
    PhoneInputComponent,
    ActivityHeaderInputComponent,
    DisclaimerComponent,
    ActivityHeaderComponent,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    FileUploadComponent,
    FileDisplayComponent,
    DateInputComponent,
    RangeComponent,
    DateTimeInputComponent,
    DateTimeRangeInputComponent,
    DateTimeRangeDisplayComponent,
    DescriptionInputComponent,
    DescriptionDisplayComponent,
    UnlayerNewsletterComponent,
    StripeComponent,
    StripeFeesComponent,
    AddressDisplayComponent,
    AddressInputComponent,
    PayTheoryComponent,
  ],
  exports: [
    TextInputComponent,
    TextAreaInputComponent,
    TextComponent,
    ButtonComponent,
    NumberInputComponent,
    PasswordInputComponent,
    AmountInputComponent,
    PhoneInputComponent,
    ActivityHeaderInputComponent,
    DisclaimerComponent,
    ActivityHeaderComponent,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    FileUploadComponent,
    FileDisplayComponent,
    DateInputComponent,
    RangeComponent,
    DateTimeInputComponent,
    DateTimeRangeInputComponent,
    DateTimeRangeDisplayComponent,
    DescriptionInputComponent,
    DescriptionDisplayComponent,
    UnlayerNewsletterComponent,
    StripeComponent,
    StripeFeesComponent,
    AddressDisplayComponent,
    AddressInputComponent,
    PayTheoryComponent,
  ],
  providers: [
    GoogleApiService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    IonicModule,
    IMaskModule,
    PipesModule,
    TranslateModule.forChild(),
    QuillModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxSpinnerModule,
    DatePickerModule,
    TimePickerModule,
    MatSliderModule,
    TimeZoneV2SelectModule,
    MatButtonModule,
    AutoCompleteModule,
  ]

})
export class FieldsModule { }
