import { Injectable, Optional, Injector, SkipSelf, Inject, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MAT_DIALOG_SCROLL_STRATEGY, MatDialogConfig } from '@angular/material/dialog';
import { Overlay, OverlayContainer } from '@angular/cdk/overlay';

// inspired from
// https://github.com/reppners/ngx-cdk-dynamic-overlay-container/blob/master/src/app/dynamic-overlay-container/dynamic-dialog.ts

@Injectable()
export class DynamicMatDialogService extends MatDialog {

  constructor(
    _overlay: Overlay,
    _injector: Injector,
    @Optional() location: Location,
    @Inject(MAT_DIALOG_SCROLL_STRATEGY) _scrollStrategy: any,
    @Optional() @SkipSelf() _parentDialog: DynamicMatDialogService,
    @Optional() defaultOptions: MatDialogConfig,
    overlayContainer: OverlayContainer,
  ) {
    super(_overlay, _injector, location, defaultOptions, _scrollStrategy, _parentDialog, overlayContainer);
  }
}
