<div class="html-container d-flex justify-content-around align-items-center">
  <div class="show-html"
       [innerHTML]="params.text | bbcodeTranslation: bbcodeTranslator.redraw | sanitize"
       convert-cordova-app-links></div>
  <div class="html-overlay d-flex justify-content-around align-items-center" (click)="openLink($event)">
    <ion-button shape="round">
      {{ "Show newsletter" | translate | uppercase }}
    </ion-button>
  </div>
</div>
