import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from './api-url.service';
import { Auth } from '@aws-amplify/auth';
import { IJsonAuthenticatedUser } from '../models/user';
import { OneTimeTokenService } from './one-time-token.service';
import { IOneTimeTokenPayload } from './one-time-token.interface';
import { Router } from '@angular/router';
import { IDeprecatedPasswordPageParams } from '../pages/deprecated-password/deprecated-password.params';
import { TranslateService } from '@ngx-translate/core';

interface ILegacyLoginResult {
  user?: IJsonAuthenticatedUser;
  error?: any;
  oneTimeToken?: string;
}

interface ILegacyTokenResult {
  oneTimeToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthMigrationService {

  constructor(
    public http: HttpClient,
    private router: Router,
    public apiUrlService: ApiUrlService,
    public translate: TranslateService,
    private oneTimeTokenService: OneTimeTokenService,
  ) {
  }

  public async login(username: string, password: string): Promise<ILegacyLoginResult> {
    return await this.http.post(
      `${this.apiUrlService.apiUrl}legacy-auth/sign-in`,
      { username, password },
    ).toPromise();
  }

  public async signInWithLegacyFallBack(username: string, password: string): Promise<any> {
    let cognitoUser: any;
    try {
      cognitoUser = await Auth.signIn(username, password);
    } catch (cognitoError) {
      if (cognitoError.code === 'NotAuthorizedException') {
        let res: ILegacyLoginResult;
        try {
          res = await this.login(username, password);
        } catch (nestedError) {
          console.debug('nestedError', nestedError);
          throw cognitoError;
        }
        if (res.error && res.oneTimeToken) {
          let lang: string = this.oneTimeTokenService.decodePayload(res.oneTimeToken).lang;
          if (lang) this.translate.use(lang);
          await this.redirectToUpdatePassword(res.oneTimeToken);
          throw res.error;
        } else {
          cognitoUser = await Auth.signIn(username, password);
        }
      } else {
        throw cognitoError;
      }
    }
    return cognitoUser;
  }

  private async redirectToUpdatePassword(oneTimeToken: string): Promise<boolean> {
    return await this.router.navigate(
      ['deprecated-password'],
      { state: { oneTimeToken } as IDeprecatedPasswordPageParams },
    );
  }

  public async updatePassword(password: string, oneTimeToken: string) {
    return await this.http.post(
      `${this.apiUrlService.apiUrl}legacy-auth/update-password`,
      { password },
      {
        headers: { Authorization: `Bearer ${oneTimeToken}` },
      },
    ).toPromise();
  }

  public async getOneTimeTokenFromLegacyToken(legacyToken: string): Promise<ILegacyTokenResult> {
    return (await this.http.get(
      `${this.apiUrlService.apiUrl}legacy-auth/exchange-token`,
      {
        headers: { Authorization: `${legacyToken}` },
      },
    ).toPromise()) as ILegacyTokenResult;
  }

  public async exchangeToken(
    legacyToken: string,
    logoutWithMessage: (message: string) => Promise<void>,
  ): Promise<string> {
    try {
      return (await this.getOneTimeTokenFromLegacyToken(legacyToken)).oneTimeToken;
    } catch (e) {
      console.debug('tokenLoginWithLegacyFallback error', e);
      if (e.error.message === 'User not found') {
        await logoutWithMessage('Expired link. Please login with your credentials instead.');
      }
      throw e;
    }
  }

  public async tokenLoginWithLegacyFallback(
    token: string,
    tokenLogin: (token: string) => Promise<void>,
    logoutWithMessage: (message: string) => Promise<void>,
  ): Promise<void> {
    let oneTimeToken: string = token;
    try {
      const tokenPayload: IOneTimeTokenPayload = this.oneTimeTokenService.decodePayload(token);
    } catch (e) {
      if ((e.message as string).includes('Invalid token specified')) {
        // token is a legacy or invalid token
        console.debug('got legacy token', token);
        oneTimeToken = await this.exchangeToken(token, logoutWithMessage);
        console.debug('replaced legacy token by new token');
      } else {
        throw e;
      }
    }
    return await tokenLogin(oneTimeToken);
  }
}
