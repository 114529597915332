<div class="checkboxes-container">
  <div class="checkbox-container" *ngFor="let option of params.options">
      <mat-checkbox
        [checked]="option.checked"
        (change)="onChange($event, option)"
        [disabled]="type == 'show' || manager.mode === 'review'">
        {{ option.keyName | bbcodeTranslation: bbcodeTranslator.redraw }}
    </mat-checkbox>
    <div class="spacer"></div>
  </div>
</div>
