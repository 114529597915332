<date-picker
  [disabled]="type == 'show' || manager.mode === 'review'"
  [placeholder]="placeholder"
  [displayFormat]="params.format || 'YYYY-MM-DD'"
  [(date)]="pickerDate"
  [minDate]="params.minDate"
  [maxDate]="params.maxDate"
  (onClose)="onChange()"
>
</date-picker>
