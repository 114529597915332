<date-picker
  [disabled]="type == 'show' || manager.mode === 'review'"
  [placeholder]="placeholder"
  [displayFormat]="params.format"
  [(date)]="pickerDate"
  [minDate]="minDate"
  [maxDate]="maxDate"
  (onClose)="onChange()"
>
</date-picker>
