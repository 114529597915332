<ion-header>
  <ion-toolbar>
    <!-- <ion-buttons end>
        <button ion-button color="primary" (click)="dismiss()">
            {{ "close" | translate }}
        </button>
    </ion-buttons> -->
    <ion-title
    >{{ "COMPONENTS.DESCRIPTION_MODAL.TITLE" | translate }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <div
    class="h-100 d-flex flex-column justify-content-between gap-3"
    >
    <div
      class="h-100 flex-grow-1 d-flex flex-column justify-content-center align-items-center gap-3"
      >
      <h1>{{infos.title}}</h1>
      <h2 *ngIf="infos.subTitle">{{infos.subTitle}}</h2>
      <div text-justify [innerHTML]="infos.content"></div>
    </div>
    <ion-button
      margin-bottom
      fill="outline"
      shape="round"
      class="positive-focus align-self-center"
      (click)="dismiss()"
    >
      {{ "close" | translate }}
    </ion-button>
  </div>
</ion-content>
