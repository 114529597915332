import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { TimeZoneV2SelectComponent } from './time-zone-v2-select.component';



@NgModule({
  declarations: [TimeZoneV2SelectComponent],
  imports: [
    CommonModule,
    IonicModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
  ],
  exports: [
    TimeZoneV2SelectComponent
  ]
})
export class TimeZoneV2SelectModule { }
