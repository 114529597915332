<div *ngIf="display"
     (pullDown)="goToNext()"
     (pullUp)="goToPrev()"
     class="form-section"
     [ngClass]="{'disabled' : !selected, 'overflow-hidden' : !selected }"
     cdkScrollable>
  <div class="static-form-section section-content">
    <label-section *ngIf="section.label" [label]="section.label"></label-section>
    <action-container *ngFor="let tuple of actionTuples; trackBy: trackByIndex"
                      [action]="tuple.action"
                      [index]="tuple.index"
                      [sectionCtrl]="this"
                      [manager]="manager">
    </action-container>
  </div>
  <button-arrow (next)="goToNext()" (prev)="goToPrev()"></button-arrow>
</div>
