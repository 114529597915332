import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import Action from '../../../../models/action';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-date-month',
  templateUrl: './action-date-month.component.html',
  styleUrls: ['./action-date-month.component.scss'],
})
export class ActionDateMonthComponent extends InputBaseController {
  public static className: string = 'action-date-month';
  public static readonly responseKeys: string[] = ['selectedDate'];
  public params: {
    selectedDate: string,
    minDate: Date,
    maxDate: Date
  };
  public pickerDate: Date;

  protected initAction(action: Action): void {
    super.initAction(action);
    if (this.params.selectedDate) {
      let serverDate: Date = new Date(this.params.selectedDate);
      this.pickerDate = new Date(serverDate.getTime() + serverDate.getTimezoneOffset() * 60000);
    }
    this.params.minDate = this.params.minDate ? new Date(this.params.minDate) : undefined;
    this.params.maxDate = this.params.maxDate ? new Date(this.params.maxDate) : undefined;
  }

  public onChange(): any {
    let localDate: Date = this.pickerDate;
    let serverDate: Date = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    this.params.selectedDate = serverDate.toISOString().slice(0, 7);
    super.onChange();
  }
}
