import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';

import { TimePickerModalComponent } from './time-picker-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgbTimepickerModule,
  ],
  declarations: [TimePickerModalComponent],
  exports: [TimePickerModalComponent]
})
export class TimePickerModalComponentModule { }
