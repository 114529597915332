<ng-container *ngIf="disabled">
  <div>
    <p *ngIf="fields?.firstName">{{ "ACTIVITY.INPUT.USER.FIRSTNAME" | translate }}: {{user.firstName}}</p>
    <p *ngIf="fields?.middleName">{{ "ACTIVITY.INPUT.USER.MIDDLENAME" | translate }}: {{user.middleName}}</p>
    <p *ngIf="fields?.lastName">{{ "ACTIVITY.INPUT.USER.LASTNAME" | translate }}: {{user.lastName}}</p>
    <p *ngIf="fields?.email">{{ "ACTIVITY.INPUT.USER.EMAIL" | translate }}: {{user.email}}</p>
    <p *ngIf="fields?.birthdate">{{ "ACTIVITY.INPUT.USER.BIRTHDATE" | translate }}: {{user.birthdate}}</p>
    <p *ngIf="fields?.gender">{{ "ACTIVITY.INPUT.USER.GENDER" | translate }}: {{user.gender}}</p>
    <p *ngIf="fields?.cellPhone">{{ "ACTIVITY.INPUT.USER.PHONE.CELL" | translate }}: {{user.phones.cell}}</p>
    <p *ngIf="fields?.homePhone">{{ "ACTIVITY.INPUT.USER.PHONE.HOME" | translate }}: {{user.phones.home}}</p>
    <p *ngIf="fields?.workPhone">{{ "ACTIVITY.INPUT.USER.PHONE.WORK" | translate }}: {{user.phones.work}}</p>
    <p *ngIf="fields?.otherPhone">{{ "ACTIVITY.INPUT.USER.PHONE.OTHER" | translate }}: {{user.phones.other}}</p>

    <ng-container *ngIf="user.addresses && user.addresses.length">
      <ion-label>{{ "ACTIVITY.INPUT.USER.ADDRESS" | translate }}:</ion-label>
      <app-input-address [address]="user.addresses[0]"
                         [params]="inputAddressParams"
                         [mode]="mode"
                         [type]="type"
                         placeholder='{{"ACTIVITY.INPUT.USER.ADDRESS" | translate}}'
                         (onChange)="setAddress($event)"
      ></app-input-address>
    </ng-container>
    <p *ngIf="fields?.language">{{ "ACTIVITY.INPUT.USER.LANGUAGE" | translate }}: {{user.language}}</p>
  </div>
</ng-container>
<div class="user-inputs" *ngIf="!disabled">
  <mat-form-field *ngIf="fields?.firstName">
    <input type="text" matInput
           placeholder='{{"ACTIVITY.INPUT.USER.FIRSTNAME" | translate}}'
           [(ngModel)]="user.firstName"
           (ngModelChange)="userUpdated()"
           [formControl]="form.get('firstName')"
           [errorStateMatcher]="matcher"
    >
    <mat-error *ngIf="form.get('firstName').hasError('required')">
      <strong>
        {{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}
      </strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="fields?.middleName">
    <input type="text" matInput
           placeholder='{{"ACTIVITY.INPUT.USER.MIDDLENAME" | translate}}'
           [(ngModel)]="user.middleName"
           (ngModelChange)="userUpdated()"
           [formControl]="form.get('middleName')"
           [errorStateMatcher]="matcher"
    >
    <mat-error *ngIf="form.get('middleName').hasError('required')">
      <strong>
        {{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}
      </strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="fields?.lastName">
    <input type="text" matInput
           placeholder='{{"ACTIVITY.INPUT.USER.LASTNAME" | translate}}'
           [(ngModel)]="user.lastName"
           (ngModelChange)="userUpdated()"
           [formControl]="form.get('lastName')"
           [errorStateMatcher]="matcher"
    >
    <mat-error *ngIf="form.get('lastName').hasError('required')">
      <strong>
        {{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}
      </strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="fields?.email">
    <input type="text" matInput
           placeholder='{{"ACTIVITY.INPUT.USER.EMAIL" | translate}}'
           [(ngModel)]="user.email"
           (ngModelChange)="userUpdated()"
           [formControl]="form.get('email')"
           [errorStateMatcher]="matcher"
    >
    <mat-error *ngIf="form.get('email').hasError('required') || form.get('email').hasError('email')">
      <strong *ngIf="form.get('email').hasError('required'); else elseBlock">
        {{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}
      </strong>
      <ng-template #elseBlock>
        <strong *ngIf="form.get('email').hasError('email')">
          {{ "ACTIVITY.INPUT.USER.INVALID_FORMAT" | translate }}
        </strong>
      </ng-template>
    </mat-error>
  </mat-form-field>

  <div class="date">
    <date-picker *ngIf="fields?.birthdate"
                 [disabled]="disabled"
                 placeholder='{{"ACTIVITY.INPUT.USER.BIRTHDATE" | translate}}'
                 [displayFormat]="'YYYY-MM-DD'"
                 [date]="birthdate"
                 [minDate]="fields.birthdate.params.minDate"
                 [maxDate]="fields.birthdate.params.maxDate"
                 (dateChange)="setBirthdate($event)"
    >
    </date-picker>
  </div>
  <p class="activity-action-error-message field-error"
     *ngIf="form.get('birthdate').hasError('required')">{{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}</p>

  <ion-item *ngIf="fields?.gender" no-padding>
    <ion-label>{{ "ACTIVITY.INPUT.USER.GENDER" | translate }}:</ion-label>
    <ion-select
      placeholder=""
      [(ngModel)]="user.gender"
      (ngModelChange)="userUpdated()"
      [formControl]="form.get('gender')"
    >
      <ion-select-option
        *ngFor="let gender of genders"
        [value]="gender">
        {{ "ACTIVITY.INPUT.USER.GENDERS." + gender | translate }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <p class="activity-action-error-message field-error"
     *ngIf="form.get('gender').hasError('required')">{{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}</p>

  <div class="phones">
    <app-input-phone *ngIf="fields?.cellPhone"
                     [inputContent]="user.phones.cell"
                     placeholder='{{"ACTIVITY.INPUT.USER.PHONE.CELL" | translate}}'
                     (onChange)="setPhone($event, 'cell')"
                     [required]="fields.cellPhone?.required"
    ></app-input-phone>

    <app-input-phone *ngIf="fields?.homePhone"
                     [inputContent]="user.phones.home"
                     placeholder='{{"ACTIVITY.INPUT.USER.PHONE.HOME" | translate}}'
                     (onChange)="setPhone($event, 'home')"
                     [required]="fields.homePhone?.required"
    ></app-input-phone>

    <app-input-phone *ngIf="fields?.workPhone"
                     [inputContent]="user.phones.work"
                     placeholder='{{"ACTIVITY.INPUT.USER.PHONE.WORK" | translate}}'
                     (onChange)="setPhone($event, 'work')"
                     [required]="fields.workPhone?.required"
    ></app-input-phone>

    <app-input-phone *ngIf="fields?.otherPhone"
                     [inputContent]="user.phones.other"
                     placeholder='{{"ACTIVITY.INPUT.USER.PHONE.OTHER" | translate}}'
                     (onChange)="setPhone($event, 'other')"
                     [required]="fields.otherPhone?.required"
    ></app-input-phone>
  </div>

  <ng-container *ngIf="fields?.addresses">
    <ion-label>{{"ACTIVITY.INPUT.USER.ADDRESS" | translate}} :</ion-label>
    <app-input-address [address]="user.addresses[0]"
                       [params]="inputAddressParams"
                       [mode]="mode"
                       [type]="type"
                       placeholder='{{"ACTIVITY.INPUT.USER.ADDRESS" | translate}}'
                       (onChange)="setAddress($event)"
    ></app-input-address>
    <p class="activity-action-error-message field-error"
       *ngIf="form.get('addresses').hasError('required')">{{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}</p>
  </ng-container>

  <ion-item *ngIf="fields?.language" no-padding>
    <ion-label>{{ "ACTIVITY.INPUT.USER.LANGUAGE" | translate }}:</ion-label>
    <ion-select
      placeholder=""
      [(ngModel)]="user.language"
      (ngModelChange)="userUpdated()"
      [formControl]="form.get('language')"
    >
      <ion-select-option
        *ngFor="let language of languages"
        [value]="language">
        {{ language | titlecase }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <p class="activity-action-error-message field-error"
     *ngIf="form.get('language').hasError('required')">{{ "ACTIVITY.INPUT.USER.REQUIRED" | translate }}</p>
</div>
