import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  Input, OnChanges,
  OnInit,
  ReflectiveInjector,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {InputBaseController} from '../input-controller';
import AFormManager from '../../../models/a-form-manager';
import Action from '../../../models/action';
import {SectionContainerComponent} from '../section-container/section-container.component';
import { BbcodeTranslatorService } from '../../../services/bbcode-translator.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-container',
  templateUrl: './action-container.component.html',
  styleUrls: ['./action-container.component.scss'],
})
export class ActionContainerComponent implements OnInit, OnChanges {
  @Input() public index: number;
  public nativElem: HTMLElement;
  public inputCtrl: InputBaseController;
  @Input() public sectionCtrl: SectionContainerComponent;
  @Input() public manager: AFormManager;
  @Input() action: Action;

  constructor(
    public viewContainerRef: ViewContainerRef,
    public element: ElementRef,
    public resolver: ComponentFactoryResolver,
    private injector: Injector,
    public bbcodeTranslatorService: BbcodeTranslatorService,
  ) {
  }

  public ngOnInit(): void {
    let factory: ComponentFactory<InputBaseController> = this.resolver.resolveComponentFactory(
      InputBaseController.actionTypes[this.action.name]
    );
    this.inputCtrl = this.viewContainerRef.createComponent(factory, null, this.getInjector({
      action: this.action,
      index: this.index,
      manager: this.manager,
      sectionCtrl: this.sectionCtrl
    }, this.viewContainerRef)).instance;
    let classList: DOMTokenList = this.inputCtrl.nativElem.classList;
    this.action.styles.forEach(cssClass => classList.add(cssClass));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    try {
      if (!this.inputCtrl) {
        return;
      }
      const updatedInputs: any = {};
      updatedInputs.action = changes.action !== undefined ? changes.action.currentValue : undefined;
      updatedInputs.manager = changes.manager !== undefined ? changes.manager.currentValue : undefined;
      updatedInputs.sectionCtrl = changes.sectionCtrl !== undefined ? changes.sectionCtrl.currentValue : undefined;
      updatedInputs.index = changes.index !== undefined ? changes.index.currentValue : undefined;
      this.inputCtrl.updateInputs(updatedInputs);
    } catch (e) {
      console.log(e);
    }
  }

  public getInjector(providers: { [key: string]: any }, container: ViewContainerRef): ReflectiveInjector {
    try {
      let inputs = Object.keys(providers).map(key => {
        return {provide: key, useValue: providers[key]};
      });
      let resolvedInputs = ReflectiveInjector.resolve(inputs);
      return ReflectiveInjector.fromResolvedProviders(resolvedInputs, container.parentInjector);
    } catch (e) {
      console.log(e);
    }
  }

  public insertAction(element: ElementRef): void {
    try {
      let parent = element.nativeElement as HTMLElement;
      parent.insertBefore(this.inputCtrl.nativElem, parent.lastChild);
    } catch (e) {
      console.log(e);
    }
  }

  public getError(): string {
    return this.action.error;
  }

  public isDisabled(): boolean {
    return this.action.isDisabled(this.manager);
  }
}
