import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[focus-on-init]'
})
export class FocusOnInitDirective implements AfterViewInit {
  @Input() focusOnInitEnabled: boolean;
  @Input() focusQuerySelector: string;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {

  }

  ngAfterViewInit(): void {
    // we need to delay our call in order to work with ionic ...
    if (!this.focusOnInitEnabled) return;
    setTimeout(() => {
      const element: HTMLElement = this.elementRef.nativeElement.querySelector(this.focusQuerySelector || 'input');
      this.renderer.selectRootElement(element, true).focus();
    }, 200);
  }

}
