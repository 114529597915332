import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NativeAppLinksSanitizerService {

  constructor() {
  }

  public sanitizeInAppLinks(div: HTMLDivElement): void {
    let links: NodeListOf<Element> = div.querySelectorAll('a[href]');
    for (let i: number = 0; i < links.length; i++) {
      this.sanitizeInAppLink(links[i] as HTMLLinkElement);
    }
  }

  public sanitizeInAppLink(elem: HTMLLinkElement): void {
    elem.setAttribute('data-target', '_system');
    elem.onclick = () => {
      window.open(elem.getAttribute('href'), '_system');
      return false;
    };
  }
}
