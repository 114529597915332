import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';

export interface INgbTime {
  hour: number;
  minute: number;
  second?: 0;
}

export interface ITimePickerModalComponentParams {
  time: INgbTime;
}

export type ITimePickerModalComponentResult = INgbTime;

@Component({
  selector: 'app-time-picker-modal',
  templateUrl: './time-picker-modal.component.html',
  styleUrls: ['./time-picker-modal.component.scss'],
})
export class TimePickerModalComponent implements OnInit, ITimePickerModalComponentParams {

  time: INgbTime = { hour: 0, minute: 0 };
  meridian = true;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() { }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  public close(): void {
    this.modalCtrl.dismiss(this.time);
  }

}
