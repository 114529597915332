<date-time-picker
  [disabled]="type == 'show' || manager.mode === 'review'"
  [placeholder]="params.placeholderFrom"
  [(date)]="params.dateFrom"
  (onChange)="onChange()"
  [min]="params.minDate"
  [max]="params.dateTo || params.maxDate"
  [timeZone]="params.timeZone || previousTimeZone"
>
</date-time-picker>
<date-time-picker
  [disabled]="type == 'show' || manager.mode === 'review'"
  [placeholder]="params.placeholderTo"
  [(date)]="params.dateTo"
  (onChange)="onChange()"
  [min]="params.dateFrom || params.minDate"
  [max]="params.maxDate"
  [timeZone]="params.timeZone || previousTimeZone"
>
</date-time-picker>
<time-zone-select class="ion-margin-top" [input]="type === 'input'" [(timeZone)]="params.timeZone" (timeZoneChange)="onTimeZonesSelect($event)"></time-zone-select>
