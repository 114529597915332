import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

interface ILegacyUrlRegexTransformer {
  regex: RegExp;
  transform: ((matches: string[]) => string);
}

@Injectable({
  providedIn: 'root'
})
export class LegacyUrlRedirectService {

  private deprecatedUrlTransformers: ILegacyUrlRegexTransformer[] = [
    {
      regex: /#\/unsubscribe/,
      transform: ((matches) => `${matches[2]}${matches[1]}`),
    },
    {
      regex: /#\/activity-redirect/,
      transform: ((matches) => `${matches[2]}${matches[1]}`),
    },
    {
      regex: /#\/home\/history\/[^/]*\/take/,
      transform: ((matches) => {
        console.log('take activity url parts', matches);

        // legacy hash (matches[2])
        // /home/history/6038c71d67fc640040fe5a22/take/5e2eb6b1133e4c0007315811

        // legacy path + query (matches[1])
        // ?token={{token}}&responses=%7B%22actAttending%22:%7B%22selectedAnswer%22:%22Yes%22%7D%7D

        // new url
        // /home/todo/history/:activityId/take/:concernedUserId?token={{token}}&responses=%7B%22actAttending%22:%7B%22selectedAnswer%22:%22Yes%22%7D%7D

        const [, , , activityId, , concernedUserId] = matches[2].split('/');
        return `/home/take/${activityId}/${concernedUserId}${matches[1]}`;
      }),
    },
    {
      regex: /#\/home\/history\/chat-conversation/,
      transform: ((matches) => {
        const [, , , , channelId] = matches[2].split('/');
        return `/home/communication/chat/${channelId}`;
      }),
    },
    {
      regex: /#\/voice-call-invitation/,
      transform: ((matches) => `${matches[2]}${matches[1]}`),
    },
    {
      regex: /#\/home\/history\/settings/,
      transform: ((matches) => `/home/settings#security`),
    },
  ];

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationStart) {
        if (!event.url) return;

        const newUrl = this.convertDeprecatedUrl(event.url);

        if (newUrl) await this.router.navigateByUrl(newUrl);
      }
    });
  }

  private convertDeprecatedUrl(url: string) {
    const matches = url.match(/\/(.*)#(.*)/);

    if (!matches) return;
    const transformer: ILegacyUrlRegexTransformer = this.deprecatedUrlTransformers.find(t => url.match(t.regex));
    if (!transformer) return;

    const newUrl: string = transformer.transform(matches);
    console.debug('legacy url', url, 'converted into', newUrl);

    return newUrl;
  }
}
