import {Component, Injector, OnInit, ViewContainerRef} from '@angular/core';
import {switchToTimeZone} from '../../../time-zone-select/time-zone-select.component';
import {InputBaseController} from '../../input-controller';
import moment from 'moment-timezone';
import {TranslateService} from '@ngx-translate/core';
import Action from '../../../../models/action';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-date-time-range',
  templateUrl: './action-date-time-range.component.html',
  styleUrls: ['./action-date-time-range.component.scss'],
})
export class ActionDateTimeRangeComponent extends InputBaseController implements OnInit {
  public static className: string = 'action-date-time-range';
  public static readonly responseKeys: string[] = ['dateFrom', 'dateTo'];
  public params: {
    dateFrom: Date,
    dateTo: Date,
    minDate: Date,
    maxDate: Date,
    placeholderFrom: string,
    placeholderTo: string,
    timeZone: string,
  };
  public previousTimeZone: string = moment.tz.guess(true);

  constructor(
    viewContainerRef: ViewContainerRef,
    injector: Injector,
    public translate: TranslateService
  ) {
    super(injector, viewContainerRef);
  }

  protected async initAction(action: Action): Promise<void> {
    super.initAction(action);
    let params: any = this.params;
    this.params.dateFrom = params.dateFrom ? new Date(params.dateFrom) : undefined;
    this.params.dateTo = params.dateTo ? new Date(params.dateTo) : undefined;

    this.params.minDate = params.minDate ? new Date(params.minDate) : new Date(1900, 0, 0, 0);
    this.params.maxDate = params.maxDate ? new Date(params.maxDate) : new Date(2100, 0, 0, 0);

    this.params.placeholderFrom = params.placeholderFrom || this.placeholder || this.translate.instant('ACTIVITY.ACTION.DATE_TIME_RANGE.FROM');
    this.params.placeholderTo = params.placeholderTo || this.placeholder || this.translate.instant('ACTIVITY.ACTION.DATE_TIME_RANGE.TO');

    try {
      this.params.timeZone = this.params.timeZone || (await this.getPod()).communities[0].timeZone || this.previousTimeZone;
      this.previousTimeZone = this.params.timeZone;
    } catch (e) {
      console.warn(e);
      this.params.timeZone = this.previousTimeZone;
    }
  }

  public validation(): string {
    if (this.params.dateFrom && this.params.dateTo && this.params.dateFrom.getTime() > this.params.dateTo.getTime()) {
      return this.translate.instant('ACTIVITY.ACTION.DATE_TIME_RANGE.END_BEFORE_START');
    }
    return super.validation();
  }

  public onTimeZonesSelect(newTimeZone: string): void {
    this.params.dateFrom = this.params.dateFrom && switchToTimeZone(this.params.dateFrom, newTimeZone, this.previousTimeZone);
    this.params.dateTo = this.params.dateTo && switchToTimeZone(this.params.dateTo, newTimeZone, this.previousTimeZone);
    this.previousTimeZone = this.params.timeZone;
  }
}
