import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'deep-link-redirect',
    loadChildren: () => import('./pages/deep-link-redirect/deep-link-redirect.module').then( m => m.DeepLinkRedirectPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/authenticated-root/authenticated-root.module').then(m => m.AuthenticatedRootPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recover',
    loadChildren: () => import('./pages/recover-account/recover-account.module').then(m => m.RecoverAccountPageModule)
  },
  {
    path: 'recover-username',
    loadChildren: () => import('./pages/recover-username/recover-username.module').then( m => m.RecoverUsernamePageModule)
  },
  {
    path: 'email-sign-in',
    loadChildren: () => import('./pages/email-sign-in/email-sign-in.module').then( m => m.EmailSignInPageModule)
  },
  {
    path: 'recover-account-sent',
    loadChildren: () => import('./pages/recover-account-sent/recover-account-sent.module').then( m => m.RecoverAccountSentPageModule)
  },
  {
    path: 'link-expired',
    loadChildren: () => import('./pages/link-expired/link-expired.module').then(m => m.LinkExpiredPageModule)
  },
  {
    path: 'link-sent',
    loadChildren: () => import('./pages/link-sent/link-sent.module').then(m => m.LinkSentPageModule)
  },
  {
    path: 'ott-mismatch-user',
    loadChildren: () => import('./pages/ott-mismatch-user/ott-mismatch-user.module').then( m => m.OttMismatchUserPageModule)
  },
  {
    path: 'verification-code-sent',
    loadChildren: () => import('./pages/auth-challenge-code/auth-challenge-code.module').then(m => m.AuthChallengeCodePageModule)
  },
  {
    path: 'deprecated-password',
    loadChildren: () => import('./pages/deprecated-password/deprecated-password.module').then(m => m.DeprecatedPasswordPageModule)
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule)
  },
  {
    path: 'stripe-checkout',
    loadChildren: () => import('./pages/stripe-checkout/stripe-checkout.module').then(m => m.StripeCheckoutPageModule)
  },
  {
    path: 'stripe-redirect',
    loadChildren: () => import('./pages/stripe-redirect/stripe-redirect.module').then(m => m.StripeRedirectPageModule)
  },
  {
    path: 'google-oauth-response',
    loadChildren: () => import('./pages/google-oauth-response/google-oauth-response.module').then( m => m.GoogleOAuthResponsePageModule)
  },
  {
    path: 'clever-oauth',
    loadChildren: () => import('./pages/clever-oauth/clever-oauth.module').then( m => m.CleverOauthPageModule)
  },
  {
    path: 'clever-oauth-alt-one',
    loadChildren: () => import('./pages/clever-oauth/clever-oauth.module').then( m => m.CleverOauthPageModule),
    data: { altOne: true },
  },
  {
    path: 'classlink-oauth',
    loadChildren: () => import('./pages/classlink-oauth/classlink-oauth.module').then( m => m.ClasslinkOauthPageModule)
  },
  {
    path: 'zendesk/sso',
    loadChildren: () => import('./pages/zendesk-sso/zendesk-sso.module').then( m => m.ZendeskSsoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unexpected-error',
    loadChildren: () => import('./pages/unexpected-error/unexpected-error.module').then( m => m.UnexpectedErrorPageModule)
  },
  {
    path: 'registration/:registrationId',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'test-page',
    loadChildren: () => import('./pages/test-page/test-page.module').then( m => m.TestPagePageModule)
  },
  {
    path: '**',
    pathMatch: 'prefix',
    redirectTo: '/not-found'
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true,
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
