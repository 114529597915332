import {Component} from '@angular/core';
import {InputBaseController} from '../../input-controller';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-range',
  templateUrl: './action-range.component.html',
  styleUrls: ['./action-range.component.scss'],
})
export class ActionRangeComponent extends InputBaseController {
  public static className: string = 'action-range';
  public static readonly responseKeys: string[] = ['number'];
  public params: { number: number, maxValue: number, minValue: number };
}
