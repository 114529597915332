export interface ISlotRow {
  name: string;
  value: string;
  index: number;
  taken: number; // 0 = free, 1 = held, 2 = taken
  userId: string;
}

export interface ISlotCol {
  name: string;
  value: Date;
  index: number;
  rows: ISlotRow[];
}

export interface IPTCMessageData {
  ActivityId: string;
  Cols: ISlotCol[];
}

export class SlotCol {
  name: string;
  value: Date;
  index: number;
  rows: SlotRow[] = [];

  constructor(day: Day, index: number) {
    this.name = day.header.title;
    this.value = day.date;
    this.index = index;
  }
}

export class SlotRow {
  name: string;
  value: Date;
  index: number;
  taken: number = 0; // 0 = free, 1 = held, 2 = taken
  userId: string = '';

  constructor(date: Date, index: number) {
    this.value = date;
    this.index = index;
    let h = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    let m = date.getMinutes();
    let suffix = date.getHours() >= 12 ? ' PM' : ' AM';
    this.name = this.twoD(h) + ':' + this.twoD(m) + suffix;
  }

  twoD(num: number): string {
    if (num >= 10) {
      return num.toString();
    }
    return '0' + num;
  }
}

export interface Day {
  header: {
    title: string;
  };
  shifts: {
    start: Date;
    end: Date;
  }[];
  date: Date;
  open: boolean;
}

export class SocketMessageOut {
  UserId: string;
  ActivityId: string;
  Day: number;
  Hour: number;
  Reserved: number;

  constructor(userId: string, activityId: string, day: SlotCol, slot: SlotRow, reserved: boolean = true) {
    this.UserId = userId;
    this.ActivityId = activityId;
    this.Reserved = reserved ? 1 : 0;
    this.Day = day.index;
    this.Hour = slot.index;
  }
}

export type SocketStatus = 'Off-line' | 'Connecting...' | 'Connected' | '';
