import {Component, Inject} from '@angular/core';
import {QuillModule} from 'ngx-quill';
import {defaultStyleFontWhitelist, defaultStyleSizeWhitelist, QuillPluginsService} from '../../../services/quill/quill-plugins.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Platform} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {BbcodeTranslatorService} from '../../../services/bbcode-translator.service';
import {NativeAppLinksSanitizerService} from '../../../services/native-app-links-sanitizer.service';
import {EmailImagesFormatterService} from '../../../services/email-images-formatter.service';
import {QuillImagesRestrictionService} from '../../../services/quill/quill-images-restriction.service';
import { delay, isMobile } from '../../../../utils';
import { QuillImagesUploaderService } from '../../../services/quill/quill-images-uploader.service';

export interface IDocModalResult {
  content: string;
  formattedText: string;
}

@Component({
  selector: 'app-doc-modal',
  templateUrl: './doc-modal.component.html',
  styleUrls: ['./doc-modal.component.scss'],
})
export class DocModalComponent {
  private quill: { root: HTMLDivElement, editor: any };
  public content: string;
  public type: string;
  public mode: string;
  public formattedText: string;

  public quillModules: QuillModule = {
    toolbar: [
      [{font: defaultStyleFontWhitelist}, {size: defaultStyleSizeWhitelist}, {header: [1, 2, 3, false]}],
      ['bold', 'italic', 'underline', 'strike'],                   // toggled buttons
      [{list: 'ordered'}, {list: 'bullet'}],                                  // text direction
      ['blockquote', {direction: 'rtl'}, {color: []}, {background: []}, {align: []}],
      ['link', 'image'],                                            // link and image, video
      ['clean'],
    ],
    imageResize: true
  };

  public canPrint: boolean = true;

  public get canSave() {
    if (!this.quill) return true;
    const uploadImagesContainer = this.quillImagesUploaderService.quillEditorToUploadingImages.get(this.quill);
    if (!uploadImagesContainer) return true;
    return uploadImagesContainer.uploadingImages.length === 0;
  }

  constructor(
    private matDialogRef: MatDialogRef<DocModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: {
      content: string,
      mode: string,
      type: string
    },
    public plt: Platform,
    private sanitizer: DomSanitizer,
    public bbcodeTranslator: BbcodeTranslatorService,
    private quillPlugins: QuillPluginsService,
    private linksSanitizer: NativeAppLinksSanitizerService,
    private emailImagesFormatter: EmailImagesFormatterService,
    private quillImagesRestriction: QuillImagesRestrictionService,
    private quillImagesUploaderService: QuillImagesUploaderService,
  ) {
    this.canPrint = !isMobile(this.plt);
    this.content = data.content;
    this.mode = data.mode;
    this.type = data.type;
    (this.quillModules as any).imageResize = this.type === 'input';
  }

  public save(): void {
    this.matDialogRef.close({content: this.content, formattedText: this.formattedText} as IDocModalResult);
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public editQuillInit(quill: any): void {
    // hyperlink placeholder
    // https://github.com/quilljs/quill/issues/1107
    quill.theme.tooltip.root.querySelector('input[data-link]').dataset.link = 'https://actionaly.com';

    this.quill = quill;
    this.linksSanitizer.sanitizeInAppLinks(this.quill.root);
  }

  public async editQuillChanged(quill: any): Promise<void> {
    // this.quillImagesRestriction.processImagesRestriction(quill);
    this.linksSanitizer.sanitizeInAppLinks(this.quill.root);
    await this.quillImagesUploaderService.extractAndUploadImages(quill);
    await this.onChange();
  }

  public async onChange() {
    this.formattedText = await this.emailImagesFormatter.formatImagesForEmailCompatibility(this.quill.root);
  }

  public async print() {
    let print: Window = window.open('', 'PRINT');
    print.document.write(`<html lang="en"><head><title> </title></head>
<body style="font-family: \'roboto\',serif;">${this.bbcodeTranslator.translate(this.content)}</body></html>`);
    print.document.close();
    await delay(100);
    print.focus();
    print.print();
    print.close();
    return true;
  }
}
