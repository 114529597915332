import { Injectable } from '@angular/core';
import AuthenticatedUser, { IJsonAuthenticatedUser } from '../models/user';
import { distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CognitoAuthService } from './cognito-auth.service';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<AuthenticatedUser> = new BehaviorSubject<AuthenticatedUser>(undefined);

  public get currentUser$(): Observable<AuthenticatedUser> {
    return this.currentUserSubject.asObservable();
  }

  public get validCurrentUser$(): Observable<AuthenticatedUser> {
    return this.currentUserAfterPendingLoad$.pipe(filter(u => !!u));
  }

  public get validCurrentUserUntilIdChange$(): Observable<AuthenticatedUser> {
    return this.validCurrentUser$.pipe(
      distinctUntilChanged((prev, curr) => prev._id === curr._id),
    );
  }

  public get currentUserAfterPendingLoad$(): Observable<AuthenticatedUser> {
    return this.cognitoAuth.isAuthenticating$.pipe(
      switchMap((isAuthenticating) => {
        if (isAuthenticating) {
          return EMPTY;
        }
        return this.loadingSubject.asObservable();
      }),
      switchMap((loading) => {
        if (loading) {
          return EMPTY;
        }
        return this.currentUser$;
      }),
    );
  }

  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get currentUser(): AuthenticatedUser {
    return this.currentUserSubject.getValue();
  }

  constructor(
    private api: ApiService,
    private cognitoAuth: CognitoAuthService,
    private translate: TranslateService,
  ) {
    this.cognitoAuth.cognitoSession$.pipe(
      filter(session => !!session),
      switchMap(() => this.loadingSubject.asObservable().pipe(filter(v => !v), take(1))),
    ).subscribe(async session => {
      await this.loadUser();
    });

    this.cognitoAuth.cognitoLogout$.subscribe(() => {
      this.currentUserSubject.next(undefined);
    });

    this.validCurrentUser$
      .pipe(distinctUntilChanged((prev, curr) => this.translate.currentLang === curr.appLanguage))
      .subscribe(user => {
        if (this.translate.getLangs().indexOf(user.appLanguage) !== -1) {
          this.translate.use(user.appLanguage);
        }
      });
  }

  public async loadUser(): Promise<AuthenticatedUser> {
    let user: AuthenticatedUser;
    try {
      this.loadingSubject.next(true);
      const response: any = await this.api.request('GET', 'Users/User');
      user = new AuthenticatedUser(response as IJsonAuthenticatedUser);
      return user;
    } catch (e) {
      throw e;
    } finally {
      this.currentUserSubject.next(user);
      this.loadingSubject.next(false);
    }
  }

  public enableChat(): void {
    this.currentUser.canChat = true;
    this.currentUserSubject.next(this.currentUser);
  }

  public enableVoice(): void {
    this.currentUser.voiceUsage.callsCount = this.currentUser.voiceUsage.callsCount || 1;
    this.currentUserSubject.next(this.currentUser);
  }

  public setUnreadMessages(unreadCound: number): void {
    this.currentUser.chatUsage.pendingMessages = unreadCound;
    this.currentUserSubject.next(this.currentUser);
  }

  public updateUser(user: AuthenticatedUser): void {
    this.currentUserSubject.next(user);
  }

  public async updateActivitiesNotif(params: string[]): Promise<void> {
    await this.api.patchNotificationTypes(params);
    this.currentUser.notificationTypes.push = params.indexOf('PUSHNOTIF') >= 0;
    this.currentUser.notificationTypes.email = params.indexOf('EMAIL') >= 0;
    this.currentUser.notificationTypes.sms = params.indexOf('SMS') >= 0;
    this.currentUserSubject.next(this.currentUser);
  }

  public async updateChatNotif(params: string[]): Promise<void> {
    await this.api.patchChatNotificationTypes(params);
    this.currentUser.chatNotificationTypes.push = params.indexOf('PUSHNOTIF') >= 0;
    this.currentUser.chatNotificationTypes.email = params.indexOf('EMAIL') >= 0;
    this.currentUser.chatNotificationTypes.sms = params.indexOf('SMS') >= 0;
    this.currentUserSubject.next(this.currentUser);
  }
}
