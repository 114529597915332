import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {DatePickerModalComponent} from '../date-picker-modal/date-picker-modal.component';
import moment from 'moment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {

  public displayableDate: string = '';

  @Input() disabled: boolean;
  @Input() displayFormat: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() placeholder: string;
  @Input() granularity: 'days' | 'minutes' = 'days';
  @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() onChange: EventEmitter<Date> = new EventEmitter<Date>();

  private _date: Date;
  @Input()
  get date(): Date {
    return this._date;
  }

  set date(newDate: Date) {
    if (this._date === newDate) {
      return;
    }
    this._date = newDate;
    this.formatToDisplay(this._date);
    this.dateChange.emit(newDate);
    this.onClose.emit(newDate);
    this.onChange.emit(newDate);
  }

  constructor(
    private matDialog: MatDialog,
    public translate: TranslateService) {
  }

  public ngOnInit() {
    this.minDate = this.minDate || new Date(1900, 0, 0, 0);
    this.maxDate = this.maxDate || new Date(2100, 0, 0, 0);
    this.formatToDisplay(this._date);
    if (moment(this.minDate).diff(this.maxDate, 'days') > 0) {
      throw new Error('Invalid: Max date must be upper than Min date');
    }
  }

  private formatToDisplay(date: Date): void {
    if (!date) {
      this.displayableDate = '';
    } else {
      let format: string = this.displayFormat || this.translate.instant('DATE_FORMAT.MOMENT.DATE_FULL');
      this.displayableDate = moment(date)
        .locale(this.translate.instant('DATE_FORMAT.MOMENT.LOCAL'))
        .format(format);
    }
  }

  public triggerDatePickerModal() {
    this.matDialog.open(DatePickerModalComponent, {
      data: {
        date: this._date,
        minDate: this.minDate,
        maxDate: this.maxDate
      },
      panelClass: 'customDialogContainer'
    }).afterClosed().subscribe(date => {
      this.date = date;
    });
  }

}
