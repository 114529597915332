import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityV2Component } from './activity-v2.component';
import { FieldsModule } from './fields/fields.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { ActivityConcernedUserDirective } from './directives/activity-concerned-user/activity-concerned-user.directive';
import { ActivityPodIdDirective } from './directives/activity-pod-id/activity-pod-id.directive';
import { ActivityContextDirective } from './directives/activity-context/activity-context.directive';
import { PipesModule } from '../../pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ActivityProgressDirective } from './directives/activity-progress/activity-progress.directive';

@NgModule({
  declarations: [
    ActivityV2Component,
    ActivityConcernedUserDirective,
    ActivityPodIdDirective,
    ActivityContextDirective,
    ActivityProgressDirective,
  ],
  imports: [
    CommonModule,
    FieldsModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    PipesModule,
    IonicModule,
    TranslateModule.forChild(),
  ],
  exports: [
    ActivityV2Component,
    ActivityConcernedUserDirective,
    ActivityPodIdDirective,
    ActivityContextDirective,
    ActivityProgressDirective,
  ]
})
export class ActivityV2Module { }
