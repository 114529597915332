<div class="modal-header d-flex justify-content-between align-items-center">
    <h2 class="html-modal-title flex-fill">{{title | bbcodeTranslation}}</h2>
    <div class="modal-btns">
        <ion-button fill="clear" (click)="close()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
    </div>
</div>

<div *ngIf="content"
     class="html-modal-content"
     [class.is-mobile]="isMobile"
     convert-cordova-app-links
     [innerHTML]="content | bbcodeTranslation | sanitize">
</div>

<div class="modal-footer">
    <div class="modal-btns">
        <ion-button shape="round" (click)="close()">
            {{ "close" | translate | uppercase }}
        </ion-button>
    </div>
</div>