import {Component, Injector, QueryList, ViewChildren, ViewContainerRef} from '@angular/core';
import {IProvidedUser} from '../../../../models/user';
import {MatExpansionPanel} from '@angular/material/expansion';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import {ValidationErrors} from '@angular/forms';
import _, {Dictionary} from 'lodash';
import {InputBaseController} from '../../input-controller';
import {AlertController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {IFieldsConfig, InputUserComponent} from '../../../inputs/input-user/input-user.component';

export interface IActionUser extends Partial<IProvidedUser> {

}

export interface IExpandableActionUser extends IActionUser {
  open: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-users',
  templateUrl: './action-users.component.html',
  styleUrls: ['./action-users.component.scss'],
})
export class ActionUsersComponent extends InputBaseController {
  public static className: string = 'action-users';
  public static readonly responseKeys: string[] = ['users'];

  @ViewChildren(InputUserComponent) inputUsers: QueryList<InputUserComponent>;
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

  public params: {
    providedUsers: IProvidedUser[];
    users: IActionUser[];
    fields: IFieldsConfig;
    protectedUsers: string[];
    languages: string[];
    hideButtons: boolean;
  };

  public expandableUsers: IExpandableActionUser[];

  public disabled: boolean = true;
  public errors: number[];

  constructor(
    protected injector: Injector,
    protected viewContainerRef: ViewContainerRef,
    public platform: Platform,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public translate: TranslateService,
  ) {
    super(injector, viewContainerRef);
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    if (!this.params.providedUsers) {
      this.params.providedUsers = [];
    }

    let providedUsers: IProvidedUser[];
    // If params.providedUsers is only one IProvidedUser   (For currentUser or concernedUser)
    if (this.params.providedUsers['id']) {
      providedUsers = [_.cloneDeep(this.params.providedUsers) as any as IProvidedUser];
    } else {
      providedUsers = _.cloneDeep(this.params.providedUsers);
    }
    let actionUsers: IActionUser[] = providedUsers.map(user => this.userToActionUser(user, this.params.fields));
    this.expandableUsers = (this.params.users || actionUsers).map(actionUser => ({...actionUser, open: false}));
    if (!this.params.protectedUsers) {
      this.params.protectedUsers = [];
    }
  }

  public userToActionUser(user: IProvidedUser, fields: IFieldsConfig): IActionUser {
    const fieldToUserPath = {
      cellPhone: ['phones', 'cell'],
      homePhone: ['phones', 'home'],
      workPhone: ['phones', 'work'],
      otherPhone: ['phones', 'other'],
    };
    const userKeys = Object.keys(fields).map(key => fieldToUserPath[key] || key);

    let u: IActionUser = _(user)
      .pick(user, userKeys, 'id')
      .defaultsDeep({phones: {}})
      .value();
    // Take only last (main) address
    if (u.addresses && u.addresses.length > 1) {
      u.addresses = [u.addresses[0]];
    }
    return u;
  }

  public closeExpansionPanel(i: number) {
    this.expandableUsers[i].open = false;
    this.validation();
  }

  public onChange() {
    this.params.users = this.expandableUsers.map(expandableUser => _.omit(expandableUser, 'open'));
    super.onChange();
  }

  public generateDefaultUser(fields: IFieldsConfig): IProvidedUser {
    return {
      id: undefined,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      addresses: [{
        placeId: '',
        formattedAddress: '',
        additionalDetails: ''
      }],
      language: undefined,
      appLanguage: 'en',
      birthdate: undefined,
      gender: undefined,
      phones: {
        home: '',
        work: '',
        cell: '',
        other: ''
      },
    };
  }

  public async addUser() {
    this.expansionPanels.forEach(expansionPanel => expansionPanel.close());
    const newUser: IActionUser = this.userToActionUser(
      this.generateDefaultUser(this.params.fields),
      this.params.fields,
    );
    this.expandableUsers.push({...newUser, open: true});
  }

  public async delUser(i: number) {
    if (!this.params.protectedUsers.find(str => str === this.expandableUsers[i].id)) {
      const confirm: HTMLIonAlertElement = await this.alertCtrl.create({
        header: this.translate.instant('ACTIVITY.ACTION.USERS.REMOVE.CONFIRM'),
        message: '',
        buttons: [
          {
            text: this.translate.instant('ACTIVITY.ACTION.USERS.REMOVE.NO'),
            handler: () => {
            }
          },
          {
            text: this.translate.instant('ACTIVITY.ACTION.USERS.REMOVE.YES'),
            handler: () => {
              this.expandableUsers.splice(i, 1);
              this.onChange();
            }
          }
        ]
      });
      await confirm.present();
    }
  }

  public showDelete(id: string): boolean {
    if (this.params.protectedUsers.find(str => str === id)) {
      return false;
    }
    return true;
  }

  public onLeave(): void {
    super.onLeave();
  }

  public validation(): string {
    if (this.manager.mode === 'view') {
      const errors: ValidationErrors[] = this.inputUsers.map(inputUser => inputUser.validate());
      this.errors = errors.map(userInputErrors =>
        Object.keys(userInputErrors).map(control => userInputErrors[control]).filter(error => error).length
      );
      if (this.containErrors(errors)) {
        return this.translate.instant('ACTIVITY.ACTION.USERS.MISSING');
      }
    }
    return super.validation();
  }

  public containErrors(errors: ValidationErrors[]) {
    return errors.some(userInputErrors => Object.keys(userInputErrors).map(control => userInputErrors[control]).some(error => error));
  }

  public toggleExpansionPanel(e: MouseEvent, panel: MatExpansionPanel) {
    e.stopPropagation();
    panel.toggle();
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    return getValue(action.params.users);
  }
}
