/// <reference types="stripe-v3" />

import { BroadcastChannel } from 'broadcast-channel';
import { Component, Injector, OnDestroy, ViewContainerRef } from '@angular/core';
import { StripeSubmitType } from '../../../../services/payment-request.interface';
import { InAppBrowserEvent } from '@ionic-native/in-app-browser';
import { delayWhen, filter, first, map, mapTo, scan, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, interval, merge, Observable, of, Subject } from 'rxjs';
import DynValue from '../../../../models/dyn-value';
import Action from '../../../../models/action';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { MatDialogRef } from '@angular/material/dialog';
import { Httpd } from '@ionic-native/httpd/ngx';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../../services/api.service';
import { UserService } from '../../../../services/user.service';
import { ExecuteWithLoaderService } from '../../../../services/execute-with-loader.service';
import { InputBaseController } from '../../input-controller';
import { FormHelperService } from '../../../../services/form-helper.service';
import { LinkPopUpComponent, LinkPopUpDialogData } from '../../../link-pop-up/link-pop-up.component';
import { Location, LocationStrategy } from '@angular/common';
import { DynamicMatDialogService } from '../../../../services/dynamic-mat-dialog/dynamic-mat-dialog.service';
import { IChcp, IVersionInfo } from '../../../../services/hot-deploy.service';
import { DeveloperService } from '../../../../services/developer.service';
import { CrossTabsMessage } from '../../../../modules/activity-v2/models/stripe.interface';

export interface ActionStripeParams {
  amount: number;
  currency?: string;
  name?: string;
  description?: string;
  images?: string[];
  submit_type: StripeSubmitType;
  paid: boolean;
}

declare var chcp: IChcp;
declare var cordova: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-stripe',
  templateUrl: './action-stripe.component.html',
  styleUrls: ['./action-stripe.component.scss'],
})
export class ActionStripeComponent extends InputBaseController implements OnDestroy {
  public static className: string = 'action-stripe';
  public static readonly responseKeys: string[] = ['paid', 'amount', 'currency'];
  public params: ActionStripeParams;

  public returnedValue: string;
  public returnedError: string;

  private stripeSessionId: string;
  private accountId: string;

  private stripeCheckoutReadySubject = new BehaviorSubject<boolean>(false);

  public get stripeCheckoutReady$() {
    return this.stripeCheckoutReadySubject.asObservable();
  }

  private stripeCheckoutRedirectReadySubject = new BehaviorSubject<boolean>(false);

  public get stripeCheckoutRedirectReady$() {
    return this.stripeCheckoutRedirectReadySubject.asObservable();
  }

  private stripeCheckoutSuccessSubject = new BehaviorSubject<boolean>(false);

  public get stripeCheckoutSuccess$() {
    return this.stripeCheckoutSuccessSubject.asObservable();
  }

  private stripeCheckoutCancelSubject = new BehaviorSubject<boolean>(false);

  public get stripeCheckoutCancel$() {
    return this.stripeCheckoutCancelSubject.asObservable();
  }

  private delayedFailurePrompt$: Observable<number>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private actionToSubject: { [action: string]: BehaviorSubject<any> } = {
    ready: this.stripeCheckoutReadySubject,
    redirectReady: this.stripeCheckoutRedirectReadySubject,
    stripeSuccess: this.stripeCheckoutSuccessSubject,
    stripeCancel: this.stripeCheckoutCancelSubject,
  };

  private channel: BroadcastChannel = new BroadcastChannel<CrossTabsMessage>('stripePayment');
  private window: Window;
  private pageOrigin: string;
  private httpdOrigin: string;
  private loader: HTMLIonLoadingElement;
  private pendingPaymentAlert: HTMLIonAlertElement;
  private paymentCancelPromptDelays: {
    ready: number;
    redirect: number;
    redirected: number;
  } = {
      ready: 8000,
      redirect: 10000,
      redirected: 20000
    };

  constructor(
    viewContainerRef: ViewContainerRef,
    injector: Injector,
    private location: Location,
    private locationStrategy: LocationStrategy,
    public api: ApiService,
    public loadingCtrl: LoadingController,
    private matDialog: DynamicMatDialogService,
    private userService: UserService,
    private alertCtrl: AlertController,
    private platform: Platform,
    private httpd: Httpd,
    public translate: TranslateService,
    public formHelper: FormHelperService,
    private executor: ExecuteWithLoaderService,
    private developerService: DeveloperService,
  ) {
    super(injector, viewContainerRef);
    console.debug(this.location.path());
    console.debug(this.location.prepareExternalUrl('/'));
    console.debug(this.locationStrategy.getBaseHref());
    console.debug(window.location.origin + this.location.prepareExternalUrl('/stripe-redirect'));
    if (this.manager.mode !== 'view') {
      return;
    }

    this.pageOrigin = window.location.protocol + '//' + window.location.host + window.location.pathname;
    console.debug('pageOrigin: ' + this.pageOrigin);
    if (this.platform.is('hybrid')) {
      this.setupHttpd();
    }
    this.channel.addEventListener('message', (e) => {
      this.onPageMessage(e);
    });
    this.destroy$.subscribe(() => {
      this.channel.close();
    });
    this.stripeCheckoutReady$.pipe(takeUntil(this.destroy$)).pipe(filter(v => v)).subscribe(() => this.onCheckoutReady());
    this.stripeCheckoutSuccess$.pipe(takeUntil(this.destroy$)).pipe(filter(v => v)).subscribe(() => this.onCheckoutSuccess());
    this.stripeCheckoutCancel$.pipe(takeUntil(this.destroy$)).pipe(filter(v => v)).subscribe(() => this.onCheckoutCancel());
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async setupHttpd(): Promise<void> {
    let httpdOptions: { localhost_only: boolean, www_root?: string, port?: number } = { localhost_only: true };
    console.debug('window.location.href', window.location.href);
    const versionInfo: IVersionInfo = await new Promise<IVersionInfo>((resolve, reject) => {
      chcp.getVersionInfo((getVersionInfoError, getVersionInfoData) => {
        if (getVersionInfoError) {
          console.debug('getVersionInfoError', JSON.stringify(getVersionInfoError));
          reject(getVersionInfoError);
        } else {
          console.debug('getVersionInfoData', JSON.stringify(getVersionInfoData));
          resolve(getVersionInfoData);
        }
      });
    });
    // android example before update
    // httpdOptions.www_root = '/data/user/0/com.blupods.messaging.actionable/files/cordova-hot-code-push-plugin/www';
    httpdOptions.www_root = decodeURI(versionInfo.currentWebFolder).replace('file://', '');
    this.httpd.startServer(httpdOptions).pipe(takeUntil(this.destroy$)).subscribe((url) => {
      console.debug('httpd url', url);
      this.httpdOrigin = url;
      if (this.platform.is('ios')) this.httpdOrigin = this.developerService.getEnvironmentUrl();
      this.httpdOrigin = this.httpdOrigin + (this.httpdOrigin.substr(this.httpdOrigin.length - 1) !== '/' ? '/' : '');
    });
  }

  protected initAction(action: Action): void {
    super.initAction(action);
  }

  private async saveDraftResults() {
    const activity = this.manager.getActivity();
    const concernedId = this.manager.getConcernedUser() && this.manager.getConcernedUser().id;

    // set response as it should be in case of successful payment
    this.action.params.paid = DynValue.CreateStatic(true);
    const responses = this.formHelper.getResponses(this.manager);

    // reset response state to pending payment
    this.action.params.paid = DynValue.CreateStatic(false);
    return this.api.saveActivityDraftResults(activity.id, responses, concernedId);
  }

  private resetEvents() {
    Object.keys(this.actionToSubject).forEach((key) => {
      this.actionToSubject[key].next(false);
    });
  }

  private async dismissLoader() {
    try {
      await this.loader.dismiss();
    } catch (e) {
    }
  }

  private async dismissAlert() {
    try {
      await this.pendingPaymentAlert.dismiss();
    } catch (e) {
    }
  }

  private async cancelCheckout() {
    try {
      // cancel any pending previous checkout session
      // reasons:
      // - the amount might have changed
      // - the user might not have closed the previous stripe checkout
      // - recommended by stripe to always create a new payment session when the user clicks pay
      if (this.stripeSessionId) {
        const res = await this.api.cancelStripePaymentSession(this.stripeSessionId, this.accountId);
      }
    } catch (e) {
      console.dir(e);
    }
    this.stripeSessionId = undefined;
  }

  // tslint:disable-next-line: cyclomatic-complexity
  public async openCheckout() {
    if (this.manager.mode !== 'view') {
      return;
    }

    this.loader = await this.loadingCtrl.create({
      // message: "Processing payment...",
      message: this.translate.instant('ACTIVITY.ACTION.STRIPE.PROCESSING_PAYMENT'),
    });
    await this.loader.present();
    this.resetEvents();
    await this.cancelCheckout();
    const draftResult = await this.saveDraftResults();
    const concernedUser = this.manager.getConcernedUser();
    const concernedUserId = concernedUser ? concernedUser.id : this.userService.currentUser._id;

    // this.pageOrigin = this.pageOrigin.replace('index.html', '');

    let successUrl: string = `${location.origin}${this.location.prepareExternalUrl(`stripe-redirect?sessionId={CHECKOUT_SESSION_ID}&redirectAction=stripeSuccess`)}`;
    let cancelUrl: string = `${location.origin}${this.location.prepareExternalUrl(`stripe-redirect?sessionId={CHECKOUT_SESSION_ID}&redirectAction=stripeCancel`)}`;

    // TODO: fix for capacitor
    if (this.platform.is('hybrid')) {
      successUrl = `${this.httpdOrigin}?virtualPath=stripe-redirect&sessionId={CHECKOUT_SESSION_ID}&redirectAction=stripeSuccess`;
      cancelUrl = `${this.httpdOrigin}?virtualPath=stripe-redirect&sessionId={CHECKOUT_SESSION_ID}&redirectAction=stripeCancel`;
    }

    try {
      ({ sessionId: this.stripeSessionId, accountId: this.accountId } = await this.api.createStripePaymentSession({
        amount: this.params.amount,
        currency: this.params.currency,
        name: this.params.name,
        description: this.params.description,
        images: this.params.images,
        submit_type: this.params.submit_type,
        activityId: this.manager.getActivity().id,
        concernedUserId,
        successUrl,
        cancelUrl,
        draftResultId: draftResult._id,
      }));
    } catch (e) {
      const message = e.json().message;
      // console.dir(message)
      switch (message) {
        case 'already paid': {
          this.params.paid = true;
          // this.returnedError = "Operation aborted, your payment had already been processed by someone else.";
          this.returnedError = this.translate.instant('ACTIVITY.ACTION.STRIPE.ALREADY_PROCESSED_ERROR');
          break;
        }
        case 'activity is closed': {
          // this.returnedError = this.sectionCtrl.manager.getActivity().title + " activity has been closed. Operation aborted, the payment has not been processed.";
          this.returnedError = this.translate.instant('ACTIVITY.ACTION.STRIPE.{{activityTitle}}_CLOSED_ERROR', this.sectionCtrl.manager.getActivity().title);
          break;
        }
        default: {
          this.returnedError = message;
          break;
        }
      }
      await this.dismissLoader();
      await this.dismissAlert();
      return;
    }

    let origin: string = !this.platform.is('hybrid') ? location.origin : this.httpdOrigin;
    if (origin.substring(origin.length - 1) === '/') origin = origin.substring(0, origin.length - 1);
    const searchParams: string = `sessionId=${this.stripeSessionId}&accountId=${this.accountId}`;
    let checkoutUrl: string = `${origin}${this.location.prepareExternalUrl(`stripe-checkout?${searchParams}`)}`;

    if (!this.platform.is('hybrid')) {
      console.debug('checkoutUrl', checkoutUrl);
      this.window = window.open(checkoutUrl, '_blank');

      // Popup blocker (most likely Safari IOS)
      // https://stackoverflow.com/questions/5649962/how-can-i-indicate-that-a-popup-has-been-blocked-by-safari
      if (!this.window) {
        await this.dismissLoader();
        this.loader = await this.loadingCtrl.create({
          // content: "Processing payment...",
          message: this.translate.instant('ACTIVITY.ACTION.STRIPE.PROCESSING_PAYMENT'),
        });
        this.paymentCancelPromptDelays.ready = 15000;
        const linkPopUpDialog: MatDialogRef<LinkPopUpComponent> = this.matDialog.open(LinkPopUpComponent, {
          disableClose: true,
          data: {
            // description: 'Click the button to be redirected to the payment platform.',
            description: this.translate.instant('ACTIVITY.ACTION.STRIPE.LINK_DIALOG_DESCRIPTION'),
            url: checkoutUrl,
            linkText: this.translate.instant('ACTIVITY.ACTION.STRIPE.LINK_DIALOG_LINK_TEXT'),
          } as LinkPopUpDialogData,
        });

        // in case the user still opens the tab from browser alert without clicking the modal url
        linkPopUpDialog.afterOpened().pipe(switchMap(() => this.stripeCheckoutReady$.pipe(filter(v => v)))).subscribe(async () => {
          linkPopUpDialog.close();
        });

        linkPopUpDialog.beforeClosed().pipe(takeUntil(this.stripeCheckoutReady$.pipe(filter(v => v)))).subscribe(async () => {
          await this.loader.present();
        });
      }
    } else {
      const encodedVirtualPath: string = encodeURIComponent(new URL(checkoutUrl).pathname);
      checkoutUrl = `${origin}?${searchParams}&virtualPath=${encodedVirtualPath}`;
      console.debug('checkoutUrl', checkoutUrl);
      this.window = (cordova as any).InAppBrowser.open(checkoutUrl, '_blank', 'location=no');
      this.window.addEventListener('message', (event: InAppBrowserEvent) => {
        console.debug('message', JSON.stringify(event));
        this.onPageMessage(event.data);
      });
      this.window.addEventListener('loadstop', (event: InAppBrowserEvent) => {
        if (event.url.includes('stripe-redirect')) {
          const url: URL = new URL(event.url);
          const redirectAction: string = url.searchParams.get('redirectAction');
          const sessionId: string = url.searchParams.get('sessionId');
          if (sessionId !== this.stripeSessionId) {
            return;
          }
          this.actionToSubject[redirectAction].next(true);
        }
      });
      this.window.addEventListener('exit', () => {
        if (this.stripeCheckoutSuccessSubject.value !== true) {
          this.actionToSubject.stripeCancel.next(true);
        }
      });
    }

    this.startPaymentCancelPromptRoutine();
  }

  // wait for 8 sec for checkout ready, 10 sec for checkout redirect and then 20 sec while making the payment
  // triggers the cancel payment prompt if no webhook was triggered yet
  private async startPaymentCancelPromptRoutine(): Promise<void> {
    const delays: number[] = [
      this.paymentCancelPromptDelays.ready,
      this.paymentCancelPromptDelays.redirect,
      this.paymentCancelPromptDelays.redirected];

    this.delayedFailurePrompt$ = merge(
      of(true),
      this.stripeCheckoutReady$,
      this.stripeCheckoutRedirectReady$,
    ).pipe(
      filter(v => !!v),
      mapTo(0),
      scan((acc, value) => acc + 1),
      map(index => delays[index]),
      switchMap(e => of(e).pipe(
        delayWhen(event => interval(e))
      )),
      takeUntil(
        merge(
          this.destroy$,
          this.stripeCheckoutSuccess$,
          this.stripeCheckoutCancel$,
        ).pipe(filter(v => !!v))
      ),
      takeWhile(() => !this.params.paid && !this.returnedError),
      take(1),
    );
    this.delayedFailurePrompt$
      .subscribe(x => this.promptPaymentCancel());
  }

  // in case something goes wrong in the payment process
  private async promptPaymentCancel() {
    await this.dismissLoader();

    // the background tab is suspended, hence the previous takeUntil and takeWhile may not execute when required
    // this reruns the checks to fix the issue
    const shouldCancel: boolean = await merge(
      merge(
        this.destroy$,
        this.stripeCheckoutSuccess$,
        this.stripeCheckoutCancel$,
      ).pipe(filter(v => !!v)),
      of(false)
    ).pipe(first()).toPromise();
    if (this.params.paid || this.returnedError || shouldCancel) {
      return;
    }

    this.pendingPaymentAlert = await this.alertCtrl.create({
      // title: 'Pending Payment',
      header: this.translate.instant('ACTIVITY.ACTION.STRIPE.PAYMENT_CANCEL_PROMPT_TITLE'),
      // subTitle: 'Did you encounter a payment issue?',
      message: this.translate.instant('ACTIVITY.ACTION.STRIPE.PAYMENT_CANCEL_PROMPT_MESSAGE'),
      buttons: [
        {
          // text: 'Cancel payment',
          text: this.translate.instant('ACTIVITY.ACTION.STRIPE.PAYMENT_CANCEL_PROMPT_BUTTON'),
          role: 'cancel',
          handler: () => {
            this.cancelCheckout();
          },
        }
      ],
    });
    return this.pendingPaymentAlert.present();
  }

  private async onPageMessage(messageData: any) {
    console.debug('onPageMessage', messageData);
    if (messageData.params.sessionId !== this.stripeSessionId) {
      return;
    }
    return this.actionToSubject[messageData.action] && this.actionToSubject[messageData.action].next(true);
  }

  private async onCheckoutReady() {
    const params = {
      amount: this.params.amount,
      title: `Actionaly payment ${this.sectionCtrl.manager.getActivity().title}`,
      sessionId: this.stripeSessionId,
    };
    if (this.platform.is('hybrid')) {
      return await (this.window as any).executeScript({
        code: 'openStripeCheckoutSync("' + params.sessionId + '", "' + this.accountId + '");',
      });
    } else {
      return this.channel.postMessage({ action: 'init', params });
    }
  }

  private async onCheckoutSuccess() {
    if (this.platform.is('hybrid')) {
      await this.window.close();
    }
    window.focus();
    await this.dismissAlert();
    await this.dismissLoader();
    this.executor.executeWithLoader(async () => {
      await this.api.notifyStripePaymentSessionCompletion(this.stripeSessionId, this.accountId);
      this.params.paid = true;
      this.onClick();
      super.onChange();
    });
  }

  private async onCheckoutCancel() {
    if (this.platform.is('hybrid')) {
      await this.window.close();
    }
    window.focus();
    await this.dismissAlert();
    await this.dismissLoader();
    await this.cancelCheckout();
  }
}
