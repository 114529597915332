export default class TargetRole {
  concernedTargetType: 'user' | 'family' | 'household';
  hasResponsible: boolean;
  responsibleType: 'self' | 'responsible';

  constructor(json: any) {
    this.concernedTargetType = json.concernedTargetType;
    this.hasResponsible = json.hasResponsible;
    this.responsibleType = json.responsibleType;
  }
}
