import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {InputAddressComponent} from './input-address.component';
import {AutoCompleteModule} from '../../ionic2-auto-complete';
import {DirectivesModule} from '../../../directives/directives.module';
import {GoogleApiService} from '../../../services/google-api.service';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatInputModule,
    MatIconModule,
    TranslateModule.forChild(),
    AutoCompleteModule,
    ReactiveFormsModule
  ],
  declarations: [
    InputAddressComponent
  ],
  exports: [
    InputAddressComponent
  ]
})
export class InputAddressModule {
}
