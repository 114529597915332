import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { DeveloperService } from './developer.service';
import { App, AppLaunchUrl, URLOpenListenerEvent } from '@capacitor/app';
import { AutoLoginService } from './auto-login.service';
import { UserService } from './user.service';


const LAUNCH_URL_PATH_KEY: string = 'deepLinkPath';

const hardReloadWhiteList: string[] = [
  '/login',
  '/google-oauth-response',
];

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  public pendingRedirection: boolean = !!window.localStorage.getItem(LAUNCH_URL_PATH_KEY);
  public canRedirect: boolean = false;

  public get redirectionPath(): string {
    return window.localStorage.getItem(LAUNCH_URL_PATH_KEY);
  }

  public set redirectionPath(value: string) {
    let blacklisted: boolean = value && hardReloadWhiteList.some(item => this.getPath(value).startsWith(item));
    if (blacklisted) return;
    if (!value) window.localStorage.removeItem(LAUNCH_URL_PATH_KEY);
    else window.localStorage.setItem(LAUNCH_URL_PATH_KEY, value);
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private zone: NgZone,
              private autoLoginService: AutoLoginService,
              private loadingController: LoadingController,
              private devService: DeveloperService,
              private userProvider: UserService,
              private alertCtrl: AlertController,
              private plt: Platform,
  ) {
  }

  /**
   * Any time a link is clicked
   * Doesn't fire when the link opens the app on iOS
   */
  public listen(): void {
    App.addListener('appUrlOpen', async (data: URLOpenListenerEvent) => {
      return await this.execute(data.url);
    });
  }

  /**
   * When the app is opened with a link on iOS
   * Stores the link before app reloads
   */
  public async iosStoreLaunchUrl(): Promise<void> {
    if (!this.plt.is('ios')) return;
    let url: AppLaunchUrl = await App.getLaunchUrl();
    if (url?.url) {
      this.redirectionPath = url.url;
      return;
    }
  }

  public async execute(url?: string): Promise<void> {
    return this.goToDeepLink(url);
  }

  /**
   * Opens the redirection page
   */
  public async goToDeepLink(url?: string): Promise<void> {
    if (!url) return;
    let path: string = this.getPath(url);
    if (hardReloadWhiteList.some(item => path.startsWith(item))) {
      document.location.href = `${window.location.origin}${path}`;
    } else {
      this.redirectionPath = path;
      await this.router.navigate(['/deep-link-redirect'], { skipLocationChange: true });
    }
  }

  private getPath(url: string): string {
    if (!url) return;
    try {
      const checkedUrl: URL = new URL(url, window.origin);
      return checkedUrl.pathname + checkedUrl.search + checkedUrl.hash;
    } catch (e) {
      return url.split('.com').pop();
    }
  }

}
