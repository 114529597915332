import AMergeable from './a-mergeable';
import ConcernedUser from './concerned-user';
import {Dictionary} from 'lodash';

export default class ActionResponse extends AMergeable {
  concernedUser: ConcernedUser;
  concernedResponses: ActionsResponses[];
  status: string;

  constructor(json) {
    super();
    this.initialize(ConcernedUser, json.concernedUser, 'concernedUser');
    this.initialize(ActionsResponses, json.concernedResponses, 'concernedResponses');
    this.status = json.status;
  }

  public lastResponse(): ActionsResponses {
    return this.concernedResponses[this.concernedResponses.length - 1];
  }
}

export class ActionsResponses extends AMergeable {
  actionResponses: Dictionary<Dictionary<any>>;
  answeredBy: any;
  responseDate: string;

  constructor(json) {
    super();
    this.actionResponses = json.actionResponses || json.actionsResponses;
    this.answeredBy = json.answeredBy;
    this.responseDate = json.responseDate;
  }
}

export interface IJsonActivityResult {
  _id: string;
  activityId: string;
  concernedEntity: string;
  responsible: string;
  actionResponses: {
    [alias: string]: {
      [key: string]: any,
    };
  };
  responseDate: string;
}
