import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import AFormManager from '../../../models/a-form-manager';
import Section from '../../../models/section';
import Action from '../../../models/action';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'section-container',
  templateUrl: './section-container.component.html',
  styleUrls: ['./section-container.component.scss'],
})
export class SectionContainerComponent implements OnInit, OnChanges {
  @Input() public index: number;
  @Input() public manager: AFormManager;
  @Input() public section: Section;
  @Input() public selected: boolean;
  public display: boolean;
  public actionTuples: { action: Action, index: number }[];
  private enterDate: number;

  public get errorActions(): Action[] {
    return this.actionTuples.filter(({action}) => action.error).map(({action}) => action);
  }

  public get firstActionWithError(): Action {
    return this.errorActions.length > 0 ? this.errorActions[0] : undefined;
  }

  public ngOnInit(): void {

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.section && changes.section.previousValue !== changes.section.currentValue) {
      this.onSectionUpdate(changes.section.currentValue);
    }
    if (!changes.selected) {
      return;
    }
    this.display = changes.selected.currentValue;
  }

  onSectionUpdate(section: Section): void {
    this.actionTuples = section.actions.map((action, index) => ({action, index})).filter(tulple => tulple.action);
    section.controller = this;
  }

  public goToNext(): void {
    if (Date.now() - this.enterDate < 500) {
      return;
    }
    const success: boolean = this.selected && this.manager.goToNext();
    if (!success && this.firstActionWithError) {
      this.firstActionWithError.controller.focus();
    }
  }

  public goToPrev(): void {
    if (Date.now() - this.enterDate < 200) {
      return;
    }
    this.selected && this.manager.goToPrev();
  }

  /*
  public onEnterPressed(event: KeyboardEvent) : void {
      this.manager.onEnterPressed(event);
  } */

  public updateFiles(actionIndex: number, files: File[]): void {
    this.manager.updateFiles(this.index, actionIndex, files);
  }

  public onEnter(): void {
    this.enterDate = Date.now();
    this.section.onEnterEvent(this.manager);
  }

  public onLeave(): void {
    this.section.onLeaveEvent(this.manager);
  }

  trackByIndex(index: number, section: { section: Section; index: number }): number {
    return section.index;
  }
}
