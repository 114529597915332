<ng-container *ngIf="ios">
  <input type="file" (change)="addFile($event)" [accept]="acceptTypes" #fileInput
         class="ios-input">
  <ion-button class="fakeIosBtn">
    {{ fileName ?
    ("ACTIVITY.ACTION.UPLOAD.CHANGE_FILE" | translate) :
    ("ACTIVITY.ACTION.UPLOAD.CHOOSE_FILE" | translate) }}
  </ion-button>
</ng-container>
<ng-container *ngIf="!ios">
  <input type="file" (change)="addFile($event)" [accept]="acceptTypes" id="file-upload" hidden>
  <label id="file-upload-label" data-tap-disabled="true" for="file-upload" class="centered-block link-cursor">
    {{ fileName ?
    ("ACTIVITY.ACTION.UPLOAD.CHANGE_FILE" | translate) :
    ("ACTIVITY.ACTION.UPLOAD.CHOOSE_FILE" | translate) }}
  </label>
</ng-container>
<ng-container *ngIf="error">
  <p><span class="text-negative">{{error}}</span></p>
</ng-container>
