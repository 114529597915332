import {Component, Inject} from '@angular/core';
import {Platform} from '@ionic/angular';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {isSafariDesktop} from '../../../../utils';

@Component({
  selector: 'app-refresh-dialog',
  templateUrl: './refresh-dialog.component.html',
  styleUrls: ['./refresh-dialog.component.scss'],
})
export class RefreshDialogComponent {
  public title: string;
  public text: SafeHtml;
  public button: string;

  constructor(
    public plt: Platform,
    private matDialogRef: MatDialogRef<RefreshDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.text = this.sanitize(data.text);
    this.button = data.button || 'Refresh';
    this.title = data.title;
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public sanitize(text: string): SafeHtml {
    if (this.plt.is('ios') || isSafariDesktop) {
      return text;
    }
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
