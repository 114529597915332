import { Dictionary, groupBy } from 'lodash';
import AMergeable from '../a-mergeable';
import Activity from '../activity';
import LocalProviders from '../form/local-providers';
import ConcernedUser from '../concerned-user';
import AFormManager from '../a-form-manager';
import { ComponentFactory } from '@angular/core';
import Action from '../action';
import Section from '../section';
import { InputBaseController } from '../../components/form/input-controller';

export default class Builder extends AMergeable {
  public activity: Activity;
  public builder: Activity;
  public form: Form;
  public localProviders: LocalProviders;
  public manager: AFormManager;
  public concernedUser: ConcernedUser;
  public aliases: Dictionary<any> = {};
  public menuName: string;

  constructor(json: any) {
    super();
    this.menuName = json.menuName;
    this.initialize(Activity, json.activity, 'activity');
    this.activity.displayName = this.activity.displayName || this.menuName;
    this.initialize(Activity, json.builder, 'builder');
    this.form = json.form ? this.create(Form, json.form) : new Form({ files: [] } as Form);
    this.initialize(ConcernedUser, json.concernedUser, 'concernedUser');
    this.localProviders = this.create(LocalProviders, json.localProviders || {});
    this.generateAliases();
  }

  private fillBlankAliases(): void {
    const actionsDico: Dictionary<Action[]> = groupBy(this.activity.getActions().filter(a => !a.alias), a => a.name);
    Object.keys(actionsDico).forEach(key => {
      actionsDico[key].forEach((a, i) => a.alias = key + '-' + (i + 1));
    });
  }

  private generateAliases(): void {
    this.fillBlankAliases();
    const aliases: { alias: string }[] =
      new Array<{ alias: string }>()
        .concat(this.activity.getActions())
        .concat(this.activity.sections)
        .concat(this.builder && this.builder.getActions())
        .concat(this.builder && this.builder.sections)
        .filter(e => e && e.alias);
    aliases.forEach(e => {
      this.aliases[e.alias] = e;
    });
  }

  // similarity is check based on sections.length and actions.length
  // sufficient until we create section and action id
  public updateActionsFromSimilarBuilder(
    builder: Builder,
    actionComponentResolver: (actionName: any) => ComponentFactory<InputBaseController>
  ): void {
    if (this.activity.sections.length !== builder.activity.sections.length) {
      return;
    }
    this.activity.sections.forEach((section, i) => {
      const previousSection: Section = builder.activity.sections[i];
      if (!section || section.actions.length !== previousSection.actions.length) {
        return;
      }
      section.actions.forEach((action, j) => {
        const previousAction: Action = previousSection.actions[j];
        if (action && previousAction) {
          if (action.type === 'show') {
            return;
          }
          const componentFactory: ComponentFactory<InputBaseController> = actionComponentResolver(action.name);
          (componentFactory.componentType as any).responseKeys.forEach(responseKey => {
            action.params[responseKey] = previousAction.params[responseKey];
          });
        }
      });
    });
  }
}

class Form extends AMergeable {
  public files: any[][][];

  constructor(json: Form) {
    super();
    this.files = json.files;
  }
}
