import {cloneDeep, Dictionary, isArray} from 'lodash';
import AMergeable from './a-mergeable';
import Execution from './execution';

export default class DynValue extends AMergeable {
  public type: string;
  public value: any;
  public updateValue: UpdateValue;

  public static path(obj: any, path: string): any {
    const field: string[] = path.split('.').filter(v => v);
    for (let i: number = 0; i < field.length; i++) {
      obj = obj ? obj[field[i]] : null;
    }
    return obj;
  }

  public static CreateStatic(value: any): DynValue {
    return new DynValue({type: 'static', value});
  }

  public static CreatePath(path: string): DynValue {
    return new DynValue({type: 'path', value: path});
  }

  constructor(json: any) {
    super();
    if (typeof (json) === 'string') {
      this.type = 'auto';
      this.value = json === '' ? '\'\'' : json; // we'll assume that we don't want the root, but the dynvalue was not setup properly
      return;
    }
    // https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript/8511350#8511350
    this.value = cloneDeep(
      (typeof json === 'object' && json !== null && json.hasOwnProperty('value')) || json.type ?
        json.value :
        json
    );
    this.type = json.type || 'static';
    this.initialize(UpdateValue, json.updateValue, 'updateValue');
  }

  public parseUpdateValue(): DynValue {
    const matchs: RegExpExecArray = /^(([a-zA-Z]+:)+|)([\S\s]*)/.exec(this.value);
    const updates: string[] = matchs[1].split(':').filter(v => v);
    this.value = matchs[3];
    let currentUpdate: { updateValue: UpdateValue } = this;
    for (let i: number = updates.length - 1; i >= 0; i--) {
      currentUpdate.updateValue = new UpdateValue(updates[i]);
      currentUpdate = currentUpdate.updateValue;
    }
    return this;
  }

  public path(obj: any): any {
    // return DynValue.path(obj, this.value)
    const result: any = DynValue.path(obj, this.value);
    return result === null || result === undefined ? '' : result;
  }

  public valuesFromString(obj: any, values: string): any[] {
    return (this.value as string).split('+').map(v => {
      const val: string = v.trim();
      if (/^'[\S\s]*'$/.test(val)) {
        return DynValue.CreateStatic(val.substr(1, val.length - 2)).parseUpdateValue().execute(obj);
      }
      const result: any = DynValue.CreatePath(val).parseUpdateValue().execute(obj);
      return result === null || result === undefined ? '' : result;
    });
  }

  public auto(obj: any): string {
    const results: any[] = this.valuesFromString(obj, this.value);
    const result: any = results.length > 1 ? results.join('') : results[0];
    return result === null || result === undefined ? '' : result;
  }

  public concat(obj: any): string {
    return this.valuesFromString(obj, this.value).join('');
  }

  public static(obj: any): any {
    return this.value;
  }

  public execute(obj: any): any {
    const result: any = this[this.type](obj);
    return this.updateValue ? this.updateValue.execute(obj, result) : result;
  }
}


export class UpdateValue extends AMergeable {
  public type: string;
  public params: Dictionary<DynValue>;
  public updateValue: UpdateValue;

  constructor(json: any) {
    super();
    if (typeof (json) === 'string') {
      this.type = json;
      this.params = {};
      return;
    }
    this.type = json.type;
    this.updateValue = this.create(UpdateValue, json.updateValue);
    this.params = this.createDictionary(DynValue, json.params) || {};
  }

  public objectFormat(obj: any, valueToUpdate: any): any {
    const newValues: { newKey: string, newValue: string }[] = this.params.newFormat.execute(obj);
    let format: any = {};
    for (let i: number = 0; i < newValues.length; i++) {
      let value: any = new DynValue(newValues[i].newValue).execute(valueToUpdate);
      let key: any = new DynValue(newValues[i].newKey).execute(valueToUpdate);
      Execution.writeValue(format, key, value);
    }
    return format;
  }

  public add(obj: any, valueToUpdate: number): number {
    const firstNumber: DynValue = new DynValue(this.params.numbers.execute(obj));
    valueToUpdate += firstNumber.execute(obj);
    return valueToUpdate;
  }

  public addToSet(obj: any, valueToUpdate: any[] | any): any[] {
    const item: any = this.params.item.execute(obj);
    const array: any[] = isArray(valueToUpdate) ? valueToUpdate : [];
    return array.filter(v => v !== item).concat(item);
  }

  public toUpper(obj: any, valueToUpdate: string): string {
    return valueToUpdate.toUpperCase();
  }

  public toLower(obj: any, valueToUpdate: string): string {
    return valueToUpdate.toLowerCase();
  }

  public join(obj: any, valueToUpdate: any[]): string {
    let separator: string = this.params.separator ? this.params.separator.execute(obj) : ' ';
    return valueToUpdate.map(e => this.toString(obj, e)).join(separator);
  }

  public substract(obj: any, valueToUpdate: number): number {
    let firstNumber: DynValue = new DynValue(this.params.numbers.execute(obj));
    valueToUpdate -= firstNumber.execute(obj);
    return valueToUpdate;
  }

  public replace(obj: any, valueToUpdate: string): string {
    let replaceWith: string = this.params.replaceWith.execute(obj);
    let regex: RegExp = new RegExp(this.params.regex.value, 'g');
    return valueToUpdate.replace(regex, replaceWith);
  }

  public toJsonString(obj: any, valueToUpdate: any): string {
    return JSON.stringify(valueToUpdate);
  }

  public fromJsonString(obj: any, valueToUpdate: any): string {
    return JSON.parse(valueToUpdate);
  }

  public toInt(obj: any, valueToUpdate: any): number {
    return parseInt(valueToUpdate, 10);
  }

  public toString(obj: any, valueToUpdate: any): string {
    return valueToUpdate.toString();
  }

  public concat(obj: any, valueToUpdate: any): string {
    let values: DynValue[] = this.params.values.execute(obj);
    values.forEach(v => valueToUpdate += v.execute(obj));
    return valueToUpdate;
  }

  public path(obj: any, valueToUpdate: any): any {
    return DynValue.path(obj, valueToUpdate);
  }

  public toStaticDyn(obj: any, valueToUpdate: any): DynValue {
    return DynValue.CreateStatic(valueToUpdate);
  }

  public toPathDyn(obj: any, valueToUpdate: any): DynValue {
    return DynValue.CreatePath(valueToUpdate);
  }

  public getValue(obj: any, valueToUpdate: DynValue): any {
    return valueToUpdate && valueToUpdate.execute(obj);
  }

  private arrayApply(obj: any, valueToUpdate: any[]): any {
    return valueToUpdate.map(e => this.updateValue.execute(obj, e));
  }

  public execute(obj: any, valueToUpdate: any): any {
    let result: any;
    if (this.type.endsWith('[]')) {
      let newUpdate: UpdateValue = new UpdateValue({type: this.type.slice(0, -2), params: this.params});
      result = (valueToUpdate as any[]).map(r => newUpdate.execute(e => obj, r));
    } else if (this[this.type]) {
      result = this[this.type](obj, valueToUpdate);
    } else {
      result = valueToUpdate;
    }
    return this.updateValue ? this.updateValue.execute(obj, result) : result;
  }
}

