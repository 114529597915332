import {NgModule} from '@angular/core';
import {PullDownDirective} from './pull-down.directive';
import {PullUpDirective} from './pull-up.directive';
import {InappLinkDirective} from './inapp-link.directive';
import {FocusOnInitDirective} from './focus-on-init.directive';
import {BpOnInitDirective} from './bp-on-init.directive';
import { ScrollbarThemeDirective } from './scrollbar-theme.directive';
import { ConvertCordovaAppLinksDirective } from './convert-cordova-app-links.directive';
import { HideAboveLGIfNotPublicUserDirective } from './hide-above-lgif-not-public-user.directive';

@NgModule({
  declarations: [
    BpOnInitDirective,
    FocusOnInitDirective,
    PullDownDirective,
    PullUpDirective,
    InappLinkDirective,
    ScrollbarThemeDirective,
    ConvertCordovaAppLinksDirective,
    HideAboveLGIfNotPublicUserDirective,
  ],
  imports: [],
  exports: [
    BpOnInitDirective,
    FocusOnInitDirective,
    PullDownDirective,
    PullUpDirective,
    InappLinkDirective,
    ScrollbarThemeDirective,
    ConvertCordovaAppLinksDirective,
    HideAboveLGIfNotPublicUserDirective,
  ]
})
export class DirectivesModule {
}
