import {Component} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {MatDialogRef} from '@angular/material/dialog';

export interface IDescriptionModalParameters {
  title: string;
  subTitle?: string;
  content: string;
}

@Component({
  selector: 'app-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: ['./description-modal.component.scss'],
})
export class DescriptionModalComponent {

  infos: IDescriptionModalParameters;

  constructor(
    private matDialogRef: MatDialogRef<DescriptionModalComponent>,
    public params: NavParams,
  ) {
    this.infos = params.data as IDescriptionModalParameters;
  }

  dismiss() {
    this.matDialogRef.close();
  }

}
