import {Component, Injector, ViewContainerRef} from '@angular/core';
import {InputBaseController} from '../../input-controller';
import {FileLoaderService, ILoader} from '../../../../services/file-loader.service';
import {TranslateService} from '@ngx-translate/core';
import Action from '../../../../models/action';

interface ILoadFromFileParams {
  profile: string;
  fileType: string;
  fileName?: string;
  data?: any;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-load-from-file',
  templateUrl: './action-load-from-file.component.html',
  styleUrls: ['./action-load-from-file.component.scss'],
  providers: [FileLoaderService]
})
export class ActionLoadFromFileComponent extends InputBaseController {

  public static className: string = 'action-load-from-file';
  public static readonly responseKeys: string[] = [];
  public params: ILoadFromFileParams = {
    profile: 'SCSS_CLASSES',
    fileType: '.xlsx',
  };

  error: string;
  profileName: string;
  loader: ILoader<any>;

  constructor(
    public injector: Injector,
    public viewContainerRef: ViewContainerRef,
    public translate: TranslateService,
    private fileLoader: FileLoaderService,
  ) {
    super(injector, viewContainerRef);
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.loader = this.fileLoader.getLoader(this.params.profile);
    this.profileName = this.loader.name;
  }

  public async addFile(fileInput: any): Promise<void> {
    let files: FileList = (fileInput.target as HTMLInputElement).files;
    let file: File = files[0];
    if (!file) {
      return;
    }
    try {
      const fileContent: string = await this.fileLoader.loadFileContent(file);
      console.dir(this.loader);
      this.params.data = await this.loader.load(fileContent);
      console.dir(this.params.data);
      // const schedule = sheetClassesToSchedule(data);
      this.params.fileName = file.name;
      this.error = undefined;
    } catch (e) {
      console.warn(e);
      this.error = this.translate.instant('ACTIVITY.ACTION.LOAD_FROM_FILE.LOAD_ERROR');
      this.params.fileName = undefined;
    }
    this.onChange();
  }

}
