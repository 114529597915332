import {Injectable, OnDestroy} from '@angular/core';
import Builder from '../models/API/builders';
import {objectsDifference} from '../../utils';
import Execution from '../models/execution';
import Activity from '../models/activity';
import _ from 'lodash';
import {merge, Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AlertController} from '@ionic/angular';
import {CognitoAuthService} from './cognito-auth.service';
import {BuilderRefService} from './builder-ref.service';
import {ActivitiesSocketIOService} from './socket.service';
import {SocketEvent} from './socket-event.enum';
import {TimerService} from './timer.service';
import { Socket } from 'socket.io-client';

@Injectable()
export class RealTimeActivityService implements OnDestroy {

  public static connectedProviders: any[] = [];

  private unsubscribe$: Subject<any> = new Subject();

  public get activityFilter(): (message: ISocketMessage) => boolean {
    return (message: ISocketMessage) => message.activityId === this.localBuilder.activity.id;
  }

  public get connected$(): Observable<any> {
    return this.socketService.onEvent$(SocketEvent.CONNECT).pipe(takeUntil(this.unsubscribe$));
  }

  public get reconnected$(): Observable<any> {
    return this.socketService.onEvent$(SocketEvent.RECONNECT).pipe(takeUntil(this.unsubscribe$));
  }

  public get disconnected$(): Observable<any> {
    return this.socketService.onEvent$(SocketEvent.DISCONNECT).pipe(takeUntil(this.unsubscribe$));
  }

  public get authenticated$(): Observable<ISocketMessage> {
    return this.socketService.onMessage$<ISocketMessage>().pipe(takeUntil(this.unsubscribe$)).pipe(filter(m => m.action === 'authenticated'));
  }

  public get activitySocketMessage$(): Observable<ISocketMessage> {
    return this.socketService.onMessage$<ISocketMessage>().pipe(takeUntil(this.unsubscribe$)).pipe(filter(this.activityFilter));
  }

  public get userActivitySocketMessage$(): Observable<ISocketMessage> {
    return this.activitySocketMessage$.pipe(filter(m => m.concernedUserId === this.localBuilder.concernedUser.id));
  }

  public get initialActivityStateUpdates$(): Observable<ISocketMessage> {
    return this.userActivitySocketMessage$
      .pipe(filter(m => m.action === 'initialActivityStateUpdates'));
  }

  public get activityStateUpdates$(): Observable<ISocketMessage> {
    return this.activitySocketMessage$.pipe(filter(m => m.action === 'activityStateUpdates'));
  }

  public get activityStateUpdatesConfirmation$(): Observable<ISocketMessage> {
    return this.userActivitySocketMessage$
      .pipe(filter(m => m.action === 'activityStateUpdatesConfirmation'));
  }

  public get activityStateUpdatesCancellation$(): Observable<ISocketMessage> {
    return this.userActivitySocketMessage$
      .pipe(filter(m => m.action === 'activityStateUpdatesCancellation'));
  }

  public get activityStateUpdatesReload$(): Observable<ISocketMessage> {
    return this.activitySocketMessage$.pipe(filter(m => m.action === 'activityStateUpdatesReload'));
  }

  private _initialActivityStateUpdatedSubject: Subject<ISocketMessage> = new Subject();

  public get initialActivityStateUpdated$(): Observable<ISocketMessage> {
    return this._initialActivityStateUpdatedSubject.asObservable();
  }

  private _activityStateUpdatedSubject: Subject<ISocketMessage> = new Subject();

  public get activityStateUpdated$(): Observable<ISocketMessage> {
    return this._activityStateUpdatedSubject.asObservable();
  }

  private _activityStateReloadedSubject: Subject<ISocketMessage> = new Subject();

  public get activityStateReloaded$(): Observable<ISocketMessage> {
    return this._activityStateReloadedSubject.asObservable();
  }

  public get timerStart$(): Observable<ISocketMessage> {
    return this.userActivitySocketMessage$
      .pipe(filter(m => m.action === 'timerStart'));
  }

  public get timerCompletion$(): Observable<ISocketMessage> {
    return this.userActivitySocketMessage$
      .pipe(filter(m => m.action === 'timerCompletion'));
  }

  public get timerExtension$(): Observable<ISocketMessage> {
    return this.userActivitySocketMessage$
      .pipe(filter(m => m.action === 'timerExtension'));
  }

  // public get testActivitiesSocket(): TestActivitiesSocketIOService {
  // 	return this.socketService as TestActivitiesSocketIOService;
  // }

  private requestId: number = 0;
  public pendingActivityStateUpdatesRequests: { message: ISocketMessage, revertDiff: any }[] = [];
  public appliedActivityStateUpdatesRequests: { message: ISocketMessage, revertDiff: any }[] = [];

  private _ready: boolean = false;
  public get ready(): boolean {
    return this._ready;
  }

  public localBuilder: Builder;

  constructor(
    public timer: TimerService,
    public socketService: ActivitiesSocketIOService,
    public builderRef: BuilderRefService,
    private cognitoAuth: CognitoAuthService,
    private alertCtrl: AlertController,
    public translate: TranslateService,
  ) {
    this.builderRef.builder$.pipe(takeUntil(this.unsubscribe$)).subscribe((builder: Builder) => {
      if (!this.localBuilder) {
        this.localBuilder = new Builder(builder);
      } else {
        this.appliedActivityStateUpdatesRequests.forEach(appliedRequest => {
          const jsonExecutions: any[] = appliedRequest.message.data.map(jsonExecution => ({
            ...jsonExecution,
            conditions: undefined,
          }));
          this.updateActivity(jsonExecutions);
        });
      }
    });

    this.authenticated$.subscribe(() => {
      if (this.localBuilder.activity.socket.timer) {
        this.timer.seconds = this.localBuilder.activity.socket.timer.duration;
        this.timer.show();
      }
      this.socketService.send(this.generateMessage('activityConnectRequest', {
        activityId: this.builderRef.builder.activity.id,
        concernedUserId: this.builderRef.builder.concernedUser.id,
      }));
    });

    this.disconnected$.subscribe(() => {
      this._ready = false;
      this.reset();
      this._activityStateReloadedSubject.next();
    });

    this.initialActivityStateUpdates$.subscribe((message: ISocketMessage) => {
      this.applyServerInitiatedActivityStateUpdates(message);
      this._ready = true;
      this._initialActivityStateUpdatedSubject.next(message);
    });

    this.activityStateUpdates$.subscribe((message: ISocketMessage) => {
      this.applyServerInitiatedActivityStateUpdates(message);
      this._activityStateUpdatedSubject.next(message);
    });

    this.activityStateUpdatesConfirmation$.subscribe((message: ISocketMessage) => {
      const confirmedRequest: { message: ISocketMessage, revertDiff: any } = this.pendingActivityStateUpdatesRequests.find(item => {
        return item.message.id === message.id;
      });

      if (!confirmedRequest) {
        console.warn('activityStateUpdatesConfirmation not found', message.id);
        return;
      }

      // revert all pendings
      const followingRequests: { message: ISocketMessage; revertDiff: any }[] = this.pendingActivityStateUpdatesRequests
        .slice()
        .filter(r => r.message.id !== message.id);
      this.pendingActivityStateUpdatesRequests.slice().reverse().forEach(request => this.applyDiff(request.revertDiff, this.localBuilder));
      this.pendingActivityStateUpdatesRequests = [];

      // apply the confirmed one without keeping track of it
      this.updateActivity(confirmedRequest.message.data, this.localBuilder);

      // reapply all the others
      followingRequests.forEach(request => this.applyActivityStateUpdatesRequest(request.message));

      const revertDiff: object = this.updateActivity(message.data);
      this.appliedActivityStateUpdatesRequests.push({message, revertDiff});
      this._activityStateUpdatedSubject.next(message);
    });

    this.activityStateUpdatesCancellation$.subscribe((message: ISocketMessage) => {
      // need to revert all requests following the cancelled one and then reapply them
      const requestIndex: number = this.pendingActivityStateUpdatesRequests.findIndex(req => req.message.id === message.id);
      if (requestIndex < 0) {
        // an initialActivityStateUpdate was received and did reset the pending requests
        console.log('activityStateUpdatesCancellation not found', message.id);
        return;
      }
      const followingRequests: { message: ISocketMessage; revertDiff: any }[] = this.pendingActivityStateUpdatesRequests.slice(requestIndex + 1);
      const updateRequest: {message: ISocketMessage, revertDiff: any} = this.pendingActivityStateUpdatesRequests[requestIndex];
      followingRequests.slice().reverse().forEach(req => {
        this.applyDiff(req.revertDiff, this.localBuilder);
      });
      this.applyDiff(updateRequest.revertDiff, this.localBuilder);

      this.pendingActivityStateUpdatesRequests = this.pendingActivityStateUpdatesRequests.slice(0, requestIndex);
      followingRequests.forEach(req => this.applyActivityStateUpdatesRequest(req.message));
      this._activityStateUpdatedSubject.next(message);
    });

    this.activityStateUpdatesReload$.subscribe((message: ISocketMessage) => {
      // need to revert all requests and resend them, but let's just reset everything for now
      this.reset();

      this.socketService.send(this.generateMessage('activityConnectRequest', {
        activityId: this.builderRef.builder.activity.id,
        concernedUserId: this.builderRef.builder.concernedUser.id,
      }));
      this._activityStateReloadedSubject.next(message);
    });

    this.timerStart$.subscribe((message: ISocketMessage) => {
      const duration: number = message.data.duration;
      this.timer.seconds = duration;
      if (this.timer.running) {
        return;
      }
      this.timer.resume();
    });

    this.timerExtension$.subscribe((message: ISocketMessage) => {
      const duration: number = message.data.duration;
      this.timer.pause();
      this.timer.seconds = duration;
      this.timer.resume();
    });

    this.timerCompletion$.subscribe((message: ISocketMessage) => {
      this.openTimerCompletionBox();
    });

    merge(this.activityStateUpdated$, this.activityStateReloaded$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message: ISocketMessage) => {
        this.builderRef.builder.activity.socket.onActivityUpdated.forEach(e => e.execute(this.builderRef.builder));
      });

    this.initialActivityStateUpdated$.pipe(takeUntil(this.unsubscribe$)).subscribe((message: ISocketMessage) => {
      this.builderRef.builder.activity.socket.onInitialActivityUpdated.forEach(e => e.execute(this.builderRef.builder));
    });

    if (this.builderRef.builder.manager.mode !== 'preview') {
      this.connect();
    }
  }

  ngOnDestroy(): void {
    RealTimeActivityService.connectedProviders = RealTimeActivityService.connectedProviders.filter(p => p !== this);
    if (!RealTimeActivityService.connectedProviders.length) {
      this.socketService.disconnect();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async connect(): Promise<void> {
    const oldSocket: Socket = this.socketService.socket;
    const cognitoAccessToken: string = await this.cognitoAuth.getCurrentAccessJwtToken();
    const socket: Socket = this.socketService.initSocket(cognitoAccessToken);

    // using pre-existing connection
    if (oldSocket === socket) {
      this.socketService.send(this.generateMessage('activityConnectRequest', {
        activityId: this.builderRef.builder.activity.id,
        concernedUserId: this.builderRef.builder.concernedUser.id,
      }));
    }

    RealTimeActivityService.connectedProviders.push(this);
  }

  public requestUpdates(...executions: Execution[]): ISocketMessage {
    const message: ISocketMessage = this.generateMessage('activityStateUpdatesRequest', executions);
    this.socketService.send(message);

    // execute locally (without condition for test purpose)
    this.applyActivityStateUpdatesRequest(message);
    return message;
  }

  private applyServerInitiatedActivityStateUpdates(message: ISocketMessage): void {
    // revert all pending requests
    this.pendingActivityStateUpdatesRequests.slice().reverse().forEach(request => this.applyDiff(request.revertDiff, this.localBuilder));

    // apply upcoming update
    this.updateActivity(message.data, this.localBuilder);

    // reapply any pending requests
    const followingRequests: {message: ISocketMessage, revertDiff: any}[] = this.pendingActivityStateUpdatesRequests.slice();
    this.pendingActivityStateUpdatesRequests = [];
    followingRequests.forEach(request => this.applyActivityStateUpdatesRequest(request.message));

    const revertDiff: object = this.updateActivity(message.data);
    this.appliedActivityStateUpdatesRequests.push({message, revertDiff});
  }

  private applyActivityStateUpdatesRequest(message: ISocketMessage): void {
    const revertDiff: object = this.updateActivity(message.data, this.localBuilder);
    this.pendingActivityStateUpdatesRequests.push({message, revertDiff});
  }

  private applyDiff(diff: any, dest: any): void {
    const customizer = (value: any, srcValue: any, key: string, object: any, source: any) => {
      if (_.isObject(value) && _.isObject(srcValue)) {
        return _.mergeWith(value, srcValue, customizer);
      }
      if (_.isObject(object) && !_.isArray(object) && _.isObject(source) && !_.isArray(source) && srcValue === undefined) {
        return object[key] = srcValue;
      }
    };
    _.mergeWith(dest, diff, customizer);
  }

  private updateActivity(jsonExecutions: any[], builder?: Builder): object {
    const beforeSnapshot: object = _.cloneDeep(builder || {activity: new Activity(this.builderRef.builder.activity)} as Builder);
    jsonExecutions.forEach(jsonExecution => {
      const execution: Execution = new Execution(jsonExecution);
      if (builder) {
        execution.execute(builder);
      } else {
        this.builderRef.builder.manager.execute(execution);
      }
    });
    return objectsDifference(beforeSnapshot, builder || {activity: new Activity(this.builderRef.builder.activity)} as Builder);
  }

  private reset(): void {
    this.appliedActivityStateUpdatesRequests.slice().reverse().forEach(request => {
      this.applyDiff(request.revertDiff, this.builderRef.builder);
    });
    this.localBuilder = new Builder(this.builderRef.builder);

    this.pendingActivityStateUpdatesRequests = [];
    this.appliedActivityStateUpdatesRequests = [];
  }

  private generateMessage(action: string, data?: any): ISocketMessage {
    return {
      action,
      id: this.requestId++,
      activityId: this.localBuilder.activity.id,
      concernedUserId: this.localBuilder.concernedUser.id,
      data,
    };
  }

  private async openTimerCompletionBox(): Promise<void> {
    await (await this.alertCtrl.create({
      message: 'Reservation period elapsed. Would you like to try again?',
      backdropDismiss: false,
      buttons: [{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          this.timer.hide();
        },
      }, {
        text: this.translate.instant('RETRY'),
        handler: () => {
          this.connect();
        },
      }],
    })).present();
  }
}

export interface ISocketMessage {
  action: string;
  id: number;
  activityId: string;
  concernedUserId: string;
  data: any;
}

class TestClass {
  public constructor(public params: any) {
  }
}
