import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TimeZoneSelectComponent} from './time-zone-select.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
  ],
  declarations: [
    TimeZoneSelectComponent,
  ],
  exports: [
    TimeZoneSelectComponent,
  ]
})
export class TimeZoneSelectModule {
}
