import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, PRIMARY_OUTLET } from '@angular/router';
import { UserService } from '../services/user.service';
import { take, filter } from 'rxjs/operators';
import AuthenticatedUser from '../models/user';
import { AutoLoginService, ILoginOutput } from '../services/auto-login.service';
import { ExpiredTokenStorageService } from '../services/expired-token-storage.service';

function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public autoLoginProcessedOnce: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private autoLoginService: AutoLoginService,
    private expiredTokenStorage: ExpiredTokenStorageService,
  ) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const url: string = state.url;

    // try to autologin only once among different possible guard calls
    console.debug('AuthGuard canActivate');
    if (!this.autoLoginProcessedOnce || url.includes('token=')) {
      console.debug('AuthGuard await isOngoing');
      const isOngoing: boolean = !(await this.autoLoginService.isComplete$.pipe(take(1)).toPromise());
      console.debug('AuthGuard isOngoing', isOngoing);
      if (isOngoing) {
        await this.autoLoginService.isComplete$.pipe(filter(v => v), take(1)).toPromise();
      }
      console.debug('AuthGuard after isOngoing await');
      if (!isOngoing || url.includes('token=')) {
        const loginOutput: ILoginOutput = await this.autoLoginService.autoLogin(undefined, url);
        this.autoLoginProcessedOnce = true;
        console.debug('loginOutput', loginOutput);

        if (loginOutput?.redirectUrlTree) {
          console.debug('loginOutput redirectUrlTree segments', loginOutput.redirectUrlTree.root.children[PRIMARY_OUTLET].segments);
          this.expiredTokenStorage.info = loginOutput.redirectState;
          const serializedRedirectUrl = this.router.serializeUrl(loginOutput.redirectUrlTree);
          console.debug('serialized redirect url', serializedRedirectUrl);
          try {
            const redirectUrl = new URL(serializedRedirectUrl, window.location.origin);
            this.router.navigate(
              [redirectUrl.pathname],
              {
                ...redirectUrl.hash && { fragment: redirectUrl.hash.replace('#', '') },
                queryParams: paramsToObject(redirectUrl.searchParams),
                state: loginOutput.redirectState
              }
              // { state: loginOutput.redirectState }, // too unreliable, does not work on native app for some reason
            );
          } catch (e) {
            console.error(e);
          }
          return false;
        }
        if (url.includes('token=') && !loginOutput?.user) return false;
      }
    }

    const authenticatedUser: AuthenticatedUser = await this.userService.currentUserAfterPendingLoad$.pipe(
      take(1),
    ).toPromise();
    if (!authenticatedUser) {
      this.router.navigate(['/login'], {
        state: {
          redirect: url
        }
      });
      return false;
    }
    return true;
  }

}
