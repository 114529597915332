import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiUrlService } from '../api-url.service';
import _ from 'lodash';
import { delay } from '../../../utils';

@Injectable({
  providedIn: 'root'
})
export class QuillImagesUploaderService {

  public quillEditorToUploadingImages: Map<any, { uploadingImages: any[] }> = new Map();

  constructor(
    private apiService: ApiService,
    private apiUrlService: ApiUrlService,
  ) { }

  public async uploadBase64Img(base64Str: string): Promise<string> {
    if (typeof base64Str !== 'string' || base64Str.length < 100) {
      return base64Str;
    }

    //https://stackoverflow.com/a/36183085
    const blob: Blob = await (await fetch(base64Str)).blob();

    // Create form data
    const formData: FormData = new FormData();
    formData.append('file', blob);

    const paths: string[] = await this.apiService.request('POST', `Activities/upload-file`, formData);

    const imgUrl: string = `${this.apiUrlService.apiUrl}Activities/ressource?filePath=${encodeURIComponent(_.first(paths))}`;
    return imgUrl;
  }

  public async processImage(quill: any, img: Element): Promise<void> {
    // inspired from https://github.com/kensnyder/quill-image-resize-module/blob/master/src/ImageResize.js
    const loaderOverlay: HTMLDivElement = document.createElement('div');
    loaderOverlay.classList.add('img-loader-overlay');
    loaderOverlay.innerHTML = '<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
    quill.editor.root.parentNode.appendChild(loaderOverlay);

    // draw 2 times because it's messed up (scrollbar late draw apparently)
    this.repositionElements(quill, img, loaderOverlay);
    await delay(10);
    this.repositionElements(quill, img, loaderOverlay);

    const changeHandler = () => {
      this.repositionElements(quill, img, loaderOverlay);
    };

    quill.editor.on('text-change', changeHandler);
    (quill.editor.root as HTMLDivElement).addEventListener('scroll', changeHandler);

    try {
      const imgApiSrc: string = await this.uploadBase64Img(img.getAttribute('src'));
      img.setAttribute('src', imgApiSrc);
    } catch (e) {
      console.error(e);
      img.setAttribute('src', 'blank');
    }

    quill.editor.off('text-change', changeHandler);
    (quill.editor.root as HTMLDivElement).removeEventListener('scroll', changeHandler);
    quill.editor.root.parentNode.removeChild(loaderOverlay);
  }

  // inspired from
  // https://github.com/quilljs/quill/issues/1089#issuecomment-613640103
  // tslint:disable-next-line: cyclomatic-complexity
  public async extractAndUploadImages(quill: any): Promise<void> {
    if (quill.delta.ops) {
      for (const deltaOp of quill.delta.ops) {
        if (
          deltaOp.hasOwnProperty('insert')
          && deltaOp.insert.hasOwnProperty('image')
        ) {
          const imgs: Element[] = Array.from(
            (quill.editor.root as HTMLDivElement).querySelectorAll('img[src^="data:"]:not(.loading)')
          );

          const quillUploadingImagesArrayContainer: { uploadingImages: any[] } =
            this.quillEditorToUploadingImages.get(quill.editor) || { uploadingImages: [] };
          if (!this.quillEditorToUploadingImages.has(quill.editor)) {
            this.quillEditorToUploadingImages.set(quill.editor, quillUploadingImagesArrayContainer);
          }
          quillUploadingImagesArrayContainer.uploadingImages = [
            ...quillUploadingImagesArrayContainer.uploadingImages,
            ...imgs
          ];

          for (const img of imgs) {
            img.classList.add('loading');
          }

          for (const img of imgs) {
            await this.processImage(quill, img);
          }

          for (const img of imgs) {
            img.classList.remove('loading');
          }

          quillUploadingImagesArrayContainer.uploadingImages = _.without(quillUploadingImagesArrayContainer.uploadingImages, ...imgs);
          if (quillUploadingImagesArrayContainer.uploadingImages.length === 0) {
            this.quillEditorToUploadingImages.delete(quill.editor);
          }
        }
      }
    }
  }

  private repositionElements(quill: any, img: Element, loaderOverlay: HTMLDivElement): void {
    const parent: Element = quill.editor.root.parentNode;
    const imgRect: DOMRect = img.getBoundingClientRect();
    const containerRect: DOMRect = parent.getBoundingClientRect();

    Object.assign(loaderOverlay.style, {
      left: `${imgRect.left - containerRect.left - 1 + parent.scrollLeft}px`,
      top: `${imgRect.top - containerRect.top + parent.scrollTop}px`,
      width: `${imgRect.width}px`,
      height: `${imgRect.height}px`,
    });

    loaderOverlay.style.setProperty('--size', `${Math.min(imgRect.width, imgRect.height) * 0.8}px`);
  }
}
