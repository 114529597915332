import {Component, Injector, OnInit, ViewContainerRef} from '@angular/core';
import {ExtendedAutocompletePrediction, GoogleApiService} from '../../../../services/google-api.service';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import Action from '../../../../models/action';
import DynValue from '../../../../models/dyn-value';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, Platform, ToastController} from '@ionic/angular';
import {InputBaseController} from '../../input-controller';
import {Dictionary} from 'lodash';
import {AutoCompleteComponent} from '../../../ionic2-auto-complete';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-address',
  templateUrl: './action-address.component.html',
  styleUrls: ['./action-address.component.scss'],
  providers: [GoogleApiService],
})
export class ActionAddressComponent extends InputBaseController implements OnInit {

  public static className: string = 'action-address';
  public static readonly responseKeys: string[] = [
    'formattedAddress',
    'placeId',
    'additionalDetails',

    'source',
    'rawAddress',

    'isPhysical',
    'isHome',
    'isMailing'];

  public params: {
    addressType: string, // place/home/mailing
    map: boolean,

    formattedAddress: string, // "12 golden Hind psg, corte madera, CA 94340, USA"
    placeId: string,
    additionalDetails: string,

    rawAddress: string[],     // ["12 golden Hind psg", "corte madera", "CA 94340", "USA"]
    source: string,    // "google" | "user"

    isPhysical: boolean,
    isHome: boolean,
    isMailing: boolean,

    textSearch: string,
  };

  public placeholders: { [key: string]: string } = {
    street_address: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.STREET_ADDRESS'),
    apt: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.APT'),
    locality: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.LOCALITY'),
    region: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.STATE'),
    postalcode: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.ZIP'),
    country: this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.COUNTRY')
  };

  public inputs: IUserInputAddress;

  public autoCompletePlaceholder: string;
  public showSearch: boolean = true;
  public showEdit: boolean = this.manager.mode === 'view';
  public inputDisable: boolean = !(this.manager.mode === 'view');
  public matcher: MyErrorStateMatcher;

  public view: string;

  public form: FormGroup = new FormGroup({
    street_address: new FormControl(
      {value: '', disabled: (this.manager.mode !== 'view')},
      [Validators.required]),
    apt: new FormControl(
      {value: '', disabled: (this.manager.mode !== 'view')},
      []),
    locality: new FormControl(
      {value: '', disabled: (this.manager.mode !== 'view')},
      [Validators.required]),
    region: new FormControl(
      {value: '', disabled: (this.manager.mode !== 'view')},
      [Validators.required]),
    postalcode: new FormControl(
      {value: '', disabled: (this.manager.mode !== 'view')},
      [Validators.required])
  });

  public firstInputDisplay: boolean = true;

  constructor(
    public injector: Injector,
    public viewContainerRef: ViewContainerRef,
    public googleApi: GoogleApiService,
    public platform: Platform,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public translate: TranslateService
  ) {
    super(injector, viewContainerRef);
  }

  protected initAction(action: Action): void {
    super.initAction(action);
    this.googleApi.emptyItemText = this.translate.instant('ACTIVITY.ACTION.ADDRESS.CANNOT_FIND.TITLE');
    this.googleApi.emptyItemSubText = this.translate.instant('ACTIVITY.ACTION.ADDRESS.CANNOT_FIND.SUBTITLE');
    this.autoCompletePlaceholder = this.placeholder || this.translate.instant('ACTIVITY.ACTION.ADDRESS.PLACEHOLDER.SEARCHBAR');

    switch (this.params.addressType) {
      case 'home':
        this.params.isHome = true;
        this.googleApi.queryFilter = ['street_address', 'premise', 'subpremise'];
        this.googleApi.emptyItem = true;
        break;

      case 'mailing':
        this.params.isMailing = true;
        this.showSearch = false;
        this.showEdit = false;
        this.params.placeId = '';
        break;
    }
    this.parseFormattedAddress();
  }

  public parseFormattedAddress(): void {
    if (!this.params.formattedAddress) {
      this.params.formattedAddress = '';
    }

    this.params.rawAddress = this.params.formattedAddress.split(',');
    if (this.params.rawAddress.length > 1) {
      this.showSearch = false;
    }

    const street_address: string = (this.params.rawAddress[0] || '').trim();
    const locality: string = (this.params.rawAddress[1] || '').trim();
    const region: string = ((this.params.rawAddress[2] || '').trim().split(' ')[0]) || '';
    const postalcode: string = ((this.params.rawAddress[2] || '').trim().split(' ')[1]) || '';
    const country: string = (this.params.rawAddress[3] || '').trim();

    this.inputs = {
      street_address,
      locality,
      region,
      postalcode,
      country
    };
  }

  public parseInputs(): void {
    this.params.rawAddress = [
      this.inputs.street_address,
      this.inputs.locality,
      this.inputs.region + ' ' + this.inputs.postalcode,
      this.inputs.country
    ];
    this.params.formattedAddress = this.params.rawAddress.join(',');
  }

  public selectOption(event: ExtendedAutocompletePrediction): void {
    if (!event.place_id || event.place_id === '') {
      this.selectUserInput();
      return;
    } else {
      this.selectPlaceId(event.place_id);
    }
  }

  private selectPlaceId(placeId: string): void {
    this.googleApi.getAddressDetails(placeId)
      .then((result) => {
        this.params.placeId = result.place_id;
        this.params.formattedAddress = result.formatted_address;

        this.params.isPhysical = true;
        this.showSearch = false;
        this.params.source = 'google';

        this.parseFormattedAddress();
        this.onChange();
      }).catch((error: any) => {
      console.error(error);
    });
  }

  private selectUserInput(): void {
    this.params.placeId = '';
    this.showSearch = false;
    this.params.isPhysical = false;
  }

  /*
      private loadPlaceId(locationDescription: string): void {
          this.googleApi.getLocalityPredictions(locationDescription)
              .then((result: ExtendedAutocompletePrediction[]) => {
                  this.loadPlaceInfos(result[0].place_id);
              }).catch((error: any) => {
                  console.error(error);
              });
      }
  */

  public sourceUserUpdate(): void {
    this.params.source = 'user';
    this.params.placeId = '';
    this.parseInputs();
    this.onChange();
  }

  public onClickIonCard(): void {
    if (this.type === 'show' && this.params.placeId !== '') {
      window.open('https://maps.google.com/?q=' + this.params.formattedAddress, '_system');
    }
  }

  public onClickEdit(): void {
    this.firstInputDisplay = false;
    this.showSearch = true;
    this.params.placeId = '';
    this.params.source = '';
    this.params.textSearch = this.inputs.street_address;
  }

  public validation(): string {
    if (this.manager.mode !== 'view' || this.type === 'show') {
      return super.validation();
    }

    if (this.params.addressType === 'mailing' ||
      (this.params.addressType === 'home' && this.params.source === 'user')) {
      if (this.inputs.street_address === '' ||
        this.inputs.locality === '' ||
        this.inputs.region === '' ||
        this.inputs.postalcode === '') {
        this.matcher = new MyErrorStateMatcher();
        return this.translate.instant('ACTIVITY.ACTION.ADDRESS.MISSING_INFO');
      }
    }
    return super.validation();
  }

  public onSuggestionClick(event: any, autoCompleteCtrl: AutoCompleteComponent, selectionData: any): void {
    this.stopEvent(event);
    autoCompleteCtrl.select(selectionData);
  }

  public stopEvent(event: Event): void {
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  public static getCsvResponses(getValue: (dyn: DynValue) => any, action: Action): Dictionary<any> {
    let csvAddress: any = getValue(action.params.formattedAddress) || '';
    let csvAddressDetails: any = getValue(action.params.additionalDetails) || '';
    return {
      Address: csvAddress,
      AdditionalDetails: csvAddressDetails
    };
  }
}

export interface IUserInputAddress {
  street_address: string;
  locality: string;
  region: string;
  postalcode: string;
  country: string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.enabled));
  }
}
