import {Injectable} from '@angular/core';
import AFormManager from '../models/a-form-manager';
import {Dictionary, fromPairs} from 'lodash';
import Activity from '../models/activity';
import Condition from '../models/condition';
import Section from '../models/section';
import Validation from '../models/validation';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor() {
  }

  public getResponses(manager: AFormManager): Dictionary<Dictionary<any>> {
    let getValueBind: any = manager.getValue.bind(manager);
    let actionsInput: any[] = manager.getActivity().getActions().filter(
      action => (
        action.type === 'input' ||
        (action.type === 'show' && action.displayCSV)
      )
    );
    let responses: string[];
    return fromPairs(actionsInput.map(a => [a.alias, a.getResponse(getValueBind)]));
  }

  public removeValidations(activity: Activity): void {
    let sections: Section[] = activity.sections;
    let protectedContainers: { validations: Validation[] }[] = activity.getActions().concat(activity.getActions());
    for (let i: number = 0; i < protectedContainers.length; i++) {
      delete protectedContainers[i].validations;
    }
  }

  public isDisabled(obj: any, conditions: Condition[][]): boolean {
    return Condition.isValid(obj, conditions);
  }

  public mailsFormating(description: string): string {
    if (!description) {
      return '';
    }

    // remove &nbsp;
    description = description.replace(/&nbsp;/g, ' ');

    // replace raw email by <a href="mailto:$1">$1</a>
    // (excluding stuff inside any <a>)
    // https://stackoverflow.com/questions/12493128/regex-replace-text-but-exclude-when-text-is-between-specific-tag
    const mailReplace: RegExp = /(([\w\.-]+)@(([\w-]+\.)+\w+))(?!(.(?!<a))*<\/a>)/g;
    description = description.replace(mailReplace, '<a href="mailto:$1">$1</a>');

    // add 'mailto:' to href raw email
    const mailTranform: RegExp = /<a ([^>]*)href="\s*(([\w\.-]+)@((\w+.)+\w+))\s*"([^>]*)>(.*?(?=<\/a>))<\/a>/g;
    description = description.replace(mailTranform, '<a $1href="mailto:$2">$7</a>');

    // remove target="*"
    description = description.replace(/<a ([^>]*href="mailto:[^"]*"[^>]*)(target="[^"]*")([^>]*)>/g, '<a $1$3>');
    return description;
  }

  public hyperlinkFormating(description: string): string {
    if (!description) {
      return;
    }
    const linkTransform: RegExp = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/g;
    description = description.replace(linkTransform, '<a href="$&">$&</a>');
    description = description.replace(/href="(?!mailto:|https?:\/\/)([^"]*)"/g, 'href="http://$1"');
    return description;
  }
}
