import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Platform} from '@ionic/angular';
import {isSafariDesktop} from '../../../../utils';

@Component({
  selector: 'app-display-disclaimer',
  templateUrl: './display-disclaimer.component.html',
  styleUrls: ['./display-disclaimer.component.scss'],
})
export class DisplayDisclaimerComponent {
  public text: SafeHtml;
  public rawText: string;

  constructor(
    public plt: Platform,
    private matDialogRef: MatDialogRef<DisplayDisclaimerComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.rawText = data.text;
    // this.text = this.sanitize(data.text);
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public sanitize(text: string): SafeHtml {
    if (this.plt.is('ios') || isSafariDesktop) {
      return text;
    }
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
