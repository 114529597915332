<date-time-picker
  [disabled]="type == 'show' || manager.mode === 'review'"
  [placeholder]="placeholder"
  [(date)]="params.selectedDate"
  (onClose)="updateDateTime()"
  [min]="params.minDate"
  [max]="params.maxDate"
  [timeZone]="params.timeZone || previousTimeZone"
>
</date-time-picker>
<time-zone-select [input]="type === 'input'" [(timeZone)]="params.timeZone" (timeZoneChange)="onTimeZonesSelect($event)"></time-zone-select>
