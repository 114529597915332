import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AUTH_CONFIG} from './auth.config';
import {CognitoAuthService} from '../../services/cognito-auth.service';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AuthModule {
  /**
   * Initialize the authentication module system. Configuration can either be specified here,
   * or by calling AuthClientConfig.set (perhaps from an APP_INITIALIZER factory function).
   * @param config The optional configuration for the SDK.
   */
  static forRoot(config?: AuthOptions): ModuleWithProviders<any> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_CONFIG,
          useValue: config,
        },
        CognitoAuthService,
      ],
    };
  }
}
