import {IEnv} from './environment.typing';

export const environment: IEnv = {
  PRODUCTION: false,
  NAME: 'Experimental',
  URL: 'https://app.qa.actionaly.com',
  API_URL: 'https://api.qa.actionaly.com',
  SOCKET_URL: 'https://socket.qa.actionaly.com',
  ACTIVITY_SOCKET_URL: 'https://activity-socket.qa.actionaly.com',
  ACTIVITY_SOCKET_PATH: '/socket.io',
  ONE_SIGNAL_APP_ID: 'bef29486-41ce-48d4-9198-afc220da2ff5',
  GOOGLE_APP_KEY: 'AIzaSyDXOXW175FagZZPNgi_YueZX67pjDEZhs0',
  PAY_THEORY_JS_URL: 'https://actionaly.sdk.paytheorylab.com/index.js',
  STRIPE_PUBLISH_KEY: 'pk_test_uW8PBdFdLCUqMMJZoc241Nh5',
  STREAM_API_KEY: 'sf43bhvfwvhn',
  GG4L_SSO: {
    GG4L_API_BASE_URL: 'https://sso.gg4l.com',
    GG4L_TOKEN_API_BASE_URL: 'https://api.qa.actionaly.com/api/gg4l',
    GG4L_WEBSITE_BASE_URL: 'https://schoolpassport.gg4l.com',
    // GG4L_WEBSITE_BASE_URL: 'https://sso.gg4l.com',
    GG4L_TOKEN_ISSUER: 'oauth.edutone.com',
  },
  ADMIN_URL: 'https://admin.qa.actionaly.com',
  COGNITO: {
    USER_POOL_ID: 'us-west-2_XEwLNSuFw',
    USER_POOL_WEB_CLIENT_ID: '4nspsti1djq2cu1aj3ose9mkeq',
    REGION: 'us-west-2',
    ENDPOINT: 'https://api.qa.actionaly.com/api/cognito-proxy',
    OAUTH: {
      DOMAIN: 'qa.auth.actionaly.com',
      ENDPOINT: `https://api.qa.actionaly.com/api/oauth2-cognito-proxy`,
    },
  },
  CLASSLINK: {
    SSO: {
      CLIENT_ID: 'c16763003543732a774e42da952659dc39c90d0409a8',
    }
  }
};
