import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OneTimeTokenInvalidatorService {


  constructor(
    public apiService: ApiService,
  ) {
  }

  public async consume(token: string): Promise<{ consumed: boolean; }> {
    const response: { consumed: boolean; } = await this.apiService.request(
      'POST',
      'magic-link-auth/consume',
      {token},
      undefined,
      false,
    );
    return response;
  }
}
