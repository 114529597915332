import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class VirtualPathRedirectService {

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationStart) {
        if (!event.url) return;
        const interpretedUrl: URL = new URL(event.url, window.location.origin);
        const virtualPath: string = interpretedUrl.searchParams.get('virtualPath');
        if (!virtualPath) return;
        // console.debug('virtualPath', virtualPath);
        interpretedUrl.searchParams.delete('virtualPath');
        const queryParams = _.transform([...interpretedUrl.searchParams.entries()], (acc, [key, value]) => acc[key] = value);
        // console.debug('queryParams', JSON.stringify(queryParams));
        await this.router.navigate(virtualPath.split('/'), { 
          queryParams,
          queryParamsHandling: 'merge' // does not 'preserve' params for some reason, so we reinject it
         });
      }
    });
  }
}
