import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {IonicModule} from '@ionic/angular';
import {DatePickerComponent} from './date-picker.component';
import {DirectivesModule} from '../../../directives/directives.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatInputModule,
  ],
  declarations: [
    DatePickerComponent
  ],
  exports: [
    DatePickerComponent
  ]
})
export class DatePickerModule {
}
