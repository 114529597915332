import { Injectable } from '@angular/core';
import { PodForPodsListRole } from '../models/user-roles';
import { distinctUntilChanged, filter, first, map, switchMap, take, shareReplay, tap, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { BehaviorSubject, from, Observable, of, EMPTY } from 'rxjs';
import { UserService } from './user.service';

export interface PodCommunity {
  _id: string;
  timeZone: string;
  name: string;
  planName: string;
  voiceEnabled: boolean;
  chatEnabled: boolean;
}

export interface PodForPodsList {
  id: string;
  creationDate: string;
  communityId: string;
  communities: PodCommunity[];
  subTitle: string;
  name: string;
  tags: any[];
  roles: PodForPodsListRole[];
}

@Injectable({
  providedIn: 'root'
})
export class PodsService {

  private loading: boolean = false;

  private podsSubject: BehaviorSubject<PodForPodsList[]> = new BehaviorSubject<PodForPodsList[]>(undefined);
  private resetSubject: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(undefined);

  public pods$: Observable<PodForPodsList[]> = this.resetSubject.pipe(
    switchMap(() =>
      this.podsSubject.asObservable().pipe(
        switchMap((pods) => {
          if (pods === undefined && !this.loading) {
            return from(this.loadPods()).pipe(catchError(error => EMPTY));
          }
          return of(pods);
        }),
        filter(pods => pods !== undefined),
        shareReplay(),
      )
    )
  );

  public get pods(): Promise<PodForPodsList[]> {
    return this.pods$.pipe(first()).toPromise();
  }

  public get followedPods$(): Observable<PodForPodsList[]> {
    return this.pods$.pipe(
      map(pods => pods.filter((pod) => {
        return pod.roles.indexOf('AUTHORITIES') !== -1 || pod.roles.indexOf('TARGETABLES') !== -1 || pod.roles.indexOf('PASSIVES') !== -1;
      }))
    );
  }

  public get managedPods$(): Observable<PodForPodsList[]> {
    return this.pods$.pipe(
      map(pods => pods.filter((pod) => pod.roles.indexOf('OWNER') !== -1))
    );
  }

  constructor(
    public apiService: ApiService,
    private userService: UserService,
  ) {
    this.userService.validCurrentUserUntilIdChange$.subscribe(async (u) => {
      this.reset();
    });
  }

  public async loadPods(): Promise<PodForPodsList[]> {
    try {
      this.loading = true;
      const res: PodForPodsList[] = await this.getPods();
      this.podsSubject.next(res);
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.loading = false;
    }
  }

  public reset(): void {
    this.podsSubject.next(undefined);
    this.resetSubject.next(undefined);
  }


  public pod(podId: string): Promise<PodForPodsList> {
    return this.pod$(podId).pipe(first()).toPromise();
  }

  public pod$(podId: string): Observable<PodForPodsList> {
    return this.pods$.pipe(map(pods => pods.find(pod => pod.id === podId))).pipe(take(1));
  }

  private async getPods(): Promise<PodForPodsList[]> {
    return this.apiService.request('GET', 'Pods');
  }

}
