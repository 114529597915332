<mat-radio-group [disabled]="type == 'show'">
  <mat-radio-button
    *ngFor="let option of params.options"
    [value]="option"
    [class.active]="option.selected"
    class="overlay-button-container"
    [checked]="option.selected"
    [disabled]="manager.mode === 'review'"
  >
    <button class="overlay-button" (click)="onRadioClick($event, option)" [disabled]="manager.mode === 'review'">
    </button>
    {{option.keyName | bbcodeTranslation: bbcodeTranslator.redraw }}
  </mat-radio-button>
</mat-radio-group>
