import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {i18n} from './i18n';
import {CognitoAuthService} from './cognito-auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UnexpectedErrorDataContainerService } from '../pages/unexpected-error/services/unexpected-error-data-container/unexpected-error-data-container.service';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorHandlerService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private cognitoAuth: CognitoAuthService,
    private unexpectedErrorDataContainer: UnexpectedErrorDataContainerService,
  ) {
    this.apiService.error$.subscribe(error => this.handleApiError(error));
    this.cognitoAuth.cognitoSessionError$.subscribe(error => this.handleAuthError(error));

    // error 500 helper, to delete when testing successful
    (window as any).triggerError500 = () => {
      this.apiService.getCurrentResponse('fersdfgv')
    }
  }

  private async handleApiError(error: any): Promise<void> {
    console.debug('api error', error);
    switch (error.status) {
      case 403:
        await this.cognitoAuth.logoutAndRedirect(i18n('LOGIN.SESSION_EXPIRED'));
        break;
      case 500:
        this.unexpectedErrorDataContainer.urlBeforeError = window.location.pathname + window.location.search + window.location.hash;
        await this.router.navigate(['unexpected-error']);
        break;
      case 503:
        await this.cognitoAuth.logoutAndRedirect(i18n('LOGIN.MAINTENANCE'));
        break;
      case 418:
        await this.cognitoAuth.logoutAndRedirect(i18n('LOGIN.REGISTRATION_BUG'));
        break;
      default:
        break;
    }
  }

  private async handleAuthError(error: any): Promise<void> {
    console.debug('auth error', error);

    if (window.location.hash === '#/recover') {
      return await this.cognitoAuth.logout();
    }

    if (typeof (error) === 'string') {
      if (error === 'No current user') {
        return await this.cognitoAuth.logoutAndRedirect(i18n('LOGIN.SESSION_EXPIRED'));
      }
      if (error === 'not authenticated') {
        return await this.cognitoAuth.logoutAndRedirect();
      }
    }
    if (error instanceof Error) {
      if (error.message === 'Cannot retrieve a new session. Please authenticate.') {
        return await this.cognitoAuth.logoutAndRedirect(i18n('LOGIN.SESSION_EXPIRED'));
      }
    }
    switch (error.code) {
      case 'NotAuthorizedException':
        await this.cognitoAuth.logoutAndRedirect(i18n('LOGIN.SESSION_EXPIRED'));
        break;
      default:
        break;
    }
  }
}
