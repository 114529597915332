import {Dictionary} from 'lodash';
import DynValue from './dyn-value';
import AMergeable from './a-mergeable';
import Builder from './API/builders';

export default class Condition extends AMergeable {

  public type: string;
  public params: Dictionary<DynValue> = {};

  constructor(json: any) {
    super();
    this.type = json.type;
    Object.keys(json.params || {}).forEach(key => {
      this.params[key] = this.create(DynValue, json.params[key]);
    });
  }

  public static isValid(rootObject: any, conditions: Condition[][], defaultResult: boolean = false): boolean {
    if (!conditions) {
      return defaultResult;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let or: number = 0; or < conditions.length; or++) {
      for (let and: number = 0; and < conditions[or].length; and++) {
        if (!conditions[or][and].eval(rootObject)) {
          break;
        }
        if (and === conditions[or].length - 1) {
          return true;
        }
      }
    }
    return false;
  }

  public getParam(obj: Builder, key: string): any {
    const dyn: DynValue = this.params[key];
    return dyn && dyn.execute(obj);
  }

  public getParams(obj: Builder): Dictionary<any> {
    const ret: Dictionary<any> = {};
    Object.keys(this.params).forEach(key => {
      ret[key] = this.params[key].execute(obj);
    });
    return ret;
  }

  public regex(obj: any): boolean {
    const regex: RegExp | string = this.getParam(obj, 'regex');
    let text: string = this.getParam(obj, 'value');
    text = (typeof text !== 'string') ? JSON.stringify(text) : text;
    return new RegExp(regex).test(text);
  }

  public doesExist(obj: Builder): boolean {
    return !!this.getParam(obj, 'value');
  }

  public lessThan(obj: any): boolean {
    return Number(this.getParam(obj, 'value')) < Number(this.getParam(obj, 'comparator'));
  }

  public moreThan(obj: any): boolean {
    // debugger;
    // const valueParam = this.getParam(obj, "value");
    // const comparatorParam = this.getParam(obj, "comparator");
    // const result = Number(valueParam) > Number(comparatorParam);
    // return result;
    return Number(this.getParam(obj, 'value')) > Number(this.getParam(obj, 'comparator'));
  }

  public equal(obj: any): boolean {
    // debugger;
    // const valueParam = this.getParam(obj, "value");
    // const comparatorParam = this.getParam(obj, "comparator");
    // const result = valueParam == comparatorParam;
    // return result;
    return this.getParam(obj, 'value') === this.getParam(obj, 'comparator');
  }

  public notEqual(obj: any): boolean {
    return this.getParam(obj, 'value') !== this.getParam(obj, 'comparator');
  }

  public invert(obj: any): boolean {
    return !(this.getParam(obj, 'value') as boolean);
  }

  public eval(obj: any): boolean {
    return (this as any)[this.type](obj);
  }
}
