import { Injectable } from '@angular/core';
import { CancellablePromise, delay } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class MainLoaderHandlerService {

  private _removalPromise: CancellablePromise<void>;

  constructor() {
  }

  public cancelRemoval(): void {
    if (this._removalPromise) {
      let loader: HTMLDivElement = document.getElementById('loader') as HTMLDivElement;
      loader.classList.remove('disappear');
      this._removalPromise.cancel();
    }
  }

  public async remove(): Promise<void> {
    let loader: HTMLDivElement = document.getElementById('loader') as HTMLDivElement;
    if (!loader) {
      return;
    }
    try {
      await (this._removalPromise = delay(800));
      loader.classList.add('disappear');
      await delay(300);
    } catch (e) {
      this._removalPromise = undefined;
      if (e.message === 'Cancelled') {
        return;
      }
    }
    this._removalPromise = undefined;
    loader.remove();
  }

}
