import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import Builder from '../models/API/builders';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BuilderRefService {
  private builderSubject: BehaviorSubject<Builder> = new BehaviorSubject(undefined);

  public get builder$(): Observable<Builder> {
    return this.builderSubject.asObservable().pipe(filter(b => !!b));
  }

  public get builder(): Builder {
    return this.builderSubject.getValue();
  }

  constructor() {
    // console.log('Hello BuilderRefProvider Provider');
  }

  public setBuilder(builder: Builder): void {
    // console.log('setBuilder')
    this.builderSubject.next(builder);
  }
}
