import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'button-arrow',
  templateUrl: './button-arrow.component.html',
  styleUrls: ['./button-arrow.component.scss'],
})
export class ButtonArrowComponent {
  @Output() public next: EventEmitter<void> = new EventEmitter<void>();
  @Output() public prev: EventEmitter<void> = new EventEmitter<void>();

  public goToNext(): void {
    this.next.emit();
  }

  public goToPrev(): void {
    this.prev.emit();
  }
}
