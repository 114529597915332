import {Component, OnDestroy} from '@angular/core';
import {ActionStripeComponent, ActionStripeParams} from '../action-stripe/action-stripe.component';
import Action from '../../../../models/action';

export interface ActionStripeFeesParams extends ActionStripeParams {
  feesAdded: boolean;
  fixFees: number;
  percentFees: number;
  preFeesAmount: number;
  checkboxText: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-stripe-fees',
  templateUrl: './action-stripe-fees.component.html',
  styleUrls: ['./action-stripe-fees.component.scss'],
})
export class ActionStripeFeesComponent extends ActionStripeComponent implements OnDestroy {
  public static className: string = 'action-stripe-fees';
  public static readonly responseKeys: string[] = ['paid', 'feesAdded', 'preFeesAmount', 'amount', 'currency'];
  public params: ActionStripeFeesParams;

  protected initAction(action: Action): void {
    super.initAction(action);
    this.params.feesAdded = this.params.feesAdded || false;
    this.params.percentFees = this.params.percentFees || 0;
    this.params.fixFees = this.params.fixFees || 0;
    this.calculateFees({checked: this.params.feesAdded});
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public calculateFees(e): void  {
    this.params.feesAdded = e.checked;
    if (e.checked) {
      this.addFees();
    } else {
      this.subFees();
    }
    super.onChange();
  }

  public round(x: number): number {
    return Math.round(x * 100) / 100;
  }

  public addFees(): void  {
    this.params.amount = this.round(
      (this.params.preFeesAmount + this.params.fixFees) / (1 - (this.params.percentFees / 100))
    );
  }

  public subFees(): void  {
    this.params.amount = this.params.preFeesAmount;
  }
}
